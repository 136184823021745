import React from 'react';
import { Tooltip, Button } from 'antd';
import { IDocumentData } from '../../../models/riskAssessment';
import styles from './DocumentList.module.css';

export const DocumentActionIcon: React.FC<IDocumentActionIconProps> = ({ toolTip, disabled, icon, onClick }) => {
  return (
    <Tooltip placement="top" title={toolTip}>
      <Button type="primary" disabled={disabled} className={styles.actionButton} shape="circle" size="small" onClick={() => onClick()}>
        {icon}
      </Button>
    </Tooltip>
  );
};

interface IDocumentActionIconProps {
  onClick: () => void;
  toolTip: string;
  disabled: boolean;
  icon: any;
}
