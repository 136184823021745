import img403 from './img/403.svg';
import img404 from './img/404.svg';
import img500 from './img/500.svg';

interface IExceptionPageTypeConfig {
  img: string;
  title: string;
  desc: string;
}

const getExceptionPageConfig = (type: string): IExceptionPageTypeConfig => {
  switch (type) {
    case '403':
      return {
        img: `url(${img403})`,
        title: '403',
        desc: 'Access denied. Please contact your administrator.',
      };
    case '404':
      return {
        img: `url(${img404})`,
        title: '404',
        desc: 'The page you were looking for was not found.',
      };
    case '500':
      return {
        img: `url(${img500})`,
        title: '500',
        desc: 'An internal error occured. Please try again later.',
      };
  }
};

export default getExceptionPageConfig;
