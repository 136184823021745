import React, { FC, CSSProperties, useState, useCallback, useEffect } from 'react';
import { Comment, Button, Drawer, Timeline } from 'antd';

import styles from './CommentHistory.module.css';
import { useTranslation } from 'react-i18next';
import { IRiskComment, createRiskComment } from '../../../models/IRiskComment';
import _ from 'lodash';
import formatter from '../../formatter';
import { FormikProps, FieldArray, getIn, FieldArrayRenderProps } from 'formik';
import FormBox from '../FormBox/FormBox';
import TextArea from 'antd/lib/input/TextArea';
import nameof from 'ts-nameof.macro';
import { getFirstCommentText } from '../../../riskAssessments/riskAssessment/formhelper';
import { HistoryOutlined } from '@ant-design/icons';

export const FormCommentWithHistory: FC<IFormCommentWithHistoryProps> = ({ formik, pathToComments, disabled, style, title, required, dataAutomationId }) => {
  const values = getIn(formik.values, pathToComments);
  return (
    <FieldArray
      name={pathToComments}
      render={(arrayHelpers) => (
        <CommentWithHistory title={title} style={style} arrayHelper={arrayHelpers} comments={values} disabled={disabled} required={required} dataAutomationId={dataAutomationId} />
      )}
    />
  );
};

const CommentWithHistory: FC<ICommentWithHistoryProps> = React.memo(
  ({ comments, disabled, arrayHelper, title, required, dataAutomationId }) => {
    const [t] = useTranslation();
    const [showDrawer, setShowDrawer] = useState(false);
    const [comment, setComment] = useState(getFirstCommentText(comments));
    const firstCommentObj = _.first(comments);
    useEffect(() => setComment(getFirstCommentText(comments)), [comments]);
    let infoTitle = title;

    if (firstCommentObj) {
      if (firstCommentObj.isNew) {
        infoTitle = `${infoTitle} (${t('common.changed')})`;
      } else {
        infoTitle = `${infoTitle} (${formatter.formatDateString(firstCommentObj.created)}, ${firstCommentObj.user})`;
      }
    }

    const onCommentChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        const comment = getOrAddComment(comments, arrayHelper);
        comment.content = value;
        setComment(value);
        arrayHelper.replace(0, comment);
      },
      [comments],
    );

    useEffect(() => {
      const currentComment = _.get(comments, '[0]') as IRiskComment;
      const prevComment = _.get(comments, '[1]') as IRiskComment;

      if (currentComment && currentComment.isNew && prevComment && currentComment.content == prevComment.content) {
        arrayHelper.remove(0);
      }
    }, [comment]);

    return (
      <FormBox requiredIndicator={required} title={infoTitle} flex>
        <div className={styles.commenthistory}>
          <div className={styles.actioncontainer}>
            <Button title={t('components.AddCommentPanel.hint')} size="small" shape="circle" icon={<HistoryOutlined />} onClick={() => setShowDrawer(true)} />
          </div>
          <div>
            <TextArea disabled={disabled} rows={4} onChange={onCommentChange} value={comment} data-automation-id={dataAutomationId || 'textarea-comment'} />
          </div>
          <CommentHistoryPanel visible={showDrawer} onCancel={() => setShowDrawer(false)} comments={comments} />
        </div>
      </FormBox>
    );
  },
  (prev, next) => {
    return prev.comments === next.comments && prev.disabled === next.disabled && prev.title === next.title;
  },
);

CommentWithHistory.displayName = 'CommentWithHistory';

function getOrAddComment(comments: IRiskComment[], arrayHelper: FieldArrayRenderProps) {
  const first = _.first(comments);
  if (first && first.isNew) {
    return first;
  }
  const comment = createRiskComment();
  arrayHelper.unshift(comment);
  return comment;
}

const CommentList: FC<{ comments: IRiskComment[]; style?: CSSProperties }> = ({ comments, style }) => {
  return (
    <div className={styles.commentlist} style={style}>
      <Timeline>
        {_.orderBy(comments, ['created'], ['desc']).map((c, index) => (
          <Timeline.Item key={index}>
            <Comment author={c.user} content={c.content} datetime={formatter.formatDateWithTimeString(c.created)} />
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

const CommentHistoryPanel: FC<{ visible: boolean; onCancel: () => void; comments: IRiskComment[] }> = ({ visible, onCancel, comments }) => {
  const [t] = useTranslation();
  const hasComments = !_.isEmpty(comments);

  if (!visible) {
    return null;
  }

  return (
    <Drawer placement="right" visible={visible} width={500} title={t('components.AddCommentPanel.header')} onClose={onCancel}>
      {hasComments && <CommentList comments={comments} />}
      {!hasComments && <NoComments />}
    </Drawer>
  );
};

const NoComments: FC<{}> = (props) => {
  const [t] = useTranslation();
  return <div className={styles.nocomments}>{t('common.noEntries')}</div>;
};

interface IFormCommentWithHistoryProps {
  title: string;
  style?: CSSProperties;
  disabled?: boolean;
  formik: FormikProps<any>;
  pathToComments: string;
  required?: boolean;
  dataAutomationId?: string;
}

interface ICommentWithHistoryProps {
  title: string;
  comments?: IRiskComment[];
  disabled?: boolean;
  arrayHelper: FieldArrayRenderProps;
  style?: CSSProperties;
  required?: boolean;
  dataAutomationId?: string;
}
