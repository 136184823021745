import React, { PureComponent, FC, useState, useEffect } from 'react';
import { IUserEditViewModel, IBranchOfIndustryRef, ITenantRef, IDepartmentRefWithTenantAndBranch, ICanDeleteResult } from '../models/user';
import { WithTranslation, withTranslation, useTranslation } from 'react-i18next';
import { HtcCardWrapper, HtcCard } from '../shared/HtcCardComponent';
import { Button, Alert, Select, Drawer, Spin, message } from 'antd';
import { FormTextLabel, FormRadioYesNo } from '../shared/FormComponents';
import * as _ from 'lodash';
import { Formik, FormikHelpers } from 'formik';

import styles from './UserEditForm.module.css';
import { TFunction } from 'i18next';
import FormBox from '../shared/components/FormBox/FormBox';
import { userService } from '../shared/services/userService';
import { TenantConfigurations } from './TenantConfigurations';
import { ITenantInfo } from '../models/masterdata/ITenant';
import { UserPermissions } from '../models';
import { SaveAction, CancelAction, OpenDrawer, DeleteAction } from '../shared/components/CommandBar/CommandBar';
import { getForLanguage } from '../models/IMultiLanguageString';
import { UserInfoCtx } from '../UserInfoContext';
import { LanguageSettings } from '../models/LanguageSettings';
import { withRouter, RouteComponentProps } from 'react-router';
import { notifySaveSuccess, notifyError } from '../shared/notificationHelper';
import { MasterdataHeader } from '../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../shared/components/CommandBar/CommandBarV2';
import { DeleteOutlined, LoadingOutlined, CopyOutlined } from '@ant-design/icons';

const Option = Select.Option;
const navPaths = ['navigation.users'];

class UserEditForm extends PureComponent<IUserEditFormProps, IUserEditFormState> {
  state: IUserEditFormState = {
    user: null,
    selectedCopyUserId: '',
    copyClaimsDrawerVisible: false,
    deleteUserDrawerVisible: false,
  };

  static getDerivedStateFromProps(props: IUserEditFormProps, state: IUserEditFormState) {
    if ((state.user && state.user.id) !== (props.user && props.user.id)) {
      return {
        user: Object.assign({}, props.user),
      };
    }
    if (state.user && props.user && !_.isEqual(state.user.assignedTenants, props.user.assignedTenants)) {
      return {
        user: Object.assign({}, props.user),
      };
    }
    return null;
  }

  private handleSave = (user: IUserEditViewModel, actions: FormikHelpers<IUserEditViewModel>) => {
    this.props.saveEditUser(user);
    actions.setSubmitting(false);
  };

  private handleUpdateAdData = async () => {
    const tr = getTranslateFunc(this.props.t);
    let hide = message.loading(tr('notifications.synchronizingUserDataWithAd'));
    let adUserData = await userService.getUserAdData(this.state.user.providerSubjectId);
    hide();

    if (!adUserData) {
      message.info(tr('notifications.noDataFound'));
    }

    let currentUser = this.state.user;
    if (currentUser.email != adUserData.mail || currentUser.name != adUserData.displayName) {
      let newUser = _.cloneDeep(currentUser);
      newUser.email = adUserData.mail;
      newUser.name = adUserData.displayName;
      this.setState({ user: newUser });
      message.success(tr('notifications.dataUpdated'));
    } else {
      message.success(tr('notifications.noDataFound'));
    }
  };

  render() {
    const { t, cancelEditUser: cancelEdit, allTenants, assignableTenants, assignableBranchesOfIndustry, assignableDepartments, permissions, users } = this.props;
    const tr = getTranslateFunc(t);
    const userOptions = _.sortBy(users, (u) => u.name);
    const nav = Object.assign([], navPaths);
    nav.push(this.state.user.name);

    return (
      <div className={styles.contentwrapper}>
        <Formik enableReinitialize={true} initialValues={this.state.user} onSubmit={this.handleSave}>
          {(formik) => {
            const { values, submitForm } = formik;
            return (
              <>
                <MasterdataHeader>
                  <CommandBarV2 paths={nav}>
                    <OpenDrawer onClick={this.showCopyClaimsDrawer} text={tr('copyClaims')} icon={<CopyOutlined />} />
                    <DeleteAction onClick={this.showDeleteUserDrawer} />
                    <CancelAction onClick={cancelEdit} />
                    <SaveAction onClick={submitForm} />
                  </CommandBarV2>
                </MasterdataHeader>
                <div className={styles.content}>
                  <HtcCardWrapper>
                    <HtcCard>
                      <FormBox title={tr('common')}>
                        <FormTextLabel {...formik} name="name" label={tr('name')} />
                        <FormTextLabel {...formik} name="email" label={tr('email')} />
                        {permissions.security.canSetUserActiveState && <FormRadioYesNo {...formik} name="isActive" label={tr('isActive')} />}
                        {permissions.security.canChangeAppAdministratorState && <FormRadioYesNo {...formik} name="isAppAdministrator" label={tr('isAppAdministrator')} />}
                        <Button style={{ margin: '0.5rem 0' }} onClick={this.handleUpdateAdData}>
                          Update User from Active Directory
                        </Button>
                        {this.getNoTenantAlert(values)}
                        {this.getBranchOfIndustryAlerts(values)}
                        {this.getDepartmentAlerts(values, this.context.languageSettings)}
                      </FormBox>
                    </HtcCard>

                    <TenantConfigurations
                      formik={formik}
                      t={t}
                      assignableTenants={assignableTenants}
                      assignableBranchesOfIndustry={assignableBranchesOfIndustry}
                      assignableDepartments={assignableDepartments}
                      allTenants={allTenants}
                      permissions={permissions}
                    />
                  </HtcCardWrapper>
                </div>
              </>
            );
          }}
        </Formik>
        <DeleteUserDrawer user={this.state.user} onClose={() => this.setState({ deleteUserDrawerVisible: false })} onDelete={this.onDeleteUser} visible={this.state.deleteUserDrawerVisible} />
        <Drawer title={tr('copyClaims')} placement="right" closable={true} onClose={this.onClose} visible={this.state.copyClaimsDrawerVisible}>
          <div className={styles.drawerContent}>
            <Select
              onChange={this.onSelect}
              key={'copyClaims'}
              placeholder={t('users.userEditForm.selectUser')}
              showSearch
              optionFilterProp="title"
              optionLabelProp="title"
              filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {userOptions.map((user) => (
                <Option value={user.id} key={user.id} title={user.name}>
                  <div className={styles.firstLine}>{user.name}</div>
                  <div className={styles.secondLine}>{user.email || '-'}</div>
                </Option>
              ))}
            </Select>
            <Button style={{ marginTop: '1rem' }} icon={<LoadingOutlined />} onClick={this.loadClaimsForUser}>
              {tr('copyClaimsSubmit')}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }

  showCopyClaimsDrawer = () => this.setState({ copyClaimsDrawerVisible: true });

  showDeleteUserDrawer = () => this.setState({ deleteUserDrawerVisible: true });

  onDeleteUser = async () => {
    const tr = getTranslateFunc(this.props.t);
    this.setState({ deleteUserDrawerVisible: false });

    const spinner = message.loading(tr('userDeletionInProgress'), 0);
    const success = await userService.deleteUser(this.state.user.id);
    spinner();

    if (success) {
      notifySaveSuccess('userDeletionSucceeded');
      this.props.cancelEditUser();
    } else {
      notifyError('userDeletionFailed');
    }
  };

  onClose = () => {
    this.setState({
      copyClaimsDrawerVisible: false,
    });
  };

  loadClaimsForUser = (event: any) => {
    this.props.loadUserWithCopiedClaims(this.state.user.id, this.state.selectedCopyUserId);
    this.onClose();
  };

  onSelect = (id: string, option: any) => {
    this.setState({ selectedCopyUserId: id });
  };

  getNoTenantAlert(user: IUserEditViewModel): JSX.Element {
    if (_.isEmpty(user.assignedTenants)) {
      const tr = getTranslateFunc(this.props.t);
      return <Alert className={styles.alertStyle} message={tr('messages.noTenantBody')} type="warning" showIcon />;
    }
    return null;
  }

  getBranchOfIndustryAlerts(user: IUserEditViewModel): JSX.Element {
    if (_.isEmpty(user.assignedTenants)) {
      return null;
    }

    const tr = getTranslateFunc(this.props.t);
    return (
      <>
        {_.chain(user.assignedTenants)
          .filter((tenant) => _.isEmpty(tenant.assignedBranchesOfIndustry))
          .map((tenant) => <Alert className={styles.alertStyle} key={tenant.id} message={tr('messages.noBranchForTenantBody', { tenant: tenant.name })} type="warning" showIcon />)
          .value()}
      </>
    );
  }

  getDepartmentAlerts(user: IUserEditViewModel, settings: LanguageSettings): JSX.Element {
    if (_.isEmpty(user.assignedTenants)) {
      return null;
    }

    const tr = getTranslateFunc(this.props.t);
    return (
      <>
        {_.chain(user.assignedTenants)
          .filter((tenant) => !_.isEmpty(tenant.assignedBranchesOfIndustry))
          .map((tenant) => _.map(tenant.assignedBranchesOfIndustry, (branch) => ({ tenant, branch })))
          .flatten()
          .map((tenantBranch) => {
            if (_.isEmpty(tenantBranch.branch.assignedDepartments)) {
              return (
                <Alert
                  className={styles.alertStyle}
                  key={`${tenantBranch.tenant.id}|${tenantBranch.branch.id}`}
                  message={tr('messages.noDepartmentBody', { tenant: tenantBranch.tenant.name, branch: getForLanguage(tenantBranch.branch.name, settings) })}
                  type="warning"
                  showIcon
                />
              );
            }
            return null;
          })
          .value()}
      </>
    );
  }
}

UserEditForm.contextType = UserInfoCtx;
export default withTranslation()(withRouter(UserEditForm));

function getTranslateFunc(t: TFunction) {
  return (key: string, values: any = undefined) => t('users.userEditForm.' + key, values);
}

interface IUserEditFormProps extends RouteComponentProps, WithTranslation {
  user: IUserEditViewModel;
  assignableTenants: ITenantRef[];
  allTenants: ITenantInfo[];
  assignableBranchesOfIndustry: IBranchOfIndustryRef[];
  assignableDepartments: IDepartmentRefWithTenantAndBranch[];
  permissions: UserPermissions;
  users: IUserEditViewModel[];
  loadUserWithCopiedClaims: (userId: string, userToCopyId: string) => void;
  cancelEditUser: () => void;
  saveEditUser: (user: IUserEditViewModel) => void;
}

interface IUserEditFormState {
  user: IUserEditViewModel;
  selectedCopyUserId: string;
  copyClaimsDrawerVisible: boolean;
  deleteUserDrawerVisible: boolean;
}

const DeleteUserDrawer: FC<{ user: IUserEditViewModel; visible: boolean; onDelete: () => void; onClose: () => void }> = ({ user, visible, onDelete, onClose }) => {
  const [t] = useTranslation();
  const tr = getTranslateFunc(t);
  const [canDeleteUser, setCanDeleteUser] = useState<ICanDeleteResult>();
  const isLoading = canDeleteUser == undefined;

  useEffect(() => {
    if (!user || !visible) {
      return;
    }

    const canDeleteUser = async () => {
      let value = await userService.canDeleteUser(user.id);
      setCanDeleteUser(value);
    };
    canDeleteUser();
  }, [user, visible]);

  const getTenantNames = () => {
    if (canDeleteUser?.valid.isValid === false) {
      return canDeleteUser?.blockingTenants.join('\n- ');
    }
    return '';
  };

  return (
    <Drawer title={tr('deleteUserTitle')} placement="right" closable={true} onClose={onClose} visible={visible}>
      <div className={styles.drawerContent}>
        {isLoading && <Spin />}
        {canDeleteUser?.valid.isValid === false && <div style={{ whiteSpace: 'pre-line' }}>{tr('canNotDeleteUser', { mandant: getTenantNames() })}</div>}
        {canDeleteUser?.valid.isValid === true && (
          <>
            <p>{tr('confirmUserDeleteRequest')}</p>
            <Button style={{ marginTop: '1rem' }} danger icon={<DeleteOutlined />} onClick={onDelete}>
              {tr('deleteUser')}
            </Button>
          </>
        )}
      </div>
    </Drawer>
  );
};
