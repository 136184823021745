import * as _ from 'lodash';
import HttpClient from './httpClient';
import { IDepartment, IDepartmentResponse, IDepartmentResponseSingle, ITopic, ITopicResponse } from '../../models/masterdata';
import { AxiosResponse } from 'axios';
import { IUniqueValidationErrorResult, UniqueValidation } from '../../models/IUniqueValidation';
import { IDepartmentSync } from '../../models/masterdata/IDepartmentSync';

const getItems = async (): Promise<IDepartment[]> => {
  const response = await HttpClient.getInstance().get<IDepartmentResponse>('departments');
  if (response && response.data && response.data.departments) {
    return response.data.departments;
  }
};

const getItemById = async (departmentId: string): Promise<IDepartment> => {
  const response = await HttpClient.getInstance().get<IDepartmentResponse>(`departments`);
  const foundDepartment = response.data.departments.find((t) => t.id === departmentId);
  return foundDepartment;
};

const getItemByTenantAndDepartmentId = async (tenantId: string, id: string): Promise<IDepartment> => {
  const response = await HttpClient.getInstance().get<IDepartmentResponseSingle>(`departments/departmentSync/${id}/${tenantId}`);
  return response.data.department;
};

const getItemsByBranchId = async (branchId: string, tenantId: string): Promise<IDepartment[]> => {
  const response = await HttpClient.getInstance().get<IDepartmentResponse>(`departments/${branchId}/${tenantId}`);
  if (response && response.data && response.data.departments) {
    return response.data.departments;
  }
};

const addDepartmentSync = async (item: IDepartmentSync): Promise<IDepartment> => {
  const response = await HttpClient.getInstance().post(`departmentsync`, item);
  return response.data;
};

const editDepartmentSync = async (item: IDepartmentSync): Promise<IDepartment> => {
  const response = await HttpClient.getInstance().put(`departmentsync`, item);
  return response.data;
};

const removeDepartmentSync = async (item: IDepartmentSync): Promise<IDepartment> => {
  const response = await HttpClient.getInstance().put(`departmentsync/remove`, item);
  return response.data;
};

const editDepartmentItem = async (item: IDepartment): Promise<IDepartment> => {
  const response = await HttpClient.getInstance().put(`departments/${item.id}`, item);
  return getSingleItem(response);
};

const deleteDepartmentItem = async (item: IDepartment): Promise<IDepartment> => {
  await HttpClient.getInstance().delete(`departments/${item.id}`);
  return item;
};

const createDepartmentItem = async (item: IDepartment): Promise<IDepartment> => {
  const response = await HttpClient.getInstance().post<IDepartmentResponse>(`departments`, item);
  return getSingleItem(response);
};

const loadAvailableTopics = async (): Promise<ITopic[]> => {
  const response = await HttpClient.getInstance().get<ITopicResponse>('topics');
  return response.data.topics;
};

function getSingleItem(response: AxiosResponse<IDepartmentResponse>): IDepartment {
  return response.data && response.data.departments && response.data.departments[0];
}

const isUnique = async (department: IDepartment): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/departments/isunique`, department);
  return new UniqueValidation(result.data.validationErrors);
};

export default {
  getItems,
  getItemsByBranchId,
  getItemById,
  getItemByTenantAndDepartmentId,
  addDepartmentSync,
  editDepartmentSync,
  removeDepartmentSync,
  editDepartmentItem,
  deleteDepartmentItem,
  createDepartmentItem,
  loadAvailableTopics,
  isUnique,
};
