import numeral from 'numeral';

export function formatNumberForDisplay(number: number, postfix?: string): string {
  if (number == undefined) {
    return '';
  }

  let val = numeral(number).format('0,0.00');

  if (postfix) {
    val = `${val} ${postfix}`;
  }
  return val;
}

export function toNumber(val: string): number {
  val = val || '';
  if (val === '') {
    return null;
  }
  return numeral(val).value();
}
