import React, { useContext } from 'react';
import { Drawer, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from 'Panel.module.css';
import { IRiskAssessmentListViewModel } from '../../../models/riskAssessment';
import { InfoRiskPanel } from '../../../shared/components/Info/Info';
import formatter from '../../../shared/formatter';
import { UserInfoCtx } from '../../../UserInfoContext';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { RouteComponentProps, withRouter } from 'react-router';

const RiskAssessmentListInfoPanel: React.FC<IRiskAssessmentListInfoPanelProps> = ({ label, drawerVisible, onClose, riskAssessment, history }) => {
  const [t] = useTranslation();
  const settings = useContext(UserInfoCtx).languageSettings;

  return (
    <Drawer title={label} placement="right" closable={true} onClose={onClose} visible={drawerVisible} width={386}>
      <InfoRiskPanel labelKey="riskAssessment.offerNr" value={riskAssessment.offerNr} />
      <InfoRiskPanel labelKey="riskAssessment.customer" value={getForLanguage(riskAssessment.customerName, settings)} />
      <InfoRiskPanel labelKey="riskAssessment.vendor" value={getForLanguage(riskAssessment.vendor, settings)} />
      <InfoRiskPanel labelKey="riskAssessment.country" value={getForLanguage(riskAssessment.countryName, settings)} />
      <InfoRiskPanel labelKey="riskAssessment.pspElement" value={riskAssessment.pspElement} />
      <InfoRiskPanel labelKey="riskAssessment.branchOfIndustry" value={getForLanguage(riskAssessment.branchName, settings)} />
      <InfoRiskPanel labelKey="riskAssessment.createdDate" value={formatter.formatDateWithTimeString(riskAssessment.createdDate)} />
      <InfoRiskPanel labelKey="riskAssessment.dueDate" value={formatter.formatDateWithTimeString(riskAssessment.dueDate)} />
      <InfoRiskPanel labelKey="riskAssessment.status" value={t(`riskAssessment.states.${riskAssessment.status}`)} />
      <Button id="editRiskAssessment" type="primary" className="infoRiskButton" onClick={() => history.push('/riskassessments/' + riskAssessment.token || riskAssessment.id)}>
        {t('commonActions.edit')}
      </Button>
    </Drawer>
  );
};

export default withRouter(RiskAssessmentListInfoPanel);

interface IRiskAssessmentListInfoPanelProps extends RouteComponentProps {
  label: string;
  drawerVisible: boolean;
  onClose: () => void;
  riskAssessment: IRiskAssessmentListViewModel;
}
