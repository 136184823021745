import { Form, Input, Checkbox, Switch, Select, DatePicker, Row, Col, Radio, InputNumber, Statistic, Popover, AutoComplete } from 'antd';
import React, { CSSProperties, useContext, FC, useState, useEffect, useMemo } from 'react';
import { connect, getIn, FormikProps, FormikConfig } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CountryPicker from './components/CountryPicker/CountryPicker';
import IncotermPicker from './components/IncotermPicker/IncotermPicker';
import { MachineTypeTreePicker } from './components/MachineTypePicker/MachineTypePicker';
import CustomerPicker from './components/CustomerPicker/CustomerPicker';
import RiskAssessmentTemplatePicker from './components/RiskAssessmentTemplatePicker/RiskAssessmentTemplatePicker';
import _ from 'lodash';
import AzureUserPicker from './components/AzureUserPicker/AzureUserPicker';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import { setLanguage, getForLanguageWithoutFallBack, IMultiLanguageString } from '../models/IMultiLanguageString';
import { UserInfoCtx, IUserInfoCtx } from '../UserInfoContext';
import LanguagePicker, { ILanguageOption } from './components/LanguagePicker/LanguagePicker';
import { createLanguageFromOption, createOptionFromLanguage, ILanguage, getDatePickerLocalFromContext } from '../models/LanguageSettings';
import { NumericInput } from './components/NumericInput/NumericInput';
import { reach } from 'yup';
import BranchOfIndustryPicker from './components/BranchOfIndustryPicker/BranchOfIndustryPicker';
import { RefItemOptionsPicker } from './components/RefItemPicker/RefItemPicker';
import { IsRef } from './components/PickerBase/PickerBase';
import classnames from 'classnames';
import UserPicker from './components/UserPicker/UserPicker';
import { QuestionOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useTenantBranch } from '../TenantBranchContext';
import { IRiskAssessmentTemplateRef } from '../models/riskAssessment';

const Option = Select.Option;
const { TextArea } = Input;

// TODO Flo: Why is that not working?
function useCachedFormValuesNotWorkingWhy(formik: Partial<FormikProps<any>>, name: string) {
  let { values, setFieldValue, setFieldTouched } = formik;
  const providedValue = getIn(values, name);
  const [currentValue, setCurrentValue] = useState(providedValue);
  useEffect(() => {
    setCurrentValue(providedValue);
  }, [providedValue]);

  const onBlur = () => {
    if (currentValue !== providedValue) {
      setFieldValue(name, currentValue, true);
    } else {
      setFieldTouched(name);
    }
  };

  return [currentValue, setFieldValue, onBlur];
}

function hasFeedback(touched: any, errors: any, name: string): boolean {
  return getIn(touched, name) && !!getIn(errors, name);
}

function getValidation(touched: any, errors: any, name: string): '' | 'error' | 'success' | 'warning' | 'validating' {
  if (!getIn(touched, name)) {
    return '';
  }
  return getIn(errors, name) && 'error';
}

function getHelp(touched: any, errors: any, name: string): string {
  return getIn(touched, name) && !!getIn(errors, name) && getIn(errors, name);
}

function dataAutomationIdOrDefaultPure(dataAutomationId: string, name: string) {
  if (dataAutomationId) {
    return dataAutomationId;
  }

  if (!name) {
    return name;
  }

  let lastDotIndex = name.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return name;
  }

  return name.substring(lastDotIndex + 1);
}

const dataAutomationIdOrDefault = _.memoize(dataAutomationIdOrDefaultPure, (dataAutomationId: string, name: string) => (dataAutomationId ?? 'null') + '||' + (name ?? 'null'));

export const FormTextInputAsyncValidation: FC<any> = ({
  values,
  errors,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  name,
  label,
  touched,
  dataAutomationId = '',
  disabled = false,
}) => {
  const checkEmail = (value: string) => {
    // only check if the field passes Yup email validation first
    if (!errors || !errors[name] || !errors[name].includes('invalid' /* or whatever your error message is*/)) {
      const checkSomething = (value: string) => {
        return new Promise((resolve, reject) => {
          const wait = setTimeout(() => {
            clearTimeout(wait);
            reject('dat jibt et schon!');
          }, 200);
        });
      };

      checkSomething(value).then(
        (success) => {
          setFieldError(name, undefined);
          // if you need to show more info, like a checkbox:
          // this.props.form.setStatus({ email: true })
        },
        (error) => {
          console.log('validation error');
          setTimeout(() => {
            setFieldError(name, 'Offer Nummer ist schon vergeben alter!');
          }, 0);
        },
      );
    }
  };
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  const debouncedCheckEmail = _.debounce(checkEmail, 300); // 300ms debounce

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.value);
    debouncedCheckEmail(event.target.value);
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(name);
    checkEmail(event.target.value);
  };

  return (
    <Form.Item label={label} hasFeedback={hasFeedback(touched, errors, name)} validateStatus={getValidation(touched, errors, name)} help={getHelp(touched, errors, name)}>
      <Input placeholder={label} value={getIn(values, name)} onChange={handleChange} onBlur={handleBlur} disabled={disabled} data-automation-id={dataAutomationId} />
    </Form.Item>
  );
};

const AntFormItem: FC<DefaultFormFieldProps> = ({ children, formik, values, errors, touched, label, name, required, formClassName, style, description }) => {
  let [t] = useTranslation();
  let labelCmp: string | React.ReactNode = label;
  if (description) {
    labelCmp = (
      <Popover trigger="click" title={t('descriptions.popoverTitle')} content={description}>
        <span style={{ cursor: 'pointer' }}>
          {label} <InfoCircleOutlined style={{ color: '#62a8ee' }} />
        </span>
      </Popover>
    );
  }

  return (
    <Form.Item
      className={formClassName}
      required={required || getIsFieldRequired(formik, values, name)}
      label={labelCmp}
      hasFeedback={hasFeedback(touched, errors, name)}
      validateStatus={getValidation(touched, errors, name)}
      help={getHelp(touched, errors, name)}
      style={style}
    >
      {children as any}
    </Form.Item>
  );
};

const FormAutoCompleteRaw: FC<DefaultFormFieldProps & IFormAutoCompleteProps> = React.memo(
  (props) => {
    let { label, name, disabled, values, setFieldValue, setFieldTouched, dataAutomationId, options } = props;
    let providedValue = getIn(values, name);
    let [currentValue, setCurrentValue] = useState(providedValue);
    useEffect(() => {
      setCurrentValue(providedValue);
    }, [providedValue]);

    let onBlur = () => {
      if (currentValue !== providedValue) {
        setFieldValue(name, currentValue, true);
      } else {
        setFieldTouched(name);
      }
    };

    dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
    return (
      <AntFormItem {...props}>
        <AutoComplete
          data-automation-id={dataAutomationId}
          placeholder={label}
          value={currentValue || ''}
          onChange={(value) => setCurrentValue(value)}
          onBlur={onBlur}
          disabled={disabled}
          options={options}
          filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
      </AntFormItem>
    );
  },
  (prev, next) => areDefaultFormFieldsEqual(prev, next),
);
export const FormAutoComplete = connect(FormAutoCompleteRaw);

const FormTextInputRaw: FC<DefaultFormFieldProps> = React.memo(
  (props) => {
    let { label, name, disabled, values, setFieldValue, setFieldTouched, dataAutomationId } = props;
    let providedValue = getIn(values, name);
    let [currentValue, setCurrentValue] = useState(providedValue);
    useEffect(() => {
      setCurrentValue(providedValue);
    }, [providedValue]);

    let onBlur = () => {
      if (currentValue !== providedValue) {
        setFieldValue(name, currentValue, true);
      } else {
        setFieldTouched(name);
      }
    };

    dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
    return (
      <AntFormItem {...props}>
        <Input data-automation-id={dataAutomationId} placeholder={label} value={currentValue} onChange={(ev) => setCurrentValue(ev.target.value)} onBlur={onBlur} disabled={disabled} />
      </AntFormItem>
    );
  },
  (prev, next) => areDefaultFormFieldsEqual(prev, next),
);
export const FormTextInput = connect(FormTextInputRaw);

const FormLanguageTextInputRaw: FC<DefaultFormFieldProps & { selectedLanguage: ILanguage; canEditMultiLanguage?: boolean }> = (props) => {
  let { values, setFieldValue, selectedLanguage, setFieldTouched, name, label, dataAutomationId, disabled = false, canEditMultiLanguage = true } = props;
  const context = useContext(UserInfoCtx);
  if (!canEditMultiLanguage) {
    selectedLanguage = context.languageSettings.getDefaultLanguage();
  }
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <Input
        placeholder={label}
        value={getForLanguageWithoutFallBack(getIn(values, name), context.languageSettings, selectedLanguage)}
        onChange={(event) => setFieldValue(name, setLanguage(getIn(values, name), selectedLanguage, event.target.value))}
        onBlur={() => setFieldTouched(name)}
        disabled={disabled}
        data-automation-id={dataAutomationId}
      />
    </AntFormItem>
  );
};
export const FormLanguageTextInput = connect(FormLanguageTextInputRaw);

const defaultFormNumberStyles = { width: '100%', maxWidth: '10rem' };

const FormNumberInputRaw: FC<DefaultFormFieldProps & IFormNumberInputProps> = (props) => {
  let { values, setFieldValue, setFieldTouched, name, min, max, formatter, parser, precision, decimalSeparator, step, defaultValue, style, dataAutomationId, disabled = false } = props;
  style = _.assign({}, defaultFormNumberStyles, style);
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <InputNumber
        style={style}
        value={getIn(values, name)}
        onChange={(event) => setFieldValue(name, event)}
        onBlur={() => setFieldTouched(name)}
        min={min}
        max={max}
        precision={precision}
        decimalSeparator={decimalSeparator}
        step={step}
        formatter={formatter}
        parser={parser}
        defaultValue={defaultValue}
        disabled={disabled}
        data-automation-id={dataAutomationId}
      />
    </AntFormItem>
  );
};

export const FormNumberInput = connect(FormNumberInputRaw);

export const FormPercentInputRaw: FC<FormikProps<any> & IFormNumberInputProps> = (props) => {
  let { values, setFieldValue, setFieldTouched, disabled, name, dataAutomationId } = props;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <NumericInput dataAutomationId={dataAutomationId} value={getIn(values, name)} onChange={(val) => setFieldValue(name, val)} onBlur={() => setFieldTouched(name)} disabled={disabled} suffix="%" />
    </AntFormItem>
  );
};

export const FormPercentInput = connect(FormPercentInputRaw);

export const FormMoneyInputRaw: FC<FormikProps<any> & IFormMoneyInput> = React.memo(
  (props) => {
    let { disabled, name, currency, values, setFieldValue, setFieldTouched, dataAutomationId } = props;
    const providedValue = getIn(values, name);
    const [currentValue, setCurrentValue] = useState(providedValue);
    useEffect(() => {
      setCurrentValue(providedValue);
    }, [providedValue]);

    const onBlur = () => {
      if (currentValue !== providedValue) {
        setFieldValue(name, currentValue, true);
      } else {
        setFieldTouched(name);
      }
    };
    dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
    return (
      <AntFormItem {...props}>
        <NumericInput
          dataAutomationId={dataAutomationId}
          value={currentValue}
          onChange={(val) => {
            setCurrentValue(val);
          }}
          onBlur={onBlur}
          disabled={disabled}
          suffix={currency}
        />
      </AntFormItem>
    );
  },
  (prev, next) => prev.currency === next.currency && areDefaultFormFieldsEqual(prev, next),
);

export const FormMoneyInput = connect(FormMoneyInputRaw);

interface IFieldProps {
  name: string;
  label?: string;
  disabled?: boolean;
  formClassName?: string;
  required?: boolean;
  text?: string;
  style?: CSSProperties;
  dataAutomationId?: string;
  description?: string;
}

interface IFormNumberInputProps extends IFieldProps {
  min?: number;
  max?: number;
  precision?: number;
  decimalSeparator?: string;
  step?: number | string;
  defaultValue?: number;
  parser?: (displayValue: string) => number | string;
  formatter?: (value: string | number) => string;
}

interface IFormAutoCompleteProps extends IFieldProps {
  options?: { value: string; label: string }[];
}

type DefaultFormFieldProps = { formik?: any } & Partial<FormikProps<any>> & IFieldProps;

interface IFormMoneyInput extends IFormNumberInputProps {
  currency: string;
}

function areDefaultFormFieldsEqual(prev: DefaultFormFieldProps, next: DefaultFormFieldProps): boolean {
  return (
    prev.name === next.name &&
    prev.label === next.label &&
    prev.disabled === next.disabled &&
    getIn(prev.values, prev.name) === getIn(next.values, next.name) &&
    getIsFieldRequired(prev.formik, prev.values, prev.name) === getIsFieldRequired(next.formik, next.values, next.name) &&
    hasFeedback(prev.touched, prev.errors, prev.name) === hasFeedback(next.touched, next.errors, next.name) &&
    getValidation(prev.touched, prev.errors, prev.name) === getValidation(next.touched, next.errors, next.name) &&
    getHelp(prev.touched, prev.errors, prev.name) === getHelp(next.touched, next.errors, next.name)
  );
}

const FormTextAreaInputRaw: FC<DefaultFormFieldProps & { rows?: number; resizeable?: boolean; placeholder?: string }> = React.memo(
  (props) => {
    let { name, label, rows = null, disabled = false, resizeable = false, values, setFieldTouched, setFieldValue, dataAutomationId, placeholder } = props;
    let style = null;
    if (!resizeable) {
      style = { resize: 'none' };
    }
    const providedValue = getIn(values, name);
    const [currentValue, setCurrentValue] = useState(providedValue);
    useEffect(() => {
      setCurrentValue(providedValue);
    }, [providedValue]);

    const onBlur = () => {
      if (currentValue !== providedValue) {
        setFieldValue(name, currentValue, true);
      } else {
        setFieldTouched(name);
      }
    };
    dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
    return (
      <AntFormItem {...props}>
        <TextArea
          data-automation-id={dataAutomationId}
          placeholder={placeholder || label}
          value={currentValue}
          onChange={(ev) => setCurrentValue(ev.target.value)}
          onBlur={onBlur}
          rows={rows}
          disabled={disabled}
          style={style}
        />
      </AntFormItem>
    );
  },
  (prev, next) => {
    return prev.resizeable === next.resizeable && areDefaultFormFieldsEqual(prev, next);
  },
);
export const FormTextAreaInput = connect(FormTextAreaInputRaw);

const FormLanguageTextAreaInputRaw: FC<DefaultFormFieldProps & { selectedLanguage?: ILanguage; rows?: number }> = (props) => {
  let { values, setFieldValue, selectedLanguage, setFieldTouched, name, label, dataAutomationId, rows = null, disabled = false } = props;
  const context = useContext(UserInfoCtx);
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <TextArea
        placeholder={label}
        value={getForLanguageWithoutFallBack(getIn(values, name), context.languageSettings, selectedLanguage)}
        onChange={(event) => setFieldValue(name, setLanguage(getIn(values, name), selectedLanguage, event.target.value))}
        onBlur={() => setFieldTouched(name)}
        rows={rows}
        disabled={disabled}
        data-automation-id={dataAutomationId}
      />
    </AntFormItem>
  );
};

export const FormLanguageTextAreaInput = connect(FormLanguageTextAreaInputRaw);

const FormRadioButtonGroupRaw: FC<DefaultFormFieldProps & IFormRadioButtonGroupProps> = (props) => {
  let { values, setFieldValue, name, dataAutomationId, options = [], disabled = false } = props;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <RadioGroup buttonStyle="solid" onChange={(event: any) => setFieldValue(name, event.target.value)} value={getIn(values, name)} disabled={disabled} data-automation-id={dataAutomationId}>
        {options.map((o) => (
          <RadioButton key={o.value} value={o.value} data-automation-id={dataAutomationId + '-radionButton-' + o.value}>
            {o.desc}
          </RadioButton>
        ))}
      </RadioGroup>
    </AntFormItem>
  );
};
export const FormRadioButtonGroup = connect(FormRadioButtonGroupRaw);

const FormRefItemPickerRaw: FC<DefaultFormFieldProps & IFormRefItemPickerProps> = (props) => {
  const context = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  let { options = [], values, setFieldValue, name, dataAutomationId, disabled = false } = props;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <RefItemOptionsPicker
        languageSettings={context.languageSettings}
        disabled={disabled}
        value={getIn(values, name)}
        onChange={(event: any) => setFieldValue(name, event)}
        options={options}
        name={name}
        dataAutomationId={dataAutomationId}
        tenantId={tenantId}
        branchId={branchId}
      />
    </AntFormItem>
  );
};

interface IFormRefItemPickerProps {
  options: IsRef[];
}

export const FormRefItemPicker = connect(FormRefItemPickerRaw);

const FormSelectInputRaw: FC<DefaultFormFieldProps & IFormSelectProps> = (props) => {
  let { index = null, options = [], values, setFieldValue, setFieldTouched, name, className, dataAutomationId, mode, disabled = false } = props;
  index = index != null ? index : name;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <Select
        data-automation-id={dataAutomationId}
        className={className}
        showSearch
        mode={mode}
        optionFilterProp="children"
        filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(event: any) => setFieldValue(index, event)}
        value={getIn(values, name)}
        onBlur={() => setFieldTouched(name)}
        disabled={disabled}
      >
        {options.map((o: { value: React.ReactText; desc: React.ReactNode }) => (
          <Option key={o.value} value={o.value} data-automation-id={dataAutomationId + '-option-' + o.value}>
            {o.desc}
          </Option>
        ))}
      </Select>
    </AntFormItem>
  );
};
export const FormSelectInput = connect(FormSelectInputRaw);

const FormCountryPickerRaw: FC<DefaultFormFieldProps & IFormSelectProps & ISelectedLanguageProps> = (props) => {
  let { index = null, values, setFieldValue, setFieldTouched, name, label, onRef, dataAutomationId, disabled = false, forBranchId = '' } = props;
  index = index != null ? index : name;
  const context = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <CountryPicker
        name={name}
        dataAutomationId={dataAutomationId}
        onRef={onRef}
        placeholder={label}
        onChange={(event) => setFieldValue(index, event)}
        value={getIn(values, name)}
        languageSettings={context.languageSettings}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        tenantId={tenantId}
        branchId={forBranchId || branchId}
      />
    </AntFormItem>
  );
};

export const FormCountryPicker = connect(FormCountryPickerRaw);

const FormUserPickerRaw: FC<DefaultFormFieldProps & IFormSelectProps & ISelectedLanguageProps> = (props) => {
  let { index = null, values, setFieldValue, setFieldTouched, name, label, onRef, dataAutomationId, disabled = false, forBranchId = '' } = props;
  index = index != null ? index : name;
  const { tenantId, branchId } = useTenantBranch();
  return (
    <AntFormItem {...props}>
      <UserPicker
        name={name}
        dataAutomationId={dataAutomationId || name}
        onRef={onRef}
        placeholder={label}
        onChange={(event) => setFieldValue(index, event)}
        value={getIn(values, name)}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        tenantId={tenantId}
        branchId={forBranchId || branchId}
      />
    </AntFormItem>
  );
};

export const FormUserPicker = connect(FormUserPickerRaw);

const FormBranchOfIndustryPickerRaw: FC<DefaultFormFieldProps & IFormSelectProps & ISelectedLanguageProps> = (props) => {
  let { index = null, values, setFieldValue, setFieldTouched, name, label, onRef, dataAutomationId, disabled = false, forBranchId = '' } = props;
  index = index != null ? index : name;
  const context = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <BranchOfIndustryPicker
        name={name}
        dataAutomationId={dataAutomationId}
        onRef={onRef}
        placeholder={label}
        onChange={(event) => setFieldValue(index, event)}
        value={getIn(values, name)}
        languageSettings={context.languageSettings}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        tenantId={tenantId}
        branchId={forBranchId || branchId}
      />
    </AntFormItem>
  );
};

export const FormBranchOfIndustryPicker = connect(FormBranchOfIndustryPickerRaw);

const FormLanguagePickerRaw: FC<DefaultFormFieldProps & IFormSelectProps & ISelectedLanguageProps> = (props) => {
  let { index = null, values, setFieldValue, setFieldTouched, name, label, onRef, dataAutomationId, disabled = false } = props;
  index = index != null ? index : name;
  const context = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <LanguagePicker
        name={name}
        dataAutomationId={dataAutomationId}
        onRef={onRef}
        placeholder={label}
        onChange={(event) => setFieldValue(index, createLanguageFromOption(event as ILanguageOption))}
        value={createOptionFromLanguage(getIn(values, name))}
        languageSettings={context.languageSettings}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        tenantId={tenantId}
        branchId={branchId}
      />
    </AntFormItem>
  );
};
export const FormLanguagePicker = connect(FormLanguagePickerRaw);

const FormIncotermPickerRaw: FC<DefaultFormFieldProps & IFormSelectProps> = (props) => {
  let { index = null, values, setFieldValue, setFieldTouched, name, label, dataAutomationId, disabled = false, forBranchId = '' } = props;
  const context = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  index = index != null ? index : name;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <IncotermPicker
        name={name}
        dataAutomationId={dataAutomationId}
        placeholder={label}
        onChange={(event) => setFieldValue(index, event)}
        value={getIn(values, name)}
        languageSettings={context.languageSettings}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        tenantId={tenantId}
        branchId={forBranchId || branchId}
      />
    </AntFormItem>
  );
};
export const FormIncotermPicker = connect(FormIncotermPickerRaw);
interface IRiskAssessmentTemplatePickerQueryDefault {
  onQueryItems?: (query: string) => Promise<IRiskAssessmentTemplateRef[]>;
}
const FormRiskAssessmentTemplatePickerRaw: FC<DefaultFormFieldProps & IFormSelectProps & IRiskAssessmentTemplatePickerQueryDefault> = (props) => {
  let { index = null, values, setFieldValue, setFieldTouched, name, label, dataAutomationId, disabled = false, forBranchId, onQueryItems } = props;
  const context = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  index = index != null ? index : name;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <RiskAssessmentTemplatePicker
        name={name}
        dataAutomationId={dataAutomationId}
        placeholder={label}
        onChange={(event) => setFieldValue(index, event)}
        value={getIn(values, name)}
        languageSettings={context.languageSettings}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        tenantId={tenantId}
        branchId={forBranchId || branchId}
        onQueryItems={onQueryItems}
      />
    </AntFormItem>
  );
};
export const FormRiskAssessmentTemplatePicker = connect(FormRiskAssessmentTemplatePickerRaw);

const FormMachineTypeTreePickerRaw: FC<DefaultFormFieldProps & IFormSelectProps> = (props) => {
  let { index = null, values, setFieldValue, setFieldTouched, name, label, dataAutomationId, disabled = false, forBranchId } = props;
  const { tenantId, branchId } = useTenantBranch();
  index = index != null ? index : name;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <MachineTypeTreePicker
        name={name}
        dataAutomationId={dataAutomationId}
        placeholder={label}
        onChange={(event) => setFieldValue(index, event)}
        value={getIn(values, name)}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        tenantId={tenantId}
        branchId={forBranchId || branchId}
      />
    </AntFormItem>
  );
};

export const FormMachineTypeTreePicker = connect(FormMachineTypeTreePickerRaw);

export const FormCustomerPickerRaw: FC<DefaultFormFieldProps & IFormSelectProps & any> = (props) => {
  let { index = null, values, setFieldValue, name, label, setFieldTouched, onRef, dataAutomationId, disabled = false, onChange, forBranchId, defaultIfSingle, allowClear } = props;
  const context = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  index = index != null ? index : name;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <CustomerPicker
        name={name}
        allowClear={allowClear}
        dataAutomationId={dataAutomationId}
        onRef={onRef}
        placeholder={label}
        onChange={(event) => {
          setFieldValue(index, event);
          onChange(event);
        }}
        value={getIn(values, name)}
        handleBlur={() => setFieldTouched(name)}
        disabled={disabled}
        languageSettings={context.languageSettings}
        tenantId={tenantId}
        branchId={forBranchId || branchId}
        defaultIfSingle={defaultIfSingle}
      />
    </AntFormItem>
  );
};
export const FormCustomerPicker = connect(FormCustomerPickerRaw);

const dateFormat = 'DD.MM.YYYY';
const FormDateInputRaw: FC<DefaultFormFieldProps & { index?: string }> = (props) => {
  const ctx = useContext(UserInfoCtx);
  let { values, setFieldValue, setFieldTouched, name, dataAutomationId, index = null, disabled = false } = props;
  index = index != null ? index : name;
  const value = getIn(values, name);
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <DatePicker
        data-automation-id={dataAutomationId}
        style={{ width: '100%' }}
        value={value && moment(value)}
        format={dateFormat}
        onChange={(event: any) => setFieldValue(index, event && event.toISOString())}
        onOpenChange={(status: any) => (status ? '' : setFieldTouched(name))}
        onBlur={() => setFieldTouched(name)}
        disabled={disabled}
        locale={getDatePickerLocalFromContext(ctx) as any}
      />
    </AntFormItem>
  );
};
export const FormDateInput = connect(FormDateInputRaw);

const FormCheckboxInputRaw: FC<DefaultFormFieldProps> = (props) => {
  let { values, setFieldValue, name, dataAutomationId, disabled = false, text } = props;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <Checkbox data-automation-id={dataAutomationId} disabled={disabled} checked={getIn(values, name)} onChange={(event) => setFieldValue(name, event.target.checked)}>
        {text}
      </Checkbox>
    </AntFormItem>
  );
};
export const FormCheckboxInput = connect(FormCheckboxInputRaw);

const FormSwitchInputRaw: FC<DefaultFormFieldProps> = (props) => {
  let { values, setFieldValue, name, dataAutomationId } = props;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <AntFormItem {...props}>
      <Switch data-automation-id={dataAutomationId} checked={getIn(values, name)} onChange={(value) => setFieldValue(name, value)} />
    </AntFormItem>
  );
};

export const FormSwitchInput = connect(FormSwitchInputRaw);

const FormRadioYesNoRaw: FC<DefaultFormFieldProps & { onChange?: (newValue: boolean) => void }> = (props) => {
  let { values, setFieldValue, name, disabled, dataAutomationId, onChange = (value) => {} } = props;
  const [t] = useTranslation();
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  let value = getIn(values, name) ?? false;
  return (
    <AntFormItem {...props}>
      <Radio.Group value={value} disabled={disabled} buttonStyle="solid" style={{ display: 'flex', width: '6rem' }} size="small" data-automation-id={dataAutomationId}>
        <Radio.Button
          data-automation-id={dataAutomationId + '-button-no'}
          disabled={disabled}
          checked={value === false}
          onChange={() => {
            setFieldValue(name, false);
            onChange(false);
          }}
          style={{ flex: '1', textAlign: 'center' }}
          value={false}
        >
          {t('common.no')}
        </Radio.Button>
        <Radio.Button
          data-automation-id={dataAutomationId + '-button-yes'}
          disabled={disabled}
          checked={value === true}
          onChange={() => {
            setFieldValue(name, true);
            onChange(true);
          }}
          style={{ flex: '1', textAlign: 'center' }}
          value={true}
        >
          {t('common.yes')}
        </Radio.Button>
      </Radio.Group>
    </AntFormItem>
  );
};

export const FormRadioYesNo = connect(FormRadioYesNoRaw);

export const FormTextLabel: FC<any> = ({ values, errors, name, label }) => (
  <Form.Item label={label} hasFeedback={!!getIn(errors, name)} validateStatus={getIn(errors, name) && 'error'} help={getIn(errors, name)}>
    <span>{getIn(values, name)}</span>
  </Form.Item>
);

export const FormTrLabel: FC<{ text?: string; trKey?: string; required?: boolean; style?: CSSProperties; block?: boolean }> = ({ trKey, text, required, style, block }) => {
  const [t] = useTranslation();

  if (block) {
    style = _.assign({ display: 'block' }, style);
  }

  let label = text;
  if (trKey) {
    label = t(trKey);
  }

  return (
    <label style={style} className={classnames('ant-form-item-label', required && 'ant-form-item-required')}>
      {label}
    </label>
  );
};

export const FormGrid: FC<{}> = (props) => {
  let children = React.Children.toArray(props.children);
  children = children.filter((c) => !!c);
  const controlChunks = _.chunk(children, 4);

  return (
    <>
      {_.map(controlChunks, (chunk, idx) => (
        <Row style={{ padding: '0.25rem 0' }} key={'row' + idx} gutter={4}>
          {_.map(chunk, (child, cIdx) => (
            <Col key={cIdx} xs={24} md={12} xl={6}>
              {child}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export const FormRow: FC<{}> = (props) => {
  let children = React.Children.toArray(props.children);
  children = children.filter((c) => !!c);
  return (
    <Row gutter={4}>
      {children.map((child, idx) => (
        <Col key={idx} xs={24} md={12} xl={6}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

export const DoubleFormRow: FC<{}> = (props) => {
  return (
    <Row gutter={4}>
      {React.Children.map(props.children, (child) => (
        <Col xs={24} md={12}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

export const SingleFormRow: FC<{}> = (props) => {
  return (
    <Row gutter={4}>
      {React.Children.map(props.children, (child) => (
        <Col xs={24}>{child}</Col>
      ))}
    </Row>
  );
};

interface IFormRadioButtonGroupProps {
  name: string;
  label: string;
  disabled?: boolean;
  options: Array<{ value: string; desc: string }>;
}

interface IFormSelectProps {
  name: string;
  label: string;
  allowClear?: boolean;
  options?: IFormSelectOption[];
  index?: string;
  forBranchId?: string;
  className?: string;
  onRef?: any;
  mode?: 'multiple' | 'tags';
}

export type IFormSelectOption = { value: string; desc: string | IMultiLanguageString };
interface ISelectedLanguageProps {
  selectedLanguage?: ILanguage;
}

export const MoneyDisplay: FC<{ value: number | string; currency: string }> = ({ value, currency }) => {
  return <Statistic style={{ textAlign: 'right' }} valueStyle={{ fontSize: '12pt' }} value={value} precision={2} suffix={currency} />;
};

export const FormAzureUserPicker: FC<IFormSelectProps & FormikProps<any> & any> = ({
  index = null,
  values,
  errors,
  setFieldValue,
  name,
  label,
  formClassName,
  setFieldTouched,
  touched,
  onRef,
  dataAutomationId,
}) => {
  const { tenantId, branchId } = useTenantBranch();
  index = index != null ? index : name;
  dataAutomationId = dataAutomationIdOrDefault(dataAutomationId, name);
  return (
    <Form.Item className={formClassName} label={label} hasFeedback={hasFeedback(touched, errors, name)} validateStatus={getValidation(touched, errors, name)} help={getHelp(touched, errors, name)}>
      <AzureUserPicker
        name={name}
        data-automation-id={dataAutomationId}
        onRef={onRef}
        placeholder={label}
        onChange={(event) => setFieldValue(index, event)}
        value={getIn(values, name)}
        handleBlur={() => setFieldTouched(name)}
        tenantId={tenantId}
        branchId={branchId}
      />
    </Form.Item>
  );
};

function getIsFieldRequired(formik: FormikConfig<any>, values: FormikProps<any>, name: string): boolean {
  const validationSchema = formik.validationSchema;
  let fieldValidationSchema;
  try {
    fieldValidationSchema = validationSchema ? (reach(validationSchema, name, values, values) as any) : false;
  } catch {
    fieldValidationSchema = false;
  }
  const resolvedSchema = fieldValidationSchema ? fieldValidationSchema.resolve({ value: values }) : false;
  const tests = resolvedSchema ? resolvedSchema.describe().tests : false;
  const isRequired = tests ? !!tests.find((test: any) => test.name === 'required') : false;
  return isRequired;
}
