import React, { PureComponent } from 'react';
import _ from 'lodash';

export interface IUniqueValidationError {
  property: string;
  message: string;
}

export interface IUniqueValidationErrorResult {
  validationErrors: IUniqueValidationError[];
}

export class UniqueValidation {
  validationErrors: IUniqueValidationError[];
  constructor(errors: IUniqueValidationError[]) {
    this.validationErrors = errors;
  }

  public hasErrors = (): boolean => this.validationErrors != null && this.validationErrors.length > 0;
  public getFormattedErrors = (t: any): string => _.map(this.validationErrors, (e) => `${e.property} ${t(`error.${e.message}`)}`).toString();
}
