import React, { FC, useContext, useMemo, CSSProperties } from 'react';
import {
  IRiskAssessmentStatusMonitorEntry,
  createRiskAssessmentStatusMonitorEntry,
  AssessmentIndicator,
  IRiskAssessmentDepartmentIndicator,
  IRiskAssessmentListSearchConfig,
} from '../../../models/riskAssessment';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import FormBox from '../../../shared/components/FormBox/FormBox';
import styles from './StatusMonitorList.module.css';
import { HtcCard } from '../../../shared/HtcCardComponent';
import _ from 'lodash';
import formatter from '../../../shared/formatter';
import { UserInfoCtx, IUserInfoCtx } from '../../../UserInfoContext';
import { useHistory } from 'react-router';
import { userService } from '../../../shared/services/userService';
import { translateStatusForTenantSettings } from '../../riskAssessment/formhelper';
import { TFunction } from 'i18next';
import { Table } from '../../../shared/components/Table/Table';
import { ITenantTerms } from '../../../models/masterdata';
import { formatNumberForDisplay } from '../../../shared/numericHelpers';
import { Link } from 'react-router-dom';
import { getForLanguage, getForLanguageWithoutFallBack } from '../../../models/IMultiLanguageString';
import { LanguageSettings } from '../../../models/LanguageSettings';
import { QuestionCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const TP = 'riskAssessment.overview.status.';

export const StatusMonitorList: FC<IStatusMonitorListProps> = ({ riskAssessments, projectVolumeView, isLoading, onTableChange, searchConfig, totalCount }) => {
  const [t] = useTranslation();
  let ctx = useContext(UserInfoCtx);
  const settings = useContext(UserInfoCtx).languageSettings;
  const firstEntry = riskAssessments.length > 0 ? riskAssessments[0] : createRiskAssessmentStatusMonitorEntry();
  let terms = useMemo(() => userService.getTenantTerms(), []);

  let columns = useMemo(() => createColumns(t, ctx, settings, terms, projectVolumeView), [firstEntry, projectVolumeView]);
  const paging = searchConfig.paging;
  return (
    <HtcCard flex>
      <FormBox contentflex flex title={t(TP + 'summary')}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={riskAssessments}
          loading={isLoading}
          onChange={onTableChange as any}
          pagination={{ current: paging.current, pageSize: paging.pageSize, total: totalCount }}
        />
      </FormBox>
    </HtcCard>
  );
};

const createColumns = (t: TFunction, ctx: IUserInfoCtx, settings: LanguageSettings, terms: ITenantTerms, projectVolumeView: boolean) => {
  const result = [];

  result.push({
    title: t(TP + 'offerNr'),
    dataIndex: 'offerNr',
    key: 'offerNr',
    width: 180,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => (
      <Link to={'/riskassessments/' + record.token} className={styles.panelLink}>
        <NoWrap text={record.offerNr} />
      </Link>
    ),
  });

  result.push({
    title: t(TP + 'customer'),
    dataIndex: 'customerName',
    key: 'customerName',
    width: 200,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => getForLanguage(record.customerName, settings),
  });

  result.push({
    title: t(TP + 'country'),
    dataIndex: 'countryName',
    key: 'countryName',
    width: 160,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => getForLanguage(record.countryName, settings),
  });

  result.push({
    title: t('riskAssessment.processTemplate'),
    dataIndex: 'processTemplate',
    key: 'processTemplate',
    sorter: true,
    width: 130,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => getForLanguage(record.processTemplate, settings),
  });

  result.push({
    title: t(TP + 'pspElement'),
    dataIndex: 'pspElement',
    key: 'pspElement',
    width: 120,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => <NoWrap text={record.pspElement} />,
  });

  result.push({
    title: t(TP + 'projectVolume'),
    dataIndex: 'projectVolume',
    key: 'projectVolume',
    width: 90,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => <NoWrap alignRight text={formatNumberForDisplay(record.projectVolume, record.currency)} />,
  });

  result.push({
    title: t(TP + 'machineType'),
    dataIndex: 'machineType',
    key: 'machineType',
    width: 200,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => <NoWrap text={getForLanguage(record.machineType, settings)} />,
  });

  result.push({
    title: t(TP + 'createdDate'),
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: 110,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => formatter.formatDateString(record.createdDate),
  });

  result.push({
    title: t(TP + 'dueDate'),
    dataIndex: 'dueDate',
    key: 'dueDate',
    width: 130,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => formatter.formatDateString(record.dueDate),
  });

  result.push({
    title: t(TP + 'evaluationIndicator'),
    dataIndex: 'evaluationIndicator',
    width: 300,
    render: (value: IRiskAssessmentDepartmentIndicator[], record: IRiskAssessmentStatusMonitorEntry) => {
      return (
        <div className={styles.indicatorGrid}>
          {value?.map((entry) => {
            return (
              <>
                <div className={styles.indicatorLabel}>{getForLanguage(entry.departmentAbbreviation, settings)}: </div>
                <div className={classnames(styles.riskselector_indicator, getIndicatorClass(entry?.indicator, true))}></div>
              </>
            );
          })}
        </div>
      );
    },
  });

  result.push({
    title: (
      <>
        <span>{t(TP + 'approvalIndicator')}</span>{' '}
        <Tooltip title={t(TP + 'approvalIndicatorHint')}>
          <QuestionCircleOutlined />
        </Tooltip>
      </>
    ),
    dataIndex: 'approvalIndicator',
    width: 300,
    render: (value: IRiskAssessmentDepartmentIndicator[], record: IRiskAssessmentStatusMonitorEntry) => {
      return (
        <div className={styles.indicatorGrid}>
          {value?.map((entry) => (
            <>
              <div className={styles.indicatorLabel}>{getForLanguage(entry.departmentAbbreviation, settings)}: </div>
              <div className={classnames(styles.riskselector_indicator, getIndicatorClass(entry?.indicator, false))}></div>
            </>
          ))}
        </div>
      );
    },
  });

  result.push({
    title: t(TP + 'finished'),
    dataIndex: 'finishedDate',
    key: 'finished',
    width: 150,
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => formatter.formatDateString(record.finishedDate),
  });

  result.push({
    width: 200,
    title: t(TP + 'status'),
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (text: string, record: IRiskAssessmentStatusMonitorEntry) => <NoWrap text={translateStatusForTenantSettings(record.status, t, ctx, terms)} />,
  });

  return result;
};

const NoWrap: React.FC<{ text: string; alignRight?: boolean }> = ({ text, alignRight }) => {
  const style: CSSProperties = { whiteSpace: 'nowrap' };
  if (alignRight) {
    style.textAlign = 'right';
    style.display = 'block';
    style.paddingRight = '15px';
  }

  return <span style={style}>{text}</span>;
};

interface IStatusMonitorListProps {
  riskAssessments: IRiskAssessmentStatusMonitorEntry[];
  projectVolumeView: boolean;
  isLoading: boolean;
  onTableChange: any;
  searchConfig: IRiskAssessmentListSearchConfig;
  totalCount: number;
}

function getIndicatorClass(indicator: AssessmentIndicator | undefined, isDepartmentEvaluation: boolean) {
  if (indicator) {
    switch (indicator) {
      case AssessmentIndicator.NotAssigned:
        return styles.notassigend;
      case AssessmentIndicator.Finished:
        return isDepartmentEvaluation ? styles.norisk : styles.finished;
      case AssessmentIndicator.NotFinished:
        return styles.notfinished;
      case AssessmentIndicator.EvaluationNoRisk:
        return styles.norisk;
      case AssessmentIndicator.EvaluationMediumRisk:
        return styles.mediumrisk;
      case AssessmentIndicator.EvaluationHighRisk:
        return styles.highrisk;
    }
  }
  return styles.risknotset;
}
