import React, { PureComponent } from 'react';
import { IUserEditViewModel, IAzureUser, IUserAddViewModel } from '../models/user';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Tag, Input, Drawer } from 'antd';
import { HtcCardWrapper, HtcCard } from '../shared/HtcCardComponent';
import FormBox, { SearchBox } from '../shared/components/FormBox/FormBox';
import _ from 'lodash';
import { NewAction, GenerateReport } from '../shared/components/CommandBar/CommandBar';
import styles from './UserList.module.css';
import UserAddForm from './UserAddForm';
import { userService } from '../shared/services/userService';
import formatter from '../shared/formatter';
import moment from 'moment';
import { UserInfoCtx } from '../UserInfoContext';
import { Table } from '../shared/components/Table/Table';
import { downloadFileBlob } from '../riskAssessments/components/DocumentList/DocumentHelper';
import { MasterdataHeader } from '../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../shared/components/CommandBar/CommandBarV2';
import { EditOutlined } from '@ant-design/icons';

const navPaths = ['navigation.users'];
class UserList extends PureComponent<IUserListComponentProps, IUserListState> {
  state: IUserListState = {
    searchText: null,
    searchDirectoryUsersText: null,
    drawerVisible: false,
  };

  constructor(props: IUserListComponentProps) {
    super(props);
    this.filterUsers = _.debounce(this.filterUsers, 200);
  }

  render() {
    const { t, isLoading } = this.props;
    const columns = this.createColumns();
    const filteredUsers = this.getFilteredUsers();
    return (
      <div className={styles.contentwrapper}>
        <MasterdataHeader>
          <CommandBarV2 paths={navPaths}>
            <GenerateReport text="riskAssessment.createUserSummary" onClick={this.generateReport} />
            <NewAction text="users.addUser.title" onClick={this.addUser} />
          </CommandBarV2>
        </MasterdataHeader>
        <HtcCardWrapper>
          <HtcCard>
            <SearchBox>
              <Input placeholder={t('users.searchText')} allowClear onChange={this.onSearch} />
            </SearchBox>
          </HtcCard>
          <HtcCard flex>
            <FormBox title={t('common.results')} flex contentflex>
              <Table rowKey="id" columns={columns} dataSource={filteredUsers} loading={isLoading} />
            </FormBox>
          </HtcCard>
        </HtcCardWrapper>
        <Drawer title={t('users.addUser.title')} placement="right" closable={true} onClose={this.onClose} visible={this.state.drawerVisible}>
          <UserAddForm onSave={this.handleAddUserSave} />
        </Drawer>
      </div>
    );
  }

  generateReport = async () => {
    const report = await userService.createUserReport(this.context.languageSettings.getActiveLanguage());
    const head = report.headers['content-type'];
    const blob = new Blob([report.data], { type: head });
    downloadFileBlob(blob, `Userreport_${formatter.formatDateWithTimeIsoString(moment().toISOString())}.xlsx`);
  };

  componentDidMount() {
    this.props.loadUsers();
  }

  onSearch = (e: any) => {
    this.filterUsers(e.target.value);
  };

  onClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  addUser = () => {
    this.setState({
      drawerVisible: true,
      searchDirectoryUsersText: null,
    });
  };

  handleAddUserSave = (user: IAzureUser) => {
    this.setState({ drawerVisible: false });
    this.props.addAndSelectUser({ id: user.id, email: user.mail, name: user.displayName } as IUserAddViewModel);
  };

  private filterUsers(text: string) {
    this.setState((prev) => ({ searchText: _.toLower(_.trim(text)) }));
  }

  private getFilteredUsers() {
    const { searchText } = this.state;
    const { users } = this.props;
    const sortedUsers = _.sortBy(users, (u) => u.name);
    return searchText ? _.filter(sortedUsers, (c) => _.includes(_.toLower(c.name), searchText) || _.includes(_.toLower(c.email), searchText)) : sortedUsers;
  }

  private createColumns = () => {
    const { t, selectUser } = this.props;
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        width: 300,
      },
      { title: 'E-Mail', dataIndex: 'email', width: 400 },
      {
        title: 'Status',
        dataIndex: 'isActive',
        render: (isActive: boolean) => <Tag color={isActive ? 'geekblue' : 'magenta'}>{t(isActive ? 'users.active' : 'users.inactive')}</Tag>,
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text: string, record: IUserEditViewModel) => <Button type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={() => selectUser(record.id)} />,
        width: 100,
      },
    ];
  };
}

UserList.contextType = UserInfoCtx;
export default withTranslation()(UserList);

interface IUserListComponentProps extends WithTranslation {
  isLoading: boolean;
  users: IUserEditViewModel[];
  loadUsers: () => void;
  selectUser: (id: string) => void;
  addAndSelectUser: (user: IUserAddViewModel) => void;
}

interface IUserListState {
  searchText: string;
  drawerVisible: boolean;
  searchDirectoryUsersText: string;
}
