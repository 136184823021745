import React, { FC, useContext, useEffect, useState } from 'react';
import { IUserEditViewModel, IBranchOfIndustryConfiguration, IDepartmentConfiguration, IDepartmentRefWithTenantAndBranch, createDepartmentConfig, ITenantConfiguration } from '../models/user';
import { Card, Row, Col, Popconfirm, Alert, Button } from 'antd';
import * as _ from 'lodash';
import { FieldArray, FormikProps, getIn } from 'formik';
import { TFunction } from 'i18next';
import FormBox from '../shared/components/FormBox/FormBox';
import { useTranslation } from 'react-i18next';
import PermissionSlider from './PermissionSlider';

import styles from './UserEditForm.module.css';
import TagGroup from '../shared/components/TagGroup/TagGroup';
import { UserPermissions } from '../models';
import { getForLanguage } from '../models/IMultiLanguageString';
import { UserInfoCtx } from '../UserInfoContext';
import { LanguageSettings } from '../models/LanguageSettings';
import { FormTrLabel } from '../shared/FormComponents';
import departmentService from '../shared/services/departmentService';
import branchOfIndustryService from '../shared/services/branchOfIndustryService';

export const DepartmentConfigurations: FC<{
  formik: FormikProps<IUserEditViewModel>;
  tenant: ITenantConfiguration;
  currentBranchOfIndustryPath: string;
  assignableDepartments: IDepartmentRefWithTenantAndBranch[];
  permissions: UserPermissions;
  assignedTenants: ITenantConfiguration[];
}> = ({ formik, currentBranchOfIndustryPath, assignableDepartments, permissions, tenant, assignedTenants }) => {
  const { values } = formik;
  const [t] = useTranslation();
  const tr = getTranslateFunc(t);
  const currentBranchOfIndustry: IBranchOfIndustryConfiguration = getIn(values, currentBranchOfIndustryPath);
  const departmentsPath = currentBranchOfIndustryPath + '.assignedDepartments';
  const context = useContext(UserInfoCtx);
  const assignedDepartments = getAssignedDepartments(values, currentBranchOfIndustryPath);
  assignableDepartments = _.orderBy(assignableDepartments, (item) => item != null && getForLanguage(item.name, context.languageSettings), ['asc']);
  const [alertStringDic, setAlertString] = useState<{ [departmentId: string]: AlertStringProps } | null>(null);

  useEffect(() => {
    const fetchAlertString = async () => {
      if (alertStringDic != null) {
        return;
      }
      const target: { [departmentId: string]: AlertStringProps } | null = {};
      for (const x of assignedDepartments) {
        const department = await departmentService.getItemByTenantAndDepartmentId(tenant.id, x.id);
        if (department?.copyClaimsFrom) {
          const branches = await branchOfIndustryService.getAllBranchesOfIndustryByTenantId(department?.copyClaimsFrom?.tenantId);
          const branch = branches.find((x) => x.id === department?.copyClaimsFrom?.branchOfIndustryId);
          const syncTenant = assignedTenants.find((x) => x.id === department?.copyClaimsFrom?.tenantId);

          target[department.id] = {
            tenantName: syncTenant?.name,
            branchName: getForLanguage(branch?.name, context.languageSettings),
            departmentName: getForLanguage(department?.copyClaimsFrom?.name, context.languageSettings),
          };
        }
      }
      setAlertString(target);
    };
    fetchAlertString();
  }, [assignedDepartments, assignedTenants, context.languageSettings, alertStringDic]);

  const getAlertStringComponent = (props: AlertStringProps | null) => {
    if (!props || !props.tenantName || !props.branchName || !props.departmentName)
      return (
        <>
          {tr('userRights')}
          <b>{tr('failure')}</b>
        </>
      );
    return (
      <>
        {tr('userRights')}
        <b>
          ({props.tenantName} / {props.branchName} / {props.departmentName})
        </b>
      </>
    );
  };

  return (
    <>
      <FieldArray
        name={departmentsPath}
        render={(arrayHelpers) => (
          <>
            {!tenant.accessFromDepartmentSync && (
              <FormBox style={{ marginTop: '1rem' }} title={tr('departmentAssignments')}>
                <TagGroup
                  title={tr('availableDepartments')}
                  tags={getNotAssignedDepartmentsTags(context.languageSettings, permissions, currentBranchOfIndustry, assignableDepartments, assignedDepartments)}
                  onClick={(t) => {
                    arrayHelpers.push(createDepartmentConfig(t.object as IDepartmentRefWithTenantAndBranch));
                  }}
                />
              </FormBox>
            )}

            <FormTrLabel text={tr('departmentConfiguration', { branchOfIndustry: getForLanguage(currentBranchOfIndustry.name, context.languageSettings) })} />
            {assignedDepartments.length > 0 ? (
              assignedDepartments.map((department, index) => (
                <Card
                  key={index}
                  className={styles.card}
                  headStyle={{ background: '#f1f1f1' }}
                  title={
                    tenant.accessFromDepartmentSync !== true ? (
                      getForLanguage(department.name, context.languageSettings)
                    ) : (
                      <span>
                        {getForLanguage(department.name, context.languageSettings)} {getAlertStringComponent(alertStringDic ? alertStringDic[department.id] : null)}
                      </span>
                    )
                  }
                  type="inner"
                >
                  {permissions.security.canEditDepartment({
                    id: department?.id,
                    name: department?.name,
                    tenantId: currentBranchOfIndustry?.tenantId,
                    branchOfIndustryId: currentBranchOfIndustry?.id,
                  }) && (
                    <>
                      <Row>
                        <Col span={24}>
                          <div className="permissionscontainer">
                            <PermissionSlider
                              disabled={tenant.accessFromDepartmentSync === true}
                              formik={formik}
                              name={`${departmentsPath}[${index}].hasDepartmentRights`}
                              label={tr('permissionSettings.hasDepartmentRights')}
                            />
                            <PermissionSlider
                              disabled={tenant.accessFromDepartmentSync === true}
                              formik={formik}
                              name={`${departmentsPath}[${index}].isEmailReceiver`}
                              label={tr('permissionSettings.isEmailReceiver')}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Popconfirm
                            disabled={tenant.accessFromDepartmentSync === true}
                            title={tr('deleteConfirmMessage')}
                            onConfirm={() => arrayHelpers.remove(index)}
                            okText={tr('yes')}
                            cancelText={tr('no')}
                          >
                            <Button disabled={tenant.accessFromDepartmentSync === true} style={{ marginTop: '1rem' }}>
                              {tr('removeDepartmentAssignment')}
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              ))
            ) : (
              <Alert style={{ margin: '1rem' }} message={tr('atLeastOneDepartmentMessage')} type="warning" />
            )}
          </>
        )}
      />
    </>
  );
};
export default DepartmentConfigurations;

function getAssignedDepartments(values: IUserEditViewModel, branchPath: string): IDepartmentConfiguration[] {
  return getIn(values, branchPath + '.assignedDepartments') || [];
}

function getNotAssignedDepartmentsTags(
  settings: LanguageSettings,
  permissions: UserPermissions,
  branchOfIndustry: IBranchOfIndustryConfiguration,
  assignableDepartments: IDepartmentRefWithTenantAndBranch[],
  assignedDepartments: IDepartmentConfiguration[],
) {
  return _.differenceWith(
    _.filter(assignableDepartments, (a) => a.branchOfIndustryId === branchOfIndustry.id && a.tenantId === branchOfIndustry.tenantId),
    assignedDepartments,
    (a, b) => a.id === b.id,
  )
    .filter((d) => permissions.security.canAssignUserToDepartment(d))
    .map((ref) => ({ tag: ref.id, title: getForLanguage(ref.name, settings), object: ref }));
}

function getTranslateFunc(t: TFunction) {
  return (key: string, values: any = undefined) => t('users.userEditForm.' + key, values);
}

interface AlertStringProps {
  tenantName: string;
  branchName: string;
  departmentName: string;
}
