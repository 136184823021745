import SettingsService from './settingsService';
import Axios from 'axios';
import { RiskAssessmentReportTypes, DepartmentEvaluationReport, ApprovalReport, EvaluationSummaryReport, OrderReleaseReport, POAReport } from '../../riskAssessments/riskAssessment/reporting/model';
import { userService } from './userService';

const createReportInternal = async (token: string, languageKey: string, reportType: string, tenantId: string, branchId: string, departmentId?: string, flowId?: string): Promise<Blob> => {
  const appConfig = SettingsService.getConfig();
  const user = userService.getCurrentUser();
  const logo = userService.getImageForTenant(tenantId);
  const templateName = logo.substr(0, logo.indexOf('.'));

  const template = `&DocTemplate=${encodeURIComponent(`${appConfig.doxTemplateLocation}${reportType}\\${templateName}.docx`)}`;
  const department = `&InputParam.departmentId=${departmentId}`;
  const branch = `&InputParam.risk-branchofindustryid=${branchId}`;
  const tenant = `&InputParam.risk-tenantid=${encodeURI(tenantId)}`;
  const assessment = `&InputParam.assessmentId=${encodeURI(token)}`;
  const authorization = `&InputParam.authorization=${user.access_token}`;
  const flow = `&InputParam.flowId=${flowId}`;
  const language = `&InputParam.langKey=${languageKey}`;
  const apiLocation = `&InputParam.riskApiLocation=${appConfig.apiInstanceUrl}${appConfig.riskApiLocation}`;

  const instance = Axios.create();
  const result = await instance.get<Blob>(
    `${appConfig.doxWebServiceLocation}?Operation=GenerateDocument${apiLocation}${template}${language}${department}${branch}${assessment}${tenant}${authorization}${flow}&ReturnAction.FileName=Report.pdf&ReturnAction.dist=attachment&ReturnAction.Format=pdf`,
    { headers: { 'Content-Type': 'application/pdf', Accept: 'application/pdf' }, responseType: 'blob' },
  );
  return result.data;
};

const createReport = async (token: string, languageKey: string, reportData: RiskAssessmentReportTypes, tenantId: string, branchId: string) => {
  let report: Blob = null;
  if (reportData instanceof DepartmentEvaluationReport) {
    report = await createReportInternal(token, languageKey, reportData.reportType, tenantId, branchId, reportData.departmentId, '0');
  } else if (reportData instanceof ApprovalReport) {
    report = await createReportInternal(token, languageKey, reportData.reportType, tenantId, branchId, reportData.approvalStageId, reportData.flowId);
  } else if (reportData instanceof EvaluationSummaryReport) {
    report = await createReportInternal(token, languageKey, reportData.reportType, tenantId, branchId, '0', '0');
  } else if (reportData instanceof POAReport) {
    report = await createReportInternal(token, languageKey, reportData.reportType, tenantId, branchId, '0', reportData.flowId);
  } else if (reportData instanceof OrderReleaseReport) {
    report = await createReportInternal(token, languageKey, reportData.reportType, tenantId, branchId, '0', reportData.flowId);
  }

  return report;
};

export default {
  createReport,
};
