import React, { SFC } from 'react';
import { Tag } from 'antd';

import styles from './TagGroup.module.css';

const TagGroup: SFC<ITagGroupProps> = ({ title, tags, onClick }) => {
  return (
    <div className={styles.container}>
      <div>
        <h6 className={styles.title}>{title}</h6>
      </div>
      <div className={styles.tagcontainer}>
        {tags.map((tag) => (
          <Tag className={styles.tag} key={tag.tag} onClick={() => onClick(tag)}>
            {tag.title}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default TagGroup;

interface ITagGroupProps {
  tags: ITag[];
  title: string;
  onClick: (tag: ITag) => void;
}

export interface ITag {
  tag: string;
  title: string;
  object?: any;
}
