import React, { PureComponent } from 'react';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import styles from './index.module.css';

interface IHeaderDropdownProps {
  placement?: any;
  overlayClassName?: string;
  overlay: JSX.Element;
  trigger?: any;
  onVisibleChange?: (visible: boolean) => void;
}
export default class HeaderDropdown extends PureComponent<IHeaderDropdownProps> {
  render() {
    const { overlayClassName, trigger, ...props } = this.props;
    return <Dropdown trigger={trigger} overlayClassName={classNames(styles.headerDropdown, styles.container, overlayClassName)} {...props} />;
  }
}
