import { IRiskAssessment } from '../../../models/riskAssessment';

export interface IGenerateReportFunc {
  (reportGenerationData: any, riskAssessment: IRiskAssessment, tenantId: string, branchId: string): Promise<void>;
}

export type RiskAssessmentReportTypes = DepartmentEvaluationReport | ApprovalReport | EvaluationSummaryReport | OrderReleaseReport | POAReport;

export class DepartmentEvaluationReport {
  private offerNr: string;
  reportType = 'FA';

  constructor(public departmentId: string, riskAssessment: IRiskAssessment) {
    this.offerNr = riskAssessment.metadata.offerNr;
  }

  getDownloadFileName(): string {
    return `EvaluationReport_${this.offerNr}.pdf`;
  }
}

export class ApprovalReport {
  private offerNr: string;
  reportType = 'GF';
  constructor(public approvalStageId: string, public flowId: string, riskAssessment: IRiskAssessment) {
    this.offerNr = riskAssessment.metadata.offerNr;
  }

  getDownloadFileName(): string {
    return `ApprovalReport_${this.offerNr}.pdf`;
  }
}

export class OrderReleaseReport {
  private offerNr: string;
  reportType = 'OrderRelease';
  constructor(public approvalStageId: string, public flowId: string, riskAssessment: IRiskAssessment) {
    this.offerNr = riskAssessment.metadata.offerNr;
  }

  getDownloadFileName(): string {
    return `OrderReleaseReport_${this.offerNr}.pdf`;
  }
}

export class POAReport {
  private offerNr: string;
  reportType = 'POA';
  constructor(public approvalStageId: string, public flowId: string, riskAssessment: IRiskAssessment) {
    this.offerNr = riskAssessment.metadata.offerNr;
  }

  getDownloadFileName(): string {
    return `POAReport_${this.offerNr}.pdf`;
  }
}

export class EvaluationSummaryReport {
  private offerNr: string;
  reportType = 'General';

  constructor(riskAssessment: IRiskAssessment) {
    this.offerNr = riskAssessment.metadata.offerNr;
  }

  getDownloadFileName(): string {
    return `GeneralReport_${this.offerNr}.pdf`;
  }
}
