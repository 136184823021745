import { connect } from 'react-redux';
import { IRootState, getUserInfo, getActiveTenant, getAvailableBranchesOfIndustry, getActiveBranchOfIndustry } from './ducks/reducer';
import AppHeaderComponent from './AppHeaderComponent';
import { selectBranchOfIndustrySettingForUser, selectLanguageForUser } from './branchselection/actions';

const mapStateToProps = (state: IRootState) => {
  return {
    user: getUserInfo(state.app),
    activeTenant: getActiveTenant(state.app),
    availableBranchesOfIndustry: getAvailableBranchesOfIndustry(state.app),
    activeBranchOfIndustry: getActiveBranchOfIndustry(state.app),
  };
};
export default connect(mapStateToProps, {
  selectActiveBranchOfIndustrySetting: selectBranchOfIndustrySettingForUser.request,
  selectLanguage: selectLanguageForUser.request,
})(AppHeaderComponent);
