import React, { FC } from 'react';
import _ from 'lodash';
import Menu from 'antd/lib/menu';
import { Dropdown } from 'antd';
import { getSubmenuAction } from '../SizeDetector';

const ButtonWithDropDown: FC<ButtonWithDropDownProps> = ({ id, dropdownOptions, disabled, onClick, dataAutomationId }) => {
  const menu = (
    <Menu triggerSubMenuAction={getSubmenuAction()} onClick={(e: any) => onClick(e.key)}>
      {_.map(dropdownOptions, (option) => (
        <Menu.Item key={option.value}>{option.name}</Menu.Item>
      ))}
    </Menu>
  );
  const buttonOption = dropdownOptions[0];

  return (
    <Dropdown.Button disabled={disabled} onClick={() => onClick(buttonOption.value)} overlay={menu} data-automation-id={dataAutomationId || id}>
      {buttonOption.name}
    </Dropdown.Button>
  );
};

export default ButtonWithDropDown;

interface ButtonWithDropDownProps {
  id?: string;
  disabled: boolean;
  dropdownOptions: IBWDOption[];
  onClick: (clickedValue: string) => void;
  dataAutomationId?: string;
}

export interface IBWDOption {
  name: string;
  value: string;
}
