import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ActionType, isActionOf } from 'typesafe-actions';
import { IRootState } from '../../ducks/reducer';
import * as actions from './actions';
import { IUsersEditViewModel, IUserEditViewModel, IUserAddViewModel } from '../../models/user';
import HttpClient from '../../shared/services/httpClient';
export type Action = ActionType<typeof actions>;

export const getAllUsersFlow: Epic<Action, Action, IRootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getAllUsersForEdit.request)),
    switchMap((action) =>
      from(getItems()).pipe(
        map(actions.getAllUsersForEdit.success),
        catchError((error) => of(actions.getAllUsersForEdit.failure(error))),
      ),
    ),
  );

export const saveEditUserFlow: Epic<Action, Action, IRootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.saveEditUser.request)),
    switchMap((action) =>
      from(saveUser(action.payload)).pipe(
        map(actions.saveEditUser.success),
        catchError((error) => of(actions.saveEditUser.failure(error))),
      ),
    ),
  );

export const addAndSelectNewUserFlow: Epic<Action, Action, IRootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.addAndSelectNewUser.request)),
    switchMap((action) =>
      from(addUser(action.payload)).pipe(
        map(actions.addAndSelectNewUser.success),
        catchError((error) => of(actions.addAndSelectNewUser.failure(error))),
      ),
    ),
  );

export const loadUserWithCopiedClaimsFlow: Epic<Action, Action, IRootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.loadUserWithCopiedClaims.request)),
    switchMap((action) =>
      from(getUserWithCopiedClaims(action.payload)).pipe(
        map(actions.loadUserWithCopiedClaims.success),
        catchError((error) => of(actions.loadUserWithCopiedClaims.failure(error))),
      ),
    ),
  );

const getItems = async (): Promise<IUsersEditViewModel> => {
  const response = await HttpClient.getInstance().get<IUsersEditViewModel>('users');
  return response.data;
};

const saveUser = async (user: IUserEditViewModel): Promise<IUserEditViewModel> => {
  const response = await HttpClient.getInstance().post<IUserEditViewModel>(`users/${user.id}`, user);
  return response.data;
};

const addUser = async (user: IUserAddViewModel): Promise<IUserEditViewModel> => {
  const response = await HttpClient.getInstance().post<IUserEditViewModel>(`users`, user);
  return response.data;
};

const getUserWithCopiedClaims = async (copyInfo: { userId: string; userToCopyId: string }) => {
  const response = await HttpClient.getInstance().post<IUserEditViewModel>(`users/claims/${copyInfo.userId}`, copyInfo);
  return response.data;
};

export const userEpics = combineEpics(getAllUsersFlow, saveEditUserFlow, addAndSelectNewUserFlow, loadUserWithCopiedClaimsFlow);
