import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IRiskAssessmentEditVm, IRiskAssessment } from '../../../models/riskAssessment';
import { HtcCardWrapper, HtcCard } from '../../../shared/HtcCardComponent';
import { FormikProps } from 'formik';
import nameof from 'ts-nameof.macro';
import {
  FormRow,
  FormTextInput,
  DoubleFormRow,
  FormTextAreaInput,
  FormCustomerPicker,
  FormCountryPicker,
  FormIncotermPicker,
  FormDateInput,
  FormGrid,
  FormRadioYesNo,
  FormMachineTypeTreePicker,
} from '../../../shared/FormComponents';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { PermissionCtx } from '../RiskAssessmentContext';
import { UserInfoCtx } from '../../../UserInfoContext';
import { AssessmentPageHeader } from '../assessment/PageHeader/PageHeader';
import { AssessmentPageContent } from '../assessment/PageContent/PageContent';
import { useTenantBranch } from '../../../TenantBranchContext';
import { KnownTenantIds } from '../../../models';

const TP = 'riskAssessment.editform.metadata.';
type RA = IRiskAssessment;

export const BasicMetadata: FC<IBasicMetadataProps> = React.memo((props) => {
  const permissions = useContext(PermissionCtx);
  const userCtx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const { tenantId, branchId } = useTenantBranch();
  const { formik, riskAssessmentVm } = props;

  const config = riskAssessmentVm.commonConfig;
  const readOnly = permissions.permissionHelper.isBasicMetaDataReadOnly();
  const pspReadonly = permissions.permissionHelper.isPspElementReadOnly();
  const hasVendorLegalEntity = config.hasVendorLegalEntity;
  const hasMachineType = config.hasMachineType;
  const hasConstructionSiteCountry = config.hasConstructionSiteCountry;
  const assessmentBranchId = props.riskAssessmentVm.assessment.metadata.branchOfIndustry.id;
  return (
    <>
      <AssessmentPageHeader mainLevelTr="metadata" current="basicmetadata" />
      <AssessmentPageContent>
        <HtcCardWrapper>
          <HtcCard>
            <FormBox title={t(TP + 'metadata')}>
              <FormRow>
                <FormTextInput
                  {...formik}
                  required
                  name={nameof.full<RA>((n) => n.metadata.offerNr)}
                  label={t('riskAssessment.offerNr')}
                  disabled={readOnly || !permissions.permissionHelper.isProjectAdmin()}
                />
                <FormCustomerPicker
                  {...formik}
                  required
                  name={nameof.full<RA>((n) => n.metadata.customer)}
                  label={t('riskAssessment.customer')}
                  disabled={readOnly}
                  forBranchId={assessmentBranchId}
                  onChange={() => {}}
                />
                {config.hasNewCustomerFlag && <FormRadioYesNo {...formik} name={nameof.full<RA>((n) => n.metadata.isNewCustomer)} label={t('riskAssessment.isNewCustomer')} disabled={readOnly} />}
                {config.hasProjectDiagnosis && <FormTextInput {...formik} name={nameof.full<RA>((n) => n.metadata.projectDiagnosis)} label={t('riskAssessment.projectDiagnosis')} />}
              </FormRow>
              <FormGrid>
                <FormIncotermPicker
                  {...formik}
                  required
                  name={nameof.full<RA>((n) => n.metadata.incoterms)}
                  label={t('riskAssessment.incoterms')}
                  disabled={readOnly}
                  forBranchId={assessmentBranchId}
                />
                <FormTextInput {...formik} required name={nameof.full<RA>((n) => n.metadata.city)} label={userCtx.t(config.incotermsSupplement)} disabled={readOnly} />
                <FormTextInput {...formik} required name={nameof.full<RA>((n) => n.metadata.vendor)} label={userCtx.t(config.vendor)} disabled={readOnly} />
                {hasVendorLegalEntity && <FormTextInput {...formik} name={nameof.full<RA>((n) => n.metadata.vendorLegalEntity)} label={t('riskAssessment.vendorLegalEntity')} />}
                <FormTextInput {...formik} name={nameof.full<RA>((n) => n.metadata.pspElement)} label={userCtx.t(config.commissionNumber)} disabled={pspReadonly} />
                <FormDateInput {...formik} required name={nameof.full<RA>((n) => n.metadata.dueDate)} label={t('riskAssessment.dueDate')} disabled={readOnly} />
                <FormCountryPicker {...formik} required name={nameof.full<RA>((n) => n.metadata.country)} label={t('riskAssessment.country')} disabled={readOnly} forBranchId={assessmentBranchId} />
                {hasMachineType && <FormMachineTypeTreePicker disabled={readOnly} required {...formik} name={nameof.full<RA>((n) => n.metadata.machineType)} label={t('riskAssessment.machineType')} />}
                {hasMachineType && <FormTextInput {...formik} name={nameof.full<RA>((n) => n.metadata.machineTypeInfo)} label={t('riskAssessment.machineTypeInfo')} disabled={readOnly} />}
                {hasConstructionSiteCountry && (
                  <FormCountryPicker
                    {...formik}
                    required
                    name={nameof.full<RA>((n) => n.metadata.constructionSiteCountry)}
                    label={t('riskAssessment.constructionSiteCountry')}
                    disabled={readOnly}
                    forBranchId={assessmentBranchId}
                  />
                )}
              </FormGrid>
            </FormBox>
          </HtcCard>
          <HtcCard>
            <FormBox title={t(TP + 'information')}>
              <DoubleFormRow>
                <FormTextAreaInput {...formik} required rows={8} name={nameof.full<RA>((n) => n.metadata.conditionsOfPayment)} label={t(TP + 'conditionsOfPayment')} disabled={readOnly} />
                <FormTextAreaInput
                  {...formik}
                  required
                  rows={8}
                  name={nameof.full<RA>((n) => n.metadata.internalComment)}
                  label={t(TP + 'internalComment')}
                  placeholder={tenantId === KnownTenantIds.Bms ? t(TP + 'internalCommentPlaceholder') : t('')}
                  disabled={readOnly}
                />
              </DoubleFormRow>
            </FormBox>
          </HtcCard>
        </HtcCardWrapper>
      </AssessmentPageContent>
    </>
  );
});

BasicMetadata.displayName = 'BasicMetadata';

interface IBasicMetadataProps {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
}
