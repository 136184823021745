import _ from 'lodash';
import { connect } from 'react-redux';
import { IRootState, getActiveTenant, getAvailableTenants } from '../ducks/reducer';
import { setActiveTenant } from './ducks/actions';
import TenantSelectionComponent from './TenantSelectionComponent';
import { IUserTenantInfo } from '../models/masterdata/ITenant';

const mapStateToProps = (state: IRootState) => {
  return {
    tenants: sortTenants(getAvailableTenants(state.app)),
    selectedTenant: getActiveTenant(state.app),
  };
};

const sortTenants = (tenants: IUserTenantInfo[]) => {
  let sortMap = new Map<string, number>([
    ['BMS', 0],
    ['BST', 1],
    ['KGF', 3],
    ['PSG', 4],
    ['BGC', 5],
  ]);

  const getSortValue = (tenant: IUserTenantInfo) => {
    if (sortMap.has(tenant.abbr)) {
      return sortMap.get(tenant.abbr);
    } else {
      return 100;
    }
  };

  return tenants.sort((a, b) => getSortValue(a) - getSortValue(b));
};

export default connect(mapStateToProps, {
  selectActiveTenant: setActiveTenant.request,
})(TenantSelectionComponent);
