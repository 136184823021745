import { riskAssessmentEpics } from '../../riskAssessments/ducks/epics';
import { IRiskAssessment } from '../riskAssessment';
import _ from 'lodash';

export interface IDocumentMetadata {
  id: string;
  fileName: string;
  comment: string;
  isParaphrased: boolean;
  isArchived?: boolean;
  archivedBy?: string;
  isCheckedOut?: boolean;
  checkedOutBy?: string;
  checkedOutAt?: string;
  documentType: DocumentTypes;
  owner: DocumentMetadataOwnerTypes;
  history: DocumentMetadataHistory;
}

export interface DocumentMetadataHistory {
  versions: DocumentMetadataVersion[];
}

export interface DocumentMetadataVersion {
  version: string;
  timestamp: Date;
  userId: string;
  userName: string;
}

export enum RiskAssessmentOwnerTypes {
  RiskAssessmentOwner = 'RiskAssessmentOwner',
  ApprovalStageOwner = 'ApprovalStageOwner',
  DepartmentEvaluationOwner = 'DepartmentEvaluationOwner',
  SalesAndApprovalOwner = 'SalesAndApprovalOwner',
  SafeOwner = 'SafeOwner',
}

export type DocumentMetadataOwnerTypes = RiskAssessmentOwner | DepartmentEvaluationOwner | ApprovalStageOwner;
export class RiskAssessmentOwner {
  riskAssessmentId: string;
  ownerType = RiskAssessmentOwnerTypes.RiskAssessmentOwner;
  constructor(id: string) {
    this.riskAssessmentId = id;
  }
}

export class DepartmentEvaluationOwner {
  riskAssessmentId: string;
  departmentId: string;
  ownerType = RiskAssessmentOwnerTypes.DepartmentEvaluationOwner;

  constructor(id: string, dId: string) {
    this.riskAssessmentId = id;
    this.departmentId = dId;
  }
}

export class ApprovalStageOwner {
  riskAssessmentId: string;
  approvalStageId: string;
  ownerType = RiskAssessmentOwnerTypes.ApprovalStageOwner;

  constructor(id: string, aId: string) {
    this.riskAssessmentId = id;
    this.approvalStageId = aId;
  }
}

export class SalesAndApprovalOwner {
  riskAssessmentId: string;
  ownerType = RiskAssessmentOwnerTypes.SalesAndApprovalOwner;

  constructor(id: string) {
    this.riskAssessmentId = id;
  }
}

export class SafeOwner {
  riskAssessmentId: string;
  ownerType = RiskAssessmentOwnerTypes.SafeOwner;

  constructor(id: string) {
    this.riskAssessmentId = id;
  }
}

export enum DocumentTypes {
  Miscellaneous = 'Miscellaneous',
  OfferOrderContract = 'OfferOrderContract',
  Calculation = 'Calculation',
  ClearanceDocuments = 'ClearanceDocuments',
  SafeDocuments = 'SafeDocuments',
}

export function createDocumentMetaData(options: Partial<IDocumentMetadata> = {}): IDocumentMetadata {
  return Object.assign(
    {
      id: '',
      fileName: '',
      comment: '',
      isParaphrased: false,
      documentType: DocumentTypes.Miscellaneous,
      owner: null,
      history: {
        versions: null,
      },
    },
    options,
  );
}

export function createDocumentMetadataForOfferOrderContract(owner: DocumentMetadataOwnerTypes): IDocumentMetadata {
  return createDocumentMetaData({ owner: owner, documentType: DocumentTypes.OfferOrderContract });
}

export function createDocumentMetadataForCalculation(owner: DocumentMetadataOwnerTypes): IDocumentMetadata {
  return createDocumentMetaData({ owner: owner, documentType: DocumentTypes.Calculation });
}

export function createDocumentMetadataForMisc(owner: DocumentMetadataOwnerTypes): IDocumentMetadata {
  return createDocumentMetaData({ owner: owner, documentType: DocumentTypes.Miscellaneous });
}

export function createDocumentMetadataForClearance(riskAssessment: IRiskAssessment): IDocumentMetadata {
  return createDocumentMetaData({ owner: new SalesAndApprovalOwner(riskAssessment.id), documentType: DocumentTypes.ClearanceDocuments });
}

export function createDocumentMetadataForSafe(riskAssessment: IRiskAssessment): IDocumentMetadata {
  return createDocumentMetaData({ owner: new SafeOwner(riskAssessment.id), documentType: DocumentTypes.SafeDocuments });
}

function getDocuments(riskAssessment: IRiskAssessment, type: RiskAssessmentOwnerTypes, docType: DocumentTypes): any {
  if (riskAssessment.documents == null) {
    return [];
  }
  return _.filter(riskAssessment.documents.documentMetadata, (d) => d.owner.ownerType === type && d.documentType === docType);
}

export function getMetadataDocuments(riskAssessment: IRiskAssessment, docType: DocumentTypes): any {
  return getDocuments(riskAssessment, RiskAssessmentOwnerTypes.RiskAssessmentOwner, docType);
}
export function getEvaluationDocuments(riskAssessment: IRiskAssessment, docType: DocumentTypes, departmentId: string): any {
  const docs = getDocuments(riskAssessment, RiskAssessmentOwnerTypes.DepartmentEvaluationOwner, docType);
  return _.filter(docs, (d) => d.owner.departmentId == departmentId);
}
export function getApprovalDocuments(riskAssessment: IRiskAssessment, docType: DocumentTypes, approvalId: string): any {
  const docs = getDocuments(riskAssessment, RiskAssessmentOwnerTypes.ApprovalStageOwner, docType);
  return _.filter(docs, (d) => d.owner.approvalStageId == approvalId);
}

export function getClearanceDocuments(riskAssessment: IRiskAssessment) {
  const docs = getDocuments(riskAssessment, RiskAssessmentOwnerTypes.SalesAndApprovalOwner, DocumentTypes.ClearanceDocuments);
  return docs;
}

export function getSafeDocuments(riskAssessment: IRiskAssessment) {
  const docs = getDocuments(riskAssessment, RiskAssessmentOwnerTypes.SafeOwner, DocumentTypes.SafeDocuments);
  return docs;
}
