import * as _ from 'lodash';
import { IGeneralSettingsEditVm } from '../../models/masterdata';
import HttpClient from './httpClient';

const saveGeneralSettings = async (item: Partial<IGeneralSettingsEditVm>): Promise<IGeneralSettingsEditVm> => {
  const result = await HttpClient.getInstance().post<IGeneralSettingsEditVm>(`generalSettings`, item);
  return result.data;
};

const getGeneralSettingsForEdit = async (): Promise<IGeneralSettingsEditVm> => {
  const result = await HttpClient.getInstance().get<IGeneralSettingsEditVm>(`generalSettings`);
  return result.data;
};

export default {
  saveGeneralSettings: saveGeneralSettings,
  getGeneralSettingsForEdit: getGeneralSettingsForEdit,
};
