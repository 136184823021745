import _ from 'lodash';
import { IMultiLanguageString } from '../IMultiLanguageString';
import { IEmailReceiver, IMessageTemplate, ApprovalFlowTypes } from './IRiskAssessment';
import { IBaseRef } from '../masterdata/IBaseRef';
import { IDepartmentRef } from '../masterdata';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { TFunction } from 'i18next';
import { ICheckableTag } from '../../shared/components/CheckableTagGroup/CheckableTagGroup';

export const NewRiskAssessmentTemplateId = 'new';
export interface IRiskAssessmentTemplate extends IBaseRef {
  id: string;
  name: IMultiLanguageString;
  hasMultipleApprovalFlows: boolean;
  notifications: IRiskAssessmentNotifications;
  additionalCreatorCcReceiverUserIds: string[];
  salesDepartments: IRiskAssessmentDepartments;
  evaluationFlow: IRiskAssessmentEvaluationFlow;
  approvalFlows: IRiskAssessmentApprovalFlowTemplates;
  salesDepartmentOptions: IDepartmentRef[];
  approvalStageOptions: IDepartmentRef[];
  assignedDepartmentOptions: IDepartmentRef[];
  additionalCreatorCcReceiversOptions: IEmailReceiver[];
}

export interface IRiskAssessmentApprovalFlowTemplates {
  flows: IRiskAssessmentApprovalFlowTemplate[];
}

export interface IRiskAssessmentApprovalFlowTemplate {
  id: string;
  name: IMultiLanguageString;
  flowType: ApprovalFlowTypes;
  stages: IApprovalStageTemplate[];
}
export enum ApprovalTypes {
  BLV = 'BLV',
  GFBMS = 'GFBMS',
  GFBGG = 'GFBGG',
}

export interface IApprovalStageTemplate {
  id: string;
  type: 'ApprovalDepartmentStageTemplate';
  canFinish: boolean;
  hasEmailReceivers: boolean;
  name: IMultiLanguageString;
  department: IDepartmentRef;
  approvalType?: ApprovalTypes;
}

export interface IRiskAssessmentEvaluationFlow {
  stages: IRiskAssessmentEvaluationStageTemplate[];
}

export interface IRiskAssessmentEvaluationStageTemplate {
  id: string;
  name: IMultiLanguageString;
  isRequired: boolean;
  isAlwaysInvolved: boolean;
  isAssessmentRequired: boolean;
  hasEmailReceivers: boolean;
}

export interface IDepartmentEvaluationStageTemplate extends IRiskAssessmentEvaluationStageTemplate {
  department: IDepartmentRef;
  type: 'DepartmentEvaluationStage';
}

export interface IRiskAssessmentNotifications {
  statusChangedTemplate: IMessageTemplate;
  reminderMailTemplate: IMessageTemplate;
  evaluationCompletedTemplate: IMessageTemplate;
  projectApprovalTemplate: IMessageTemplate;
  projectApprovedTemplate: IMessageTemplate;
}

export interface IRiskAssessmentTemplateRef {
  id: string;
  name: IMultiLanguageString;
}

export function createRiskAssessmentTemplate(options: Partial<IRiskAssessmentTemplate> = {}): IRiskAssessmentTemplate {
  return Object.assign(
    {
      id: '',
      name: '',
      hasMultipleApprovalFlows: false,
      notifications: {
        statusChangedTemplate: IMultiLanguageString.empty(),
        reminderMailTemplate: IMultiLanguageString.empty(),
        evaluationCompletedTemplate: IMultiLanguageString.empty(),
        projectApprovalTemplate: IMultiLanguageString.empty(),
        projectApprovedTemplate: IMultiLanguageString.empty(),
      },
      additionalCreatorCcReceiverUserIds: [],
      salesDepartments: [],
      evaluationFlow: { stages: [] },
      assignedDepartments: [],
      approvalStages: [],
      approvalStageOptions: [],
      salesDepartmentOptions: [],
      assignedDepartmentOptions: [],
      additionalCreatorCcReceiversOptions: [],
      approvalFlows: {
        flows: [],
      },
    },
    options,
  );
}

export interface IRiskAssessmentDepartments {
  departments: IRiskAssessmentDepartment[];
}

export interface IRiskAssessmentDepartment {
  id: string;
  name: IMultiLanguageString;
  isRequired: boolean;
  isAlwaysInvolved: boolean;
  isAssessmentRequired: boolean;
  hasEmailReceivers: boolean;
  mustParticipate: true;
}

export interface IRiskAssessmentDepartmentEmailReceiverConfig {
  id: string;
  name: IMultiLanguageString;
  hasEmailReceivers?: boolean;
  mustParticipate: boolean;
  isRequired: boolean;
}

export interface IRiskAssessmentDepartmentEmailReceiver extends IRiskAssessmentDepartmentEmailReceiverConfig {
  emailReceivers: IEmailReceiver[];
}

export function getApprovalTypeTags(t: TFunction): ICheckableTag[] {
  return ['BLV', 'GFBMS', 'GFBGG'].map((v) => ({
    tag: v,
    title: t('masterdata.riskAssessmentTemplates.approvalTypes.' + v),
  }));
}

export function createRiskApprovalStage(options: Partial<IApprovalStageTemplate> = {}): IApprovalStageTemplate {
  return Object.assign(
    {
      id: '',
      name: '',
      type: 'ApprovalDepartmentStageTemplate' as const,
      canFinish: false,
      department: null,
      hasEmailReceivers: false,
    },
    options,
  );
}

export function getDepartmentEvaluationStages(template: IRiskAssessmentTemplate) {
  // TODO Type Guard
  return template.evaluationFlow.stages as IDepartmentEvaluationStageTemplate[];
}

export function createDepartmentEvaluationStageTemplate(departmentRef: IDepartmentRef): IDepartmentEvaluationStageTemplate {
  return {
    id: departmentRef.id,
    name: departmentRef.name,
    department: departmentRef,
    isRequired: false,
    isAlwaysInvolved: false,
    isAssessmentRequired: false,
    type: 'DepartmentEvaluationStage',
    hasEmailReceivers: false,
  };
}

export function createDepartmentApprovalStageTemplate(departmentRef: IDepartmentRef | IDepartmentRef[]): IApprovalStageTemplate {
  return createRiskApprovalStage({ id: (departmentRef as IDepartmentRef).id, name: (departmentRef as IDepartmentRef).name, department: departmentRef as IDepartmentRef });
}

export function getApprovalStagesForFlowByIndex(template: IRiskAssessmentTemplate, index: number): IApprovalStageTemplate[] {
  return template.approvalFlows?.flows[index]?.stages || [];
}

export function createApprovalFlow(): IRiskAssessmentApprovalFlowTemplate {
  return {
    id: null,
    flowType: ApprovalFlowTypes.Default,
    name: IMultiLanguageString.empty(),
    stages: [],
  };
}

export function getApprovalStageForFlowById(template: IRiskAssessmentTemplate, flowId: string, stageId: string): IApprovalStageTemplate {
  let flow = _.find(template.approvalFlows.flows, (f) => f.id === flowId);
  return _.find(flow?.stages, (s) => s.id === stageId);
}
