import * as _ from 'lodash';
import { ITopic, ITopicResponse, ITopicWithDepartmentUsages } from '../../models/masterdata';
import HttpClient from './httpClient';
import { UniqueValidation, IUniqueValidationErrorResult } from '../../models/IUniqueValidation';

const getAllTopics = async (): Promise<ITopicWithDepartmentUsages[]> => {
  const result = await HttpClient.getInstance().get<ITopicResponse>(`topics`);
  return getTopicsFromResponse(result.data);
};
const createTopic = async (item: ITopic): Promise<ITopicWithDepartmentUsages> => {
  const result = await HttpClient.getInstance().post<ITopicResponse>(`topics`, item);
  return getTopicsFromResponse(result.data)[0];
};

const editTopic = async (item: ITopic): Promise<ITopicWithDepartmentUsages> => {
  const result = await HttpClient.getInstance().put<ITopicResponse>(`topics/${item.id}`, item);
  return getTopicsFromResponse(result.data)[0];
};

const deleteTopic = async (item: ITopic): Promise<void> => {
  await HttpClient.getInstance().delete(`topics/${item.id}`);
};

const isUnique = async (topic: ITopic): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/topics/isunique`, topic);
  return new UniqueValidation(result.data.validationErrors);
};

function getTopicsFromResponse(response: ITopicResponse): ITopicWithDepartmentUsages[] {
  return _.map(response.topics, (topic) => {
    return topic;
  });
}

export default {
  getAllTopics,
  createTopic,
  editTopic,
  deleteTopic,
  isUnique,
};
