import React, { PureComponent, useContext } from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import Header from './Header';
import { Row, Col, Form } from 'antd';
import {
  IRiskAssessmentEditVm,
  IRiskAssessment,
  getDepartmentStagesToEvaluate,
  IRiskAssessmentPermissions,
  IRiskAssessmentHistoryCollection,
  IRiskAssessmentOnSaveCallback,
  IEnsureDocumentCallback,
  IUploadDocumentsCallback,
  getCurrentApprovalStages,
  IRiskAssessmentConfig,
  getCustomerName,
} from '../../models/riskAssessment';
import { SaveAction, CommandBar, CancelAction, getCurrentStatusMessage } from '../../shared/components/CommandBar/CommandBar';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import _ from 'lodash';
import styles from './RiskAssessmentEdit.module.css';
import { RiskAssessmentUpdates } from '../../models/riskAssessment/RiskAssessmentUpdates';
import MainMenu from './menu/MainMenu';
import { BasicMetadata } from './metadata/BasicMetadata';
import Calculation from './metadata/Calculation';
import { DocumentsMetadata } from './metadata/Documents';
import { Communication } from './overview/Communication';
import EvaluationPage from './assessment/EvaluationPage/EvaluationPage';
import EvaluationSummary from './overview/EvaluationSummary';
import { PermissionCtx } from './RiskAssessmentContext';
import { ValidationError } from 'yup';
import { ValidationErrorDrawer } from './ValidationErrorDrawer';
import { RiskAssessmentPermissionHelper } from './RiskAssessmentPermissionHelper';
import PermissionCheck from '../../shared/components/PermissionCheck/PermissionCheck';
import { getRiskAssessmentEditRoutes } from '../../shared/Routes';
import { RouteComponentProps, withRouter, match, Route, Redirect } from 'react-router';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { UserInfoCtx } from '../../UserInfoContext';
import { notifySaveSuccess } from '../../shared/notificationHelper';
import BasicValidation, { getValidatorForOfferNumber } from '../../models/riskAssessment/RiskAssessmentStatusHelper';
import { IGenerateReportFunc } from './reporting/model';
import { AdditionalInformation } from './metadata/AdditionalInformation';
import { Approval } from './approval/Approval';
import { TFunction } from 'i18next';
import { TenantBranchCtx } from '../../TenantBranchContext';
import { SizeDetector } from '../../shared/components/SizeDetector';

class RiskAssessmentEdit extends PureComponent<IRaEditProps, IRaEditState> {
  state: IRaEditState = {
    riskAssessmentVm: null,
    riskAssessmentInitValues: null,
    showHeaderMenu: false,
    selectedDepartmentId: null,
    showReportButton: false,
    validationError: null,
    hasCancelledEdit: false,
    tenantBranch: { tenantId: null, branchId: null },
    isSmallDevice: false,
  };

  getNavPath = _.memoize((offerNr: string) => {
    const { t } = this.props;
    return [t('riskAssessment.header.riskAssessment'), offerNr || ''];
  });

  static getDerivedStateFromProps(props: IRaEditProps, state: IRaEditState) {
    if (props.riskAssessmentVm != state.riskAssessmentVm) {
      let assessment = props.riskAssessmentVm?.assessment;
      let updates: Partial<IRaEditState> = {
        riskAssessmentVm: _.cloneDeep(props.riskAssessmentVm),
        riskAssessmentInitValues: _.cloneDeep(assessment),
        tenantBranch: {
          tenantId: assessment?.tenantId,
          branchId: assessment?.branchId,
        },
      };

      return updates;
    }
    return null;
  }

  render() {
    const { riskAssessmentVm, hasCancelledEdit, riskAssessmentInitValues, tenantBranch } = this.state;
    const { t, onSave, match, getEvaluationHistory, generateReport, onEnsureDocument, onUploadDocuments } = this.props;

    const permissions = riskAssessmentVm.permissions;
    const config = riskAssessmentVm.commonConfig;
    const routes = getRiskAssessmentEditRoutes(match);
    return (
      <TenantBranchCtx.Provider value={tenantBranch}>
        <SizeDetector onChange={(isSmall) => this.setState({ isSmallDevice: isSmall })} />
        <PermissionCheck hasAccess={riskAssessmentVm.permissions.canReadRiskAssessment}>
          <Formik enableReinitialize={true} initialValues={riskAssessmentInitValues} onSubmit={this.handleSave}>
            {(formik) => {
              const { values } = formik;
              let { isSmallDevice } = this.state;
              return (
                <div className={styles.mainwrapper}>
                  <nav className={styles.mainnav} style={{ display: 'flex', minWidth: 0 }}>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                      <Row>
                        <Col span={24}>
                          <MainMenu riskAssessment={formik.values} permissions={permissions} config={config} />
                        </Col>
                      </Row>
                    </div>
                    <div className={styles.commandbarwrapper}>
                      <CommandBar>
                        <CancelAction onClick={() => this.handleCancel(formik)} />
                        <SaveAction onClick={formik.handleSubmit} />
                      </CommandBar>
                    </div>
                  </nav>
                  <div className={styles.contentScrollWrapper}>
                    <RiskPrompt active={!hasCancelledEdit && RiskAssessmentUpdates.hasUpdates(riskAssessmentVm.assessment, values)} />

                    <CommandBar
                      style={{ margin: '0.5rem 0' }}
                      translatePaths={false}
                      info={values}
                      showInfo={!isSmallDevice}
                      showCategorization={riskAssessmentVm.permissions.canReadCalculation}
                      config={config}
                    />
                    {!isSmallDevice && (
                      <div className={styles.headerwrapper}>
                        <Header metadata={values.metadata} status={values.status} categorization={values.categorization} config={config} template={values.processTemplate} />
                      </div>
                    )}
                    {isSmallDevice && <SmallDeviceInfo assessment={values} config={config} />}
                    <PermissionCtx.Provider
                      value={{
                        permissionHelper: new RiskAssessmentPermissionHelper(riskAssessmentVm.assessment, riskAssessmentVm.permissions),
                      }}
                    >
                      <div className={styles.maincontentwrapper}>
                        <main className={styles.maincontent}>
                          <Form layout="vertical">
                            {createMetadataRoutes(permissions, riskAssessmentVm, formik, onSave, onEnsureDocument, onUploadDocuments, match)}
                            {createAssessmentRoutes(permissions, riskAssessmentVm, formik, onSave, onEnsureDocument, onUploadDocuments, getEvaluationHistory, generateReport, match)}
                            {createOverviewRoutes(permissions, riskAssessmentVm, formik, onSave, generateReport, match)}
                            {createApprovalRoutes(permissions, riskAssessmentVm, formik, onSave, onEnsureDocument, onUploadDocuments, generateReport, match)}
                            <Route exact path={match.url} render={() => <Redirect to={routes.metadata.basicMetadata} />} />
                          </Form>
                        </main>
                      </div>
                    </PermissionCtx.Provider>
                  </div>
                </div>
              );
            }}
          </Formik>
          <UserInfoCtx.Consumer>
            {(userInfoCtx) => (
              <ValidationErrorDrawer
                config={config}
                userInfoCtx={userInfoCtx}
                visible={this.state.validationError != null}
                onCancel={() => this.setState({ validationError: null })}
                validationError={this.state.validationError}
                title={t('riskAssessment.notifications.validationError')}
              />
            )}
          </UserInfoCtx.Consumer>
        </PermissionCheck>
      </TenantBranchCtx.Provider>
    );
  }

  handleCancel = (formik: FormikProps<IRiskAssessment>) => {
    formik.handleReset();
    this.setState({ hasCancelledEdit: true }, () => this.props.history.push('/riskassessments/listoverview/list'));
  };

  showReportButton = (show: boolean, selectedDepartmentId: string) => {
    this.setState({ showReportButton: show, selectedDepartmentId: selectedDepartmentId });
  };

  validate = async (riskAssessmentEditVm: IRiskAssessmentEditVm, riskAssessment: IRiskAssessment, t: TFunction): Promise<ValidationError> => {
    try {
      const validationScheme = BasicValidation.getValidatorForSaveMetaData(riskAssessment.status);
      validationScheme.validateSync(riskAssessment.metadata, {
        abortEarly: false,
      });

      if (RiskAssessmentUpdates.hasOfferNrChanged(riskAssessmentEditVm.assessment, riskAssessment)) {
        const offerValidator = getValidatorForOfferNumber(riskAssessment, t);
        await offerValidator.validate(riskAssessment.metadata);
      }
    } catch (e) {
      return Promise.resolve(e) as any;
    }

    return null;
  };

  handleSave = async (riskAssessment: IRiskAssessment, actions: FormikHelpers<IRiskAssessment>) => {
    const { riskAssessmentVm } = this.state;
    try {
      const validationError = await this.validate(riskAssessmentVm, riskAssessment, this.props.t);
      if (validationError) {
        this.setState({ validationError: validationError });
        return false;
      }

      const updates = RiskAssessmentUpdates.from(riskAssessmentVm.assessment, riskAssessment);
      if (updates.hasUpdates) {
        this.props.onSave(updates);
      } else {
        notifySaveSuccess('riskAssessment.notifications.noChangesToSave');
      }
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors(error.toString() as any);
    }
  };
}

export default withTranslation()(withRouter(RiskAssessmentEdit));

interface IRaEditProps extends WithTranslation, RouteComponentProps {
  riskAssessmentVm: IRiskAssessmentEditVm;
  onSave: IRiskAssessmentOnSaveCallback;
  onEnsureDocument: IEnsureDocumentCallback;
  onUploadDocuments: IUploadDocumentsCallback;
  getEvaluationHistory: (riskAssessmentId: string, departmentId: string) => Promise<IRiskAssessmentHistoryCollection>;
  generateReport: IGenerateReportFunc;
}

interface IRaEditState {
  riskAssessmentVm: IRiskAssessmentEditVm;
  riskAssessmentInitValues: IRiskAssessment;
  showHeaderMenu: boolean;
  selectedDepartmentId: string;
  showReportButton: boolean;
  validationError: ValidationError;
  hasCancelledEdit: boolean;
  tenantBranch: IRaEditTenantBranch;
  isSmallDevice: boolean;
}

interface IRaEditTenantBranch {
  tenantId: string;
  branchId: string;
}

const createMetadataRoutes = (
  permissions: IRiskAssessmentPermissions,
  riskAssessmentVm: IRiskAssessmentEditVm,
  formik: FormikProps<IRiskAssessment>,
  onSave: IRiskAssessmentOnSaveCallback,
  onEnsureDocument: IEnsureDocumentCallback,
  onUploadDocuments: IUploadDocumentsCallback,
  match: match<{}>,
) => {
  if (!permissions.canReadMetadata) {
    return null;
  }

  const routes = getRiskAssessmentEditRoutes(match);

  return (
    <>
      <Route path={routes.metadata.basicMetadata} render={(props) => <BasicMetadata {...props} riskAssessmentVm={riskAssessmentVm} formik={formik} />} />
      {riskAssessmentVm.commonConfig.hasMultipleApprovalFlows && (
        <Route path={routes.metadata.additionalInformation} render={(props) => <AdditionalInformation {...props} riskAssessmentVm={riskAssessmentVm} formik={formik} />} />
      )}
      <Route path={routes.metadata.calculation} render={(props) => <Calculation {...props} riskAssessmentVm={riskAssessmentVm} formik={formik} />} />
      <Route
        path={routes.metadata.documents}
        render={(props) => (
          <DocumentsMetadata {...props} riskAssessmentVm={riskAssessmentVm} formik={formik} onSave={onSave} onEnsureDocument={onEnsureDocument} onUploadDocuments={onUploadDocuments} />
        )}
      />
      <Route path={routes.metadata.communication} render={(props) => <Communication {...props} riskAssessmentVm={riskAssessmentVm} formik={formik} onSave={onSave} />} />
      <Route exact path={routes.metadata.exact} render={() => <Redirect to={routes.metadata.basicMetadata} />} />
    </>
  );
};

const createAssessmentRoutes = (
  permissions: IRiskAssessmentPermissions,
  riskAssessmentVm: IRiskAssessmentEditVm,
  formik: FormikProps<IRiskAssessment>,
  onSave: IRiskAssessmentOnSaveCallback,
  onEnsureDocument: IEnsureDocumentCallback,
  onUploadDocuments: IUploadDocumentsCallback,
  getEvaluationHistory: (riskAssessmentId: string, departmentId: string) => Promise<IRiskAssessmentHistoryCollection>,
  generateReport: IGenerateReportFunc,
  match: match<{}>,
) => {
  if (!permissions.canReadAssessments) {
    return null;
  }
  const routes = getRiskAssessmentEditRoutes(match);
  const departmentsToEvaluate = getDepartmentStagesToEvaluate(formik.values);
  const firstDepartmentId = _.get(departmentsToEvaluate, '[0].id');

  return (
    <>
      <Route
        path={`${routes.assessment}/:departmentId`}
        render={(props) => (
          <EvaluationPage
            getEvaluationHistory={getEvaluationHistory}
            {...(props as any)}
            onSave={onSave}
            onEnsureDocument={onEnsureDocument}
            onUploadDocuments={onUploadDocuments}
            formik={formik}
            riskAssessmentVm={riskAssessmentVm}
            generateReport={generateReport}
          />
        )}
      />
      {firstDepartmentId && <Route exact path={routes.assessment} render={() => <Redirect to={`${routes.assessment}/${firstDepartmentId}`} />} />}
    </>
  );
};

const createOverviewRoutes = (
  permissions: IRiskAssessmentPermissions,
  riskAssessmentVm: IRiskAssessmentEditVm,
  formik: FormikProps<IRiskAssessment>,
  onSave: IRiskAssessmentOnSaveCallback,
  generateReport: IGenerateReportFunc,
  match: match<{}>,
) => {
  if (!permissions.canReadEvaluation) {
    return null;
  }
  const routes = getRiskAssessmentEditRoutes(match);
  return (
    <>
      <Route
        path={routes.overview.evaluationSummary}
        render={(props) => <EvaluationSummary {...props} riskAssessmentVm={riskAssessmentVm} formik={formik} onSave={onSave} generateReport={generateReport} />}
      />
      <Route path={routes.overview.communication} render={(props) => <Communication {...props} riskAssessmentVm={riskAssessmentVm} formik={formik} onSave={onSave} />} />
      <Route exact path={routes.overview.exact} render={() => <Redirect to={routes.overview.evaluationSummary} />} />
    </>
  );
};

const createApprovalRoutes = (
  permissions: IRiskAssessmentPermissions,
  riskAssessmentVm: IRiskAssessmentEditVm,
  formik: FormikProps<IRiskAssessment>,
  onSave: IRiskAssessmentOnSaveCallback,
  onEnsureDocument: IEnsureDocumentCallback,
  onUploadDocuments: IUploadDocumentsCallback,
  generateReport: IGenerateReportFunc,
  match: match<{}>,
) => {
  if (!permissions.canReadApproval) {
    return null;
  }
  const routes = getRiskAssessmentEditRoutes(match);
  const stages = getCurrentApprovalStages(formik.values);
  let flowId = stages && formik.values.approvalFlows.currentFlowId;
  const firstApprovalStageId = _.get(stages, '[0].id');
  return (
    <>
      <Route
        path={`${routes.approval}/:flowId/:id`}
        render={(props) => (
          <Approval
            {...props}
            riskAssessmentVm={riskAssessmentVm}
            formik={formik}
            onSave={onSave}
            onEnsureDocument={onEnsureDocument}
            onUploadDocuments={onUploadDocuments}
            generateReport={generateReport}
          />
        )}
      />
      {firstApprovalStageId && <Route exact path={routes.assessment} render={() => <Redirect to={`${routes.approval}(${flowId}/${firstApprovalStageId}`} />} />}
    </>
  );
};

const SmallDeviceInfo: React.FC<{ assessment: IRiskAssessment; config: IRiskAssessmentConfig }> = ({ assessment, config }) => {
  let [t] = useTranslation();
  let context = useContext(UserInfoCtx);

  if (assessment == null) {
    return null;
  }

  return (
    <div className={styles.smallDeviceInfoContainer}>
      <InfoItem labelKey="riskAssessment.header.offerNr" value={assessment.metadata.offerNr} />
      <InfoItem labelKey="riskAssessment.header.customer" value={getCustomerName(assessment.metadata, context.languageSettings)} />
      <InfoItem labelKey="riskAssessment.header.currentStatus" value={getCurrentStatusMessage(assessment, config, t, context)} />
    </div>
  );
};

const InfoItem: React.FC<{ labelKey: string; value: string }> = ({ labelKey, value }) => {
  let [t] = useTranslation();
  return (
    <div className={styles.infoItem_container}>
      <span className={styles.infoItem_label}>{t(labelKey)}</span>
      <span className={styles.infoItem_content}>{value}</span>
    </div>
  );
};
