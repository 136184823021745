import * as yup from 'yup';
import i18n from 'i18next';
import { IRef } from '../models/IRef';

yup.setLocale({
  mixed: {
    required: i18n.t('error.required'),
  },
  string: {
    min: i18n.t('error.string.min'),
    max: i18n.t('error.string.max'),
    required: i18n.t('error.required'),
  } as any,
  date: {
    min: i18n.t('error.date.min'),
    max: i18n.t('error.date.max'),
    required: i18n.t('error.required'),
    isValid: i18n.t('error.date.dateValid'),
  } as any,
  object: {
    isType: i18n.t('error.required'),
  } as any,
  number: {
    positive: i18n.t('error.number.positive'),
    typeError: i18n.t('error.required'),
  } as any,
});

export default yup;

function stringVal(required: boolean, max?: number, min?: number) {
  let result = yup.string().typeError(i18n.t('error.required'));

  if (required) {
    result = result.required(i18n.t('error.required'));
  }

  if (min) {
    result = result.max(min, i18n.t('error.string.min'));
  }
  if (max) {
    result = result.max(max, i18n.t('error.string.max'));
  }

  return result;
}

function requiredRiskString() {
  return stringVal(true, 100);
}

function riskString() {
  return stringVal(false, 100);
}

function refType<T extends IRef>(required: boolean) {
  let result = yup
    .object<any>()
    .shape({
      id: yup.string().required(),
      name: yup.mixed().notRequired(),
    })
    .typeError(i18n.t('error.required'));

  if (required) {
    result = result.required(i18n.t('error.required'));
  }
  return result;
}

function requiredRiskRef<T extends IRef>() {
  return refType<T>(true);
}

function optionalRiskRef<T extends IRef>() {
  return refType<T>(false);
}

export { stringVal, requiredRiskString, riskString, requiredRiskRef, optionalRiskRef };
