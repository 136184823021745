import { UserPermissions } from './UserPermissions';
import {
  CanEditCountries,
  CanEditCustomers,
  CanEditMachineType,
  CanEditGeneralSetting,
  CanEditDepartments,
  CanEditTenantSettings,
  CanEditTopics,
  CanEditIncoterms,
  CanEditBranchesOfIndustry,
  CanEditRiskAssessmentTemplates,
} from './RiskAppClaims';

export class MetadataPermissions {
  constructor(private permissions: UserPermissions) {}

  public get canEditCountries() {
    return this.hasBranchClaim(CanEditCountries);
  }

  public get canEditCustomers() {
    return this.hasBranchClaim(CanEditCustomers);
  }

  public get canEditMachineType() {
    return this.hasBranchClaim(CanEditMachineType);
  }

  public get canEditGeneralSetting() {
    return this.hasBranchClaim(CanEditGeneralSetting);
  }

  public get canEditDepartments() {
    return this.hasBranchClaim(CanEditDepartments);
  }

  public get canEditTenantSettings() {
    return this.hasBranchClaim(CanEditTenantSettings);
  }

  public get canEditTopics() {
    return this.hasBranchClaim(CanEditTopics);
  }

  public get canEditRiskAssessmentTemplates() {
    return this.hasBranchClaim(CanEditRiskAssessmentTemplates);
  }

  public get canEditIncoterms() {
    return this.hasBranchClaim(CanEditIncoterms);
  }

  public get canEditBranchesOfIndustry() {
    return this.hasBranchClaim(CanEditBranchesOfIndustry);
  }

  public get canEditSomeMetadata() {
    return (
      this.canEditCountries ||
      this.canEditCustomers ||
      this.canEditMachineType ||
      this.canEditGeneralSetting ||
      this.canEditDepartments ||
      this.canEditTenantSettings ||
      this.canEditTopics ||
      this.canEditIncoterms ||
      this.canEditBranchesOfIndustry
    );
  }

  private hasBranchClaim(type: string) {
    return this.permissions.hasBranchClaim(type);
  }
}
