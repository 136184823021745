import React, { FC, CSSProperties, useContext, ReactNode } from 'react';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styles from './CommandBar.module.css';
import { IRiskAssessment, RiskAssessmentStatus, emptyCategorization, getCustomerName, IRiskAssessmentConfig, getCurrentApprovalStage, getCurrentApprovalFlow } from '../../../models/riskAssessment';
import { TFunction } from 'i18next';
import { UserInfoCtx, IUserInfoCtx } from '../../../UserInfoContext';
import { PathBreadcrumb } from '../PathBreadcrumb/PathBreadcrumb';
import { translateStatusForAssessment } from '../../../riskAssessments/riskAssessment/formhelper';
import ButtonWithDropDown from '../ButtonWithDropDown/ButtonWithDropDown';
import { PlusOutlined, SaveOutlined, CloseOutlined, EditOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { ButtonType } from 'antd/lib/button';

export const CommandBar: FC<ICommandBarProps> = React.memo(({ children, paths, style, translatePaths = true, info, showInfo, showCategorization = false, config }) => {
  const [t] = useTranslation();
  const context = useContext(UserInfoCtx);
  const categorization = (info && info.categorization) || emptyCategorization();
  const { isRiskProject, isLossProject, isMajorProject } = categorization;

  return (
    <div style={style} className={styles.commandbarwrapper}>
      <div className={styles.headerwrapper}>
        <PathBreadcrumb paths={paths} translatePaths={translatePaths} />
        {showInfo && info && (
          <>
            <div className="infoColumnContainer">
              <div className={styles.infoColumnLabel}>{t('riskAssessment.header.offerNr')}</div>
              <div className={styles.infoColumnContent}>{info.metadata.offerNr}</div>
            </div>
            <div className="infoColumnContainer">
              <div className={styles.infoColumnLabel}>{context.t(config.commissionNumber)}</div>
              <div className={styles.infoColumnContent}>{info.metadata.pspElement}</div>
            </div>
            <div className="infoColumnContainer">
              <div className={styles.infoColumnLabel}>{t('riskAssessment.header.currentStatus')}</div>
              <div className={styles.infoColumnContent}>{getCurrentStatusMessage(info, config, t, context)}</div>
            </div>
            <div className="infoColumnContainer">
              <div className={styles.infoColumnLabel}>{t('riskAssessment.header.customer')}</div>
              <div className={styles.infoColumnContent}>{getCustomerName(info.metadata, context.languageSettings)}</div>
            </div>
            {showCategorization && (
              <div className="infoColumnContainer">
                <div className={styles.infoColumnLabel}>{t('riskAssessment.header.projectcategory')}</div>
                <div className={styles.infoColumnContent}>
                  {isMajorProject && <span className={styles.project_type_label}>{t('riskAssessment.header.isMajorProject')}</span>}
                  {isLossProject && <span className={styles.project_type_label}>{t('riskAssessment.header.isLossProject')}</span>}
                  {isRiskProject && <span className={styles.project_type_label}>{t('riskAssessment.header.isRiskProject')}</span>}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {React.Children.count(children) > 0 && <div className={styles.actionwrapper}>{children}</div>}
    </div>
  );
});

CommandBar.displayName = 'CommandBar';

export function getCurrentStatusMessage(assessment: IRiskAssessment, config: IRiskAssessmentConfig, t: TFunction, ctx: IUserInfoCtx): string {
  let approvalStage = getCurrentApprovalStage(assessment);
  if (assessment.status === RiskAssessmentStatus.Approval && approvalStage) {
    if (config.hasMultipleApprovalFlows) {
      let flow = getCurrentApprovalFlow(assessment);
      return t('riskAssessment.states.ApprovalWithFlowAndStage', { flow: ctx.t(flow.name), stage: ctx.t(approvalStage.name) });
    }
    return t('riskAssessment.states.ApprovalWithStage', { stage: ctx.t(approvalStage.name) });
  }
  return translateStatusForAssessment(assessment.status, t, ctx, config);
}

interface ICommandBarProps {
  paths?: CommandBarNavTypes[];
  translatePaths?: boolean;
  style?: CSSProperties;
  info?: IRiskAssessment;
  config?: IRiskAssessmentConfig;
  showInfo?: boolean;
  showCategorization?: boolean;
}

export type CommandBarNavTypes = string | { name: string; linkTo: string };

const CommandBarActionButton: FC<ICommandBarActionButtonProps> = ({ id, icon, disabled, onClick, text, type, dataAutomationId }) => {
  return (
    <Button id={id} className={styles.actionbutton} icon={icon} disabled={disabled} onClick={onClick} type={type} data-automation-id={dataAutomationId || id}>
      {text}
    </Button>
  );
};

interface ICommandBarActionButtonProps {
  id?: string;
  text?: string;
  icon?: ReactNode;
  disabled?: boolean;
  type?: ButtonType;
  onClick: (value?: any) => void;
  dataAutomationId?: string;
}

export interface IReportOptions {
  name: string;
  value: string;
}

export const NewAction: FC<{ onClick: () => void } & Partial<ICommandBarActionButtonProps>> = ({ onClick, disabled, text }) => {
  const [t] = useTranslation();
  const translated = t(text || 'commonActions.new');
  return <CommandBarActionButton icon={<PlusOutlined />} text={translated} onClick={onClick} disabled={disabled} type="primary" id="createAction" dataAutomationId="createAction" />;
};

export const SaveAction: FC<{ onClick: () => void } & Partial<ICommandBarActionButtonProps>> = ({ onClick, disabled, text }) => {
  return <CommandBarActionButton icon={<SaveOutlined />} onClick={onClick} disabled={disabled} type="primary" id="saveAction" dataAutomationId="saveAction" />;
};

export const CancelAction: FC<{ onClick: () => void } & Partial<ICommandBarActionButtonProps>> = ({ onClick, disabled, text }) => {
  return <CommandBarActionButton icon={<CloseOutlined />} onClick={onClick} disabled={disabled} id="cancelAction" dataAutomationId="cancelAction" />;
};

export const EditAction: FC<{ onClick: () => void } & Partial<ICommandBarActionButtonProps>> = ({ onClick, disabled, text }) => {
  const [t] = useTranslation();
  const translated = t(text || 'commonActions.edit');
  return <CommandBarActionButton icon={<EditOutlined />} text={translated} onClick={onClick} disabled={disabled} id="editAction" dataAutomationId="editAction" />;
};

export const GenerateReport: FC<{ onClick: (value?: string) => void; reportOptions?: IReportOptions[]; text?: string } & Partial<ICommandBarActionButtonProps>> = ({
  onClick,
  disabled,
  reportOptions,
  text,
}) => {
  const [t] = useTranslation();
  const translated = t(text || 'commonActions.generateReport');
  const options = reportOptions || ([{ name: translated, value: '' }] as IReportOptions[]);
  return <ButtonWithDropDown dropdownOptions={options} onClick={onClick} disabled={disabled} id="generateReportAction" dataAutomationId="generateReportAction"></ButtonWithDropDown>;
};

export const OpenDrawer: FC<{ onClick: () => void } & Partial<ICommandBarActionButtonProps>> = ({ onClick, disabled, text, icon }) => {
  const [t] = useTranslation();
  const translated = t(text || 'commonActions.openDrawer');
  return <CommandBarActionButton icon={icon} text={translated} onClick={onClick} disabled={disabled} dataAutomationId="openDrawerAction" />;
};

export const DeleteAction: FC<{ onClick: () => void } & Partial<ICommandBarActionButtonProps>> = ({ onClick, disabled, text }) => {
  const [t] = useTranslation();
  const translated = t(text || 'commonActions.delete');

  return (
    <Popconfirm title={translated} placement="bottomRight" onConfirm={onClick} okText={t('commonActions.yes')} cancelText={t('commonActions.no')}>
      <CommandBarActionButton icon={<DeleteOutlined />} onClick={() => {}} disabled={disabled} dataAutomationId="deleteAction" />
    </Popconfirm>
  );
};
