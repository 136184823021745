import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';
import { Spin } from 'antd';
import _ from 'lodash';
import { IBranchOfIndustry, createBranchOfIndustry, IBranchOfIndustryCommand, createBranchOfIndustryCommand } from '../../models/masterdata';
import branchOfIndustryService from '../../shared/services/branchOfIndustryService';
import { BranchesOfIndustryList } from './BranchesOfIndustryList';
import { UserInfoCtx } from '../../UserInfoContext';
import { BranchOfIndustryEditFormComponent } from './BranchesOfIndustryEditFormComponent';

export const BranchesOfIndustry: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);
  const [searchText, setSearchText] = useState('');
  const { loading, data: branchesOfIndustry } = useData<IBranchOfIndustry[]>(id, branchOfIndustryService.getAllBranchesOfIndustry);
  const [editObj, setEditObj] = useState<IBranchOfIndustryCommand>();
  useEffect(() => setEditObj(id && createBranchOfIndustryCommand(_.cloneDeep(_.find(branchesOfIndustry, (t) => t.id === id) || createBranchOfIndustry()))), [id, branchesOfIndustry]);

  const cancelEdit = (): void => history.goBack();
  const onSave = async (branchOfIndustry: IBranchOfIndustryCommand) => {
    const saveAction = branchOfIndustry.id ? branchOfIndustryService.editBranchOfIndustry(branchOfIndustry) : branchOfIndustryService.createBranchOfIndustry(branchOfIndustry);
    const newEditObj = await tryAsyncAction(saveAction);
    setEditObj(createBranchOfIndustryCommand(newEditObj));
    setTimeout(() => cancelEdit(), 0);
  };
  const onDelete = async (machineType: IBranchOfIndustry) => tryAsyncAction(branchOfIndustryService.deleteBranchOfIndustry(machineType));

  const filteredBranchesOfIndustry = useMemo(() => {
    const filtered = searchText ? _.filter(branchesOfIndustry, (c) => _.includes(_.toLower(ctx.t(c.name)), _.toLower(searchText))) : branchesOfIndustry;
    return _.sortBy(filtered, ['description']);
  }, [searchText, branchesOfIndustry]);

  if (loading) {
    return <Spin></Spin>;
  }

  if (editObj) {
    return <BranchOfIndustryEditFormComponent totalBranchCount={branchesOfIndustry.length} loading={loading} branchOfIndustry={editObj} onSave={onSave} onDelete={onDelete} cancelEdit={cancelEdit} />;
  }
  return <BranchesOfIndustryList branchesOfIndustry={filteredBranchesOfIndustry} loading={loading} searchText={searchText} setSearchText={setSearchText} />;
};
