import React, { SFC, CSSProperties, Children } from 'react';
import { Drawer } from 'antd';
import { IDocumentMetadata } from '../../../models/documents/IDocumentMetaData';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from 'Panel.module.css';

export class Panel<T> extends React.Component<IPanelProps, {}> {
  public render() {
    const { t, children, label } = this.props;
    return (
      <Drawer title={label} placement="right" closable={true} onClose={this.props.onClose} visible={this.props.drawerVisible} width={386}>
        {children}
      </Drawer>
    );
  }
}

export default withTranslation()(Panel);

interface IPanelProps extends WithTranslation {
  label: string;
  drawerVisible: boolean;
  onClose: () => void;
}
