import React, { FC, useContext, useEffect } from 'react';
import { IUserEditViewModel, ITenantConfiguration, IBranchOfIndustryRef, createBranchConfig, IDepartmentRefWithTenantAndBranch } from '../models/user';
import { Button, Row, Col, Popconfirm, Alert, Tabs } from 'antd';
import * as _ from 'lodash';
import { FieldArray, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import FormBox from '../shared/components/FormBox/FormBox';
import PermissionSlider from './PermissionSlider';
import DepartmentConfigurations from './DepartmentConfigurations';

import styles from './UserEditForm.module.css';
import TagGroup from '../shared/components/TagGroup/TagGroup';
import { UserPermissions, KnownTenantIds } from '../models';
import { getForLanguage } from '../models/IMultiLanguageString';
import { UserInfoCtx } from '../UserInfoContext';
import { LanguageSettings } from '../models/LanguageSettings';
import { FormTrLabel } from '../shared/FormComponents';

const TabPane = Tabs.TabPane;

export const TenantConfiguration: FC<{
  formik: FormikProps<IUserEditViewModel>;
  t: TFunction;
  tenant: ITenantConfiguration;
  assignableBranchesOfIndustry: IBranchOfIndustryRef[];
  assignableDepartments: IDepartmentRefWithTenantAndBranch[];
  valuesIndex: number;
  permissions: UserPermissions;
  assignedTenants: ITenantConfiguration[];
  onTenantRemove: () => void;
}> = React.memo(({ tenant, formik, t, assignableBranchesOfIndustry, assignableDepartments, valuesIndex, onTenantRemove, permissions, assignedTenants }) => {
  const { values } = formik;
  const tr = getTranslateFunc(t);
  const isBranchEditable = (branchRef: IBranchOfIndustryRef) => permissions.security.canEditBranchOfIndustry(branchRef);
  const isBranchVisible = (branchRef: IBranchOfIndustryRef) => permissions.security.canSeeBranchOfIndustry(branchRef, assignableDepartments);
  const assignedBranchesOfIndustry = getAssignedBranchesOfIndustry(values, valuesIndex);
  const tenantPath = `assignedTenants[${valuesIndex}]`;
  const branchesOfIndustryPath = `${tenantPath}.assignedBranchesOfIndustry`;
  const context = useContext(UserInfoCtx);

  //TODO: hasSafeDocuments muss aus den TenantSettings (Backend) ermittelt werden.
  // Derzeit können diese hier aber noch nicht direkt abgefragt werden
  const hasSafeDocuments = tenant.id === KnownTenantIds.Bms;
  return (
    <>
      {(permissions.security.canCanChangeTenantAdministratorState || permissions.security.canAssignUserToTenant) && (
        <FormBox title={tr('common')}>
          {permissions.security.canCanChangeTenantAdministratorState && (
            <PermissionSlider
              disabled={!permissions.security.canCanChangeTenantAdministratorState || tenant.accessFromDepartmentSync === true}
              formik={formik}
              name={`${tenantPath}.isTenantAdministrator`}
              label={tr('isTenantAdministrator')}
            />
          )}
          {permissions.security.canAssignUserToTenant && (
            <Popconfirm disabled={tenant.accessFromDepartmentSync === true} title={tr('deleteConfirmMessage')} onConfirm={onTenantRemove} okText={tr('yes')} cancelText={tr('no')}>
              <Button disabled={tenant.accessFromDepartmentSync === true} style={{ marginTop: '1rem' }}>
                {tr('removeTenantAssignment')}
              </Button>
            </Popconfirm>
          )}
        </FormBox>
      )}
      <div>
        <FieldArray
          name={branchesOfIndustryPath}
          render={(arrayHelpers) => (
            <div>
              {!tenant.accessFromDepartmentSync && (
                <FormBox title={tr('branchesOfIndustry')}>
                  <TagGroup
                    title={tr('availableBranchesOfIndustry')}
                    tags={getNotAssignedBranchesOfIndustryTags(permissions, tenant, assignableBranchesOfIndustry, assignedBranchesOfIndustry, context.languageSettings)}
                    onClick={(t) => arrayHelpers.push(createBranchConfig(t.object))}
                  />
                </FormBox>
              )}
              <FormTrLabel text={tr('branchOfIndustryConfiguration', { tenant: tenant.name })} />
              {_.some(assignedBranchesOfIndustry, isBranchVisible) ? (
                <Tabs type="card" style={{ padding: '0.5rem' }} tabBarStyle={{ textTransform: 'uppercase' }}>
                  {assignedBranchesOfIndustry.map((branchOfIndustry, index) => (
                    <TabPane key={branchOfIndustry.id} tab={getForLanguage(branchOfIndustry.name, context.languageSettings)}>
                      {isBranchEditable(branchOfIndustry) && (
                        <>
                          <Row>
                            <Col span={12}>
                              <h1 className={styles.permissionheader}>{tr('common')}</h1>
                              <div className="permissionscontainer">
                                <PermissionSlider
                                  disabled={tenant.accessFromDepartmentSync === true}
                                  formik={formik}
                                  name={`${branchesOfIndustryPath}[${index}].canEditUsers`}
                                  label={tr('permissionSettings.canEditUsers')}
                                />
                                <PermissionSlider
                                  disabled={tenant.accessFromDepartmentSync === true}
                                  formik={formik}
                                  name={`${branchesOfIndustryPath}[${index}].canEditConfig`}
                                  label={tr('permissionSettings.canEditConfig')}
                                />
                                <PermissionSlider
                                  disabled={tenant.accessFromDepartmentSync === true}
                                  formik={formik}
                                  name={`${branchesOfIndustryPath}[${index}].isProjectAdmin`}
                                  label={tr('permissionSettings.isProjectAdmin')}
                                />
                                <div style={{ marginTop: '2rem' }}>
                                  <PermissionSlider
                                    disabled={tenant.accessFromDepartmentSync === true}
                                    formik={formik}
                                    name={`${branchesOfIndustryPath}[${index}].dontShowCalculation`}
                                    label={tr('permissionSettings.dontShowCalculation')}
                                  />
                                  <PermissionSlider
                                    disabled={tenant.accessFromDepartmentSync === true}
                                    formik={formik}
                                    name={`${branchesOfIndustryPath}[${index}].dontShowCalculationMargin`}
                                    label={tr('permissionSettings.dontShowCalculationMargin')}
                                  />
                                  <PermissionSlider
                                    disabled={tenant.accessFromDepartmentSync === true}
                                    formik={formik}
                                    name={`${branchesOfIndustryPath}[${index}].dontShowClearanceDocuments`}
                                    label={tr('permissionSettings.dontShowClearanceDocuments')}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col span={12}>
                              <h1 className={styles.permissionheader}>{tr('reportsAndDocuments')}</h1>
                              <div className="permissionscontainer">
                                <PermissionSlider
                                  disabled={tenant.accessFromDepartmentSync === true}
                                  formik={formik}
                                  name={`${branchesOfIndustryPath}[${index}].canPrintRiskAnalysisReport`}
                                  label={tr('permissionSettings.canPrintRiskAnalysisReport')}
                                />
                                <PermissionSlider
                                  disabled={tenant.accessFromDepartmentSync === true}
                                  formik={formik}
                                  name={`${branchesOfIndustryPath}[${index}].canEditAppendicesDocuments`}
                                  label={tr('permissionSettings.canEditAppendicesDocuments')}
                                />
                                <PermissionSlider
                                  disabled={tenant.accessFromDepartmentSync === true}
                                  formik={formik}
                                  name={`${branchesOfIndustryPath}[${index}].canEditContractDocuments`}
                                  label={tr('permissionSettings.canEditContractDocuments')}
                                />
                                <PermissionSlider
                                  disabled={tenant.accessFromDepartmentSync === true}
                                  formik={formik}
                                  name={`${branchesOfIndustryPath}[${index}].canEditPmCalcDocuments`}
                                  label={tr('permissionSettings.canEditPmCalcDocuments')}
                                />
                                {hasSafeDocuments && permissions.security.canChangeSafeDocumentsState() && (
                                  <PermissionSlider
                                    disabled={tenant.accessFromDepartmentSync === true}
                                    formik={formik}
                                    name={`${branchesOfIndustryPath}[${index}].canEditSafeDocuments`}
                                    label={tr('permissionSettings.canEditSafeDocuments')}
                                  />
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Popconfirm
                                disabled={tenant.accessFromDepartmentSync === true}
                                title={tr('deleteConfirmMessage')}
                                onConfirm={() => arrayHelpers.remove(index)}
                                okText={tr('yes')}
                                cancelText={tr('no')}
                              >
                                <Button disabled={tenant.accessFromDepartmentSync === true} style={{ marginTop: '1rem' }}>
                                  {tr('removeBranchOfIndustryAssignment')}
                                </Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col span={24}>
                          <DepartmentConfigurations
                            currentBranchOfIndustryPath={`${branchesOfIndustryPath}[${index}]`}
                            assignableDepartments={assignableDepartments}
                            permissions={permissions}
                            formik={formik}
                            tenant={tenant}
                            assignedTenants={assignedTenants}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
                </Tabs>
              ) : (
                <Alert style={{ margin: '1rem' }} message={tr('atLeastOneBranchOfIndustryMessage')} type="warning" />
              )}
            </div>
          )}
        />
      </div>
    </>
  );
});

TenantConfiguration.displayName = 'TenantConfiguration';

function getNotAssignedBranchesOfIndustryTags(
  permissions: UserPermissions,
  tenant: ITenantConfiguration,
  assignableBranchesOfIndustry: IBranchOfIndustryRef[],
  assignedBranchesOfIndustry: IBranchOfIndustryRef[],
  settings: LanguageSettings,
) {
  return _.differenceWith(
    _.filter(assignableBranchesOfIndustry, (a) => a.tenantId === tenant.id),
    assignedBranchesOfIndustry,
    (a, b) => a.id === b.id && a.tenantId === b.tenantId,
  )
    .filter((b) => permissions.security.canAssignUserToBranchOfIndustry(b.tenantId, b.id))
    .map((b) => ({ tag: b.id, title: getForLanguage(b.name, settings), object: b }));
}

function getAssignedBranchesOfIndustry(values: IUserEditViewModel, index: number) {
  return values.assignedTenants[index].assignedBranchesOfIndustry;
}

function getTranslateFunc(t: TFunction) {
  return (key: string, values: any = undefined) => t('users.userEditForm.' + key, values);
}
