import { createAsyncAction } from 'typesafe-actions';
import {
  ICreateRiskAssessmentCommand,
  ICreateRiskAssessmentResult,
  IRiskAssessmentStatusMonitorViewModel,
  IRiskAssessmentListSearchConfig,
  IRiskAssessmentListViewModels,
} from '../../models/riskAssessment';

export const getRiskAssessmentList = createAsyncAction('getRiskAssessmentList/fetch_request', 'getRiskAssessmentList/fetch_success', 'getRiskAssessmentList/fetch_failure')<
  IRiskAssessmentListSearchConfig,
  IRiskAssessmentListViewModels,
  Error
>();

export const getRiskAssessmentStatusMonitor = createAsyncAction(
  'getRiskAssessmentStatusMonitor/fetch_request',
  'getRiskAssessmentStatusMonitor/fetch_success',
  'getRiskAssessmentStatusMonitor/fetch_failure',
)<IRiskAssessmentListSearchConfig, IRiskAssessmentStatusMonitorViewModel, Error>();

export const createRiskAssessment = createAsyncAction('createRiskAssessment/fetch_request', 'createRiskAssessment/fetch_success', 'createRiskAssessment/fetch_failure')<
  ICreateRiskAssessmentCommand,
  ICreateRiskAssessmentResult,
  Error
>();
