import { Divider } from 'antd';
import React, { SFC } from 'react';
import styles from './SortableCard.module.css';
import { HtcCard } from '../../HtcCardComponent';
import { ArrowUpOutlined, CloseOutlined, ArrowDownOutlined } from '@ant-design/icons';

export const SortableCard: SFC<any> = ({ index, title, children, onRemove, onSortUp, onSortDown, isFirst, isLast, cardStyle, dividerStyle }) => (
  <HtcCard style={cardStyle}>
    <div className={styles.contentContainer}>
      <div className={styles.sortActions}>
        <div style={{ top: 10, left: 10, zIndex: 1 }} className={styles.actionContainer}>
          {isFirst && <ArrowUpOutlined className={styles.arrowUp} onClick={onSortUp} />}
        </div>
        <div style={{ top: 0, right: 7, zIndex: 1 }} className={styles.actionContainer}>
          <CloseOutlined className={styles.arrowDelete} onClick={onRemove} />
        </div>
        <div style={{ left: 10, bottom: 10, zIndex: 1 }} className={styles.actionContainer}>
          {isLast && <ArrowDownOutlined className={styles.arrowDown} onClick={onSortDown} />}
        </div>
      </div>
      <div className={styles.cardContent}>
        <div>
          <Divider orientation="left" className={`${styles.divider} ${dividerStyle}`}>
            {title}
          </Divider>
        </div>
        <div style={{ display: 'flex', flexFlow: 'column nowrap', flex: 1, minHeight: 0 }}>{children}</div>
      </div>
    </div>
  </HtcCard>
);
