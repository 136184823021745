import { IPickerBaseProps, IPickerBaseState, IsRef } from '../PickerBase/PickerBase';
import customerService from '../../services/customerService';
import { ICustomerRef } from '../../../models/masterdata';
import LanguageAwarePickerBase from '../PickerBase/LanguageAwarePickerBase';

class CustomerPicker extends LanguageAwarePickerBase<ICustomerRef> {
  static getDerivedStateFromProps(props: IPickerBaseProps<ICustomerRef>, state: IPickerBaseState<ICustomerRef>): Partial<IPickerBaseState<ICustomerRef>> {
    if ((state.value && (state.value as ICustomerRef).id) !== (props.value && (props.value as ICustomerRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }
  customRender(name: string, additionalInformation: ICustomerRef): string {
    if (additionalInformation === null) {
      return name;
    } else {
      return name + ' ' + additionalInformation?.debitorNumber;
    }
  }
  onQueryItems(query: string): Promise<ICustomerRef[]> {
    const onQueryItems = this.props.onQueryItems || customerService.queryCustomerOptions;
    return onQueryItems(query, this.props.languageSettings, this.props.tenantId, this.props.branchId);
  }
}

export default CustomerPicker;
