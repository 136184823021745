import React, { FC } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { IMachineType } from '../../models/masterdata';
import _ from 'lodash';
import { MachineTypeTree } from './components/MachineTypeTree';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';

const navPaths = ['navigation.masterdata', 'navigation.machineTypes'];
export const MachineTypeList: FC<IMachineTypeListProps> = ({ loading, machineTypes, onSelect }) => {
  return (
    <ContentWrapper>
      <MasterdataHeader>
        <CommandBarV2 paths={navPaths}></CommandBarV2>
      </MasterdataHeader>
      <HtcCardWrapper>
        <HtcCard>
          <MachineTypeTree machineTypes={machineTypes} onSelect={onSelect} />
        </HtcCard>
      </HtcCardWrapper>
    </ContentWrapper>
  );
};

interface IMachineTypeListProps {
  machineTypes: IMachineType[];
  loading: boolean;
  onSelect: (itemId: string, parentId: string) => void;
}
