import { AssessmentTransition } from '../../../models/riskAssessment/AssessmentTransition';

export interface IApprovalFlowsState {
  showDrawer: boolean;
  currentTransition: AssessmentTransition;
  mustProvideNotificationData: boolean;
  mustEnsureRequiredDepartmentsForNotifications: boolean;
}

export function createEmptyState(): IApprovalFlowsState {
  return {
    showDrawer: false,
    mustProvideNotificationData: true,
    currentTransition: null,
    mustEnsureRequiredDepartmentsForNotifications: true,
  };
}

export type Action = { type: 'requestTransition'; transition: AssessmentTransition } | { type: 'cancel' };

export function reducer(state: IApprovalFlowsState, action: Action): IApprovalFlowsState {
  switch (action.type) {
    case 'cancel':
      return createEmptyState();
    case 'requestTransition':
      return { ...state, showDrawer: true, currentTransition: action.transition };
  }
  return state;
}

export function createCancel() {
  return {
    type: 'cancel' as const,
  };
}

export function requestTransition(transition: AssessmentTransition) {
  return {
    type: 'requestTransition' as const,
    transition: transition,
  };
}
