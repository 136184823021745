import React, { useContext } from 'react';
import { IRiskAssessmentEditVm, IRiskAssessment, indicationOfMainScopeOptions } from '../../../models/riskAssessment';
import { FormikProps } from 'formik';
import { HtcCardWrapper, HtcCard } from '../../../shared/HtcCardComponent';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { useTranslation } from 'react-i18next';
import { FormGrid, FormTextInput, FormDateInput, FormTextAreaInput, FormAutoComplete } from '../../../shared/FormComponents';
import nameof from 'ts-nameof.macro';
import { PermissionCtx } from '../RiskAssessmentContext';
import { AssessmentPageHeader } from '../assessment/PageHeader/PageHeader';
import { AssessmentPageContent } from '../assessment/PageContent/PageContent';

type RA = IRiskAssessment;
const TP = 'riskAssessment.editform.metadata.';

export const AdditionalInformation: React.FC<IAdditionalInformation> = ({ riskAssessmentVm, formik }) => {
  const [t] = useTranslation();
  const permissions = useContext(PermissionCtx);
  const readOnly = permissions.permissionHelper.isBasicMetaDataReadOnly();
  const cantEditPoaData = readOnly || !riskAssessmentVm.permissions.canEditPoaData;
  const cantEditPartialClearanceData = readOnly || !riskAssessmentVm.permissions.canEditPartialClearanceData;
  const config = riskAssessmentVm.commonConfig;

  return (
    <>
      <AssessmentPageHeader mainLevelTr="metadata" current="additionalinformation" />
      <AssessmentPageContent>
        <HtcCardWrapper>
          <HtcCard>
            <FormBox title={t(TP + 'poa')}>
              <FormGrid>
                {
                  <FormTextAreaInput
                    {...formik}
                    rows={4}
                    name={nameof.full<RA>((r) => r.metadata.vendorPoa)}
                    label={t('riskAssessment.vendorPoa')}
                    placeholder={t('riskAssessment.vendorPoaPlaceholder')}
                    disabled={cantEditPoaData}
                  />
                }
                {config.hasPOAReport && config.hasOfferNrPoa && (
                  <FormTextInput {...formik} name={nameof.full<RA>((r) => r.metadata.offerNrPoa)} label={t('riskAssessment.offerNrPoa')} disabled={cantEditPoaData} />
                )}
              </FormGrid>
            </FormBox>
          </HtcCard>
          <HtcCard>
            <FormBox title={t(TP + 'partialClearance')}>
              {(config.hasEstimatedDateOfTurnover || config.hasIndicationOfMainScope) && (
                <FormGrid>
                  {config.hasEstimatedDateOfTurnover && (
                    <FormDateInput
                      {...formik}
                      name={nameof.full<RA>((r) => r.metadata.estimatedDateOfTurnover)}
                      label={t('riskAssessment.estimatedDateOfTurnover')}
                      disabled={cantEditPartialClearanceData}
                    />
                  )}
                  {config.hasIndicationOfMainScope && (
                    <FormAutoComplete
                      {...formik}
                      name={nameof.full<RA>((r) => r.metadata.indicationOfMainScope)}
                      label={t('riskAssessment.indicationOfMainScope')}
                      disabled={cantEditPartialClearanceData}
                      options={indicationOfMainScopeOptions}
                    />
                  )}
                </FormGrid>
              )}
              <FormGrid>
                <FormTextInput
                  {...formik}
                  name={nameof.full<RA>((r) => r.metadata.deliveryTimes)}
                  label={t('riskAssessment.deliveryTimes')}
                  disabled={cantEditPartialClearanceData}
                  description={t('descriptions.deliveryTimes')}
                />
                <FormDateInput {...formik} name={nameof.full<RA>((r) => r.metadata.signatureDate)} label={t('riskAssessment.signatureDate')} disabled={cantEditPartialClearanceData} />
                <FormDateInput
                  {...formik}
                  name={nameof.full<RA>((r) => r.metadata.projectStart)}
                  label={t('riskAssessment.projectStart')}
                  disabled={cantEditPartialClearanceData}
                  description={t('descriptions.projectStart')}
                />
              </FormGrid>
              <FormGrid>
                <FormTextAreaInput
                  {...formik}
                  rows={3}
                  name={nameof.full<RA>((r) => r.metadata.billRecipient)}
                  label={t('riskAssessment.billRecipient')}
                  placeholder={t('riskAssessment.billRecipientPlaceholder')}
                  disabled={cantEditPartialClearanceData}
                />
                <FormTextAreaInput {...formik} rows={3} name={nameof.full<RA>((r) => r.metadata.generalRemarks)} label={t('riskAssessment.generalRemarks')} disabled={cantEditPartialClearanceData} />
              </FormGrid>
              <FormGrid>
                <FormTextAreaInput
                  {...formik}
                  rows={6}
                  name={nameof.full<RA>((r) => r.metadata.termsOfPaymentOrderRelease)}
                  label={t('riskAssessment.termsOfPaymentOrderRelease')}
                  placeholder={t('riskAssessment.termsOfPaymentOrderRelease')}
                  disabled={cantEditPartialClearanceData}
                />
              </FormGrid>
            </FormBox>
          </HtcCard>
        </HtcCardWrapper>
      </AssessmentPageContent>
    </>
  );
};

export interface IAdditionalInformation {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
}
