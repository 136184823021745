import React from 'react';
import { RiskAssessmentPermissionHelper } from './RiskAssessmentPermissionHelper';

export const PermissionCtx = React.createContext<IPermissionCtx>({
  permissionHelper: null,
});

export interface IPermissionCtx {
  permissionHelper: RiskAssessmentPermissionHelper;
}
