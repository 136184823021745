import * as _ from 'lodash';
import HttpClient from './httpClient';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';
import { IRiskAssessment, IMessageTemplate, IMessageTemplateGenerationData } from '../../models/riskAssessment';

export interface IFormattedMessageResult {
  subject: IMultiLanguageString;
  body: IMultiLanguageString;
  footer: IMultiLanguageString;
}

async function getFormattedMessageForRiskAssessment(assessment: IRiskAssessment, template: IMessageTemplate, generationData?: IMessageTemplateGenerationData): Promise<IFormattedMessageResult> {
  try {
    const result = await HttpClient.getInstance().post<IFormattedMessageResult>(`templates/assessments/email`, { assessment, template, generationData });
    return result.data;
  } catch {}
}

export default {
  getFormattedMessageForRiskAssessment,
};
