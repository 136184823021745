import React from 'react';
import { SearchBox } from '../../../shared/components/FormBox/FormBox';
import { Button, Input } from 'antd';
import CountryPicker from '../../../shared/components/CountryPicker/CountryPicker';
import CustomerPicker from '../../../shared/components/CustomerPicker/CustomerPicker';
import { ICheckableTag } from '../../../shared/components/CheckableTagGroup/CheckableTagGroup';
import styles from './ListFilter.module.css';
import _ from 'lodash';
import { ICountryRef, ICustomerRef, IBranchOfIndustry } from '../../../models/masterdata';
import { WithTranslation, withTranslation } from 'react-i18next';
import CheckableTagFilterGroup from '../CheckableTagFilterGroup/CheckableTagFilterGroup';
import { UserInfoCtx } from '../../../UserInfoContext';
import { IRiskAssessmentFilter, createRiskAssessmentListFilter, IRiskAssessmentTemplateRef } from '../../../models/riskAssessment';
import RiskAssessmentTemplatePicker from '../../../shared/components/RiskAssessmentTemplatePicker/RiskAssessmentTemplatePicker';
import { TenantBranchCtx } from '../../../TenantBranchContext';
import { TenantConfiguration } from '../../../users/TenantConfiguration';

class ListFilter extends React.Component<
  IListFilterProps,
  { filterConfig: IRiskAssessmentFilter; currentBranchFilter: ICheckableTag[]; currentStatusFilter: ICheckableTag[]; canFilterConstructionSite: boolean }
> {
  private AllTag: ICheckableTag = { tag: 'CheckableTag_ALL', title: 'CheckableTag_ALL' };

  constructor(props: IListFilterProps) {
    super(props);
    this.state = {
      filterConfig: props.filterConfig || createRiskAssessmentListFilter(),
      currentBranchFilter: this.getBranchFilterFrom(props),
      currentStatusFilter: this.getStatusFilterFrom(props),
      canFilterConstructionSite: false,
    };
  }

  componentDidUpdate(prevProps: IListFilterProps) {
    if (prevProps.branchOfIndustryId !== this.props.branchOfIndustryId) {
      this.resetFilter();
    } else if (!_.isEqual(prevProps.filterConfig, this.props.filterConfig) && !_.isEqual(this.props.filterConfig, this.state.filterConfig)) {
      this.setState({ filterConfig: this.props.filterConfig, currentBranchFilter: this.getBranchFilterFrom(this.props), currentStatusFilter: this.getStatusFilterFrom(this.props) });
    }
  }

  componentDidMount(): void {
    const { userInfo } = this.context;
    const tenant = userInfo.availableTenants.find((t) => t.id == userInfo.userSetting.activeTenantId);
    this.setState({ canFilterConstructionSite: tenant.hasConstructionSiteCountry });
  }

  getBranchFilterFrom(props: IListFilterProps) {
    let tags = props.filterConfig?.branchOfIndustryFilter;
    if (!tags) {
      tags = [props.branchOfIndustryId];
    }

    return props.branchesOfIndustryOptions.filter((option) => _.find(tags, (t) => option.tag === t) != null);
  }

  getStatusFilterFrom(props: IListFilterProps) {
    let tags = props.filterConfig?.statusFilter;
    if (!tags) {
      tags = [];
    }

    return props.riskAssessmentStatusOptions.filter((option) => _.find(tags, (t) => option.tag === t) != null);
  }

  render() {
    const { t } = this.props;
    const { filterConfig } = this.state;
    const settings = this.context.languageSettings;
    return (
      <TenantBranchCtx.Consumer>
        {(tenantBranch) => (
          <SearchBox>
            <Button
              data-automation-id="resetFilter-button"
              style={{ margin: '0.25rem 0' }}
              className={styles.margin}
              onClick={() => {
                this.resetFilter();
              }}
            >
              {t('commonActions.resetFilter')}
            </Button>
            <Input
              data-automation-id="searchText-input"
              style={{ margin: '0.25rem 0' }}
              className={styles.margin}
              placeholder={t('riskAssessment.searchText')}
              allowClear
              onChange={this.onSearch}
              value={filterConfig.searchText}
            />
            <div style={this.state.canFilterConstructionSite ? { display: 'grid', gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)', gridTemplateRows: '1fr 1fr', gridGap: '0.25rem' } : {}}>
              <div>
                <CountryPicker
                  style={{ width: '100%', margin: this.state.canFilterConstructionSite ? '' : '0.25rem 0' }}
                  key="countryPicker"
                  name="countryPicker"
                  onChange={this.handleCountryFilterChanged}
                  value={filterConfig.countriesFilter}
                  placeholder={t('riskAssessment.country')}
                  languageSettings={settings}
                  tenantId={tenantBranch.tenantId}
                  branchId={tenantBranch.branchId}
                  mode="multiple"
                />
              </div>
              <div>
                {this.state.canFilterConstructionSite && (
                  <CountryPicker
                    style={{ width: '100%', margin: this.state.canFilterConstructionSite ? '' : '0.25rem 0', justifySelf: this.state.canFilterConstructionSite ? 'end' : 'start' }}
                    key="constructionSiteCountryPicker"
                    name="constructionSiteCountryPicker"
                    onChange={this.handleConstructionSiteCountryFilterChanged}
                    value={filterConfig.constructionSiteCountriesFilter}
                    placeholder={t('riskAssessment.constructionSiteCountry')}
                    languageSettings={settings}
                    tenantId={tenantBranch.tenantId}
                    branchId={tenantBranch.branchId}
                    mode="multiple"
                  />
                )}
              </div>
              <div>
                <CustomerPicker
                  style={{ width: '100%', margin: this.state.canFilterConstructionSite ? '' : '0.25rem 0' }}
                  key="customerPicker"
                  name="customerPicker"
                  onChange={this.handleCustomerFilterChanged}
                  value={filterConfig.customerFilter}
                  placeholder={t('riskAssessment.customer')}
                  languageSettings={settings}
                  tenantId={tenantBranch.tenantId}
                  branchId={tenantBranch.branchId}
                />
              </div>
              <div>
                <RiskAssessmentTemplatePicker
                  style={{ width: '100%', margin: this.state.canFilterConstructionSite ? '' : '0.25rem 0', justifySelf: this.state.canFilterConstructionSite ? 'end' : 'start' }}
                  name={'processTemplate'}
                  dataAutomationId={'processTemplate'}
                  placeholder={t('riskAssessment.processTemplate')}
                  onChange={this.handleTemplateFilterChanged}
                  value={filterConfig.templateFilter}
                  languageSettings={settings}
                  tenantId={tenantBranch.tenantId}
                  branchId={tenantBranch.branchId}
                />
              </div>
            </div>
            <CheckableTagFilterGroup
              dataAutomationId="statusTagFilter"
              isFilter={true}
              isGrid={true}
              title={t('riskAssessment.status')}
              allTag={this.AllTag}
              tags={this.props.riskAssessmentStatusOptions}
              onChange={this.handleRiskAssessmentStatusChanged}
              currentSelection={this.state.currentStatusFilter}
              useAllTag={true}
            />
            <CheckableTagFilterGroup
              dataAutomationId="branchTagFilter"
              isFilter={true}
              isGrid={true}
              title={t('riskAssessment.branchFilter')}
              allTag={this.AllTag}
              tags={this.props.branchesOfIndustryOptions}
              onChange={this.handleBranchesOfIndustryOptionChanged}
              currentSelection={this.state.currentBranchFilter}
              useAllTag={this.props.canFilterAllBranches}
            />
          </SearchBox>
        )}
      </TenantBranchCtx.Consumer>
    );
  }

  private resetFilter = () => {
    const branchOfIndustryFilter = _.find(this.props.branchesOfIndustryOptions, (b) => this.props.branchOfIndustryId === b.tag);
    const filter = createRiskAssessmentListFilter();
    filter.branchOfIndustryFilter = [branchOfIndustryFilter.tag];

    this.setState({ filterConfig: filter, currentBranchFilter: [branchOfIndustryFilter], currentStatusFilter: [] });
    this.props.onFilterChange(filter);
  };

  private handleBranchesOfIndustryOptionChanged = (tags: ICheckableTag[]) => {
    const filter = _.cloneDeep(this.props.filterConfig);
    filter.branchOfIndustryFilter = tags.map((t) => t.tag);
    this.setState({ filterConfig: filter, currentBranchFilter: tags });
    this.props.onFilterChange(filter);
  };

  private handleRiskAssessmentStatusChanged = (tags: ICheckableTag[]) => {
    const filter = _.cloneDeep(this.props.filterConfig);
    filter.statusFilter = tags.map((t) => t.tag);
    this.setState({ filterConfig: filter, currentStatusFilter: tags });
    this.props.onFilterChange(filter);
  };

  private handleCountryFilterChanged = (select: ICountryRef | ICountryRef[]) => {
    const filter = _.cloneDeep(this.props.filterConfig);
    filter.countriesFilter = select || [];
    this.setState({ filterConfig: filter });
    this.props.onFilterChange(filter);
  };

  private handleConstructionSiteCountryFilterChanged = (select: ICountryRef | ICountryRef[]) => {
    const filter = _.cloneDeep(this.props.filterConfig);
    filter.constructionSiteCountriesFilter = select;
    this.setState({ filterConfig: filter });
    this.props.onFilterChange(filter);
  };

  private handleCustomerFilterChanged = (select: ICustomerRef | ICustomerRef[]) => {
    const filter = _.cloneDeep(this.props.filterConfig);
    if (!Array.isArray(select)) {
      filter.customerFilter = select;
    }
    this.setState({ filterConfig: filter });
    this.props.onFilterChange(filter);
  };

  private handleTemplateFilterChanged = (select: IRiskAssessmentTemplateRef | IRiskAssessmentTemplateRef[]) => {
    if (!Array.isArray(select)) {
      this.applyFilter({ templateFilter: select });
    }
  };

  private applyFilter(toApply: Partial<IRiskAssessmentFilter>) {
    let filter = _.assign(_.cloneDeep(this.props.filterConfig), toApply);
    this.setState({ filterConfig: filter });
    this.props.onFilterChange(filter);
  }

  onSearch = (e: any) => {
    const filter = _.cloneDeep(this.props.filterConfig);
    filter.searchText = e.target.value;
    this.setState({ filterConfig: filter }, () => {
      this.props.onFilterChange(filter);
    });
  };
}

ListFilter.contextType = UserInfoCtx;
export default withTranslation()(ListFilter);

interface IListFilterProps extends WithTranslation {
  filterConfig: IRiskAssessmentFilter;
  style?: React.CSSProperties;
  branchOfIndustryId: string;
  availableBranchesOfIndustry: IBranchOfIndustry[];
  riskAssessmentStatusOptions: ICheckableTag[];
  branchesOfIndustryOptions: ICheckableTag[];
  onFilterChange: (filter: IRiskAssessmentFilter) => void;
  canFilterAllBranches: boolean;
}
