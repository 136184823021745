import React from 'react';
import countryService from '../../services/countryService';
import { IPickerBaseProps, IPickerBaseState } from '../PickerBase/PickerBase';
import { ICountryRef } from '../../../models/masterdata';
import LanguageAwarePickerBase from '../PickerBase/LanguageAwarePickerBase';

class CountryPicker extends LanguageAwarePickerBase<ICountryRef> {
  static getDerivedStateFromProps(props: IPickerBaseProps<ICountryRef | ICountryRef[]>, state: IPickerBaseProps<ICountryRef | ICountryRef[]>): Partial<IPickerBaseProps<ICountryRef | ICountryRef[]>> {
    if (Array.isArray(props.value)) {
      if (!Array.isArray(state.value) || !CountryPicker.compareArrays(props.value, state.value)) {
        return {
          value: props.value.map((v) => Object.assign({}, v)),
        };
      }
    } else if (state.value && (state.value as ICountryRef).id !== (props.value && (props.value as ICountryRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<ICountryRef[]> {
    const onQueryItems = this.props.onQueryItems || countryService.queryCountryOptions;
    return onQueryItems(query, this.props.languageSettings, this.props.tenantId, this.props.branchId);
  }

  static compareArrays = (a, b) => a.length === b.length && a.map((v) => v.id).every((element, index) => element === b[index]?.id);
}

export default CountryPicker;
