import React, { PureComponent } from 'react';
import { HtcCardWrapper, HtcCard } from '../../../shared/HtcCardComponent';
import {
  RiskAssessmentStatus,
  getRiskAssessmentStatusOptions,
  IRiskAssessmentListViewModel,
  createRiskAssessmentListSearchConfig,
  IRiskAssessmentFilter,
  IRiskAssessmentListSearchConfig,
  RecursivePartial,
  IRiskAssessmentListViewModels,
  IStoredRiskAssessmentListSearchConfig,
} from '../../../models/riskAssessment';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'antd';
import styles from './RiskAssessmentList.module.css';
import { NewRiskAssessmentId } from '../../ducks';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { ICheckableTag } from '../../../shared/components/CheckableTagGroup/CheckableTagGroup';
import _ from 'lodash';
import { IBranchOfIndustry, ITenantTerms } from '../../../models/masterdata';
import './RiskAssessmentListTableDefinition.css';
import { NewAction } from '../../../shared/components/CommandBar/CommandBar';
import ListFilter from '../../components/ListFilter/ListFilter';
import formatter from '../../../shared/formatter';
import RiskAssessmentListInfoPanel from './RiskAssessmentListInfoPanel';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { UserInfoCtx, IUserInfoCtx } from '../../../UserInfoContext';
import { LanguageSettings } from '../../../models/LanguageSettings';
import { RouteComponentProps, withRouter } from 'react-router';
import { Table } from '../../../shared/components/Table/Table';
import Media from 'react-media';
import { MasterdataHeader } from '../../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../../shared/components/CommandBar/CommandBarV2';
import { RiskAssessmentNavMenu } from '../../RiskAssessments';
import { userService } from '../../../shared/services/userService';
import { TFunction } from 'i18next';
import { translateStatusForTenantSettings } from '../../riskAssessment/formhelper';
import { KnownTenantIds } from '../../../models/constants';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { EditOutlined, WindowsFilled } from '@ant-design/icons';

const navPaths = ['navigation.projects'];
const storeFilterConfigKey = 'RiskAssessmentList_storeFilterConfig';
class RiskAssessmentList extends PureComponent<IRiskAssessmentsListProps, IRiskAssessmentsListState> {
  state: IRiskAssessmentsListState;

  private riskAssessmentStatusOptions: ICheckableTag[];
  private branchesOfIndustryOptions: ICheckableTag[];
  private terms: ITenantTerms;

  constructor(props: IRiskAssessmentsListProps, context: any) {
    super(props, context);
    this.terms = userService.getTenantTerms();

    this.riskAssessmentStatusOptions = this.getStatusOptions(props);
    this.branchesOfIndustryOptions = _.map(this.props.availableBranchesOfIndustry, (o) => ({
      tag: o.id,
      title: getForLanguage(o.name, context.languageSettings),
    }));
    this.load = _.debounce(this.load, 200);
    this.executeSearch = _.debounce(this.executeSearch, 400);
    this.state = {
      searchConfig: this.searchWith(this.loadFilterConfig() ?? createRiskAssessmentListSearchConfig(), { language: context.languageSettings.getActiveLanguage()?.key }),
      riskAssessmetStatus: [],
      showPane: false,
      selectedAssessment: {} as IRiskAssessmentListViewModel,
      tenantId: userService.getActiveTenantId(),
    };
  }

  private getStatusOptions(props: IRiskAssessmentsListProps) {
    let statusOptions = _.map(getRiskAssessmentStatusOptions(props.t, this.terms, this.context), (o) => ({ tag: o.value, title: o.desc }));
    statusOptions.unshift({ tag: RiskAssessmentStatus.FilterOpen, title: translateStatusForTenantSettings(RiskAssessmentStatus.FilterOpen, props.t, this.context, this.terms) });
    return statusOptions;
  }

  searchWith(current: IRiskAssessmentListSearchConfig, options: RecursivePartial<IRiskAssessmentListSearchConfig>): IRiskAssessmentListSearchConfig {
    const config = _.cloneDeep(current);
    return _.assign(config, options);
  }

  executeSearch(options: RecursivePartial<IRiskAssessmentListSearchConfig>): void {
    const config = this.searchWith(this.state.searchConfig, options);
    this.storeFilterConfig(config);
    this.setState({ searchConfig: config });
    this.load(config);
  }

  storeFilterConfig(filterConfig: IRiskAssessmentListSearchConfig) {
    let storeValue: IStoredRiskAssessmentListSearchConfig = _.assign(_.cloneDeep(filterConfig), { tenantId: userService.getActiveTenantId(), branchId: userService.getActiveBranchOfIndustryId() });
    window.sessionStorage.setItem(storeFilterConfigKey, JSON.stringify(storeValue));
  }

  loadFilterConfig(): IRiskAssessmentListSearchConfig | null {
    let rawValue = window.sessionStorage.getItem(storeFilterConfigKey);
    if (!rawValue) {
      return null;
    }
    let storeValue: IStoredRiskAssessmentListSearchConfig = JSON.parse(rawValue);
    if (storeValue.tenantId !== userService.getActiveTenantId() || storeValue.branchId !== userService.getActiveBranchOfIndustryId()) {
      window.sessionStorage.removeItem(storeFilterConfigKey);
      return null;
    }
    return storeValue;
  }

  onTableChange = (pagination: PaginationConfig, filters: any, sorter: SorterResult<IRiskAssessmentListViewModel>, extra: TableCurrentDataSource<IRiskAssessmentListViewModel>) => {
    this.executeSearch({ paging: pagination, sorting: { field: sorter.field as string, order: sorter.order } });
  };

  render() {
    const { t, isLoading } = this.props;
    const paging = this.state.searchConfig.paging;

    const columns = this.createColumns(this.context.languageSettings, userService.hasMachineType(), userService.hasVendorLegalEntity(), t, this.context, this.terms);
    const { totalCount, riskAssessments } = this.props.riskAssessments;
    return (
      <>
        <MasterdataHeader>
          <CommandBarV2 paths={navPaths}>
            <RiskAssessmentNavMenu />
            {this.context.userInfo.permissions.riskAssessment.CanCreateRiskAssessment && <NewAction text="riskAssessment.newRiskAssessment" onClick={this.create} />}
          </CommandBarV2>
        </MasterdataHeader>
        <HtcCardWrapper>
          <HtcCard>
            <ListFilter
              onFilterChange={this.onFilterChange}
              availableBranchesOfIndustry={this.props.availableBranchesOfIndustry}
              branchOfIndustryId={this.props.branchOfIndustryId}
              branchesOfIndustryOptions={this.branchesOfIndustryOptions}
              filterConfig={this.state.searchConfig.filter}
              riskAssessmentStatusOptions={this.riskAssessmentStatusOptions}
              canFilterAllBranches={true}
            />
          </HtcCard>
          <HtcCard flex>
            <FormBox title={t('common.results')} flex contentflex>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={riskAssessments}
                loading={isLoading}
                onChange={this.onTableChange as any}
                pagination={{ current: paging.current, pageSize: paging.pageSize, total: totalCount }}
              />
            </FormBox>
          </HtcCard>
        </HtcCardWrapper>
        <RiskAssessmentListInfoPanel
          label={t('common.results')}
          drawerVisible={this.state.showPane}
          onClose={() => this.setState({ showPane: false })}
          riskAssessment={this.state.selectedAssessment}
        />
      </>
    );
  }

  private createColumns = (settings: LanguageSettings, hasMachineType: boolean, hasVendorLegalEntity: boolean, t: TFunction, ctx: IUserInfoCtx, terms: ITenantTerms) => {
    const result = [];
    const activeTenantId = userService.getActiveTenantId();
    const isPackSys = activeTenantId === KnownTenantIds.PackSys;
    const isServtec = activeTenantId === KnownTenantIds.Servtec;
    const isMaschinenbau = activeTenantId === KnownTenantIds.Bms;

    const hasBranchOfIndustryColumn = !isPackSys && !isServtec && !isMaschinenbau;
    const hasProcessTemplateColumn = isPackSys || isMaschinenbau;
    const hasPspColumn = isServtec || isMaschinenbau;

    result.push({
      title: this.props.t('riskAssessment.offerNr'),
      dataIndex: 'offerNr',
      key: 'offerNr',
      width: 180,
      sorter: true,
      render: (text: string, record: IRiskAssessmentListViewModel) => (
        <Media query={{ maxWidth: 1600 }}>
          {(matches) =>
            matches ? (
              <a
                href="#"
                className={styles.panelLink}
                onClick={() => {
                  this.onShowPane(record);
                }}
              >
                {record.offerNr}
              </a>
            ) : (
              <a href="#" className={styles.panelLink} onClick={() => this.props.history.push('/riskassessments/' + (record.token || record.id))}>
                {record.offerNr}
              </a>
            )
          }
        </Media>
      ),
    });

    result.push({
      title: this.props.t('riskAssessment.customer'),
      dataIndex: 'customerName',
      key: 'customer',
      width: 200,
      sorter: true,
      render: (text: string, record: IRiskAssessmentListViewModel) => getForLanguage(record.customerName, settings),
    });

    if (hasPspColumn) {
      result.push({
        title: ctx.t(terms.commissionNumber),
        dataIndex: 'pspElement',
        key: 'pspElement',
        width: 150,
        sorter: true,
        render: (text: string, record: IRiskAssessmentListViewModel) => record.pspElement,
      });
    }

    result.push({
      title: this.props.t('riskAssessment.country'),
      dataIndex: 'countryName',
      key: 'country',
      width: 150,
      sorter: true,
      render: (text: string, record: IRiskAssessmentListViewModel) => getForLanguage(record.countryName, settings),
    });

    if (hasVendorLegalEntity) {
      result.push({
        title: this.props.t('riskAssessment.vendorLegalEntity'),
        dataIndex: 'vendorLegalEntity',
        key: 'vendorLegalEntity',
        width: 200,
        sorter: true,
      });
    }

    result.push({
      title: ctx.t(terms.vendor),
      dataIndex: 'vendor',
      key: 'vendor',
      sorter: true,
      width: 130,
    });

    if (hasMachineType) {
      result.push({
        title: this.props.t('riskAssessment.machineType'),
        dataIndex: 'machineTypeName',
        key: 'machineType',
        width: 150,
        sorter: true,
        render: (text: string, record: IRiskAssessmentListViewModel) => getForLanguage(record.machineTypeName, settings),
      });
    }

    if (hasBranchOfIndustryColumn) {
      result.push({
        title: this.props.t('riskAssessment.branchOfIndustry'),
        dataIndex: 'branchName',
        key: 'branchOfIndustry',
        width: 110,
        sorter: true,
        render: (text: string, record: IRiskAssessmentListViewModel) => getForLanguage(record.branchName, settings),
      });
    }

    if (hasProcessTemplateColumn) {
      result.push({
        title: this.props.t('riskAssessment.processTemplate'),
        dataIndex: 'processTemplate',
        key: 'processTemplate',
        sorter: true,
        width: 130,
        render: (text: string, record: IRiskAssessmentListViewModel) => getForLanguage(record.processTemplate, settings),
      });
    }

    result.push({
      title: this.props.t('riskAssessment.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 110,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: IRiskAssessmentListViewModel) => formatter.formatDateString(record.createdDate),
    });

    result.push({
      title: this.props.t('riskAssessment.dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 110,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: IRiskAssessmentListViewModel) => formatter.formatDateString(record.dueDate),
    });

    result.push({
      width: 150,
      title: this.props.t('riskAssessment.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text: string, record: IRiskAssessmentListViewModel) => translateStatusForTenantSettings(record.status, t, ctx, terms),
    });

    result.push({
      title: 'Actions',
      key: 'actions',
      width: 100,
      render: (text: string, record: IRiskAssessmentListViewModel) => (
        <Button
          id="editRiskAssessment"
          type="primary"
          shape="circle"
          size="small"
          icon={<EditOutlined />}
          onClick={() => this.props.history.push('/riskassessments/' + (record.token || record.id))}
          data-automation-id={'editRiskAssessmentButton-' + record.offerNr}
        />
      ),
    });

    return result;
  };

  public async componentDidMount() {
    let filter = this.loadFilterConfig() ?? this.getSearchConfigForBranchOfIndustry();
    this.executeSearch(filter);
  }

  private create = () => this.props.history.push('/riskassessments/' + NewRiskAssessmentId);

  private getSearchConfigForBranchOfIndustry() {
    const searchConfig = _.cloneDeep(this.state.searchConfig);
    const option = _.find(this.branchesOfIndustryOptions, (b) => this.props.branchOfIndustryId == b.tag);
    if (option != null) {
      searchConfig.filter.branchOfIndustryFilter = [option.tag];
    }
    return searchConfig;
  }

  componentDidUpdate(prevProps: IRiskAssessmentsListProps) {
    if (prevProps.branchOfIndustryId !== this.props.branchOfIndustryId) {
      this.executeSearch(this.getSearchConfigForBranchOfIndustry());
    }
  }

  onSearch = (e: any) => {
    this.executeSearch({ filter: { searchText: e.target.value } });
  };

  private onShowPane = (record: IRiskAssessmentListViewModel) => {
    this.setState({ showPane: true, selectedAssessment: record });
  };

  private onFilterChange = (filter: IRiskAssessmentFilter) => {
    this.executeSearch({ filter: filter });
  };

  private load = (seachConfig: IRiskAssessmentListSearchConfig) => {
    this.props.load(seachConfig);
  };
}

RiskAssessmentList.contextType = UserInfoCtx;
export default withTranslation()(withRouter(RiskAssessmentList));

interface IRiskAssessmentsListProps extends WithTranslation, RouteComponentProps {
  branchOfIndustryId: string;
  riskAssessments: IRiskAssessmentListViewModels;
  isLoading: boolean;
  availableBranchesOfIndustry: IBranchOfIndustry[];
  load: (filter: IRiskAssessmentListSearchConfig) => void;
}

interface IRiskAssessmentsListState {
  searchConfig: IRiskAssessmentListSearchConfig;
  riskAssessmetStatus: RiskAssessmentStatus[];
  showPane: boolean;
  selectedAssessment: IRiskAssessmentListViewModel;
  tenantId: string;
}
