import React, { useMemo, useContext, useCallback } from 'react';
import { Tree } from 'antd';
import { IMachineType, NewMachineTypeId } from '../../../models/masterdata';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './MachineTypeTree.overrides.css';
import { UserInfoCtx, IUserInfoCtx } from '../../../UserInfoContext';

export const MachineTypeTree: React.FC<IMachineTypeTreeProps> = ({ machineTypes, onSelect }) => {
  let [t] = useTranslation();
  let onNodeSelect = useCallback(
    (selected, info) => {
      let data = info.node.htcProps;
      if (data.isAdd) {
        return onSelect(NewMachineTypeId, data.parentId);
      }
      return onSelect(data.id, data.parentId);
    },
    [onSelect],
  );

  let ctx = useContext(UserInfoCtx);
  let addNodeText = t('masterdata.machineTypes.newMachineType');
  let treeData = useMemo(() => buildTreeData(machineTypes, ctx, addNodeText), [machineTypes, ctx, addNodeText]);

  return <Tree showLine={true} onSelect={onNodeSelect} treeData={treeData} defaultExpandAll />;
};

function buildTreeData(machineTypes: IMachineType[], ctx: IUserInfoCtx, addNewNodeLabel) {
  let allNodes = _.map(machineTypes, (mt) => {
    return {
      title: ctx.t(mt.name),
      key: mt.id,
      children: [
        {
          title: addNewNodeLabel,
          key: mt.id + 'New',
          htcProps: {
            isAdd: true,
            parentId: mt.id,
            id: null,
          },
        },
      ],
      htcProps: {
        isAdd: false,
        parentId: mt.parentId,
        id: mt.id,
      },
    };
  });
  // Build Childrelations

  _.forEach(allNodes, (node) => {
    if (node.htcProps.parentId) {
      let parent = _.find(allNodes, (n) => n.htcProps.id === node.htcProps.parentId);
      if (parent !== undefined) {
        parent.children.unshift(node);
      }
    }
  });

  let rootNodes = _.filter(allNodes, (n) => !n.htcProps.parentId);
  rootNodes.push({
    title: addNewNodeLabel,
    key: 'New',
    htcProps: {
      isAdd: true,
      parentId: null,
      id: null,
    },
    children: [],
  });

  return rootNodes;
}

interface IMachineTypeTreeProps {
  machineTypes: IMachineType[];
  onSelect: (itemId: string, parentId: string) => void;
}
