import _ from 'lodash';
import { IPickerBaseProps, IPickerBaseState } from '../PickerBase/PickerBase';
import { IRiskAssessmentTemplateRef } from '../../../models/riskAssessment';
import { RiskAssessmentTemplateService } from '../../services/riskAssessmentTemplateService';
import LanguageAwarePickerBase from '../PickerBase/LanguageAwarePickerBase';

class RiskAssessmentTemplatePicker extends LanguageAwarePickerBase<IRiskAssessmentTemplateRef> {
  constructor(props: any) {
    super(props);
  }
  static getDerivedStateFromProps(props: IPickerBaseProps<IRiskAssessmentTemplateRef>, state: IPickerBaseState<IRiskAssessmentTemplateRef>): Partial<IPickerBaseState<IRiskAssessmentTemplateRef>> {
    if ((state.value && (state.value as IRiskAssessmentTemplateRef).id) !== (props.value && (props.value as IRiskAssessmentTemplateRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<IRiskAssessmentTemplateRef[]> {
    const onQueryItems = this.props.onQueryItems || RiskAssessmentTemplateService.queryRiskAssessmentTemplateOptions;
    return onQueryItems(query, this.props.languageSettings, this.props.tenantId, this.props.branchId);
  }
}

export default RiskAssessmentTemplatePicker;
