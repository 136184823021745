import React from 'react';
import { Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

const TR = 'components.DeleteButtonConfirm.';

export const DeleteButtonConfirm: React.FC<{ onDelete: () => void; disabled?: boolean }> = ({ onDelete, disabled }) => {
  const [t] = useTranslation();
  return (
    <Popconfirm disabled={disabled} onConfirm={onDelete} title={t(TR + 'title')} okText={t(TR + 'yes')} cancelText={t(TR + 'no')}>
      <Button icon={<DeleteOutlined />} disabled={disabled} danger>
        {t(TR + 'label')}
      </Button>
    </Popconfirm>
  );
};
