import { createAsyncAction } from 'typesafe-actions';
import { UserInfo } from '../models/user/UserInfo';
import { IBranchOfIndustrySetting } from './epics';
export const selectBranchOfIndustrySettingForUser = createAsyncAction(
  'selectBranchOfIndustrySettingForUser/fetch_request',
  'selectBranchOfIndustrySettingForUser/fetch_success',
  'selectBranchOfIndustrySettingForUser/fetch_failure',
)<IBranchOfIndustrySetting, UserInfo, Error>();

export const selectLanguageForUser = createAsyncAction('selectLanguageForUser/fetch_request', 'selectLanguageForUser/fetch_success', 'selectLanguageForUser/fetch_failure')<
  IBranchOfIndustrySetting,
  UserInfo,
  Error
>();
