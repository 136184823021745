import React, { PureComponent } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Formik, FormikHelpers, FormikProps, getIn } from 'formik';
import nameof from 'ts-nameof.macro';
import {
  FormTextInput,
  FormRow,
  FormRadioYesNo,
  FormCountryPicker,
  FormIncotermPicker,
  FormCustomerPicker,
  FormDateInput,
  FormGrid,
  FormMachineTypeTreePicker,
  IFormSelectOption,
  FormRiskAssessmentTemplatePicker,
} from '../../shared/FormComponents';
import { createNewRiskAssessment, ICreateRiskAssessmentCommand, IRiskAssessmentMetadata, IRiskAssessmentTemplateRef, INewRiskassessmentVm, IRiskAssessmentConfig } from '../../models/riskAssessment';
import _ from 'lodash';
import { CancelAction, SaveAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import { ICustomerRef, IIncotermRef, ICountryRef, IMachineTypeRef, ICustomer, ICountry } from '../../models/masterdata';
import * as actions from '../ducks';
import { connect } from 'react-redux';
import { IRootState, getActiveBranchOfIndustryId, getAvailableBranchesOfIndustry } from '../../ducks/reducer';
import styles from './NewRiskAssessment.module.css';
import { Spin, Form } from 'antd';
import customerService from '../../shared/services/customerService';
import countryService from '../../shared/services/countryService';
import CustomerCreationPanel from '../components/PanelComponents/CustomerCreationPanel';
import CountryCreationPanel from '../components/PanelComponents/CountryCreationPanel';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { IBranchOfIndustryInfo } from '../../models/masterdata/ITenant';
import PermissionCheck from '../../shared/components/PermissionCheck/PermissionCheck';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { RouteComponentProps, withRouter } from 'react-router';
import riskAssessmentService from '../../shared/services/riskAssessmentService';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { getYesterday } from '../../models/riskAssessment/RiskAssessmentStatusHelper';
import { requiredRiskString, riskString, requiredRiskRef } from '../../shared/validation';
import * as yup from 'yup';
import { PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import { multiLanguageStringMatchesQuery } from '../../shared/services/masterdataQueryHelper';
import { getForLanguage } from '../../models/IMultiLanguageString';

const TP = 'riskAssessment.editform.';
const navPaths = ['navigation.projects', 'riskAssessment.newRiskAssessment'];

class NewRiskAssessment extends PureComponent<INewRiskAssessmentProps, INewRiskAssessmentState> {
  customerPicker: any = null;
  countryPicker: any = null;

  state: INewRiskAssessmentState = {
    createCustomerPaneOpen: false,
    createCountryPaneOpen: false,
    riskAssessment: null,
    newRiskAssessmentVm: null,
    validationScheme: null,
    allowedProcessTemplates: [],
  };
  constructor(props: INewRiskAssessmentProps) {
    super(props);
    this.customerPicker = React.createRef();
    this.countryPicker = React.createRef();
  }

  public componentDidMount() {
    this.ensureNewRiskAssessment();
  }

  public componentDidUpdate(prevProps: INewRiskAssessmentProps) {
    if (prevProps.branchOfIndustryId !== this.props.branchOfIndustryId) {
      this.ensureNewRiskAssessment();
    }
  }

  ensureNewRiskAssessment = async () => {
    const model = await riskAssessmentService.getNewRiskAssessmentVm(this.props.branchOfIndustryId);
    if (model) {
      this.setState({
        riskAssessment: createNewRiskAssessment(model),
        newRiskAssessmentVm: model,
        validationScheme: this.getValidationScheme(this.props.t, model.config),
        allowedProcessTemplates: model.config.allowedProcessTemplates,
      });
    }
  };

  render() {
    const { t } = this.props;
    const assessment = this.state.riskAssessment;
    const settings = this.context.languageSettings;
    const userCtx = this.context as IUserInfoCtx;

    if (!assessment) {
      return <Spin></Spin>;
    }

    const config = this.state.newRiskAssessmentVm.config;
    return (
      <PermissionCheck hasAccess={this.context.userInfo.permissions.riskAssessment.CanCreateRiskAssessment}>
        <ContentWrapper>
          <Formik enableReinitialize={true} initialValues={assessment} validationSchema={this.state.validationScheme} onSubmit={this.handleSave}>
            {(formik) => {
              const { submitForm } = formik;
              return (
                <>
                  <MasterdataHeader>
                    <CommandBarV2 paths={navPaths}>
                      <CancelAction onClick={this.navigateBack} />
                      <SaveAction text="riskAssessment.newRiskAssessment" onClick={submitForm} />
                    </CommandBarV2>
                  </MasterdataHeader>
                  <HtcCardWrapper>
                    <HtcCard>
                      <FormBox title={t(TP + 'metadata.metadata')}>
                        <Form layout="vertical">
                          <FormRow>
                            <FormTextInput {...formik} required name={nameof<IRiskAssessmentMetadata>((n) => n.offerNr)} label={t('riskAssessment.offerNr')} />
                            <span style={{ display: 'flex' }}>
                              <span style={{ width: '100%' }}>
                                <FormCustomerPicker
                                  required
                                  {...formik}
                                  name={nameof<IRiskAssessmentMetadata>((n) => n.customer)}
                                  label={t('riskAssessment.customer')}
                                  onRef={(ref: any) => (this.customerPicker = ref)}
                                  onChange={(ev) => this.onCustomerChange(ev, formik)}
                                  settings={settings}
                                />
                              </span>
                              <span className={styles.ColumnWithPane}>
                                <PlusOutlined onClick={() => this.setState({ createCustomerPaneOpen: true })} data-automation-id={'add-' + nameof<IRiskAssessmentMetadata>((n) => n.customer)} />
                              </span>
                            </span>
                            {config.hasNewCustomerFlag && <FormRadioYesNo {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.isNewCustomer)} label={t('riskAssessment.isNewCustomer')} />}
                            <span style={{ display: 'flex' }}>
                              <span style={{ width: '100%' }}>
                                <FormCountryPicker
                                  required
                                  {...formik}
                                  name={nameof<IRiskAssessmentMetadata>((n) => n.country)}
                                  label={t('riskAssessment.country')}
                                  onRef={(ref: any) => (this.countryPicker = ref)}
                                />
                              </span>
                              <span className={styles.ColumnWithPane}>
                                <PlusOutlined onClick={() => this.setState({ createCountryPaneOpen: true })} data-automation-id={'add-' + nameof<IRiskAssessmentMetadata>((n) => n.country)} />
                              </span>
                            </span>
                            {config.hasProjectDiagnosis && <FormTextInput {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.projectDiagnosis)} label={t('riskAssessment.projectDiagnosis')} />}
                          </FormRow>
                          <FormGrid>
                            {config.hasMachineType && (
                              <FormMachineTypeTreePicker required {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.machineType)} label={t('riskAssessment.machineType')} />
                            )}
                            {config.hasMachineType && <FormTextInput {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.machineTypeInfo)} label={t('riskAssessment.machineTypeInfo')} />}
                            <FormTextInput {...formik} required name={nameof<IRiskAssessmentMetadata>((n) => n.vendor)} label={userCtx.t(config.vendor)} />
                            {config.hasVendorLegalEntity && (
                              <FormTextInput {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.vendorLegalEntity)} label={t('riskAssessment.vendorLegalEntity')} />
                            )}
                            <span style={{ display: 'flex' }}>
                              <span style={{ width: '100%' }}>
                                <FormIncotermPicker required {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.incoterms)} label={t('riskAssessment.incoterms')} />
                              </span>
                              <span className={styles.ColumnWithPane}>
                                <a tabIndex={-1} style={{ width: '5%', marginLeft: '5px' }} href="../../../assets/2014-03-25-Incoterms2010.pdf" target="_blanc">
                                  <QuestionOutlined />
                                </a>
                              </span>
                            </span>
                            <FormTextInput required {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.city)} label={userCtx.t(config.incotermsSupplement)} />
                            <FormTextInput {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.pspElement)} label={userCtx.t(config.commissionNumber)} />
                            <FormDateInput required {...formik} name={nameof<IRiskAssessmentMetadata>((n) => n.dueDate)} label={t('riskAssessment.dueDate')} />
                          </FormGrid>
                        </Form>
                      </FormBox>
                    </HtcCard>
                    <HtcCard>
                      <FormBox title={t(TP + 'projektTemplate')}>
                        <Form layout="vertical">
                          <FormRow>
                            <FormRiskAssessmentTemplatePicker
                              required
                              {...formik}
                              name={nameof<ICreateRiskAssessmentCommand>((r) => r.processTemplate)}
                              label={t('riskAssessment.processTemplate')}
                              forBranchId={this.props.branchOfIndustryId}
                              onQueryItems={(query) => this.onQueryRiskAssessmentTemplates(query)}
                            />
                          </FormRow>
                        </Form>
                      </FormBox>
                    </HtcCard>
                  </HtcCardWrapper>
                </>
              );
            }}
          </Formik>
          <CustomerCreationPanel
            hasDescription={config.hasCustomerDescription}
            label={t(TP + 'addCustomer')}
            isOpen={this.state.createCustomerPaneOpen}
            onSaveCustomer={this.onSaveCustomer}
            onClose={() => this.setState({ createCustomerPaneOpen: false })}
          />
          <CountryCreationPanel
            label={t(TP + 'addCountry')}
            isOpen={this.state.createCountryPaneOpen}
            onSaveCountry={this.onSaveCountry}
            onClose={() => this.setState({ createCountryPaneOpen: false })}
          />
        </ContentWrapper>
      </PermissionCheck>
    );
  }

  private onSaveCustomer = async (customer: ICustomer) => {
    const newCustomer = await customerService.createCustomer(customer);
    const customerRef = { id: newCustomer.id, name: newCustomer.name } as ICustomerRef;

    this.customerPicker.addAndSelectOption(customerRef);
    this.setState({ createCustomerPaneOpen: false });
  };

  private onSaveCountry = async (country: ICountry) => {
    const newCountry = await countryService.createCountry(country);
    const countryRef = { id: newCountry.id, name: newCountry.name } as ICountryRef;

    this.countryPicker.addAndSelectOption(countryRef);
    this.setState({ createCountryPaneOpen: false });
  };

  private onCustomerChange = (newCustomer: ICustomer, formik: FormikProps<ICreateRiskAssessmentCommand>) => {
    if (!newCustomer.country) {
      return;
    }

    const countryFieldName = nameof<ICreateRiskAssessmentCommand>((c) => c.country) as 'country';
    const hasCountrySet = getIn(formik.values, countryFieldName) != null;

    if (hasCountrySet) {
      return;
    }

    formik.setFieldValue(countryFieldName, newCustomer.country);
  };

  private onQueryRiskAssessmentTemplates(query: string): Promise<IRiskAssessmentTemplateRef[]> {
    let settings = this.context.languageSettings;
    let templateOptions = this.state.allowedProcessTemplates || [];
    let matchingOptions = templateOptions.filter((o) => multiLanguageStringMatchesQuery(query, settings, o.name));
    let options = _.orderBy(matchingOptions, (item) => item != null && getForLanguage(item.name, settings), ['asc']);
    return Promise.resolve(options);
  }

  private navigateBack = () => this.props.history.push('/riskassessments/listoverview/list');

  private handleSave = (riskAssessment: ICreateRiskAssessmentCommand, actions: FormikHelpers<ICreateRiskAssessmentCommand>) => {
    try {
      this.props.createRiskAssessment(riskAssessment);
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors(error.toString() as any);
    }
  };

  private getValidationScheme = (t: any, config: IRiskAssessmentConfig) => {
    const validationConfig = {
      offerNr: requiredRiskString().test('Unique Offer No.', t('error.offerNoMustBeUnique'), (value: string): Promise<boolean> => {
        return this.offerNumberAvailable(config.tenantId, config.branchId, value)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }),
      dueDate: yup.date().typeError(t('error.required')).required().min(getYesterday()) as any,
      city: requiredRiskString(),
      machineTypeInfo: riskString(),
      vendor: requiredRiskString(),
      vendorLegalEntity: riskString(),
      pspElement: riskString(),
      customer: requiredRiskRef<ICountryRef>(),
      incoterms: requiredRiskRef<IIncotermRef>(),
      country: requiredRiskRef<ICountryRef>(),
      processTemplate: requiredRiskRef<IRiskAssessmentTemplateRef>(),
      machineType: undefined,
    };

    if (config.hasMachineType) {
      validationConfig.machineType = requiredRiskRef<IMachineTypeRef>();
    }

    return yup.object().shape<any>(validationConfig);
  };
  private offerNumberAvailable = (tenantId: string, branchId: string, value: string): Promise<yup.ValidationError> => {
    return new Promise<yup.ValidationError>((resolve, reject) => {
      const checkOfferNumber = async () => {
        if (!value) {
          resolve(undefined);
          return;
        }

        const result = await riskAssessmentService.isOfferNoValid(tenantId, branchId, value);
        if (result) {
          resolve(undefined);
        } else {
          const validationError = new yup.ValidationError('value already exists', value, '');
          reject(validationError);
        }
      };
      checkOfferNumber();
    });
  };
}

const mapStateToProps = (state: IRootState) => {
  return {
    isLoading: state.riskAssessment.isLoading,
    branchOfIndustryId: getActiveBranchOfIndustryId(state.app),
    availableBranchesOfIndustry: getAvailableBranchesOfIndustry(state.app),
  };
};

NewRiskAssessment.contextType = UserInfoCtx;
export default connect(mapStateToProps, {
  createRiskAssessment: actions.createRiskAssessment.request,
})(withTranslation()(withRouter(NewRiskAssessment)));

interface INewRiskAssessmentProps extends WithTranslation, RouteComponentProps {
  createRiskAssessment: (command: ICreateRiskAssessmentCommand) => void;
  existingOrderNumbers: string[];
  branchOfIndustryId: string;
  availableBranchesOfIndustry: IBranchOfIndustryInfo[];
}

interface INewRiskAssessmentState {
  riskAssessment: ICreateRiskAssessmentCommand;
  createCustomerPaneOpen: boolean;
  createCountryPaneOpen: boolean;
  newRiskAssessmentVm: INewRiskassessmentVm;
  validationScheme: yup.ObjectSchema<any>;
  allowedProcessTemplates: IRiskAssessmentTemplateRef[];
}
