import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { User } from 'oidc-client';
import React, { PureComponent, FC, useEffect } from 'react';
import nameof from 'ts-nameof.macro';
import { Countries } from './masterdata/countries/Countries';
import { Departments } from './masterdata/departments/Departments';
import { Incoterms } from './masterdata/incoterms/Incoterms';
import { IUserTenantInfo } from './models/masterdata/ITenant';
import { Navigation } from './Navigation';
import TenantSelection from './shared/components/TenantSelection/TenantSelection';
import TenantSelectionContainer from './tenantselection/TenantSelection';
import AppHeader from './AppHeader';
import Users from './users/Users';
import { BranchesOfIndustry } from './masterdata/branchesofindustry/BranchesOfIndustry';
import { Topics } from './masterdata/topic/Topics';
import './overrides.css';
import './overridesIE.css';
import styles from './App.module.css';
import { Customers } from './masterdata/customers/Customers';
import { MachineTypes } from './masterdata/machinetypes/MachineTypes';
import { GeneralSettings } from './masterdata/generalsettings/GeneralSettings';
import Exception from './shared/components/Exception/Exception';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import { UserInfo } from './models/user/UserInfo';
import { UserPermissions } from './models';
import RiskAssessments from './riskAssessments/RiskAssessments';
import { RiskAssessment } from './riskAssessments/riskAssessment/RiskAssessment';
import NewRiskAssessment from './riskAssessments/riskAssessment/NewRiskAssessment';
import { ILanguage } from './models/LanguageSettings';
import { RiskAssessmentTemplates } from './masterdata/riskAssessmentTemplate/RiskAssessmentTemplates';
import { userService } from './shared/services/userService';
import { useMedia } from 'react-use';
import { SizeDetector } from './shared/components/SizeDetector';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

const { Content, Sider } = Layout;

interface IAppState {
  loggedIn: boolean;
  user?: User | undefined;
  apiData: string;
  collapsed: boolean;
  match: any;
}

class AppComponent extends PureComponent<IAppComponentProps, IAppState> {
  constructor(props: IAppComponentProps) {
    super(props);
    this.state = {
      loggedIn: false,
      apiData: '',
      collapsed: true,
      match: '',
    };
  }
  public render() {
    const { collapsed } = this.state;
    const { userInfo, activeTenant, t, permissions } = this.props;
    if (this.noTenantAvailable(userInfo) && !this.isAdmin(userInfo)) {
      return (
        <Layout>
          <Content className={styles.content}>
            <Exception type="403" desc={t('app.accessDenied')} />
          </Content>
        </Layout>
      );
    } else if (this.tenantSelectionRequired(userInfo) && !this.isAdmin(userInfo)) {
      return (
        <Layout>
          <Content className={styles.content}>
            <TenantSelection
              availableTenants={userInfo.availableTenants}
              selectedTenantId={userInfo.userSetting != null ? userInfo.userSetting.activeTenantId : null}
              onSelectedTenantChange={this.handleSelectedTenantChange}
            />
          </Content>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <Sider className={classNames(styles.sider, isMobile && styles.siderMobile)} width="auto">
            <Navigation collapsed={collapsed} userInfo={userInfo} activeTenant={activeTenant} permissions={permissions} />
          </Sider>
          <Layout>
            <AppHeader collapsed={collapsed} onLogout={this.logoutUser} toggleCollapsed={this.toggleCollapsed} availableLanguages={this.props.availableLanguages} />
            <Content className={styles.content}>
              <Switch>
                <Route path="/riskassessments/new" component={NewRiskAssessment} />
                <Route path={'/riskassessments/listoverview'} component={RiskAssessments} />
                <Route path="/riskassessments/:riskAssessmentId" component={RiskAssessment} />
                {permissions.metadata.canEditDepartments && <Route path="/departments/:id?" component={Departments} />}
                {permissions.metadata.canEditIncoterms && <Route path="/incoterms/:id?" component={Incoterms} />}
                {permissions.metadata.canEditCountries && <Route path="/countries/:id?" component={Countries} />}
                {permissions.metadata.canEditCustomers && <Route path="/customers/:id?" component={Customers} />}
                {permissions.metadata.canEditMachineType && <Route path="/machinetypes/:id?" component={MachineTypes} />}
                {permissions.metadata.canEditGeneralSetting && <Route path="/generalsettings/:id?" component={GeneralSettings} />}
                {permissions.metadata.canEditBranchesOfIndustry && <Route path="/branchesofindustry/:id?" component={BranchesOfIndustry} />}
                {permissions.metadata.canEditTopics && <Route path="/topics/:id?" component={Topics} />}
                {permissions.metadata.canEditRiskAssessmentTemplates && <Route path="/riskAssessmenttemplates/:id?" component={RiskAssessmentTemplates} />}
                {permissions.security.canManageUsers && <Route path="/users" component={Users} />}
                <Route path="/changetenant" component={TenantSelectionContainer} />
                <Redirect from="/" to="/riskassessments/listoverview" />
              </Switch>
            </Content>
          </Layout>
          <AutoTokenRenew user={this.props.user}></AutoTokenRenew>
          <SizeDetector
            onChange={(isSmall) => {
              if (isMobile) {
                this.setState({ collapsed: isSmall });
              }
            }}
          />
        </Layout>
      );
    }
  }

  public async componentDidMount() {
    let user = this.props.user;
    const userinfo = this.props.userInfo;
    if (user) {
      console.log('User', { profile: user.profile, userinfo });
    } else {
      try {
        user = await userService.signinSilent();
      } catch (e) {
        console.log('Silent Login failed', e);
      }
    }

    if (user) {
      this.setState({ user, loggedIn: true });
    }
  }

  public toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  private logoutUser = () => {
    userService.logout();
  };

  private handleSelectedTenantChange = (tenant: IUserTenantInfo) => {
    this.props.selectActiveTenant(tenant);
  };

  private tenantSelectionRequired = (settings: UserInfo): boolean => {
    if (!settings || !settings.availableTenants) {
      return true;
    }
    if (settings.availableTenants.length > 1 && (!settings.userSetting || !settings.userSetting.activeTenantId)) {
      return true;
    }
    return false;
  };
  private noTenantAvailable = (settings: UserInfo): boolean => {
    if (!settings || !settings.availableTenants || settings.availableTenants.length === 0) {
      return true;
    }
  };
  private isAdmin = (settings: UserInfo): boolean => {
    // TODO prüfen ob Admin Rechte bestrehen
    if (!settings || !settings.claims || settings.claims.length === 0) {
      return false;
    }
    const adminClaim = _.find(settings.claims, (c) => c.type === 'AppAdministrator');
    return adminClaim != null;
  };
}

export default withTranslation()(AppComponent);

export interface IAppComponentProps extends WithTranslation {
  user: User | undefined;
  userInfo: UserInfo;
  availableLanguages: ILanguage[];
  activeTenant: IUserTenantInfo;
  permissions: UserPermissions;
  selectActiveTenant: (item: IUserTenantInfo) => void;
  collapsed: boolean;
}

const AutoTokenRenew: FC<{ user: User }> = ({ user }) => {
  useEffect(() => {
    if (!user) {
      return;
    }

    const refreshIntervalId = setInterval(() => {
      userService.signinSilent();
    }, 20 * 60 * 1000);

    const visibilityChangedHandler = () => {
      if (document.visibilityState === 'visible') {
        userService.signinSilent();
      }
    };

    document.addEventListener('visibilitychange', visibilityChangedHandler);

    return () => {
      clearInterval(refreshIntervalId);
      document.removeEventListener('visibilitychange', visibilityChangedHandler);
    };
  }, [user]);
  return null;
};
