import { IDocumentMetadata } from '../../../models/documents/IDocumentMetaData';
import _ from 'lodash';

export interface IDocumentListState {
  actionMetadata: IDocumentMetadata;
  showEditPanel: boolean;
  showCheckInPanel: boolean;
  showConfirmationPanel: boolean;
  showUploadPanel: boolean;
  showArchived: boolean;
  confirmAction: IConfirmAction;
}

export interface IConfirmAction {
  (actionMetadata: IDocumentMetadata): void;
}

function createEmptyState(): IDocumentListState {
  return {
    actionMetadata: null,
    showEditPanel: false,
    showUploadPanel: false,
    showConfirmationPanel: false,
    showCheckInPanel: false,
    showArchived: false,
    confirmAction: null,
  };
}

type Action =
  | { type: 'edit'; actionMetadata: IDocumentMetadata }
  | { type: 'upload'; actionMetadata: IDocumentMetadata }
  | { type: 'checkIn'; actionMetadata: IDocumentMetadata }
  | { type: 'confirm'; actionMetadata: IDocumentMetadata; confirmAction: IConfirmAction }
  | { type: 'archived'; showArchived: boolean }
  | { type: 'reset' };

export function reducer(state: IDocumentListState, action: Action): IDocumentListState {
  switch (action.type) {
    case 'edit':
      return { ...state, showEditPanel: true, actionMetadata: action.actionMetadata };
    case 'upload':
      return { ...state, showUploadPanel: true, actionMetadata: action.actionMetadata };
    case 'checkIn':
      return { ...state, showCheckInPanel: true, actionMetadata: action.actionMetadata };
    case 'confirm':
      return { ...state, showConfirmationPanel: true, actionMetadata: action.actionMetadata, confirmAction: action.confirmAction };
    case 'archived':
      return { ...state, showArchived: action.showArchived };
    case 'reset':
      return _.merge(createEmptyState(), { showArchived: state.showArchived });
  }
}

export function createEdit(data: IDocumentMetadata) {
  return {
    type: 'edit' as const,
    actionMetadata: data,
  };
}

export function createUpload(data: IDocumentMetadata) {
  return {
    type: 'upload' as const,
    actionMetadata: data,
  };
}

export function createCheckIn(data: IDocumentMetadata) {
  return {
    type: 'checkIn' as const,
    actionMetadata: data,
  };
}

export function createConfirm(data: IDocumentMetadata, action: IConfirmAction) {
  return {
    type: 'confirm' as const,
    actionMetadata: data,
    confirmAction: action,
  };
}

export function createShowArchived(showArchived: boolean) {
  return {
    type: 'archived' as const,
    showArchived,
  };
}

export function createReset() {
  return {
    type: 'reset' as const,
  };
}
