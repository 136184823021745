import React, { FC, useContext } from 'react';
import {
  IRiskAssessmentHistoryCollection,
  IRiskAssessmentHistoryEntry,
  RiskAssessmentHistoryChangeTypes,
  AppraisalChange,
  RiskAssessmentHistoryRisksChanges,
  TopicEvaluationChange,
  MeasuresChange,
  IRiskAssessmentConfig,
  ITopicEvaluationVm,
  ApprovalDataChanges,
} from '../../../../models/riskAssessment';
import { Drawer, Spin, Timeline, Empty, Alert } from 'antd';
import _ from 'lodash';
import formatter from '../../../../shared/formatter';
import styles from './HistoryPane.module.css';
import { useTranslation } from 'react-i18next';
import FormBox from '../../../../shared/components/FormBox/FormBox';
import { formatNumberForDisplay } from '../../../../shared/numericHelpers';
import { UserInfoCtx, IUserInfoCtx } from '../../../../UserInfoContext';
import nameof from 'ts-nameof.macro';
import { riskToString } from '../../../../models/masterdata';
import { TFunction } from 'i18next';
import classnames from 'classnames';
import useSWR from 'swr';
import { ArrowRightOutlined } from '@ant-design/icons';

const TP = 'components.HistoryPane.';

export const HistoryPane: FC<{
  searchKey: string;
  visible: boolean;
  config: IRiskAssessmentConfig;
  onClose: () => void;
  getHistory: () => Promise<IRiskAssessmentHistoryCollection>;
}> = ({ visible, onClose, getHistory, searchKey, config }) => {
  let [t] = useTranslation();
  let { data } = useSWR(visible ? searchKey : null, getHistory);
  let loading = !data;
  let history = data || emptyHistoryCollection();
  return (
    <Drawer width={600} onClose={onClose} visible={visible} title={t(TP + 'header')}>
      <div className={styles.drawerContainer}>
        {loading && <Spin />}
        {!loading && !history.hasEntries && <NoHistory />}
        {!loading && history.hasEntries && <HistoryTimeline config={config} history={history} />}
      </div>
    </Drawer>
  );
};

export const HistoryTimeline: FC<{ history: IRiskAssessmentHistoryCollection; config: IRiskAssessmentConfig }> = ({ history, config }) => {
  return (
    <div className={styles.scrollContainer}>
      <Timeline>
        {_.map(history.entries, (entry, idx) => (
          <Timeline.Item key={idx}>
            <HistoryItem entry={entry} config={config} />
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

const HistoryItem: FC<{ entry: IRiskAssessmentHistoryEntry; config: IRiskAssessmentConfig }> = React.memo(({ entry, config }) => {
  const [t] = useTranslation();
  const topicChanges: TopicEvaluationChange[] = [];
  const riskChanges: RiskAssessmentHistoryRisksChanges[] = [];
  const approvalDataChanges: ApprovalDataChanges[] = [];
  const changes = [];

  _.forEach(entry.changes, (entry) => {
    switch (entry.type) {
      case 'MeasureAmountChange':
      case 'RiskAfterMeasureAmountChange':
      case 'RiskValueChange':
        riskChanges.push(entry);
        break;
      case 'TopicEvaluationChange':
        topicChanges.push(entry);
        break;

      case 'VendorPoaChange':
      case 'OfferNrPoaChange':
      case 'DeliveryTimesClearanceChange':
      case 'BillRecipientClearanceChange':
      case 'SignatureDateClearanceChange':
      case 'ProjectStartClearanceChange':
        approvalDataChanges.push(entry);
        break;
      default:
        changes.push(entry);
        break;
    }
  });

  return (
    <div>
      <div>
        <span className={styles.header}>
          {formatter.formatDateWithTimeString(entry.timestamp)}, {entry.userName}
        </span>
      </div>
      <div>
        <Risks changes={riskChanges} config={config} />
        <Topics changes={topicChanges} config={config} />
        <ApprovalData changes={approvalDataChanges} config={config} />
        {_.map(changes, (change, idx) => (
          <div key={idx}>{getComponentForChange(change, t)}</div>
        ))}
      </div>
    </div>
  );
});

HistoryItem.displayName = 'HistoryItem';

const NoHistory: FC = () => {
  return <Empty description={false} />;
};

function emptyHistoryCollection() {
  return { entries: [], hasEntries: false };
}

function getComponentForChange(change: RiskAssessmentHistoryChangeTypes, t: TFunction) {
  switch (change.type) {
    case 'AppraisalChange':
      return <Appraisal change={change} />;
    case 'MeasuresChange':
      return <Measures change={change} />;
    case 'EvaluationCompletedChange':
      return <Alert message={t(TP + 'evaluationCompletedChange')} type="info" />;
    case 'EvaluationReopenedChange':
      return <Alert message={t(TP + 'evaluationReopenedChange')} type="info" />;
  }

  return <div>Unknown Change</div>;
}

function fallback(value: any, fallback: string) {
  if (value == null || value === '') {
    return fallback;
  }
  return value;
}

function moneyWithFallback(currency: string, t: TFunction) {
  const fallbackValue = t('common.novalue');
  const formatFunc = (amount: number) => {
    if (amount == null) {
      return fallbackValue;
    }

    return formatNumberForDisplay(amount, currency);
  };

  return formatFunc;
}

const Risks: FC<{ changes: RiskAssessmentHistoryRisksChanges[]; config: IRiskAssessmentConfig }> = ({ changes, config }) => {
  const [t] = useTranslation();

  if (_.isEmpty(changes)) {
    return null;
  }
  const { currency } = config;
  const format = moneyWithFallback(currency, t);

  return (
    <FormBox title={t(TP + 'risks.header')}>
      <table className={styles.riskstable}>
        <tbody>
          {_.map(changes, (change, idx) => (
            <tr className={classnames(idx % 2 !== 0 && styles.alternaterow)} key={idx}>
              <td className={classnames(styles.tablecol, styles.titlecell)}>{t(TP + 'risks.' + change.type)}</td>
              <td className={classnames(styles.tablecol, styles.alignRight)}>{format(change.oldValue)}</td>
              <td className={styles.tablecol}>
                <Separator />
              </td>
              <td className={classnames(styles.tablecol, styles.alignRight)}>{format(change.newValue)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </FormBox>
  );
};

const Topics: FC<{ changes: TopicEvaluationChange[]; config: IRiskAssessmentConfig }> = ({ changes, config }) => {
  const [t] = useTranslation();
  const ctx = useContext(UserInfoCtx);

  if (_.isEmpty(changes)) {
    return null;
  }
  const { currency } = config;
  const topic = t(TP + 'topics.header');

  return (
    <>
      {_.map(changes, (change, idx) => {
        const topicChanges = getTopicChanges(change.oldValue, change.newValue, t, currency, ctx);
        return (
          <FormBox key={idx} title={topic + ': ' + ctx.t(change.newValue.name)}>
            <table className={styles.topicstable}>
              <tbody>
                {_.map(topicChanges, (topicChange, tidx) => (
                  <tr className={classnames(tidx % 2 !== 0 && styles.alternaterow)} key={tidx}>
                    <td className={classnames(styles.tablecol, styles.titlecell)}>{topicChange.title}</td>
                    <td className={styles.tablecol}>{topicChange.oldV}</td>
                    <td className={styles.tablecol}>
                      <Separator />
                    </td>
                    <td className={styles.tablecol}>{topicChange.newV}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </FormBox>
        );
      })}
    </>
  );
};

function getTopicChanges(oldValue: ITopicEvaluationVm, newValue: ITopicEvaluationVm, t: TFunction, currency: string, ctx: IUserInfoCtx): { title: string; oldV: string; newV: string }[] {
  const changes = [];
  const formatText = (text: string) => fallback(text, t('common.novalue'));
  const formatMoney = moneyWithFallback(currency, t);
  if (!_.isEqual(oldValue.selectedCriterion, newValue.selectedCriterion)) {
    changes.push({ title: t(TP + 'topics.selectedCriterion'), oldV: formatText(ctx.t(oldValue.selectedCriterion?.description)), newV: formatText(ctx.t(newValue.selectedCriterion?.description)) });
  }
  if (!_.isEqual(oldValue.risk, newValue.risk)) {
    changes.push({ title: t(TP + 'topics.risk'), oldV: riskToString(t, oldValue.risk), newV: riskToString(t, newValue.risk) });
  }
  if (!_.isEqual(oldValue.riskAmount, newValue.riskAmount)) {
    changes.push({ title: t(TP + 'topics.riskAmount'), oldV: formatMoney(oldValue.riskAmount), newV: formatMoney(newValue.riskAmount) });
  }
  if (!_.isEqual(oldValue.measureAmount, newValue.measureAmount)) {
    changes.push({ title: t(TP + 'topics.measureAmount'), oldV: formatMoney(oldValue.measureAmount), newV: formatMoney(newValue.measureAmount) });
  }
  if (!_.isEqual(oldValue.comment, newValue.comment)) {
    changes.push({ title: t(TP + 'topics.comment'), oldV: formatText(oldValue.comment), newV: formatText(newValue.comment) });
  }

  return changes;
}

const ApprovalData: FC<{ changes: ApprovalDataChanges[]; config: IRiskAssessmentConfig }> = ({ changes, config }) => {
  let [t] = useTranslation();
  let formatValue = React.useMemo(() => getFormatValue(t), [t]);
  return (
    <FormBox title={t(TP + 'risks.header')}>
      <table className={styles.riskstable}>
        <tbody>
          {_.map(changes, (change, idx) => (
            <tr className={classnames(idx % 2 !== 0 && styles.alternaterow)} key={idx}>
              <td className={classnames(styles.tablecol, styles.titlecell)}>{t(TP + 'risks.' + change.type)}</td>
              <td className={classnames(styles.tablecol, styles.alignRight)}>{formatValue(change, change.oldValue)}</td>
              <td className={styles.tablecol}>
                <Separator />
              </td>
              <td className={classnames(styles.tablecol, styles.alignRight)}>{formatValue(change, change.newValue)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </FormBox>
  );
};

const Separator: FC = () => <ArrowRightOutlined />;

const Appraisal: FC<{ change: AppraisalChange }> = ({ change }) => {
  const [t] = useTranslation();
  return <FormBox title={t(TP + 'appraisal')}>{change.newValue}</FormBox>;
};

const Measures: FC<{ change: MeasuresChange }> = ({ change }) => {
  const [t] = useTranslation();
  return <FormBox title={t(TP + 'measures')}>{change.newValue}</FormBox>;
};

function getFormatValue(t: TFunction) {
  const formatText = (text: string) => fallback(text, t('common.novalue'));
  const formatDate = (text: string) => fallback(formatter.formatDateString(text), t('common.novalue'));

  const formatValue = (change: RiskAssessmentHistoryChangeTypes, value: any) => {
    switch (change.type) {
      case 'SignatureDateClearanceChange':
      case 'ProjectStartClearanceChange':
        return formatDate(value);
    }

    return formatText(value);
  };

  return formatValue;
}
