import React, { useContext, FC } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { ICountry, NewCountryId } from '../../models/masterdata';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import _ from 'lodash';
import styles from './CountryList.module.css';
import { SearchBox, FormBox } from '../../shared/components/FormBox/FormBox';
import { NewAction } from '../../shared/components/CommandBar/CommandBar';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import { Table } from '../../shared/components/Table/Table';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';

const navPaths = ['navigation.masterdata', 'navigation.countries'];
export const CountryList: FC<ICountryListProps> = ({ countries, loading, setSearchText, searchText }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);

  const create = React.useCallback(() => history.push('/countries/' + NewCountryId), []);
  const edit = React.useCallback((id: string) => history.push('/countries/' + id), []);
  const columns = React.useMemo(() => createColumns(t, ctx, edit), []);
  const onSearch = (e: any) => setSearchText(e.target.value);
  return (
    <ContentWrapper>
      <MasterdataHeader>
        <CommandBarV2 paths={navPaths}>
          <NewAction text="masterdata.countries.newCountry" onClick={create} />
        </CommandBarV2>
      </MasterdataHeader>
      <HtcCardWrapper>
        <HtcCard>
          <SearchBox>
            <Input placeholder={t('masterdata.countries.searchText')} allowClear onChange={onSearch} value={searchText} />
          </SearchBox>
        </HtcCard>
        <HtcCard>
          <FormBox title={t('common.results')} flex contentflex>
            <Table rowKey="id" columns={columns} dataSource={countries} loading={loading} />
          </FormBox>
        </HtcCard>
      </HtcCardWrapper>
    </ContentWrapper>
  );
};

function createColumns(t: TFunction, ctx: IUserInfoCtx, onEdit: (id: string) => void) {
  return [
    {
      title: t('masterdata.countries.icon'),
      dataIndex: 'icon',
      key: 'icon',
      render: (icon: string) => <img src={icon} style={{ width: 25 }} />,
      width: 100,
    },
    {
      title: t('masterdata.countries.name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text: string, record: ICountry) => ctx.t(record.name),
    },
    {
      title: t('masterdata.countries.isoCode3Letters'),
      dataIndex: 'isoCode3Letters',
      key: 'isoCode3Letters',
      width: 250,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: ICountry) => <Button id="editCountry" type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={() => onEdit(record.id)} />,
      width: 100,
    },
  ];
}

interface ICountryListProps {
  countries: ICountry[];
  loading: boolean;
  setSearchText: (text: string) => void;
  searchText: string;
}
