import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';
import { IDepartment, getDepartmentTypeOptions, createDepartment, ITopic } from '../../models/masterdata';
import { Spin } from 'antd';
import _ from 'lodash';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import departmentService from '../../shared/services/departmentService';
import { DepartmentList } from './DepartmentList';
import { sortResult } from '../../shared/services/masterdataQueryHelper';
import { useTranslation } from 'react-i18next';
import { DepartmentEditFormComponent } from './DepartmentEditFormComponent';
import topicService from '../../shared/services/topicService';

export const Departments: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const { loading: departmentsLoading, data: departments } = useData<IDepartment[]>(id, departmentService.getItems);
  const { loading: topicsLoading, data: topics } = useData<ITopic[]>(id, topicService.getAllTopics);
  const [editObj, setEditObj] = useState<IDepartment>();
  useEffect(() => setEditObj(id && _.cloneDeep(_.find(departments, (t) => t.id === id) || createDepartment())), [id, departments]);
  const loading = departmentsLoading || topicsLoading;
  const availableTopics = useMemo(() => (topics || []).sort((a, b) => ctx.t(a.name).localeCompare(ctx.t(b.name))), [topics]);
  const cancelEdit = (): void => history.goBack();
  const onSave = async (department: IDepartment) => {
    const saveAction = department.id ? departmentService.editDepartmentItem(department) : departmentService.createDepartmentItem(department);
    const newEditObj = await tryAsyncAction(saveAction);
    setEditObj(newEditObj);
    cancelEdit();
  };
  const onDelete = async (department: IDepartment) => {
    await tryAsyncAction(departmentService.deleteDepartmentItem(department));
    cancelEdit();
  };

  const [searchText, setSearchText] = useState('');
  const [filterDepartments, setFilterDepartments] = useState<string[]>([]);
  const filteredDepartments = useMemo(() => {
    return prepareFilteredItems(departments, searchText, filterDepartments, ctx);
  }, [searchText, departments, filterDepartments]);
  const tagOptions = useMemo(() => getDepartmentTypeOptions(t), []);

  if (loading) {
    return <Spin></Spin>;
  }

  if (editObj) {
    return (
      <DepartmentEditFormComponent
        availableTopics={availableTopics}
        loading={loading}
        department={editObj}
        departmentTypeOptions={tagOptions}
        onSave={onSave}
        onDelete={onDelete}
        cancelEdit={cancelEdit}
      />
    );
  }
  return (
    <DepartmentList
      loading={loading}
      departments={filteredDepartments}
      searchText={searchText}
      setSearchText={setSearchText}
      filterDepartmentTypes={filterDepartments}
      setFilterDepartmentTypes={setFilterDepartments}
      departmentTypeTagOptions={tagOptions}
    />
  );
};

function prepareFilteredItems(departments: IDepartment[], searchText: string, filterDepartments: string[], ctx: IUserInfoCtx) {
  const filtered = _.filter(departments, (department) => {
    const matchesSearchText = !searchText || _.includes(_.toLower(ctx.t(department.name)), _.toLower(searchText)) || _.includes(_.toLower(ctx.t(department.abbreviation)), _.toLower(searchText));
    const matchesDepartmentTypes = _.isEmpty(filterDepartments) || _.some(filterDepartments, (type) => type === department.type);

    return matchesSearchText && matchesDepartmentTypes;
  });
  return sortResult(filtered, ctx.languageSettings);
}
