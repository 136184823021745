import SettingsService from '../../services/settingsService';
import _ from 'lodash';
import { IPickerBaseProps, IPickerBaseState } from '../PickerBase/PickerBase';
import LanguageAwarePickerBase from '../PickerBase/LanguageAwarePickerBase';
import { IMultiLanguageString } from '../../../models/IMultiLanguageString';

class LanguagePicker extends LanguageAwarePickerBase<ILanguageOption> {
  static getDerivedStateFromProps(props: IPickerBaseProps<ILanguageOption>, state: IPickerBaseState<ILanguageOption>): Partial<IPickerBaseState<ILanguageOption>> {
    if ((state.value && (state.value as ILanguageOption).id) !== (props.value && (props.value as ILanguageOption).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<ILanguageOption[]> {
    const onQueryItems = this.props.onQueryItems || this.getLanguageOptions;
    return onQueryItems(query);
  }

  private getLanguageOptions = (): Promise<ILanguageOption[]> => {
    const appConfig = SettingsService.getConfig();
    const availableLanguages = appConfig.availableLanguages;
    return new Promise((resolve, reject) => resolve(_.map(availableLanguages, (l) => ({ id: l.key, name: l.name }))));
  };
}

export default LanguagePicker;

export interface ILanguageOption {
  id: string;
  name: IMultiLanguageString;
}
