import React, { FC, useContext, useState, useMemo } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { IRiskAssessmentTemplate, NewRiskAssessmentTemplateId } from '../../models/riskAssessment';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import styles from './RiskAssessmentTemplatesList.module.css';
import { SearchBox, FormBox } from '../../shared/components/FormBox/FormBox';
import _ from 'lodash';
import { NewAction } from '../../shared/components/CommandBar/CommandBar';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { sortResult } from '../../shared/services/masterdataQueryHelper';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import { Table } from '../../shared/components/Table/Table';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { RiskAssessmentTemplateService } from '../../shared/services/riskAssessmentTemplateService';

const navPaths = ['navigation.masterdata', 'navigation.riskAssessmentTemplates'];

export const RiskAssessmentTemplatesList: FC<{ loading: boolean; templates: IRiskAssessmentTemplate[] }> = ({ loading, templates }) => {
  const [searchText, setSearchText] = useState('');

  const [t] = useTranslation();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);

  const filteredTemplates = useMemo(() => {
    const filtered = searchText ? _.filter(templates, (c) => _.includes(_.toLower(ctx.t(c.name)), _.toLower(searchText))) : templates;
    return sortResult(filtered, ctx.languageSettings);
  }, [searchText, templates]);

  const create = React.useCallback(() => history.push('/riskassessmenttemplates/' + NewRiskAssessmentTemplateId), []);
  const edit = React.useCallback((id: string) => history.push('/riskassessmenttemplates/' + id), []);
  const clone = React.useCallback(async (template: IRiskAssessmentTemplate) => {
    let clonedTemplate = await RiskAssessmentTemplateService.cloneRiskAssessmentTemplate(template);
    console.log(clonedTemplate);
    return history.push('/riskassessmenttemplates/' + clonedTemplate.id);
  }, []);
  const columns = React.useMemo(() => createColumns(t, ctx, edit, clone), []);
  const onSearch = (e: any) => setSearchText(e.target.value);

  return (
    <ContentWrapper>
      <MasterdataHeader>
        <CommandBarV2 paths={navPaths}>
          <NewAction text="masterdata.riskAssessmentTemplates.newRiskAssessmentTemplate" onClick={create} />
        </CommandBarV2>
      </MasterdataHeader>
      <HtcCardWrapper>
        <HtcCard>
          <SearchBox>
            <Input placeholder={t('masterdata.riskAssessmentTemplates.searchText')} allowClear onChange={onSearch} />
          </SearchBox>
        </HtcCard>
        <HtcCard flex>
          <FormBox title={t('common.results')}>
            <Table rowKey="id" columns={columns} dataSource={filteredTemplates} loading={loading} />
          </FormBox>
        </HtcCard>
      </HtcCardWrapper>
    </ContentWrapper>
  );
};

const createColumns = (t: TFunction, ctx: IUserInfoCtx, onEdit: (id: string) => void, onClone: (template: IRiskAssessmentTemplate) => Promise<void>) => {
  let canCloneTemplate = ctx.userInfo.permissions.security.isAppAdministrator;
  return [
    {
      title: t('masterdata.riskAssessmentTemplates.name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text: string, record: IRiskAssessmentTemplate) => ctx.t(record.name),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      render: (text: string, record: IRiskAssessmentTemplate) => (
        <>
          <Button id="editRiskAssessmentTemplate" type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={(_) => onEdit(record.id)} />
          {canCloneTemplate && <Button id="cloneRiskAssessmentTemplate" shape="circle" size="small" icon={<CopyOutlined />} onClick={(_) => onClone(record)} style={{ marginLeft: '1rem' }} />}
        </>
      ),
    },
  ];
};
