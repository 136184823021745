import { ILanguage, LanguageSettings } from './LanguageSettings';
import _ from 'lodash';
import * as yup from 'yup';

export class IMultiLanguageString {
  languageStrings: { [langKey: string]: string };

  static newWithDefault(defaultLanguage: string, defaultValue: string) {
    const result = new IMultiLanguageString();
    result.languageStrings = { [defaultLanguage]: defaultValue };
    return result;
  }

  static empty() {
    return new IMultiLanguageString();
  }
}

export function getForLanguage(stringObject: IMultiLanguageString, settings: LanguageSettings, forLanguage: ILanguage = null): string {
  if (stringObject == null || stringObject.languageStrings == null) {
    return '';
  }
  const languageWithoutFallBack = getForLanguageWithoutFallBack(stringObject, settings, forLanguage);
  if (!_.isEmpty(languageWithoutFallBack)) {
    return languageWithoutFallBack;
  }
  if (settings.getActiveLanguage() != null && !_.isEmpty(stringObject.languageStrings[settings.getActiveLanguage().key])) {
    return stringObject.languageStrings[settings.getActiveLanguage().key];
  }
  if (!_.isEmpty(stringObject.languageStrings[settings.getDefaultLanguage().key])) {
    return stringObject.languageStrings[settings.getDefaultLanguage().key];
  }

  return '';
}

export function getForLanguageWithoutFallBack(stringObject: IMultiLanguageString, settings: LanguageSettings, forLanguage: ILanguage = null): string {
  if (stringObject == null || stringObject.languageStrings == null) {
    return '';
  }
  if (forLanguage != null && !_.isEmpty(stringObject.languageStrings[forLanguage.key])) {
    return stringObject.languageStrings[forLanguage.key];
  }
  return '';
}

export function setLanguage(stringObject: IMultiLanguageString, language: ILanguage, translation: string): IMultiLanguageString {
  if (stringObject == null || stringObject.languageStrings == null) {
    return IMultiLanguageString.newWithDefault(language.key, translation);
  }
  stringObject.languageStrings[language.key] = translation;
  return stringObject;
}

export function yupDefaultLangIsSet(value: IMultiLanguageString, defaultLanguage: ILanguage): Promise<yup.ValidationError> {
  return new Promise<yup.ValidationError>((resolve, reject) => {
    if (!value || _.isEmpty(defaultLanguage.key)) {
      resolve(undefined);
    }

    if (_.isEmpty(value.languageStrings[defaultLanguage.key])) {
      const validationError = new yup.ValidationError('Defaultlanguage must be set', value, '');
      reject(validationError);
    } else {
      resolve(undefined);
    }
  });
}

function yupLanguageStringsNotLongerThan(value: IMultiLanguageString, length: number): Promise<yup.ValidationError> {
  return new Promise<yup.ValidationError>((resolve, reject) => {
    if (!value) {
      resolve(undefined);
    }

    const inValidStrings = _.filter(value.languageStrings, (l) => l.length > length);
    if (inValidStrings.length > 0) {
      const validationError = new yup.ValidationError('Defaultlanguage must be set', value, '');
      reject(validationError);
    } else {
      resolve(undefined);
    }
  });
}

export function testLanguageStringMaxLength(length: number, t: any) {
  return yup.mixed().test('Language Strings', t('error.languageStringTooLong'), (value: IMultiLanguageString): Promise<boolean> => {
    return yupLanguageStringsNotLongerThan(value, length)
      .then((res: any) => {
        return true;
      })
      .catch((res: any) => {
        return false;
      });
  });
}

export function testRequiredLanguageFields(length: number, defaultLanguage: ILanguage, t: any) {
  return yup
    .mixed()
    .test('Default Language', t('error.defaultLangaugeRequired'), (value: IMultiLanguageString): Promise<boolean> => {
      return yupDefaultLangIsSet(value, defaultLanguage)
        .then((res: any) => {
          return true;
        })
        .catch((res: any) => {
          return false;
        });
    })
    .test('Language Strings', t('error.languageStringTooLong'), (value: IMultiLanguageString): Promise<boolean> => {
      return yupLanguageStringsNotLongerThan(value, length)
        .then((res: any) => {
          return true;
        })
        .catch((res: any) => {
          return false;
        });
    })
    .required();
}
