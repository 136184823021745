import { createBrowserHistory } from 'history';
import { match } from 'react-router';

export function getRiskAssessmentEditRoutes(match: match<{}>) {
  return {
    metadata: {
      basicMetadata: `${match.url}/metadata/basicmetadata`,
      additionalInformation: `${match.url}/metadata/additionalinformation`,
      calculation: `${match.url}/metadata/calculation`,
      documents: `${match.url}/metadata/documents`,
      communication: `${match.url}/metadata/communication`,
      exact: `${match.url}/metadata`,
    },
    assessment: `${match.url}/assessment`,
    overview: {
      evaluationSummary: `${match.url}/overview/evaluationsummary`,
      communication: `${match.url}/overview/communication`,
      exact: `${match.url}/overview`,
    },
    approval: `${match.url}/approval`,
  };
}
abstract class RouteInfo {
  constructor(public readonly path: string) {}
}

class NewRiskAssessmentRoute extends RouteInfo {
  constructor() {
    super('/riskassessments/new');
  }

  public getPath(): string {
    return this.path;
  }

  public getUrl(): string {
    return '/riskassessments';
  }
}

class EditRiskAssessmentRoute extends RouteInfo {
  constructor() {
    super('/riskassessments/:riskAssessmentRouteId');
  }

  public getPath(riskAssessmentId: string): string {
    return `/riskassessments/${riskAssessmentId}`;
  }
}

class RiskOverviewRoute extends RouteInfo {
  constructor() {
    super('/riskassessments/listoverview/list');
  }

  public getPath(): string {
    return this.path;
  }
}

export default {
  NewRiskAssessment: new NewRiskAssessmentRoute(),
  EditRiskAssessment: new EditRiskAssessmentRoute(),
  RiskAssessmentOverview: new RiskOverviewRoute(),
};

export function navigate(url: string): void {
  const history = createBrowserHistory();
  history.push(url);
}
