import { IsRef, IPickerBaseProps, IPickerBaseState } from '../PickerBase/PickerBase';
import LanguageAwarePickerBase, { ILanguageAwarePickerBaseProps } from '../PickerBase/LanguageAwarePickerBase';
import _ from 'lodash';
import { UserInfoCtx } from '../../../UserInfoContext';

export class RefItemPicker extends LanguageAwarePickerBase<IsRef> {
  static getDerivedStateFromProps(props: IPickerBaseProps<IsRef>, state: IPickerBaseState<IsRef>): Partial<IPickerBaseState<IsRef>> {
    if ((state.value && (state.value as IsRef).id) !== (props.value && (props.value as IsRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<IsRef[]> {
    if (!this.props.onQueryItems) {
      console.error('you need a onQueryItems handler in your props');
      return Promise.resolve([]);
    }

    return this.props.onQueryItems(query);
  }
}

export class RefItemOptionsPicker extends LanguageAwarePickerBase<IsRef, IRefItemOptionsPickerProps> {
  static getDerivedStateFromProps(props: IPickerBaseProps<IsRef>, state: IPickerBaseState<IsRef>): Partial<IPickerBaseState<IsRef>> {
    if ((state.value && (state.value as IsRef).id) !== (props.value && (props.value as IsRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: IRefItemOptionsPickerProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ data: this.props.options });
    }
  }

  onQueryItems(query: string): Promise<IsRef[]> {
    if (!query) {
      return Promise.resolve(this.props.options);
    }
    const result = _.filter(this.props.options, (o) => (query ? this.context.t(o.name).toLowerCase().indexOf(query.toLowerCase()) >= 0 : true));
    return Promise.resolve(result);
  }
}

RefItemOptionsPicker.contextType = UserInfoCtx;

interface IRefItemOptionsPickerProps extends ILanguageAwarePickerBaseProps<IsRef> {
  options: IsRef[];
}
