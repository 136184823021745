import { IMultiLanguageString } from '../IMultiLanguageString';
import { IRef } from '../IRef';
import { IUserInfoCtx } from '../../UserInfoContext';

export const NewMachineTypeId = 'new';
export interface IMachineType extends IMachineTypeRef {
  id: string;
  name: IMultiLanguageString;
  fullName?: IMultiLanguageString;
  parentId: string;
  childIds: string[];
}

export interface IMachineTypeRef {
  id: string;
  name: IMultiLanguageString;
  fullName?: IMultiLanguageString;
}

export function getFullNameOrDefault(machineTypeRef: IMachineTypeRef, ctx: IUserInfoCtx) {
  if (!machineTypeRef) {
    return null;
  }

  return ctx.t(machineTypeRef.fullName ?? machineTypeRef.name);
}

export function createMachineType(options: Partial<IMachineType> = {}): IMachineType {
  return Object.assign({ id: '', name: null, fullName: null, parentId: '', childIds: [] }, options);
}

export interface ITreeOptionNode {
  id: string;
  parentId: string;
  label: IMultiLanguageString;
  value: IRef;
  children: ITreeOptionNode[];
  isLeaf: boolean;
}
