import styles from './RiskColorCodes.module.css';
import { RiskTypes } from '../../models/masterdata';

export const getIndicatorClass = (risk?: RiskTypes) => {
  if (risk) {
    switch (risk) {
      case RiskTypes.High:
        return styles.highrisk;
      case RiskTypes.Medium:
        return styles.mediumrisk;
      case RiskTypes.None:
        return styles.norisk;
    }
  }
  return styles.risknotset;
};

export const getIndicatorClassForEvalSummary = (risk?: RiskTypes) => {
  if (risk) {
    switch (risk) {
      case RiskTypes.High:
        return styles.highrisk;
      case RiskTypes.Medium:
        return styles.mediumrisk;
    }
  }
  return styles.norisk;
};
