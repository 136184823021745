import React, { FC, useContext, useState, useMemo, useEffect } from 'react';
import { Button, Form, Drawer, Checkbox } from 'antd';
import { Formik, FormikHelpers, FieldArray } from 'formik';
import * as yup from 'yup';
import { ITopic, ICriterion, createCriterion, IMasterdataEditProps } from '../../models/masterdata';
import { FormLanguageTextInput } from '../../shared/FormComponents';
import { useTranslation } from 'react-i18next';
import { HtcCard, HtcCardWrapper } from '../../shared/HtcCardComponent';
import CriterionEditFormComponent from './CriterionEditFormComponent';
import * as _ from 'lodash';
import { SaveAction, CancelAction, DeleteAction } from '../../shared/components/CommandBar/CommandBar';
import { FormBox } from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { getForLanguage, testRequiredLanguageFields } from '../../models/IMultiLanguageString';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import { ILanguage } from '../../models/LanguageSettings';
import { TFunction } from 'i18next';
import { hasChanges } from '../../shared/masterDataHelpers';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import styles from './TopicsEditFormComponent.module.css';
import { Table } from '../../shared/components/Table/Table';
import topicService from '../../shared/services/topicService';
import { notifyError } from '../../shared/notificationHelper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FormCheckboxInput } from '../../shared/FormComponents';

const navPaths = ['navigation.masterdata', { name: 'navigation.topics', linkTo: '/topics' }];
export const TopicsEditFormComponent: FC<ITopicsEditFormComponentProps> = ({ cancelEdit, topic, onSave, onDelete }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const settings = ctx.languageSettings;
  const topicClone = useMemo(() => _.cloneDeep(topic), [topic]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICriterion>();
  const [criteria, setCriteria] = useState(topic.criteria);
  useEffect(() => {
    setCriteria(topic.criteria);
    setSelectedItem(null);
  }, [topic]);

  const [validationScheme] = useState(getValidationScheme(t, settings.getDefaultLanguage()));
  const isNew = !topic.id;
  const selectedLanguage = ctx.languageSettings;
  const nav = Object.assign([], navPaths);
  nav.push(getForLanguage(topic.name, selectedLanguage));

  const addCriterion = () => {
    setDrawerVisible(true);
    setSelectedItem(createCriterion());
  };

  const editCriterion = (keyToEdit: string) => {
    const item = _.find(criteria, { key: keyToEdit });
    setDrawerVisible(true);
    setSelectedItem(item);
  };

  const handleCriterionSave = (criterion: ICriterion) => {
    const itemCopy: ICriterion[] = _.cloneDeep(criteria);
    const index = _.findIndex(criteria, { key: criterion.key });
    if (index !== -1) {
      itemCopy[index] = criterion;
    } else {
      itemCopy.push(criterion);
    }
    setCriteria(itemCopy);
    setDrawerVisible(false);
    setSelectedItem(null);
  };

  const removeCriterion = (keyToDelete: string) => {
    const itemCopy: ICriterion[] = _.cloneDeep(criteria);
    const index = _.findIndex(criteria, { key: keyToDelete });
    if (index !== -1) {
      itemCopy.splice(index, 1);
    }
    setCriteria(itemCopy);
    setDrawerVisible(false);
    setSelectedItem(null);
  };

  const onClose = () => {
    setDrawerVisible(false);
    setSelectedItem(null);
  };
  const handleDelete = () => onDelete(topic);
  const handleSave = async (topic: ITopic, actions: FormikHelpers<ITopic>) => {
    const validationErrors = await topicService.isUnique(topic);
    if (!validationErrors.hasErrors()) {
      try {
        topic.criteria = criteria;
        await onSave(topic);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={topicClone} onSubmit={handleSave} validationSchema={validationScheme}>
      {(formik) => {
        const { isSubmitting, values, submitForm } = formik;
        return (
          <ContentWrapper>
            <MasterdataHeader>
              <RiskPrompt active={hasChanges(topic, values)} />
              <CommandBarV2 paths={nav}>
                {!isNew && <DeleteAction disabled={isSubmitting} onClick={handleDelete} />}
                <CancelAction disabled={isSubmitting} onClick={cancelEdit} />
                <SaveAction disabled={isSubmitting} onClick={submitForm} />
              </CommandBarV2>
            </MasterdataHeader>
            <HtcCardWrapper>
              <LanguageTabPage>
                {(lp) => (
                  <HtcCard flex>
                    <FormBox title={t('masterdata.topics.editform.details')} flex contentflex>
                      <Form layout="vertical">
                        <FormLanguageTextInput {...formik} name="name" label={t('masterdata.topics.name')} selectedLanguage={lp.lang} />
                        <FormCheckboxInput {...formik} name="showSeparately" label={t('masterdata.topics.showSeparately')} />
                        <FormBox title={t('masterdata.topics.editform.criterias')} flex contentflex>
                          <Button className={styles.addcriterion} icon={<PlusOutlined />} onClick={addCriterion}>
                            {t('masterdata.topics.editform.addCriterion')}
                          </Button>
                          <FieldArray
                            name="criteria"
                            render={(arrayHelpers) => (
                              <div>
                                <Table rowKey="key" columns={createColumns(t, ctx, editCriterion, removeCriterion)} dataSource={criteria} loading={false} />
                              </div>
                            )}
                          />
                        </FormBox>
                      </Form>
                    </FormBox>
                    <Drawer title={t('masterdata.topics.editform.addCriterion')} placement="right" closable={true} onClose={onClose} visible={drawerVisible}>
                      <CriterionEditFormComponent criterion={selectedItem} onSave={handleCriterionSave} />
                    </Drawer>
                  </HtcCard>
                )}
              </LanguageTabPage>
            </HtcCardWrapper>
          </ContentWrapper>
        );
      }}
    </Formik>
  );
};

function createColumns(t: TFunction, ctx: IUserInfoCtx, editCriterion: (id: string) => void, removeCriterion: (id: string) => void) {
  return [
    {
      title: t('masterdata.topics.criterion.description'),
      dataIndex: 'description',
      key: 'description',
      width: '50%',
      render: (text: string, record: ICriterion) => ctx.t(record.description),
    },
    {
      title: t('masterdata.topics.criterion.risk'),
      dataIndex: 'risk',
      key: 'risk',
      width: '40%',
      render: (text: string) => <span>{t('masterdata.topics.types.' + text)}</span>,
    },
    {
      title: t('masterdata.topics.editform.criterionActions'),
      dataIndex: 'delete',
      key: 'delete',
      render: (text: any, record: any) => (
        <>
          <EditOutlined
            style={{ marginRight: 10 }}
            onClick={(e) => {
              e.stopPropagation();
              editCriterion(record.key);
            }}
          />
          <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              removeCriterion(record.key);
            }}
          />
        </>
      ),
    },
  ];
}

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  return yup.object().shape<any>({
    name: testRequiredLanguageFields(200, defaultLanguage, t),
  });
}

interface ITopicsEditFormComponentProps extends IMasterdataEditProps<ITopic> {
  topic: ITopic;
}
