import React, { SFC, useState, useCallback } from 'react';
import { Tag, Row, Col } from 'antd';
import _ from 'lodash';
import styles from './CheckableTagGroup.module.css';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const CheckableTag = Tag.CheckableTag;
const AllTag: ICheckableTag = { tag: 'CheckableTag_ALL', title: 'CheckableTag_ALL' };

const CheckableTagGroup: SFC<ICheckableTagGroupProps> = ({ title, tags, onSelectedTagsChange, isFilter = false, isGrid = false, isMulti = false, defaultSelected }) => {
  const [t] = useTranslation();
  const defaultSelectedTags = defaultSelected ? _.filter(tags, (t) => _.find(defaultSelected, (ds) => ds == t.tag) != null) : [];
  const [selectedTags, setSelectedTags] = useState<ICheckableTag[]>(defaultSelectedTags);

  const handleChange = useCallback(
    (tag: ICheckableTag, checked: boolean) => {
      let nextSelectedTags: ICheckableTag[];
      if (tag === AllTag) {
        nextSelectedTags = [];
      } else {
        if (isMulti) {
          nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t: ICheckableTag) => t !== tag);
        } else {
          nextSelectedTags = checked ? [tag] : [];
        }
      }

      setSelectedTags(nextSelectedTags);
      onSelectedTagsChange(nextSelectedTags);
    },
    [isMulti, onSelectedTagsChange, selectedTags],
  );
  const containerStyle = title ? styles.container : styles.containerNoTitle;
  if (isGrid) {
    return (
      <Row className={containerStyle}>
        {title && (
          <Col>
            <h6 className={styles.title}>{title}</h6>
          </Col>
        )}

        <Col>{getCheckableTagComponent(handleChange, tags, selectedTags, t, isFilter)}</Col>
      </Row>
    );
  }
  return (
    <div className={containerStyle}>
      <h6 className={styles.title}>{title}</h6>
      {getCheckableTagComponent(handleChange, tags, selectedTags, t, isFilter)}
    </div>
  );
};

const getCheckableTagComponent = (handleChange: any, tags: ICheckableTag[], selectedTags: ICheckableTag[], t: TFunction, isFilter = false) => {
  return (
    <span>
      {isFilter && (
        <CheckableTag key={AllTag.tag} checked={selectedTags.length === 0} onChange={(checked) => handleChange(AllTag, checked)}>
          {t('common.all')}
        </CheckableTag>
      )}
      {tags.map((tag) => (
        <CheckableTag key={tag.tag} checked={_.some(selectedTags, tag)} onChange={(checked) => handleChange(tag, checked)}>
          {tag.title}
        </CheckableTag>
      ))}
    </span>
  );
};

export default CheckableTagGroup;

interface ICheckableTagGroupProps {
  tags: ICheckableTag[];
  title?: string;
  onSelectedTagsChange: (selectedTags: ICheckableTag[]) => void;
  isFilter?: boolean;
  isGrid?: boolean;
  isMulti?: boolean;
  defaultSelected?: string[];
}

export interface ICheckableTag {
  tag: string;
  title: string;
}
