import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';
import { IMachineType, createMachineType } from '../../models/masterdata';
import { Spin } from 'antd';
import _ from 'lodash';
import machineTypesService from '../../shared/services/machineTypesService';
import { MachineTypeList } from './MachineTypesList';
import { MachineTypeEditFormComponent } from './MachineTypesEditFormComponent';

export const MachineTypes: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [machineTypeParentId, setMachineTypeParentId] = useState('');

  const { loading, data: machineTypes } = useData<IMachineType[]>(id, machineTypesService.getAllMachineTypes);
  const [editObj, setEditObj] = useState<IMachineType>();
  useEffect(() => {
    setEditObj(id && _.cloneDeep(_.find(machineTypes, (t) => t.id === id) || createMachineType()));
  }, [id, machineTypes]);
  const onEdit = React.useCallback((itemid: string) => history.push('/machineTypes/' + itemid), []);
  const onSelect = React.useCallback((itemId: string, parentId: string) => {
    setMachineTypeParentId(parentId);
    onEdit(itemId);
  }, []);
  const cancelEdit = (): void => history.goBack();
  const onSave = async (machineType: IMachineType) => {
    const saveAction = machineType.id ? machineTypesService.editMachineType(machineType) : machineTypesService.createMachineType(machineType);
    const newEditObj = await tryAsyncAction(saveAction);
    setEditObj(newEditObj);
    cancelEdit();
  };
  const onDelete = async (machineType: IMachineType) => {
    await tryAsyncAction(machineTypesService.deleteMachineType(machineType));
    cancelEdit();
  };

  if (loading) {
    return <Spin></Spin>;
  }

  if (editObj) {
    return <MachineTypeEditFormComponent loading={loading} machineType={editObj} onSave={onSave} onDelete={onDelete} cancelEdit={cancelEdit} machineTypeParentId={machineTypeParentId} />;
  }
  return <MachineTypeList loading={loading} machineTypes={machineTypes} onSelect={onSelect} />;
};
