import * as _ from 'lodash';
import { ICountry, ICountryRef } from '../../models/masterdata';
import HttpClient from './httpClient';
import LRU from 'lru-cache';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';
import { LanguageSettings } from '../../models/LanguageSettings';
import { queryMasterdataOptions } from './masterdataQueryHelper';
import { UniqueValidation, IUniqueValidationErrorResult } from '../../models/IUniqueValidation';

interface ICountryResponse {
  countries: ICountry[];
}

interface ICountryOptionResponse {
  options: ICountryRef[];
}

const cacheOptions = { maxAge: 1000 * 60 * 60 };
const cache = new LRU<string, Map<IMultiLanguageString, ICountryRef>>(cacheOptions);

const createCountry = async (item: ICountry): Promise<ICountry> => {
  const result = await HttpClient.getInstance().post<ICountryResponse>(`countries`, item);
  const response = getCountriesFromResponse(result.data);
  return response[0];
};

const editCountry = async (item: ICountry): Promise<ICountry> => {
  const result = await HttpClient.getInstance().put<ICountryResponse>(`countries/${item.id}`, item);
  const response = getCountriesFromResponse(result.data);
  return response[0];
};

const deleteCountry = async (item: ICountry): Promise<void> => {
  await HttpClient.getInstance().delete(`countries/${item.id}`);
};

const getAllCountries = async (): Promise<ICountry[]> => {
  const result = await HttpClient.getInstance().get<ICountryResponse>(`countries`);
  return getCountriesFromResponse(result.data);
};

function getCountriesFromResponse(response: ICountryResponse) {
  return _.map(response.countries, (c) => {
    ensureProps(c);
    return c;
  });
}

async function queryCountryOptions(query: string, settings: LanguageSettings, tenantId: string, branchId: string): Promise<ICountryRef[]> {
  return queryMasterdataOptions('countries', query, settings, cache, tenantId, branchId);
}

function ensureProps(country: ICountry) {
  country.icon = getIconUrl(country);
}

const isUnique = async (country: ICountry): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/countries/isunique`, country);
  return new UniqueValidation(result.data.validationErrors);
};

function getIconUrl(country: ICountry): string {
  const icon = availableIcons[country && country.isoCode2Letters.toLowerCase()];
  return `./countryIcons/${icon || 'unknown.svg'}`;
}

export default {
  createCountry,
  getAllCountries,
  editCountry,
  deleteCountry,
  queryCountryOptions,
  isUnique,
};

const availableIcons = {
  ad: 'ad.svg',
  ae: 'ae.svg',
  af: 'af.svg',
  ag: 'ag.svg',
  ai: 'ai.svg',
  al: 'al.svg',
  am: 'am.svg',
  an: 'an.svg',
  ao: 'ao.svg',
  aq: 'aq.svg',
  ar: 'ar.svg',
  as: 'as.svg',
  at: 'at.svg',
  au: 'au.svg',
  aw: 'aw.svg',
  ax: 'ax.svg',
  az: 'az.svg',
  ba: 'ba.svg',
  bb: 'bb.svg',
  bd: 'bd.svg',
  be: 'be.svg',
  bf: 'bf.svg',
  bg: 'bg.svg',
  bh: 'bh.svg',
  bi: 'bi.svg',
  bj: 'bj.svg',
  bl: 'bl.svg',
  bm: 'bm.svg',
  bn: 'bn.svg',
  bo: 'bo.svg',
  bq: 'bq.svg',
  br: 'br.svg',
  bs: 'bs.svg',
  bt: 'bt.svg',
  bv: 'bv.svg',
  bw: 'bw.svg',
  by: 'by.svg',
  bz: 'bz.svg',
  ca: 'ca.svg',
  cc: 'cc.svg',
  cd: 'cd.svg',
  cf: 'cf.svg',
  cg: 'cg.svg',
  ch: 'ch.svg',
  ci: 'ci.svg',
  ck: 'ck.svg',
  cl: 'cl.svg',
  cm: 'cm.svg',
  cn: 'cn.svg',
  co: 'co.svg',
  cr: 'cr.svg',
  cu: 'cu.svg',
  cv: 'cv.svg',
  cw: 'cw.svg',
  cx: 'cx.svg',
  cy: 'cy.svg',
  cz: 'cz.svg',
  de: 'de.svg',
  dj: 'dj.svg',
  dk: 'dk.svg',
  dm: 'dm.svg',
  do: 'do.svg',
  dz: 'dz.svg',
  ec: 'ec.svg',
  ee: 'ee.svg',
  eg: 'eg.svg',
  eh: 'eh.svg',
  er: 'er.svg',
  es: 'es.svg',
  et: 'et.svg',
  eu: 'eu.svg',
  fi: 'fi.svg',
  fj: 'fj.svg',
  fk: 'fk.svg',
  fm: 'fm.svg',
  fo: 'fo.svg',
  fr: 'fr.svg',
  ga: 'ga.svg',
  'gb-eng': 'gb-eng.svg',
  'gb-nir': 'gb-nir.svg',
  'gb-sct': 'gb-sct.svg',
  'gb-wls': 'gb-wls.svg',
  gb: 'gb.svg',
  gd: 'gd.svg',
  ge: 'ge.svg',
  gf: 'gf.svg',
  gg: 'gg.svg',
  gh: 'gh.svg',
  gi: 'gi.svg',
  gl: 'gl.svg',
  gm: 'gm.svg',
  gn: 'gn.svg',
  gp: 'gp.svg',
  gq: 'gq.svg',
  gr: 'gr.svg',
  gs: 'gs.svg',
  gt: 'gt.svg',
  gu: 'gu.svg',
  gw: 'gw.svg',
  gy: 'gy.svg',
  hk: 'hk.svg',
  hm: 'hm.svg',
  hn: 'hn.svg',
  hr: 'hr.svg',
  ht: 'ht.svg',
  hu: 'hu.svg',
  id: 'id.svg',
  ie: 'ie.svg',
  il: 'il.svg',
  im: 'im.svg',
  in: 'in.svg',
  io: 'io.svg',
  iq: 'iq.svg',
  ir: 'ir.svg',
  is: 'is.svg',
  it: 'it.svg',
  je: 'je.svg',
  jm: 'jm.svg',
  jo: 'jo.svg',
  jp: 'jp.svg',
  ke: 'ke.svg',
  kg: 'kg.svg',
  kh: 'kh.svg',
  ki: 'ki.svg',
  km: 'km.svg',
  kn: 'kn.svg',
  kp: 'kp.svg',
  kr: 'kr.svg',
  kw: 'kw.svg',
  ky: 'ky.svg',
  kz: 'kz.svg',
  la: 'la.svg',
  lb: 'lb.svg',
  lc: 'lc.svg',
  li: 'li.svg',
  lk: 'lk.svg',
  lr: 'lr.svg',
  ls: 'ls.svg',
  lt: 'lt.svg',
  lu: 'lu.svg',
  lv: 'lv.svg',
  ly: 'ly.svg',
  ma: 'ma.svg',
  mc: 'mc.svg',
  md: 'md.svg',
  me: 'me.svg',
  mf: 'mf.svg',
  mg: 'mg.svg',
  mh: 'mh.svg',
  mk: 'mk.svg',
  ml: 'ml.svg',
  mm: 'mm.svg',
  mn: 'mn.svg',
  mo: 'mo.svg',
  mp: 'mp.svg',
  mq: 'mq.svg',
  mr: 'mr.svg',
  ms: 'ms.svg',
  mt: 'mt.svg',
  mu: 'mu.svg',
  mv: 'mv.svg',
  mw: 'mw.svg',
  mx: 'mx.svg',
  my: 'my.svg',
  mz: 'mz.svg',
  na: 'na.svg',
  nc: 'nc.svg',
  ne: 'ne.svg',
  nf: 'nf.svg',
  ng: 'ng.svg',
  ni: 'ni.svg',
  nl: 'nl.svg',
  no: 'no.svg',
  np: 'np.svg',
  nr: 'nr.svg',
  nu: 'nu.svg',
  nz: 'nz.svg',
  om: 'om.svg',
  pa: 'pa.svg',
  pe: 'pe.svg',
  pf: 'pf.svg',
  pg: 'pg.svg',
  ph: 'ph.svg',
  pk: 'pk.svg',
  pl: 'pl.svg',
  pm: 'pm.svg',
  pn: 'pn.svg',
  pr: 'pr.svg',
  ps: 'ps.svg',
  pt: 'pt.svg',
  pw: 'pw.svg',
  py: 'py.svg',
  qa: 'qa.svg',
  re: 're.svg',
  ro: 'ro.svg',
  rs: 'rs.svg',
  ru: 'ru.svg',
  rw: 'rw.svg',
  sa: 'sa.svg',
  sb: 'sb.svg',
  sc: 'sc.svg',
  sd: 'sd.svg',
  se: 'se.svg',
  sg: 'sg.svg',
  sh: 'sh.svg',
  si: 'si.svg',
  sj: 'sj.svg',
  sk: 'sk.svg',
  sl: 'sl.svg',
  sm: 'sm.svg',
  sn: 'sn.svg',
  so: 'so.svg',
  sr: 'sr.svg',
  ss: 'ss.svg',
  st: 'st.svg',
  sv: 'sv.svg',
  sx: 'sx.svg',
  sy: 'sy.svg',
  sz: 'sz.svg',
  tc: 'tc.svg',
  td: 'td.svg',
  tf: 'tf.svg',
  tg: 'tg.svg',
  th: 'th.svg',
  tj: 'tj.svg',
  tk: 'tk.svg',
  tl: 'tl.svg',
  tm: 'tm.svg',
  tn: 'tn.svg',
  to: 'to.svg',
  tr: 'tr.svg',
  tt: 'tt.svg',
  tv: 'tv.svg',
  tw: 'tw.svg',
  tz: 'tz.svg',
  ua: 'ua.svg',
  ug: 'ug.svg',
  um: 'um.svg',
  us: 'us.svg',
  uy: 'uy.svg',
  uz: 'uz.svg',
  va: 'va.svg',
  vc: 'vc.svg',
  ve: 've.svg',
  vg: 'vg.svg',
  vi: 'vi.svg',
  vn: 'vn.svg',
  vu: 'vu.svg',
  wf: 'wf.svg',
  ws: 'ws.svg',
  xk: 'xk.svg',
  ye: 'ye.svg',
  yt: 'yt.svg',
  za: 'za.svg',
  zm: 'zm.svg',
  zw: 'zw.svg',
};
