import React, { SFC } from 'react';
import Exception from '../Exception/Exception';

const PermissionCheck: SFC<IPermissionCheckProps> = ({ hasAccess, children }) => {
  if (!hasAccess) {
    return <Exception type="403"></Exception>;
  }
  return <>{children}</>;
};

export default PermissionCheck;

interface IPermissionCheckProps {
  hasAccess: boolean;
  children: any;
}
