import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { IUserEditViewModel, IBranchOfIndustryRef, ITenantRef, IDepartmentRefWithTenantAndBranch } from '../../models/user';
import * as actions from './actions';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { notifySaveSuccess } from '../../shared/notificationHelper';
export interface IUserState {
  selectedUserId: string;
  users: IUserEditViewModel[];
  isLoading: boolean;
  assignableTenants: ITenantRef[];
  assignableBranchesOfIndustry: IBranchOfIndustryRef[];
  assignableDepartments: IDepartmentRefWithTenantAndBranch[];
}

export type UsersAction = ActionType<typeof actions>;

export const userReducer = combineReducers<IUserState, UsersAction>({
  users: (state: IUserEditViewModel[] = [], action: UsersAction) => {
    switch (action.type) {
      case getType(actions.getAllUsersForEdit.success):
        return action.payload.users || [];
      case getType(actions.saveEditUser.success):
        return _.map(state, (u) => (u.id === action.payload.id ? action.payload : u));
      case getType(actions.loadUserWithCopiedClaims.success):
        return _.map(state, (u) => (u.id === action.payload.id ? action.payload : u));
      case getType(actions.addAndSelectNewUser.success): {
        const stateCopy = [...state];
        if (!_.find(stateCopy, (s) => s.id === action.payload.id)) {
          stateCopy.push(action.payload);
          return stateCopy;
        }
        return state;
      }
      default:
        return state;
    }
  },
  isLoading: (state: boolean = false, action: UsersAction) => {
    switch (action.type) {
      case getType(actions.saveEditUser.request):
      case getType(actions.getAllUsersForEdit.request):
        return true;
      case getType(actions.saveEditUser.success):
        notifySaveSuccess();
        return false;
      case getType(actions.getAllUsersForEdit.success):
      case getType(actions.getAllUsersForEdit.failure):
        return false;
    }
    return state;
  },
  selectedUserId: (state: string = null, action: UsersAction) => {
    switch (action.type) {
      case getType(actions.selectUser):
        return action.payload;
      case getType(actions.addAndSelectNewUser.success):
        return action.payload.id;
      case getType(actions.cancelEditUser):
      case getType(actions.saveEditUser.success):
        return null;
    }
    return state;
  },
  assignableTenants: (state = [], action: UsersAction) => {
    switch (action.type) {
      case getType(actions.getAllUsersForEdit.success):
        return action.payload.assignableTenants || [];
    }

    return state;
  },

  assignableBranchesOfIndustry: (state = [], action: UsersAction) => {
    switch (action.type) {
      case getType(actions.getAllUsersForEdit.success):
        return action.payload.assignableBranchesOfIndustry || [];
    }

    return state;
  },

  assignableDepartments: (state = [], action: UsersAction) => {
    switch (action.type) {
      case getType(actions.getAllUsersForEdit.success):
        return action.payload.assignableDepartments || [];
    }

    return state;
  },
});

const getUsers = (state: IUserState) => state.users;
const getSelectedUserId = (state: IUserState) => state.selectedUserId;

export const getSelectedUser = createSelector([getUsers, getSelectedUserId], (users: IUserEditViewModel[], selectedUserId: string) => {
  return selectedUserId && _.find(users, (u) => u.id === selectedUserId);
});
