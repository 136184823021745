import React, { FC, PureComponent } from 'react';
import { Layout, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, BranchesOutlined, RetweetOutlined, InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation, useTranslation } from 'react-i18next';
import styles from './AppHeader.module.css';
import { IUserTenantInfo, IBranchOfIndustryInfo } from './models/masterdata/ITenant';
import HeaderDropdown from './shared/components/HeaderDropdown';
import { UserInfo } from './models/user/UserInfo';
import { getForLanguage, IMultiLanguageString } from './models/IMultiLanguageString';
import { IBranchOfIndustrySetting } from './branchselection/epics';
import { ILanguage, LanguageSettings, createLanguage, DefaultLanguages } from './models/LanguageSettings';
import _ from 'lodash';
import { UserInfoCtx } from './UserInfoContext';
import { RouteComponentProps, withRouter } from 'react-router';
import SubMenu from 'antd/lib/menu/SubMenu';
import { getSubmenuAction, isMobileDevice } from './shared/components/SizeDetector';
const { Header } = Layout;

interface IAppHeaderComponentProps extends RouteComponentProps, WithTranslation {
  collapsed: boolean;
  user: UserInfo;
  activeTenant: IUserTenantInfo;
  availableBranchesOfIndustry: IBranchOfIndustryInfo[];
  availableLanguages: ILanguage[];
  activeBranchOfIndustry: IBranchOfIndustryInfo;
  selectActiveBranchOfIndustrySetting: (setting: IBranchOfIndustrySetting) => void;
  selectLanguage: (setting: IBranchOfIndustrySetting) => void;
  toggleCollapsed: () => void;
  onLogout: () => void;
}

export class AppHeaderComponent extends PureComponent<IAppHeaderComponentProps> {
  render() {
    const { collapsed, toggleCollapsed, user, activeTenant, t, activeBranchOfIndustry, availableBranchesOfIndustry } = this.props;
    const selectedLanguage =
      this.context.languageSettings.getActiveLanguage() || createLanguage({ key: DefaultLanguages.de_DE, name: IMultiLanguageString.newWithDefault(DefaultLanguages.de_DE, 'Deutsch') });

    return (
      <Header className={styles.header}>
        {collapsed ? <MenuUnfoldOutlined className={styles.menuTrigger} onClick={toggleCollapsed} /> : <MenuFoldOutlined className={styles.menuTrigger} onClick={toggleCollapsed} />}
        <div className={styles.headerActions}>
          <HeaderDropdown
            trigger={['click']}
            overlay={
              <BranchMenu
                onClick={this.onBranchMenuSelected}
                activeBranchOfIndustry={activeBranchOfIndustry}
                availableBranchesOfIndustry={availableBranchesOfIndustry}
                settings={this.context.languageSettings}
              />
            }
          >
            <div className={`${styles.action} ${styles.branchSelection}`}>
              <span className={styles.name}>
                {(activeBranchOfIndustry && getForLanguage(activeBranchOfIndustry.name, this.context.languageSettings)) || t('appHeader.noBranchOfIndustryAvailable')}
              </span>
              <span className={styles.branch}>{t('appHeader.branchOfIndustry')}</span>
            </div>
          </HeaderDropdown>
          <HeaderDropdown
            trigger={['click']}
            overlay={
              <UserMenu onClick={this.onUserMenuClick} availableLanguages={this.props.availableLanguages} languageSettings={this.context.languageSettings} selectedLanguage={selectedLanguage} />
            }
          >
            <div className={`${styles.action} ${styles.account}`}>
              <span className={styles.name}>{user.name}</span>
              <span className={styles.tenant}>{activeTenant && activeTenant.name}</span>
            </div>
          </HeaderDropdown>
        </div>
      </Header>
    );
  }

  onUserMenuClick = (e: any) => {
    const { onLogout, history } = this.props;
    switch (e.key) {
      case 'logout':
        onLogout();
        break;
      case 'changetenant':
        history.push(`/changetenant`);
        break;
      default:
        this.onLanguageSelected(e);
        break;
    }
  };

  onBranchMenuSelected = (e: any) => {
    this.props.selectActiveBranchOfIndustrySetting({ branchOfIndustryId: e.key, language: this.props.user.userSetting.selectedLanguage });
  };

  onLanguageSelected = (e: any) => {
    const branchId = this.props.activeBranchOfIndustry != null ? this.props.activeBranchOfIndustry.id : null;
    this.props.selectLanguage({ branchOfIndustryId: branchId || null, language: _.find(this.props.availableLanguages, (l) => l.key === e.key) });
  };
}

AppHeaderComponent.contextType = UserInfoCtx;
export default withTranslation()(withRouter(AppHeaderComponent));

const BranchMenu: FC<{
  activeBranchOfIndustry: IBranchOfIndustryInfo;
  availableBranchesOfIndustry: IBranchOfIndustryInfo[];
  onClick: (e: any) => void;
  settings: LanguageSettings;
}> = ({ activeBranchOfIndustry, availableBranchesOfIndustry, onClick, settings }) => {
  return (
    <Menu triggerSubMenuAction={getSubmenuAction()} className={styles.menu} selectedKeys={[]} onClick={onClick}>
      {availableBranchesOfIndustry
        .filter((b) => b.id !== (activeBranchOfIndustry && activeBranchOfIndustry.id))
        .map((b) => (
          <Menu.Item key={b.id}>
            <BranchesOutlined />
            {getForLanguage(b.name, settings)}
          </Menu.Item>
        ))}
    </Menu>
  );
};

const UserMenu: FC<{ onClick: (e: any) => void; selectedLanguage: ILanguage; availableLanguages: ILanguage[]; languageSettings: any }> = ({
  onClick,
  selectedLanguage,
  availableLanguages,
  languageSettings,
}) => {
  const [t] = useTranslation();
  return (
    <Menu triggerSubMenuAction={getSubmenuAction()} className={styles.menu} selectedKeys={[selectedLanguage.key]} onClick={onClick}>
      <Menu.Item key="changetenant">
        <RetweetOutlined />
        {t('appHeader.changeTenant')}
      </Menu.Item>
      <SubMenu title={t('appHeader.changeLanguage')}>
        {availableLanguages.map((b) => (
          <Menu.Item key={b.key}>
            <span className={b.key === selectedLanguage.key ? styles.subMenuActive : styles.subMenu}>
              <BranchesOutlined /> {getForLanguage(b.name, languageSettings, selectedLanguage)}
            </span>
          </Menu.Item>
        ))}
      </SubMenu>
      <Menu.Divider />
      <Menu.Item key="version">
        <InfoCircleOutlined />
        {process.env.REACT_APP_VERSION}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined />
        {t('appHeader.logoutUser')}
      </Menu.Item>
    </Menu>
  );
};
