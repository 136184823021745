import React from 'react';
import styles from './DocumentList.module.css';

export const DocumentLink: React.FC<DocumentLinkProps> = ({ isDownloadAble, linkText, downloadFile }) => {
  return (
    <>
      {isDownloadAble && (
        <a href="#" className={styles.documentLink} onClick={downloadFile}>
          {linkText}
        </a>
      )}
      {!isDownloadAble && <span>{linkText}</span>}
    </>
  );
};

interface DocumentLinkProps {
  isDownloadAble: boolean;
  linkText: string;
  downloadFile: () => void;
}
