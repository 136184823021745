import { Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useContext, useState, useMemo } from 'react';
import * as yup from 'yup';
import { ICountry } from '../../models/masterdata';
import { FormTextInput, FormLanguageTextInput } from '../../shared/FormComponents';
import { useTranslation } from 'react-i18next';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { CancelAction, SaveAction, DeleteAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { testRequiredLanguageFields } from '../../models/IMultiLanguageString';
import { UserInfoCtx } from '../../UserInfoContext';
import { ILanguage } from '../../models/LanguageSettings';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import _ from 'lodash';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { TFunction } from 'i18next';
import styles from './CountryEditFormComponent.module.css';
import { hasChanges } from '../../shared/masterDataHelpers';
import countryService from '../../shared/services/countryService';
import { notifyError } from '../../shared/notificationHelper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';

const navPaths = ['navigation.masterdata', { name: 'navigation.countries', linkTo: '/countries' }];

export const CountryEditFormComponent: FC<ICountryEditFormProps> = ({ cancelEdit, country, onDelete, onSave }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const settings = ctx.languageSettings;
  const countryClone = useMemo(() => _.cloneDeep(country), [country]);
  const [validationScheme] = useState(getValidationScheme(t, settings.getDefaultLanguage()));
  const isNew = !country.id;
  const nav = Object.assign([], navPaths);
  nav.push(ctx.t(country.name));

  const handleDelete = () => onDelete(country);
  const handleSave = async (country: ICountry, actions: FormikHelpers<ICountry>) => {
    const validationErrors = await countryService.isUnique(country);
    if (!validationErrors.hasErrors()) {
      try {
        onSave(country);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={countryClone} onSubmit={handleSave} validationSchema={validationScheme}>
      {(formik) => {
        const { isSubmitting, submitForm, values } = formik;
        return (
          <ContentWrapper>
            <RiskPrompt active={hasChanges(country, values)} />
            <MasterdataHeader>
              <CommandBarV2 paths={nav}>
                {!isNew && <DeleteAction disabled={isSubmitting} onClick={handleDelete} />}
                <CancelAction disabled={isSubmitting} onClick={cancelEdit} />
                <SaveAction disabled={isSubmitting} onClick={submitForm} />
              </CommandBarV2>
            </MasterdataHeader>
            <HtcCardWrapper>
              <LanguageTabPage>
                {(lp) => (
                  <HtcCard flex>
                    <FormBox title={t('masterdata.countries.editform.details')} flex contentflex>
                      <Form layout="vertical">
                        <FormLanguageTextInput {...formik} name="name" label={t('masterdata.countries.name')} selectedLanguage={lp.lang} />
                        <FormTextInput {...formik} name="isoCode3Letters" label={t('masterdata.countries.isoCode3Letters')} />
                      </Form>
                    </FormBox>
                  </HtcCard>
                )}
              </LanguageTabPage>
            </HtcCardWrapper>
          </ContentWrapper>
        );
      }}
    </Formik>
  );
};

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  return yup.object().shape<any>({
    name: testRequiredLanguageFields(50, defaultLanguage, t),
    isoCode3Letters: yup.string().min(3).max(3).required(),
  });
}

interface ICountryEditFormProps {
  loading: boolean;
  country: ICountry;
  onSave: (country: ICountry) => void;
  onDelete: (country: ICountry) => void;
  cancelEdit: () => void;
}
