import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { TFunction, useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import '../node_modules/antd/dist/antd.less';
import { IUserTenantInfo } from './models/masterdata/ITenant';
import styles from './Navigation.module.css';
import { UserInfo } from './models/user/UserInfo';
import { UserPermissions } from './models';
import { userService } from './shared/services/userService';
import {
  ToolOutlined,
  BranchesOutlined,
  SnippetsOutlined,
  CarOutlined,
  CrownOutlined,
  ClusterOutlined,
  TagsOutlined,
  GlobalOutlined,
  BarsOutlined,
  IdcardOutlined,
  ProjectOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { getSubmenuAction, isMobileDevice } from './shared/components/SizeDetector';

const SubMenu = Menu.SubMenu;
const Divider = Menu.Divider;
const { Sider } = Layout;

export const Navigation: React.FC<INavigationProps> = ({ permissions, collapsed, activeTenant }) => {
  let [t] = useTranslation();
  let isMobile = isMobileDevice();
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={isMobile ? 0 : undefined} theme="light" className={styles.sideNav} width={250}>
        <div className={styles.logoContainer} id="logo">
          <img src={collapsed ? getSmallLogoUrl() : getTenantLogoUrlOrFallback(activeTenant)} alt="logo" />
        </div>
        <Menu
          triggerSubMenuAction={getSubmenuAction()}
          selectedKeys={[
            window.location.pathname === '/' ? window.location.pathname : window.location.pathname.replace('/', ''),
            isCurrent(null, window.location, 'riskassessments') ? 'riskassessments' : '',
          ]}
          mode="inline"
          theme="light"
          className={styles.navMenu}
        >
          {createMenuItem('projects', '/riskassessments', 'riskassessments', <ProjectOutlined />, t)}
          {permissions.metadata.canEditSomeMetadata && createMasterdataMenu(permissions, t)}
          {permissions.security.canManageUsers && createMenuItem('permissions', '/users', 'users', <IdcardOutlined />, t)}
        </Menu>
      </Sider>
    </Layout>
  );
};

function getTenantLogoUrlOrFallback(activeTenant: IUserTenantInfo) {
  return `${process.env.PUBLIC_URL}/assets/${activeTenant?.logo || 'logoSmall.png'}`;
}

function getSmallLogoUrl() {
  return `${process.env.PUBLIC_URL}/assets/logoSmall.png`;
}

const logout = () => {
  userService.logout();
};

const createMasterdataMenu = (permissions: UserPermissions, t: TFunction) => {
  if (!permissions.metadata.canEditSomeMetadata) {
    return null;
  }

  return (
    <SubMenu key="masterdata" title={subMenuTitle('masterdata', <SettingOutlined />, t)}>
      {permissions.metadata.canEditIncoterms && createMenuItem('incoterms', '/incoterms', 'incoterms', <BarsOutlined />, t)}
      {permissions.metadata.canEditCountries && createMenuItem('countries', '/countries', 'countries', <GlobalOutlined />, t)}
      {permissions.metadata.canEditTopics && createMenuItem('topics', '/topics', 'topics', <TagsOutlined />, t)}
      {permissions.metadata.canEditDepartments && createMenuItem('departments', '/departments', 'departments', <ClusterOutlined />, t)}
      {permissions.metadata.canEditCustomers && createMenuItem('customers', '/customers', 'customers', <CrownOutlined />, t)}
      {permissions.metadata.canEditMachineType && createMenuItem('machineTypes', '/machinetypes', 'machinetypes', <CarOutlined />, t)}
      {permissions.metadata.canEditRiskAssessmentTemplates && createMenuItem('riskAssessmentTemplates', '/riskassessmenttemplates', 'riskAssessmentTemplates', <SnippetsOutlined />, t)}
      <Divider />
      {permissions.metadata.canEditBranchesOfIndustry && createMenuItem('branchesOfIndustry', '/branchesofindustry', 'branchesofindustry', <BranchesOutlined />, t)}
      {permissions.metadata.canEditGeneralSetting && createMenuItem('settings', '/generalsettings', 'generalsettings', <ToolOutlined />, t)}
    </SubMenu>
  );
};

const renderNavLink = (title: string) => {
  return <span> {title}</span>;
};

const createMenuItem = (titleKey: string, to: string, key: string, icon: JSX.Element, t: TFunction) => {
  const title = t(`navigation.${titleKey}`);
  return (
    <Menu.Item className={styles.menuItem} key={key} title={title}>
      <Link to={to} style={{ width: '100%' }} data-automation-id={'menu-' + key}>
        {icon} {renderNavLink(title)}
      </Link>
    </Menu.Item>
  );
};
// Workaround zum Highlighting der aktiven Felder. Für die aktuelle Anforderung ausreichend!
const isCurrent = (isPartiallyCurrent: any, location: Location, name: string) => {
  return isPartiallyCurrent || location.pathname.split('/').includes(name);
};
const subMenuTitle = (titleKey: string, icon: JSX.Element, t: TFunction) => {
  return (
    <span>
      {icon}
      <span>{t(`navigation.${titleKey}`)}</span>
    </span>
  );
};

interface INavigationProps {
  collapsed: boolean;
  userInfo: UserInfo;
  activeTenant: IUserTenantInfo;
  permissions: UserPermissions;
}
