import {
  ICountryRef,
  ICustomerRef,
  IBranchOfIndustryRef,
  IMachineTypeRef,
  IIncotermRef,
  RiskTypes,
  IDepartmentRef,
  ITenantTerms,
  AllowedReturnStages as AllowedApprovalReturnStages,
} from '../masterdata';
import { TFunction } from 'i18next';
import moment from 'moment';
import {
  IRiskAssessmentTemplate,
  IRiskAssessmentDepartmentEmailReceiver,
  IRiskAssessmentTemplateRef,
  IDepartmentEvaluationStageTemplate,
  IRiskAssessmentDepartmentEmailReceiverConfig,
} from './IRiskAssessmentTemplate';
import _ from 'lodash';
import { IDocumentMetadata } from '../documents/IDocumentMetaData';
import nameof from 'ts-nameof.macro';
import { AssessmentTransition } from './AssessmentTransition';
import { IMultiLanguageString, getForLanguage } from '../IMultiLanguageString';
import { LanguageSettings } from '../LanguageSettings';
import { IBranchOfIndustryPermissions, IUserViewModelRef } from '../user/models';
import { RiskAssessmentUpdates } from './RiskAssessmentUpdates';
import { IEnsureRiskAssessmentDocument, IUploadRiskAssessmentDocuments } from './IEnsureRiskAssessmentDocument';
import { IUserInfoCtx } from '../../UserInfoContext';
import { translateStatusForTenantSettings, getFlowById } from '../../riskAssessments/riskAssessment/formhelper';
import { KnownTenantIds, DefaultPageSize } from '../constants';
import { userService } from '../../shared/services/userService';
import { string } from 'yup';

export const RiskAssessmentDefaultId = 'default-risk-assessment-id';

export enum RiskAssessmentStatus {
  Created = 'Created',
  Assessment = 'Assessment',
  Evaluation = 'Evaluation',
  Approval = 'Approval',
  Approved = 'Approved',
  Rejected = 'Rejected',
  FilterOpen = 'FilterOpen',
}

export interface ICreateRiskAssessmentCommand {
  offerNr: string;
  vendor: string;
  dueDate: string;
  isRiskProject: boolean;
  isMajorProject: boolean;
  isLossProject: boolean;
  machineTypeInfo?: string;
  pspElement: string;
  city: string;
  customer: ICustomerRef;
  isNewCustomer: boolean;
  country: ICountryRef;
  branchOfIndustry: IBranchOfIndustryRef;
  machineType: IMachineTypeRef;
  incoterms: IIncotermRef;
  processTemplate: Partial<IRiskAssessmentTemplateRef>;
}

export interface ICreateRiskAssessmentResult {
  id: string;
  token: string;
}

export interface IRiskAssessment {
  id: string;
  token: string;
  tenantId: string;
  branchId: string;
  created: string;
  status: RiskAssessmentStatus;
  categorization: IRiskAssessmentCategorization;
  metadata: IRiskAssessmentMetadata;
  calculation: IRiskAssessmentCalculation;
  processTemplate: IRiskAssessmentTemplate;
  documents: IDocumentData;
  evaluation: IRiskAssessmentEvaluations;
  evaluationSummary: IRiskAssessmentEvaluationSummary;
  approvalFlows: IApprovalFlowsState;
  statusHistory: IStatusHistory;
  assessmentRequiredDepartmentsWithoutCompletion?: IDepartmentRef[];
}

export interface INewRiskassessmentVm {
  config: IRiskAssessmentConfig;
  defaultRiskAssessmentTemplate: IRiskAssessmentTemplateRef;
}

export interface IStatusHistory {
  entries: IStatusChange[];
}

export interface IDocumentData {
  documentMetadata: IDocumentMetadata[];
}

export interface IStatusChange {
  oldStatus: RiskAssessmentStatus;
  newStatus: RiskAssessmentStatus;
  notificationData: INotificationDataWithEmails;
  approvalFlowId?: string;
  oldApprovalStageId?: string;
  newApprovalStageId?: string;
  timestamp: string;
  user: string;
  mailData: IStatusChangeMailData;
}

export interface IStatusChangeMailData {
  externalMessageId: string;
  mailHasBeenSent: boolean;
  mailSentDate: string;
  receivers: IRiskEmailReceivers;
}

export interface IRiskEmailReceivers {
  to: IRiskEmailAddress[];
  cc: IRiskEmailAddress[];
}

export interface IRiskEmailAddress {
  address: string;
  displayName: string;
}

export interface IRiskAssessmentEvaluationSummary {
  departmentSummaries: IDepartmentEvaluationSummary[];
  defaultRiskInPercent?: number;
  summaryDefaultRiskAmount?: number;
  summaryIncreasedRiskAmount?: number;
}

export interface IApprovalFlowsState {
  currentFlowId: string;
  lastCompletedFlowId: string;
  currentStageId: string;
  notActive: boolean;
  flows: IApprovalFlow[];
  approvalData: IApprovalFlowData;
}

export interface IApprovalFlow {
  id: string;
  startedBy: string;
  startedAt: string;
  completedBy: string;
  completedAt: string;
  isCompleted: boolean;
  name: IMultiLanguageString;
  flowType: ApprovalFlowTypes;
  stages: IApprovalFlowStage[];
}

export enum ApprovalFlowTypes {
  Default = 'Default',
  ClearanceOnly = 'ClearanceOnly',
  PowerofAttorney = 'PowerofAttorney',
  PartialClearance = 'PartialClearance',
  FullClearance = 'FullClearance',
  DefaultWithPowerofAttorney = 'DefaultWithPowerofAttorney',
}

export function getApprovalFlowTypesTranslationKey(type: ApprovalFlowTypes): string {
  return `approvalFlowTypes.${type}`;
}

export interface IApprovalFlowTypeOptions {
  value: string;
  desc: string;
}

export function getApprovalFlowOptions(t: TFunction): IApprovalFlowTypeOptions[] {
  return [
    { value: ApprovalFlowTypes.Default, desc: t(getApprovalFlowTypesTranslationKey(ApprovalFlowTypes.Default)) },
    { value: ApprovalFlowTypes.DefaultWithPowerofAttorney, desc: t(getApprovalFlowTypesTranslationKey(ApprovalFlowTypes.DefaultWithPowerofAttorney)) },
    { value: ApprovalFlowTypes.ClearanceOnly, desc: t(getApprovalFlowTypesTranslationKey(ApprovalFlowTypes.ClearanceOnly)) },
    { value: ApprovalFlowTypes.PowerofAttorney, desc: t(getApprovalFlowTypesTranslationKey(ApprovalFlowTypes.PowerofAttorney)) },
    { value: ApprovalFlowTypes.PartialClearance, desc: t(getApprovalFlowTypesTranslationKey(ApprovalFlowTypes.PartialClearance)) },
    { value: ApprovalFlowTypes.FullClearance, desc: t(getApprovalFlowTypesTranslationKey(ApprovalFlowTypes.FullClearance)) },
  ];
}

export const indicationOfMainScopeOptions = [
  { label: 'main shipment', value: 'main shipment' },
  { label: 'end of installation', value: 'end of installation' },
  { label: 'acceptance', value: 'acceptance' },
];

export interface IApprovalFlowStage {
  id: string;
  name: IMultiLanguageString;
  completedBy: string;
  completedAt: string;
  byOrderOf: string;
  hasBeenStarted: boolean;
  isCompleted: boolean;
  canFinishProcess: boolean;
  appraisal: IRiskAssessmentComments;
  appraisalAllFlows: IAppraisalFlowCommentCollection;
  department: IDepartmentRef;
}

export interface IAppraisalFlowCommentCollection {
  comments: IAppraisalFlowComment[];
}

export interface IAppraisalFlowComment {
  flowName: IMultiLanguageString;
  user: string;
  content: string;
  created: string;
}

export interface IRiskAssessmentApprovalStageRef {
  id: string;
}

export interface IDepartmentEvaluationSummary {
  department: IDepartmentRef;
  isEvaluationRequired: boolean;
  isEvaluationCompleted: boolean;
  risk: RiskTypes;
  evaluationAmounts: RiskAmounts;
  evaluationComment: string;
  evaluationMeasures: string;
  evaluationCompletedBy: string;
  evaluationCompletedAt: string;
  appraisalChangedBy?: string;
  appraisalChangedAt?: string;
  measuresChangedAt?: string;
  measuresChangedBy?: string;
  summaryRiskAmount: number;
  summaryMeasureAmount: number;
  summaryRiskAfterMeasureAmount: number;
  summaryIncreasedRiskAmount: number;
  summaryComment: string;
}

export interface RiskAmounts {
  riskAmount: number;
  measureAmount: number;
  riskAfterMeasureAmount: number;
}

export function emptyRiskAmounts(): RiskAmounts {
  return {
    riskAmount: 0,
    measureAmount: 0,
    riskAfterMeasureAmount: 0,
  };
}

export interface IRiskAssessmentEvaluations {
  evaluations: IRiskAssessmentEvaluation[];
}

export interface IRiskAssessmentEvaluation {
  completedBy?: string;
  completedAt?: string;
  appraisalChangedBy?: string;
  appraisalChangedAt?: string;
  isCompleted: boolean;
  department: IDepartmentRef;
  riskAmount?: number;
  measureAmount?: number;
  riskAfterMeasureAmount?: number;
  topicEvaluations: ITopicEvaluation[];
  measures: IRiskAssessmentComments;
  appraisal: IRiskAssessmentComments;
  internalComment: IRiskAssessmentComments;
  evaluationDocuments: IRiskAssessmentDocuments;
}

export interface IRiskAssessmentComments {
  comments: RiskAssessmentComment[];
}

export interface IRiskAssessmentDocuments {
  metadata: IDocumentMetadata[];
}

export interface RiskAssessmentComment {
  user: string;
  content: string;
  created: string;
}

export interface ITopicEvaluation {
  topicId: string;
  name: IMultiLanguageString;
  riskAmount?: number;
  measureAmount?: number;
  comment?: string;
  selectedCriterion?: ICriterion;
  possibleCriteria: ICriterion[];
  risk?: RiskTypes;
}

export interface ICriterion {
  key: string;
  description: IMultiLanguageString;
  risk: RiskTypes;
}

export interface IRiskAssessmentListViewModel {
  id: string;
  token: string;
  branchName: IMultiLanguageString;
  status: RiskAssessmentStatus;
  offerNr: string;
  vendor: IMultiLanguageString;
  createdDate: string;
  dueDate: string;
  customerName: IMultiLanguageString;
  countryName: IMultiLanguageString;
  pspElement: string;
  machineTypeName: IMultiLanguageString;
  vendorLegalEntity: string;
  processTemplate: IMultiLanguageString;
}

export interface IRiskAssessmentListViewModels {
  totalCount: number;
  riskAssessments: IRiskAssessmentListViewModel[];
}

export interface IRiskAssessmentStatusMonitorViewModel {
  totalCount: number;
  reportEntries: IRiskAssessmentStatusMonitorEntry[];
}
export interface IRiskAssessmentStatusMonitorEntry {
  id: string;
  token: string;
  branchName: IMultiLanguageString;
  status: RiskAssessmentStatus;
  offerNr: string;
  createdDate: string;
  dueDate: string;
  customerName: IMultiLanguageString;
  countryName: IMultiLanguageString;
  pspElement: string;
  machineType: IMultiLanguageString;
  evaluationIndicator: IRiskAssessmentDepartmentIndicator[];
  approvalIndicator: IRiskAssessmentDepartmentIndicator[];
  processTemplate: IMultiLanguageString;
  projectVolume: number;
  currency: string;
  finishedDate?: string;
}

export interface IRiskAssessmentDepartmentIndicator {
  indicator: AssessmentIndicator;
  departmentId: string;
  departmentAbbreviation: IMultiLanguageString;
}

export enum AssessmentIndicator {
  NotAssigned = 'NotAssigned',
  Finished = 'Finished',
  NotFinished = 'NotFinished',
  EvaluationHighRisk = 'EvaluationHighRisk',
  EvaluationMediumRisk = 'EvaluationMediumRisk',
  EvaluationNoRisk = 'EvaluationNoRisk',
}

export interface IRiskAssessmentFilter {
  searchText: string;
  countriesFilter: ICountryRef | ICountryRef[];
  constructionSiteCountriesFilter: ICountryRef | ICountryRef[];
  customerFilter: ICustomerRef;
  templateFilter: IRiskAssessmentTemplateRef;
  statusFilter: string[];
  branchOfIndustryFilter: string[];
}
export interface IRiskAssessmentListSearchConfig {
  filter: IRiskAssessmentFilter;
  paging?: ISearchPaging;
  sorting?: ISearchSorting;
  language?: string;
}

export interface IStoredRiskAssessmentListSearchConfig extends IRiskAssessmentListSearchConfig {
  tenantId: string;
  branchId: string;
}

export interface ISearchPaging {
  pageSize: number;
  current: number;
}

export interface ISearchSorting {
  order: string;
  field: string;
}

export interface IRiskAssessmentStatusMonitorFilterConfig {
  searchText: string;
  countriesFilter: ICountryRef | ICountryRef[];
  customerFilter: ICustomerRef | ICustomerRef[];
  statusFilter: string[];
  branchOfIndustryFilter: string[];
}

export interface IEmailReceiver {
  userId: string;
  email: string;
  username: string;
}

export interface IRiskAssessmentMetadata {
  offerNr: string;
  vendor: string;
  vendorLegalEntity: string;
  createdDate: string;
  dueDate: string;
  machineTypeInfo?: string;
  pspElement: string;
  projectDiagnosis?: string;
  city: string;
  conditionsOfPayment: string;
  internalComment: string;
  customer: ICustomerRef;
  isNewCustomer?: boolean;
  country: ICountryRef;
  branchOfIndustry: IBranchOfIndustryRef;
  machineType: IMachineTypeRef;
  constructionSiteCountry: ICountryRef;
  incoterms: IIncotermRef;
  vendorPoa?: string;
  offerNrPoa?: string;
  supplier?: string;
  billRecipient?: string;
  generalRemarks?: string;
  termsOfPaymentOrderRelease?: string;
  signatureDate?: string;
  projectStart?: string;
  deliveryTimes?: string;
  estimatedDateOfTurnover?: string;
  indicationOfMainScope?: string;
}

export interface IRiskAssessmentCalculation {
  machineTypeTotal: number;
  machineTypeDb3MinPercent: number;
  machineTypeDb3OfferPercent: number;
  pAndDTotal: number;
  pAndDb3MinInPercent: number;
  defaultRiskInPercent: number;
  currency: string;
  icProject: IIcProject;
}

export interface IIcProject {
  isIcProject: boolean;
  discount?: number;
  accordingToDirective: boolean;
}

export interface IRiskAssessmentEditVm {
  assessment: IRiskAssessment;
  permissions: IRiskAssessmentPermissions;
  additionalMailReceivers: IRiskAssessmentAdditionalMailReceivers;
  commonConfig: IRiskAssessmentConfig;
  byOrderUsers: IByOrderUsers;
}

export interface IByOrderUsers {
  users: IByOrderUserRef[];
}

export interface IByOrderUserRef {
  id: string;
  name: string;
  eMail: string;
}

export interface IRiskAssessmentConfig {
  tenantId: string;
  branchId: string;
  hasPOAReport: boolean;
  hasOfferNrPoa: boolean;
  hasVendorLegalEntity: boolean;
  hasConstructionSiteCountry: boolean;
  hasEstimatedDateOfTurnover: boolean;
  hasIndicationOfMainScope: boolean;
  hasMachineType: boolean;
  hasProjectDiagnosis: boolean;
  hasNewCustomerFlag: boolean;
  hasCustomerDescription: boolean;
  hasMultipleApprovalFlows: boolean;
  hasClearanceDocuments: boolean;
  hasSafeDocuments: boolean;
  mustEnsureRequiredDepartmentsForNotifications: boolean;
  hasApprovalActionsOnEvaluationPage: boolean;
  hasPAndD: boolean;
  currency: string;
  defaultRiskInPercent?: number;
  useDefaultRiskValue: boolean;
  marginalReturn: IMultiLanguageString;
  salesDepartment: IMultiLanguageString;
  commissionNumber: IMultiLanguageString;
  incotermsSupplement: IMultiLanguageString;
  contractAndCalculationDocuments: IMultiLanguageString;
  pmCalculationDocuments: IMultiLanguageString;
  generalDocuments: IMultiLanguageString;
  vendor: IMultiLanguageString;
  constructionSiteCountry: IMultiLanguageString;
  allowedApprovalReturnStages: AllowedApprovalReturnStages;
  allowedProcessTemplates: IRiskAssessmentTemplateRef[];
}

export interface IRiskAssessmentAdditionalMailReceivers {
  receivers: IEmailReceiver[];
}

export interface IRiskAssessmentPermissions {
  canReadRiskAssessment: boolean;
  canReadMetadata: boolean;
  canReadCalculation: boolean;
  canReadCalculationMargin: boolean;
  canReadEvaluation: boolean;
  canReadAssessments: boolean;
  canReadApproval: boolean;
  canReadCommunication: boolean;
  canReadClearanceDocuments: boolean;
  canEditPoaData: boolean;
  canEditPartialClearanceData: boolean;

  canEditMetadata: boolean;
  canEditEvaluation: string[];
  canEditAssessments: boolean;
  canEditApproval: string[];
  canEditCommunication: boolean;
  canEditClearanceDocuments: boolean;
  assignedDepartments: string[];

  branchPermissions: IBranchOfIndustryPermissions;
}

export interface IRiskAssessmentCategorization {
  isRiskProject: boolean;
  isMajorProject: boolean;
  isLossProject: boolean;
}

export interface IMessageTemplate {
  subject: IMultiLanguageString;
  body: IMultiLanguageString;
  footer: IMultiLanguageString;
}

export interface IMessageTemplateGenerationData {
  completingDepartmentId?: string;
  fromDepartmentId?: string;
  fromDepartmentName?: string;
  toDepartmentId?: string;
  approvalFlowId?: string;
}

export interface IMessageData {
  subject: string;
  body: string;
}

export interface INotificationData {
  messageData: IMessageData;
  departmentReceivers: IRiskAssessmentDepartmentEmailReceiverConfig[];
  additionalReceivers: IEmailReceiver[];
  notificationToCreator: boolean;
}

export interface INotificationDataWithEmails {
  messageData: IMessageData;
  departmentReceivers: IRiskAssessmentDepartmentEmailReceiver[];
  additionalReceivers: IEmailReceiver[];
  notificationToCreator: boolean;
}

export type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export function createRiskAssessmentListSearchConfig(): IRiskAssessmentListSearchConfig {
  return {
    filter: createRiskAssessmentListFilter(),
    paging: {
      current: 1,
      pageSize: DefaultPageSize,
    },
  };
}

export function createRiskAssessmentListFilter(): IRiskAssessmentFilter {
  return {
    searchText: '',
    countriesFilter: [] as ICountryRef[],
    constructionSiteCountriesFilter: [] as ICountryRef[],
    customerFilter: {} as ICustomerRef,
    statusFilter: [],
    branchOfIndustryFilter: [userService.getActiveBranchOfIndustryId()],
    templateFilter: {} as IRiskAssessmentTemplateRef,
  };
}

export function createRiskAssessmentStatusMonitorViewModel(): IRiskAssessmentStatusMonitorViewModel {
  return {
    totalCount: 0,
    reportEntries: [createRiskAssessmentStatusMonitorEntry()],
  };
}
export function createRiskAssessmentStatusMonitorEntry(): IRiskAssessmentStatusMonitorEntry {
  return {
    id: null,
    token: null,
    branchName: null,
    status: RiskAssessmentStatus.Created,
    offerNr: null,
    createdDate: null,
    dueDate: null,
    customerName: null,
    countryName: null,
    pspElement: null,
    machineType: null,
    evaluationIndicator: null,
    approvalIndicator: null,
    processTemplate: null,
    projectVolume: 0,
    currency: null,
    finishedDate: null,
  };
}
export function createRiskAssessmentStatusMonitorFilter(): IRiskAssessmentListSearchConfig {
  return createRiskAssessmentListSearchConfig();
}
export function createNewRiskAssessment(model: INewRiskassessmentVm): ICreateRiskAssessmentCommand {
  return {
    offerNr: '',
    customer: null,
    isNewCustomer: false,
    country: null,
    vendor: '',
    dueDate: null,
    branchOfIndustry: null,
    isRiskProject: false,
    isMajorProject: false,
    isLossProject: false,
    machineType: null,
    incoterms: null,
    machineTypeInfo: '',
    pspElement: '',
    city: '',
    processTemplate: model.defaultRiskAssessmentTemplate,
  };
}
export function createRiskAssessment(options: RecursivePartial<IRiskAssessment> = {}): IRiskAssessment {
  const base: IRiskAssessment = {
    id: null,
    token: null,
    tenantId: null,
    branchId: null,
    created: null,
    status: RiskAssessmentStatus.Created,
    metadata: {
      offerNr: null,
      customer: null,
      country: null,
      vendor: null,
      vendorLegalEntity: null,
      createdDate: moment().toISOString(),
      dueDate: null,
      branchOfIndustry: null,
      machineType: null,
      constructionSiteCountry: null,
      incoterms: null,
      machineTypeInfo: null,
      pspElement: null,
      city: null,
      conditionsOfPayment: null,
      internalComment: null,
    },
    categorization: emptyCategorization(),
    calculation: {
      machineTypeDb3MinPercent: 0,
      machineTypeDb3OfferPercent: 0,
      machineTypeTotal: 0,
      pAndDTotal: 0,
      pAndDb3MinInPercent: 0,
      defaultRiskInPercent: 0,
      icProject: {
        isIcProject: false,
        accordingToDirective: false,
        discount: null,
      },
      currency: null,
    },
    evaluation: { evaluations: [] },
    evaluationSummary: { departmentSummaries: [] },
    approvalFlows: {
      flows: [],
      notActive: true,
      currentFlowId: null,
      lastCompletedFlowId: null,
      currentStageId: null,
      approvalData: null,
    },
    processTemplate: null,
    documents: null,
    statusHistory: { entries: [] },
  };

  return _.merge({}, base, options);
}

export function mustProvideNotificationData(from: RiskAssessmentStatus, to: RiskAssessmentStatus): boolean {
  if (to === RiskAssessmentStatus.Evaluation || to === RiskAssessmentStatus.Rejected) {
    return false;
  }
  return true;
}

export function emptyCategorization(): IRiskAssessmentCategorization {
  return {
    isRiskProject: false,
    isMajorProject: false,
    isLossProject: false,
  };
}

export function getRiskAssessmentStatusOptions(t: TFunction, terms: ITenantTerms, ctx: IUserInfoCtx): IRiskAssessmentStatusOption[] {
  return ['Created', 'Assessment', 'Evaluation', 'Approval', 'Approved', 'Rejected'].map((v) => ({
    value: v,
    desc: translateStatusForTenantSettings(v as RiskAssessmentStatus, t, ctx, terms),
  }));
}

export interface IRiskAssessmentStatusOption {
  value: string;
  desc: string;
}

export interface IChangeStatusData {
  toStatus: RiskAssessmentStatus;
  notificationData: INotificationData;
}

export interface IAssessmentTransitionData {
  transition: AssessmentTransition;
  notificationData?: INotificationData;
  transitionData?: TransitionData;
}

export type TransitionData = PoaData | PartialClearanceData | FullClearanceData | ByOrderOfData;
export class PoaData {
  vendorPoa: string;
  offerNrPoa?: string;
}

export class ByOrderOfData {
  byOrderOf: IByOrderUserRef;
}

export class PartialClearanceData {
  deliveryTimes: string;
  billRecipient: string;
  signatureDate: string;
  estimatedDateOfTurnover: string;
  indicationOfMainScope: string;
}

export class FullClearanceData {
  deliveryTimes: string;
  billRecipient: string;
  signatureDate: string;
  projectStart: string;
  estimatedDateOfTurnover: string;
  indicationOfMainScope: string;
}

export interface IApprovalFlowData {
  poa: PoaData;
  partialClearance: PartialClearanceData;
  fullClearance: FullClearanceData;
}

export function getAssessmentId(vm: IRiskAssessmentEditVm): string | null {
  return vm && vm.assessment && vm.assessment.id;
}
export function getDepartmentStagesToEvaluate(assessment: IRiskAssessment): IDepartmentEvaluationStageTemplate[] {
  // TODO type guard auf IDepartmentEvaluationStage hier könnten alle Stages kommen
  const departments: IDepartmentEvaluationStageTemplate[] = _.get(assessment, nameof.full(assessment.processTemplate.evaluationFlow.stages, 1)) || [];
  return _.filter(departments, (d) => d.isRequired);
}

export function getDepartmentSummaries(assessment: IRiskAssessment): IDepartmentEvaluationSummary[] {
  return _.get(assessment, nameof.full(assessment.evaluationSummary.departmentSummaries, 1)) || [];
}

export function getEvaluationSummary(assessment: IRiskAssessment): IRiskAssessmentEvaluationSummary {
  return _.get(assessment, nameof.full(assessment.evaluationSummary, 1));
}

export function getCurrentApprovalFlow(assessment: IRiskAssessment): IApprovalFlow {
  let flowId = assessment.approvalFlows.notActive ? assessment.approvalFlows.lastCompletedFlowId : assessment.approvalFlows.currentFlowId;
  return _.find(assessment.approvalFlows.flows, (f) => f.id === flowId);
}

export function getApprovalStage(assessment: IRiskAssessment, id: string) {
  return _.find(getCurrentApprovalStages(assessment), (s) => s.id === id);
}

export function getCurrentApprovalStages(assessment: IRiskAssessment): IApprovalFlowStage[] {
  if (assessment.approvalFlows.notActive) {
    return [];
  }

  let currentFlow = _.find(assessment.approvalFlows.flows, (f) => f.id === assessment.approvalFlows.currentFlowId);
  return currentFlow?.stages ?? [];
}

export function getVisibleApprovalStages(assessment: IRiskAssessment): IApprovalFlowStage[] {
  let flowId = isActiveApprovalOrClosed(assessment) ? assessment.approvalFlows.lastCompletedFlowId : assessment.approvalFlows.currentFlowId;
  let currentFlow = _.find(assessment.approvalFlows.flows, (f) => f.id === flowId);
  if (currentFlow == null) {
    return [];
  }

  const activeStages = _.filter(currentFlow.stages, (s) => isAccessibleStage(assessment, currentFlow, s));
  return activeStages;
}

export function getFlowsWithAccessibleStages(assessment: IRiskAssessment) {
  return _.filter(assessment.approvalFlows?.flows, (flow) => _.some(flow.stages, (stage) => isAccessibleStage(assessment, flow, stage)));
}

export function getAccessibleStagesForFlow(assessment: IRiskAssessment, flow: IApprovalFlow) {
  if (!flow) {
    return [];
  }
  return _.filter(flow.stages, (stage) => isAccessibleStage(assessment, flow, stage));
}

function isAccessibleStage(assessment: IRiskAssessment, flow: IApprovalFlow, stage: IApprovalFlowStage) {
  return stage.hasBeenStarted || stage.isCompleted || isCurrentApprovalStage(assessment, flow, stage);
}

const isActiveApprovalOrClosed = (assessment: IRiskAssessment): boolean =>
  assessment.approvalFlows.notActive && (assessment.status === RiskAssessmentStatus.Approved || assessment.status === RiskAssessmentStatus.Rejected);

export function isCurrentApprovalStage(assessment: IRiskAssessment, flow: IApprovalFlow, stage: IApprovalFlowStage): boolean {
  return assessment.approvalFlows.currentFlowId === flow.id && assessment.approvalFlows.currentStageId === stage.id;
}

export function getCurrentApprovalStage(assessment: IRiskAssessment): IApprovalFlowStage {
  let stages = getCurrentApprovalStages(assessment);
  let flow = getCurrentApprovalFlow(assessment);
  return _.find(stages, (stage) => isCurrentApprovalStage(assessment, flow, stage));
}

export function getApprovalStagesForFlowId(assessment: IRiskAssessment, flowId: string) {
  let flow = getFlowById(assessment, flowId);
  return flow?.stages ?? [];
}

export function getApprovalStageInfo(assessment: IRiskAssessment, flowId: string, stageId: string): IApprovalStageInfo {
  const stage = getApprovalStagesForFlowId(assessment, flowId);
  const currentStage = _.find(stage, (s) => s.id === stageId);
  if (!currentStage) {
    return {
      isCurrent: false,
      isCompleted: false,
    };
  }

  return {
    isCurrent: currentStage.id === assessment.approvalFlows.currentStageId,
    isCompleted: currentStage.isCompleted,
  };
}

export function getCustomerName(metadata: IRiskAssessmentMetadata, settings: LanguageSettings): string {
  const name = _.get(metadata, nameof.full(metadata.customer.name, 1));
  const companyGroup = _.get(metadata, nameof.full(metadata.customer.companyGroup.name, 1));
  if (companyGroup) {
    return `${getForLanguage(name, settings)} (${getForLanguage(companyGroup, settings)})`;
  }

  return getForLanguage(name, settings);
}

export function getDepartentEvaluationStageTemplates(riskAssessment: IRiskAssessment): IDepartmentEvaluationStageTemplate[] {
  // TODO: Filter auf korrekte Stage setzen
  return (riskAssessment.processTemplate?.evaluationFlow?.stages ?? []) as IDepartmentEvaluationStageTemplate[];
}

export interface IApprovalStageInfo {
  isCurrent: boolean;
  isCompleted: boolean;
}

export interface IRiskAssessmentHistoryCollection {
  hasEntries: boolean;
  entries: IRiskAssessmentHistoryEntry[];
}

export interface IRiskAssessmentHistoryEntry {
  timestamp: string;
  userId: string;
  userName: string;
  hasChanges: boolean;
  changes: RiskAssessmentHistoryChangeTypes[];
}

export type RiskAssessmentHistoryRisksChanges = RiskValueChange | MeasureAmountChange | RiskAfterMeasureAmountChange;
export type ApprovalDataChanges = VendorPoaChange | OfferNrPoaChange | DeliveryTimesClearanceChange | BillRecipientClearanceChange | SignatureDateClearanceChange | ProjectStartClearanceChange;
export type RiskAssessmentHistoryChangeTypes =
  | RiskAssessmentHistoryRisksChanges
  | AppraisalChange
  | MeasuresChange
  | TopicEvaluationChange
  | EvaluationCompletedChange
  | EvaluationReopenedChange
  | ApprovalDataChanges;

export interface EvaluationCompletedChange {
  type: 'EvaluationCompletedChange';
}

export interface EvaluationReopenedChange {
  type: 'EvaluationReopenedChange';
}

export interface RiskValueChange {
  type: 'RiskValueChange';
  oldValue?: number;
  newValue?: number;
}

export interface MeasureAmountChange {
  type: 'MeasureAmountChange';
  oldValue?: number;
  newValue?: number;
}

export interface RiskAfterMeasureAmountChange {
  type: 'RiskAfterMeasureAmountChange';
  oldValue?: number;
  newValue?: number;
}

export interface AppraisalChange {
  type: 'AppraisalChange';
  oldValue?: string;
  newValue?: string;
}

export interface MeasuresChange {
  type: 'MeasuresChange';
  oldValue?: string;
  newValue?: string;
}

export interface TopicEvaluationChange {
  type: 'TopicEvaluationChange';
  oldValue?: ITopicEvaluationVm;
  newValue?: ITopicEvaluationVm;
}

export interface ITopicEvaluationVm {
  topicId: string;
  name: IMultiLanguageString;
  riskAmount?: number;
  measureAmount?: number;
  comment?: string;
  selectedCriterion?: ICriterion;
  risk?: RiskTypes;
}

export interface VendorPoaChange {
  type: 'VendorPoaChange';
  oldValue?: string;
  newValue?: string;
}

export interface OfferNrPoaChange {
  type: 'OfferNrPoaChange';
  oldValue?: string;
  newValue?: string;
}

export interface DeliveryTimesClearanceChange {
  type: 'DeliveryTimesClearanceChange';
  oldValue?: string;
  newValue?: string;
}

export interface BillRecipientClearanceChange {
  type: 'BillRecipientClearanceChange';
  oldValue?: string;
  newValue?: string;
}

export interface SignatureDateClearanceChange {
  type: 'SignatureDateClearanceChange';
  oldValue?: string;
  newValue?: string;
}

export interface ProjectStartClearanceChange {
  type: 'ProjectStartClearanceChange';
  oldValue?: string;
  newValue?: string;
}

export interface IRiskAssessmentOnSaveCallback {
  (updates: RiskAssessmentUpdates): Promise<void>;
}

export interface IEnsureDocumentCallback {
  (data: IEnsureRiskAssessmentDocument): Promise<void>;
}

export interface IUploadDocumentsCallback {
  (data: IUploadRiskAssessmentDocuments): Promise<void>;
}

export function hasAppraisalForAllFlows(stage: IApprovalFlowStage) {
  return stage.appraisalAllFlows?.comments?.length > 0;
}

export function getCategorisationHelpDocumentUrl(assessment: IRiskAssessment) {
  //TODO: Gefällt mir so nicht, dies Info sollte eher aus dem Backend kommen (wie die Dokumente auch)
  switch (assessment.tenantId) {
    case KnownTenantIds.Servtec:
      return '../../../assets/BGG RL 023 Risk-Tool Richtlinie_BST_inkl_Freigabematrix.pdf';
    case KnownTenantIds.PackSys:
      return '../../../assets/BGG RL 024 Risk-Tool Richtlinie_PSS_Anhang1.pdf';
    case KnownTenantIds.Bms:
      return '../../../assets/BGG RL 021 Risk-Tool Richtlinie_BMS.pdf';
    case KnownTenantIds.Kiefel:
      return '../../../assets/BGG RL 022 Risk-Tool Richtlinie_KGF.pdf';
  }

  return null;
}
