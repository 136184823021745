import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDocumentsUploadData, createFrom, IDocumentUploadData } from '../../../models/documents/IDocumentUploadMetaData';
import { Drawer, Button } from 'antd';
import { Formik, FieldArray, FormikProps, FieldArrayRenderProps } from 'formik';
import nameof from 'ts-nameof.macro';
import FormBox from '../../../shared/components/FormBox/FormBox';
import Dragger from 'antd/lib/upload/Dragger';
import { Table } from '../../../shared/components/Table/Table';
import { TFunction } from 'i18next';
import { FormRadioYesNo, FormTextInput } from '../../../shared/FormComponents';
import { IDocumentMetadata } from '../../../models/documents/IDocumentMetaData';
import _ from 'lodash';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';

export const UploadDocumentsPanel: FC<{ metadata: IDocumentMetadata; visible: boolean; onClose: () => void; onUpload: (data: IDocumentsUploadData) => Promise<void> }> = ({
  visible,
  onClose,
  onUpload,
  metadata,
}) => {
  const [t] = useTranslation();
  const [data] = useState<IDocumentsUploadData>({ files: [] });
  return (
    <Drawer width={'60%'} title={t('commonActions.uploadDocuments')} visible={visible} onClose={onClose}>
      <Formik
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (data, actions) => {
          await onUpload(data);
          actions.setSubmitting(false);
        }}
      >
        {(formik) => {
          const { values, submitForm, isSubmitting } = formik;
          return (
            <FieldArray
              name={nameof<IDocumentsUploadData>((d) => d.files)}
              render={(arrayHelpers) => (
                <>
                  <FormBox style={{ marginTop: '1rem' }} title={t('components.DocumentList.selection')}>
                    <Dragger disabled={false} name="files" multiple={true} customRequest={(o) => arrayHelpers.push(createFrom(o, metadata))} showUploadList={false}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{t('commonActions.uploadDocumentsDesc')}</p>
                      <p className="ant-upload-hint">{t('commonActions.uploadDocumentsHint')}</p>
                    </Dragger>
                  </FormBox>
                  <FormBox title={t('components.DocumentList.details')}>
                    <Table loading={isSubmitting} rowKey="id" columns={createColumns(t, formik, arrayHelpers)} dataSource={values.files}></Table>
                  </FormBox>
                  <div style={{ marginTop: '0.5rem' }}>
                    <Button onClick={submitForm} disabled={isSubmitting || values.files.length === 0} type="primary" style={{ marginRight: '0.25rem' }}>
                      {t('commonActions.uploadDocuments')}
                    </Button>
                    <Button disabled={isSubmitting} onClick={onClose}>
                      {t('commonActions.cancel')}
                    </Button>
                  </div>
                </>
              )}
            />
          );
        }}
      </Formik>
    </Drawer>
  );
};

function createColumns(t: TFunction, formik: FormikProps<IDocumentsUploadData>, arrayHelpers: FieldArrayRenderProps) {
  const getFieldPrefix = (record: IDocumentUploadData) => {
    const index = _.indexOf(formik.values.files, record);
    return `files[${index}]`;
  };

  return [
    {
      title: 'Name',
      dataIndex: 'fileName',
      width: 200,
    },
    {
      title: t('riskAssessmentDocument.paraphrased'),
      dataIndex: 'isParaphrased',
      width: 100,
      render: (text: string, record: IDocumentUploadData) => {
        return <FormRadioYesNo {...formik} name={`${getFieldPrefix(record)}.isParaphrased`} />;
      },
    },
    {
      title: t('riskAssessmentDocument.comment'),
      dataIndex: 'comment',
      width: 300,
      render: (text: string, record: IDocumentUploadData) => {
        return <FormTextInput {...formik} name={`${getFieldPrefix(record)}.comment`} />;
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      render: (text: string, record: IDocumentUploadData) => (
        <Button shape="circle" size="small" icon={<DeleteOutlined />} onClick={() => arrayHelpers.remove(_.indexOf(formik.values.files, record))} />
      ),
      width: 80,
    },
  ];
}
