import React, { FC, useReducer, useContext } from 'react';
import {
  IRiskAssessmentEditVm,
  IRiskAssessment,
  RiskAssessmentStatus,
  IAssessmentTransitionData,
  IRiskAssessmentConfig,
  IRiskAssessmentOnSaveCallback,
  IApprovalFlowStage,
  getCurrentApprovalStage,
} from '../../../models/riskAssessment';
import { FormikProps } from 'formik';
import { RiskAssessmentUpdates } from '../../../models/riskAssessment/RiskAssessmentUpdates';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import styles from './TransitionActions.module.css';
import { Button, Alert, Tabs } from 'antd';
import { createAction } from 'typesafe-actions';
import { createActionPayload, ActionsUnion } from '../../../shared/hookActions';
import {
  AssessmentTransition,
  ApprovalStageTransition,
  CompleteAssessmentAnalysis,
  ReturnToEvaluation,
  ReleaseProjectForCommunication,
  NoProject,
  CompleteApprovalStage,
  ReopenApprovalStage,
  MoveToApproved,
} from '../../../models/riskAssessment/AssessmentTransition';
import { AssessmentTransitionConfirm } from '../../AssessmentTransitionConfirm/AssessmentTransitionConfirm';
import RiskAssessmentStatusHelpers from '../../../models/riskAssessment/RiskAssessmentStatusHelper';
import { UserInfoCtx } from '../../../UserInfoContext';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { FormTextInput } from '../../../shared/FormComponents';
import { EditOutlined, LeftOutlined, SafetyCertificateOutlined, RightOutlined, FrownOutlined, EuroOutlined, MailOutlined, FileDoneOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const TR = 'components.ApprovalActions.';

const ACTION_SHOW_DRAWER = 'ACTION_SHOW_DRAWER';
const ACTION_CANCEL = 'ACTION_CANCEL';

const AppActions = {
  cancel: createAction<typeof ACTION_CANCEL>(ACTION_CANCEL),
  showDrawer: createActionPayload<typeof ACTION_SHOW_DRAWER, { transition: AssessmentTransition; mustProvideNotificationData: boolean; mustEnsureRequiredDepartmentsForNotifications?: boolean }>(
    ACTION_SHOW_DRAWER,
  ),
};
type AcceptedActions = ActionsUnion<typeof AppActions>;
const stageActionsInitialState: ITransitionActionsState = { showDrawer: false, mustProvideNotificationData: true };

function stageReducer(state: ITransitionActionsState, action: AcceptedActions): ITransitionActionsState {
  switch (action.type) {
    case 'ACTION_SHOW_DRAWER':
      return {
        showDrawer: true,
        mustProvideNotificationData: action.payload.mustProvideNotificationData,
        currentTransition: action.payload.transition,
        mustEnsureRequiredDepartmentsForNotifications: action.payload.mustEnsureRequiredDepartmentsForNotifications,
      };
    case 'ACTION_CANCEL':
      return stageActionsInitialState;
  }
  return state;
}
export const TransitionActions: FC<ITransitionActions> = ({ onSave, riskAssessmentVm, formik, transitions, additionalWarnings }) => {
  const [state, dispatch] = useReducer(stageReducer, stageActionsInitialState);
  const assessment = formik.values;
  if (riskAssessmentVm.additionalMailReceivers && riskAssessmentVm.additionalMailReceivers.receivers && riskAssessmentVm.additionalMailReceivers.receivers.length > 0) {
    riskAssessmentVm.additionalMailReceivers.receivers = _.sortBy(riskAssessmentVm.additionalMailReceivers.receivers, (r) => r.username);
  }
  const config = riskAssessmentVm.commonConfig;
  const [t] = useTranslation();

  if (transitions == null || !hasTransition(transitions)) {
    return (
      <>
        {t('components.ApprovalActions.noActionsAvailable')}
        <AdditionalMessages messages={additionalWarnings} />
      </>
    );
  }

  return (
    <>
      <LegacyTransitionsPane transitions={transitions} assessment={assessment} additionalWarnings={additionalWarnings} dispatch={dispatch} config={config} />
      <AssessmentTransitionConfirm
        config={config}
        possibleAdditionalMailReceivers={riskAssessmentVm.additionalMailReceivers}
        byOrderUsers={riskAssessmentVm.byOrderUsers}
        riskAssessment={assessment}
        visible={state.showDrawer}
        transition={state.currentTransition}
        mustProvideNotificationData={state.mustProvideNotificationData}
        mustEnsureRequiredDepartmentsForNotifications={state.mustEnsureRequiredDepartmentsForNotifications}
        onCancel={() => dispatch(AppActions.cancel())}
        onConfirm={(data) => {
          performUpdate(data, assessment, riskAssessmentVm, onSave);
          dispatch(AppActions.cancel());
        }}
      />
    </>
  );
};

const AdditionalMessages: FC<{ messages: string[] }> = ({ messages }) => {
  if (_.isEmpty(messages)) {
    return null;
  }

  return (
    <div style={{ marginTop: '0.5rem' }}>
      {messages.map((msg, idx) => (
        <Alert key={idx} type="warning" message={msg} />
      ))}
    </div>
  );
};

const hasTransition = (transition: ApprovalStageInfo) =>
  transition.canShareForCommunication ||
  transition.canGoToEvaluation ||
  transition.canMoveToNoProject ||
  transition.canMoveToApproved ||
  transition.canCompleteAnalysis ||
  transition.canCompleteCurrentApprovalStage ||
  transition.canReopenCurrentApprovalStage ||
  transition.prevStage ||
  transition.currentStage ||
  transition.nextStage;

function performUpdate(data: IAssessmentTransitionData, riskAssessment: IRiskAssessment, riskAssessmentVm: IRiskAssessmentEditVm, onSave: IRiskAssessmentOnSaveCallback) {
  const updates = RiskAssessmentUpdates.from(riskAssessmentVm.assessment, riskAssessment).withTransition(data);
  onSave(updates);
}

function returnToEvaluation(fromStatus: RiskAssessmentStatus) {
  let mustProvideNotificationData = true;
  if (fromStatus !== RiskAssessmentStatus.Approval) {
    mustProvideNotificationData = false;
  }
  return AppActions.showDrawer({ transition: new ReturnToEvaluation(fromStatus), mustProvideNotificationData: mustProvideNotificationData });
}

function completeAnalysis(fromStatus: RiskAssessmentStatus) {
  return AppActions.showDrawer({ transition: new CompleteAssessmentAnalysis(fromStatus), mustProvideNotificationData: true });
}

function moveToApprovalStage(toStage: IApprovalFlowStage, fromStage: IApprovalFlowStage) {
  return AppActions.showDrawer({ transition: ApprovalStageTransition.from(toStage, fromStage), mustProvideNotificationData: true, mustEnsureRequiredDepartmentsForNotifications: true });
}

function confirmNoProject(currentStatus: RiskAssessmentStatus) {
  return AppActions.showDrawer({ transition: new NoProject(currentStatus), mustProvideNotificationData: false });
}

function moveToApproved(currentStatus: RiskAssessmentStatus) {
  return AppActions.showDrawer({ transition: new MoveToApproved(currentStatus), mustProvideNotificationData: false });
}

function shareForCommunication(currentStatus: RiskAssessmentStatus) {
  return AppActions.showDrawer({ transition: new ReleaseProjectForCommunication(currentStatus), mustProvideNotificationData: true });
}

function completeApprovalStage(stage: IApprovalFlowStage) {
  return AppActions.showDrawer({ transition: new CompleteApprovalStage(stage), mustProvideNotificationData: false });
}

function reopenApprovalStage(stage: IApprovalFlowStage) {
  return AppActions.showDrawer({ transition: new ReopenApprovalStage(stage), mustProvideNotificationData: false });
}

interface ITransitionActions {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
  id?: string;
  onSave: IRiskAssessmentOnSaveCallback;
  transitions: ApprovalStageInfo;
  additionalWarnings?: string[];
}

interface ITransitionActionsState {
  showDrawer: boolean;
  mustProvideNotificationData: boolean;
  currentTransition?: AssessmentTransition;
  mustEnsureRequiredDepartmentsForNotifications?: boolean;
}

export interface ApprovalStageInfo {
  canShareForCommunication: boolean;
  canGoToEvaluation: boolean;
  canMoveToNoProject: boolean;
  canMoveToApproved: boolean;
  canCompleteAnalysis: boolean;
  canCompleteCurrentApprovalStage?: boolean;
  canReopenCurrentApprovalStage?: boolean;
  prevStage?: IApprovalFlowStage;
  currentStage?: IApprovalFlowStage;
  nextStage?: IApprovalFlowStage;
  canMoveToPrevStage?: boolean;
}

const LegacyTransitionsPane: React.FC<{ transitions: ApprovalStageInfo; assessment: IRiskAssessment; additionalWarnings: string[]; dispatch: any; config: IRiskAssessmentConfig }> = ({
  transitions,
  assessment,
  additionalWarnings,
  dispatch,
  config,
}) => {
  const context = useContext(UserInfoCtx);
  const [t] = useTranslation();
  return (
    <div className={styles.actions}>
      {transitions.canReopenCurrentApprovalStage && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<EditOutlined />}
          onClick={() => dispatch(reopenApprovalStage(getCurrentApprovalStage(assessment)))}
          data-automation-id="reopenApprovalStage"
        >
          {t(TR + 'reopenApprovalStage')}
        </Button>
      )}
      {transitions.canCompleteCurrentApprovalStage && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<FileDoneOutlined />}
          onClick={() => dispatch(completeApprovalStage(getCurrentApprovalStage(assessment)))}
          data-automation-id="completeApprovalStage"
          disabled={!RiskAssessmentStatusHelpers.canCompleteAnalyis(transitions.currentStage)}
        >
          {t(TR + 'completeApprovalStage')}
        </Button>
      )}
      {transitions.canShareForCommunication && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<MailOutlined />}
          data-automation-id="shareForCommunication"
          onClick={() => dispatch(shareForCommunication(assessment.status))}
          disabled={!RiskAssessmentStatusHelpers.canShareForCommunication(assessment.status)}
        >
          {t(TR + 'shareforcommunication')}
        </Button>
      )}
      {transitions.canGoToEvaluation && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<EuroOutlined />}
          data-automation-id="returnToEvaluation"
          onClick={() => dispatch(returnToEvaluation(assessment.status))}
          disabled={!RiskAssessmentStatusHelpers.canMoveToEvaluation(assessment.status)}
        >
          {t(TR + 'backToEvaluation', { department: context.t(config.salesDepartment) })}
        </Button>
      )}
      {transitions.canMoveToNoProject && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<FrownOutlined />}
          data-automation-id="confirmNoProject"
          onClick={() => dispatch(confirmNoProject(assessment.status))}
          disabled={!RiskAssessmentStatusHelpers.canMoveToNoProject(assessment.status)}
        >
          {t(TR + 'noproject')}
        </Button>
      )}
      {transitions.canMoveToApproved && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<SafetyCertificateOutlined />}
          data-automation-id="confirmApproved"
          onClick={() => dispatch(moveToApproved(assessment.status))}
          disabled={!RiskAssessmentStatusHelpers.canMoveToApproved(assessment.status)}
        >
          {t(TR + 'approve')}
        </Button>
      )}
      {transitions.canMoveToPrevStage && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<LeftOutlined />}
          onClick={() => dispatch(moveToApprovalStage(transitions.prevStage, transitions.currentStage))}
          data-automation-id="moveToPrevApprovalStage"
        >
          {t(TR + 'backToStage', { stage: getForLanguage(transitions.prevStage.name, context.languageSettings) })}
        </Button>
      )}
      {transitions.nextStage && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<RightOutlined />}
          onClick={() => dispatch(moveToApprovalStage(transitions.nextStage, transitions.currentStage))}
          data-automation-id="moveToNextApprovalStage"
        >
          {t(TR + 'goToStage', { stage: getForLanguage(transitions.nextStage.name, context.languageSettings) })}
        </Button>
      )}
      {transitions.canCompleteAnalysis && (
        <Button
          size="large"
          className={styles.action}
          type="primary"
          icon={<SafetyCertificateOutlined />}
          data-automation-id="completeAnalysis"
          onClick={() => dispatch(completeAnalysis(assessment.status))}
          disabled={!transitions.canCompleteAnalysis}
        >
          {t(TR + 'completeAnalysis')}
        </Button>
      )}
      <AdditionalMessages messages={additionalWarnings} />
    </div>
  );
};
