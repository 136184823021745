import React from 'react';
import { Button, Form } from 'antd';
import * as yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import { Formik, FormikHelpers } from 'formik';

import Panel from '../../../shared/components/Panel/Panel';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { FormTextInput, FormLanguageTextInput } from '../../../shared/FormComponents';
import { ICountry, createCountry } from '../../../models/masterdata';
import { UserInfoCtx } from '../../../UserInfoContext';
import { HtcCard } from '../../../shared/HtcCardComponent';
import LanguageTabPage from '../../../shared/components/LanguageTabPage/LanguageTabPage';
import { SaveOutlined } from '@ant-design/icons';

class CountryCreationPanel<T> extends React.Component<ICountryCreationPanelProps, {}> {
  render() {
    const { t } = this.props;
    return (
      <Panel label={this.props.label} drawerVisible={this.props.isOpen == true} onClose={() => this.props.onClose()}>
        <Formik enableReinitialize={true} onSubmit={this.onSubmit} initialValues={createCountry()} validationSchema={getCountryValidationScheme()}>
          {(formik) => {
            const { isSubmitting, submitForm } = formik;
            return (
              <>
                <LanguageTabPage>
                  {(lp) => (
                    <HtcCard flex>
                      <FormBox title={t('masterdata.countries.editform.details')} flex contentflex>
                        <Form layout="vertical">
                          <FormLanguageTextInput {...formik} name="name" label={t('masterdata.countries.name')} selectedLanguage={lp.lang} />
                          <FormTextInput {...formik} name="isoCode3Letters" label={t('masterdata.countries.isoCode3Letters')} />
                        </Form>
                      </FormBox>
                    </HtcCard>
                  )}
                </LanguageTabPage>
                <Button id="saveAction" icon={<SaveOutlined />} style={{ marginBottom: 25 }} onClick={submitForm} disabled={isSubmitting} type="primary" data-automation-id="saveAction">
                  {t('commonActions.save')}
                </Button>
              </>
            );
          }}
        </Formik>
      </Panel>
    );
  }

  onSubmit = (country: ICountry, actions: FormikHelpers<ICountry>) => {
    this.props.onSaveCountry(country);
    actions.resetForm();
  };
}

const getCountryValidationScheme = () => {
  return yup.object().shape<any>({
    name: yup.mixed().required(),
    isoCode3Letters: yup.string().required(),
  });
};

CountryCreationPanel.contextType = UserInfoCtx;
export default withTranslation()(CountryCreationPanel);

interface ICountryCreationPanelProps extends WithTranslation {
  isOpen: boolean;
  label: string;
  onSaveCountry: (country: ICountry) => void;
  onClose: () => void;
}
