import React, { FC } from 'react';
import styles from './IconNavLink.module.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '@ant-design/icons';

const IconNavLink: FC<{ icon: React.ForwardRefExoticComponent<any>; name?: string; nameTrKey?: string; nameTrPrefix?: string; to: string; dataAutomationId?: string }> = React.memo((props) => {
  const { to, name, icon, nameTrKey, nameTrPrefix = '', dataAutomationId } = props;
  const [t] = useTranslation();

  let displayName = 'Unknown';
  if (nameTrKey) {
    displayName = t(nameTrPrefix + nameTrKey);
  } else if (name) {
    displayName = name;
  }

  return (
    <Link className={styles.nav_link} to={to} data-automation-id={dataAutomationId || 'link-' + to}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Icon className={styles.nav_link_icon} component={icon as any} />
        {displayName}
      </div>
    </Link>
  );
});

IconNavLink.displayName = 'IconNavLink';

export default IconNavLink;
