import React, { useState } from 'react';
import { Upload, Button } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { IDocumentMetadata } from '../../../models/documents/IDocumentMetaData';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FileUploadBox } from './FileUploadBox';
import Panel from '../Panel/Panel';
import Form from 'antd/lib/form/Form';
import { SafetyOutlined } from '@ant-design/icons';

const Dragger = Upload.Dragger;

const FileCheckinPanel: React.FC<FileCheckinPanelProps> = ({ onClose, onSave, disabled = false, oldFileName, isVisible }) => {
  const [t] = useTranslation();
  const [file, setFile] = useState(null);

  const canSave = (isSubmitting: boolean): boolean => {
    return !isSubmitting && file != null;
  };

  const handleSave = async (data: any, actions: FormikHelpers<IDocumentMetadata>) => {
    onSave(file);
    actions.setSubmitting(false);
  };

  return (
    <Panel label={t('components.DocumentList.checkInDocument')} drawerVisible={isVisible} onClose={() => onClose()}>
      <div>{t('components.DocumentList.confirmCheckIn')}</div>
      <Formik enableReinitialize={true} initialValues={{} as any} onSubmit={handleSave}>
        {(formik) => {
          const { isSubmitting, submitForm } = formik;
          return (
            <Form layout="vertical">
              <FileUploadBox
                existingFileName={oldFileName}
                disabled={disabled}
                setFile={setFile}
                uploadHint={t('commonActions.uploadDocumentHint')}
                uploadDesc={t('commonActions.uploadDocumentDesc')}
              />
              <div style={{ marginTop: 25 }}>{file ? file.file.name : oldFileName}</div>
              <Button id="saveAction" icon={<SafetyOutlined />} style={{ marginBottom: 25, marginTop: 25 }} onClick={submitForm} disabled={!canSave(isSubmitting) || disabled} type="primary">
                {t('commonActions.checkIn')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Panel>
  );
};

export default FileCheckinPanel;

interface FileCheckinPanelProps {
  disabled?: boolean;
  oldFileName?: string;
  onSave: (file: any) => void;
  isVisible;
  onClose: () => void;
}
