import React, { PureComponent, FC } from 'react';
import { connect } from 'react-redux';
import { HtcCard } from '../../../../shared/HtcCardComponent';
import {
  IRiskAssessmentEditVm,
  IRiskAssessment,
  IRiskAssessmentEvaluation,
  IRiskAssessmentHistoryCollection,
  IRiskAssessmentOnSaveCallback,
  IEnsureDocumentCallback,
  IUploadDocumentsCallback,
} from '../../../../models/riskAssessment';
import { FormikProps, getIn } from 'formik';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { Button, Tabs } from 'antd';
import { FormCommentWithHistory } from '../../../../shared/components/CommentHistory/CommentHistory';
import Evaluation from '../Evaluation';
import styles from './EvaluationPage.module.css';
import { getPathToRiskAssessmentEvaluationField, getRiskAssessmentEvaluation, getFirstCommentText } from '../../formhelper';
import { IRootState } from '../../../../ducks/reducer';
import { IDocumentMetadata, DocumentTypes, createDocumentMetadataForMisc, DepartmentEvaluationOwner, getEvaluationDocuments } from '../../../../models/documents/IDocumentMetaData';
import _ from 'lodash';
import { DocumentList } from '../../../components/DocumentList/DocumentList';
import { RiskAssessmentUpdates } from '../../../../models/riskAssessment/RiskAssessmentUpdates';
import nameof from 'ts-nameof.macro';
import { PermissionCtx } from '../../RiskAssessmentContext';
import { GenerateReport } from '../../../../shared/components/CommandBar/CommandBar';
import PermissionCheck from '../../../../shared/components/PermissionCheck/PermissionCheck';
import { RouteComponentProps } from 'react-router';
import { HistoryPane } from './HistoryPane';
import { Risks } from './Risks';
import { EllipsisText } from '../../../../shared/components/EllipsisText/EllipsisText';
import FormBox from '../../../../shared/components/FormBox/FormBox';
import { IGenerateReportFunc, DepartmentEvaluationReport } from '../../reporting/model';
import { AssessmentPageHeader } from '../PageHeader/PageHeader';
import { AssessmentPageContent } from '../PageContent/PageContent';
import { DocumentTabs } from '../../shared/DocumentTabs/DocumentTabs';
import { HistoryOutlined } from '@ant-design/icons';
import { DefaultDocumentListScrollConfig } from '../../../../models';
import { TenantBranchCtx } from '../../../../TenantBranchContext';

const TP = 'riskAssessment.editform.assessment.';
const TabPane = Tabs.TabPane;

class EvaluationPage extends PureComponent<IEvaluationPageProps, IEvaluationPageState> {
  state: IEvaluationPageState = {
    selectedCommentPath: null,
    selectedDocumentMetaData: null,
    showHistoryPane: false,
  };
  render() {
    let { formik, match, t, onSave, riskAssessmentVm, getEvaluationHistory, generateReport, onEnsureDocument, onUploadDocuments } = this.props;
    let { showHistoryPane } = this.state;
    let departmentId = match.params.departmentId;
    let config = riskAssessmentVm.commonConfig;
    let evaluation = getRiskAssessmentEvaluation(formik.values, departmentId);
    let riskAssessment = formik.values;
    let readOnly = this.context.permissionHelper.isAssessmentReadOnly(departmentId) || evaluation.isCompleted;
    let scrollConfig = DefaultDocumentListScrollConfig;
    return (
      <TenantBranchCtx.Consumer>
        {({ tenantId, branchId }) => (
          <PermissionCheck hasAccess={riskAssessmentVm.permissions.canReadAssessments}>
            <AssessmentPageHeader current={evaluation.department.name}>
              {this.context.permissionHelper.canPrintRiskAnalysisReport() && (
                <GenerateReport
                  text="riskAssessment.createEvaluationReport"
                  onClick={() => generateReport(new DepartmentEvaluationReport(departmentId, riskAssessment), riskAssessment, tenantId, branchId)}
                />
              )}
              <Button style={{ marginLeft: '0.25rem' }} icon={<HistoryOutlined />} onClick={() => this.setState({ showHistoryPane: true })} />
            </AssessmentPageHeader>
            <AssessmentPageContent>
              <div className={styles.evaluationdatacontainer}>
                <HtcCard style={{ minHeight: '20rem' }}>
                  <DocumentTabs defaultActiveKey="1">
                    <TabPane tab={t('riskAssessment.documents.miscellaneous')} key="1">
                      <DocumentList
                        riskAssessment={riskAssessment}
                        riskAssessmentVm={riskAssessmentVm}
                        onSave={this.props.onSave}
                        onEnsureDocument={onEnsureDocument}
                        onUploadDocuments={onUploadDocuments}
                        disabled={readOnly}
                        createNewMetadata={() => createDocumentMetadataForMisc(new DepartmentEvaluationOwner(riskAssessment.id, departmentId))}
                        documents={getEvaluationDocuments(riskAssessment, DocumentTypes.Miscellaneous, departmentId)}
                        scroll={scrollConfig}
                      />
                    </TabPane>
                  </DocumentTabs>
                  <InternalComment formik={formik} departmentId={departmentId} />
                </HtcCard>
                <HtcCard flexRow>
                  <div className={styles.textcontainer}>
                    <Appraisal formik={formik} departmentId={departmentId} disabled={readOnly} />
                    <Measures departmentId={departmentId} formik={formik} disabled={readOnly} />
                  </div>
                  <Risks
                    riskAssessmentVm={riskAssessmentVm}
                    onSave={onSave}
                    formik={formik}
                    departmentId={departmentId}
                    disabled={readOnly}
                    finishButtonDisabled={this.context.permissionHelper.isAssessmentReadOnly(departmentId)}
                  />
                </HtcCard>
              </div>
              <div className={styles.evaluationcontainer}>
                <HtcCard flex>
                  <Evaluation departmentId={departmentId} formik={formik} disabled={readOnly} riskAssessmentEditVm={riskAssessmentVm} />
                </HtcCard>
              </div>
            </AssessmentPageContent>
            <HistoryPane
              searchKey={`${riskAssessment.id}|${departmentId}`}
              config={config}
              getHistory={() => getEvaluationHistory(riskAssessment.id, departmentId)}
              visible={showHistoryPane}
              onClose={() => this.setState({ showHistoryPane: false })}
            />
          </PermissionCheck>
        )}
      </TenantBranchCtx.Consumer>
    );
  }

  openCommentDrawer = (commentPath: string) => {
    this.setState({ selectedCommentPath: commentPath });
  };

  onSaveCommentDrawer = (content: string) => {
    this.setState({ selectedCommentPath: null });
  };

  save = () => this.props.onSave(RiskAssessmentUpdates.from(this.props.riskAssessmentVm?.assessment, this.props.formik.values));
}

interface IEvaluationPageState {
  selectedCommentPath: string;
  selectedDocumentMetaData: IDocumentMetadata;
  showHistoryPane: boolean;
}

interface IEvaluationPageProps extends RouteComponentProps<{ departmentId: string }>, WithTranslation {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
  onSave: IRiskAssessmentOnSaveCallback;
  onEnsureDocument: IEnsureDocumentCallback;
  onUploadDocuments: IUploadDocumentsCallback;
  getEvaluationHistory: (riskAssessmentId: string, departmentId: string) => Promise<IRiskAssessmentHistoryCollection>;
  generateReport: IGenerateReportFunc;
}

const mapStateToProps = (state: IRootState) => {
  return {
    userInfo: state.app.userInfo,
  };
};

EvaluationPage.contextType = PermissionCtx;
export default withTranslation()(connect(mapStateToProps)(EvaluationPage));

function pathToMeasures(assessment: IRiskAssessment, departmentId: string) {
  return getPathToRiskAssessmentEvaluationField(
    assessment,
    departmentId,
    nameof.full<IRiskAssessmentEvaluation>((r) => r.measures.comments),
  );
}

const Measures: FC<{ formik: FormikProps<IRiskAssessment>; departmentId: string; disabled: boolean }> = ({ formik, departmentId, disabled = false }) => {
  const [t] = useTranslation();

  return (
    <FormCommentWithHistory
      title={t(TP + 'measurements')}
      formik={formik}
      pathToComments={pathToMeasures(formik.values, departmentId)}
      style={{ maxHeight: '20rem' }}
      disabled={disabled}
      dataAutomationId="evaluation-measurements"
    />
  );
};

const InternalComment: FC<{ formik: FormikProps<IRiskAssessment>; departmentId: string }> = ({ formik, departmentId }) => {
  const [t] = useTranslation();
  const comment = getFirstCommentText(getIn(formik.values, pathToInternalComment(formik.values, departmentId)));
  return (
    <FormBox title={t(TP + 'internalComment')}>
      <EllipsisText title={t(TP + 'internalComment')} text={comment} lines={3} />
    </FormBox>
  );
};

function pathToInternalComment(assessment: IRiskAssessment, departmentId: string) {
  return getPathToRiskAssessmentEvaluationField(
    assessment,
    departmentId,
    nameof.full<IRiskAssessmentEvaluation>((r) => r.internalComment.comments),
  );
}

const Appraisal: FC<{ formik: FormikProps<IRiskAssessment>; departmentId: string; disabled: boolean }> = ({ formik, departmentId, disabled = false }) => {
  const [t] = useTranslation();
  return (
    <FormCommentWithHistory
      required
      title={t(TP + 'evaluation')}
      formik={formik}
      pathToComments={pathToAppraisal(formik.values, departmentId)}
      style={{ maxHeight: '12rem' }}
      disabled={disabled}
      dataAutomationId="evaluation-appraisal"
    />
  );
};

function pathToAppraisal(assessment: IRiskAssessment, departmentId: string) {
  return getPathToRiskAssessmentEvaluationField(
    assessment,
    departmentId,
    nameof.full<IRiskAssessmentEvaluation>((r) => r.appraisal.comments),
  );
}
