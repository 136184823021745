import * as _ from 'lodash';
import { IIncoterm, IIncotermRef } from '../../models/masterdata';
import HttpClient from './httpClient';
import LRU from 'lru-cache';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';
import { LanguageSettings } from '../../models/LanguageSettings';
import { queryMasterdataOptions } from './masterdataQueryHelper';
import { UniqueValidation, IUniqueValidationErrorResult } from '../../models/IUniqueValidation';

interface IIncotermResponse {
  incoterms: IIncoterm[];
}

interface IIncotermOptionResponse {
  options: IIncotermRef[];
}

const cacheOptions = { maxAge: 1000 * 60 * 60 };
const cache = new LRU<string, Map<IMultiLanguageString, IIncotermRef>>(cacheOptions);

const getAllIncoterms = async (): Promise<IIncoterm[]> => {
  const result = await HttpClient.getInstance().get<IIncotermResponse>(`incoterms`);
  return getIncotermsFromResponse(result.data);
};
const createIncoterm = async (item: IIncoterm): Promise<IIncoterm> => {
  const result = await HttpClient.getInstance().post<IIncotermResponse>(`incoterms`, item);
  return getIncotermsFromResponse(result.data)[0];
};

const editIncoterm = async (item: IIncoterm): Promise<IIncoterm> => {
  const result = await HttpClient.getInstance().put<IIncotermResponse>(`incoterms/${item.id}`, item);
  return getIncotermsFromResponse(result.data)[0];
};

const deleteIncoterm = async (item: IIncoterm): Promise<void> => {
  await HttpClient.getInstance().delete(`incoterms/${item.id}`);
};

function getIncotermsFromResponse(response: IIncotermResponse) {
  return _.map(response.incoterms, (incoterm) => {
    return incoterm;
  });
}

const isUnique = async (incoterm: IIncoterm): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/incoterms/isunique`, incoterm);
  return new UniqueValidation(result.data.validationErrors);
};

async function queryIncotermOptions(query: string, settings: LanguageSettings, tenantId: string, branchId: string): Promise<IIncotermRef[]> {
  // keine Sortierung weil Incoterm eine Custom Sort Order haben
  return queryMasterdataOptions('incoterms', query, settings, cache, tenantId, branchId, false);
}

export default {
  getAllIncoterms,
  createIncoterm,
  editIncoterm,
  deleteIncoterm,
  queryIncotermOptions,
  isUnique,
};
