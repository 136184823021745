import { DocumentMetadataOwnerTypes, DocumentTypes, IDocumentMetadata } from './IDocumentMetaData';

export interface IDocumentUploadData extends IDocumentUploadMetadata {
  id: string;
  file: any;
}

export interface IDocumentUploadMetadata {
  fileName: string;
  comment: string;
  isParaphrased: boolean;
  owner: DocumentMetadataOwnerTypes;
  documentType: DocumentTypes;
}

export interface IDocumentsUploadData {
  files: IDocumentUploadData[];
}

export function createFrom(o: any, metadata: IDocumentMetadata): IDocumentUploadData {
  return {
    id: o.file.uid,
    comment: null,
    isParaphrased: false,
    fileName: o.file.name,
    file: o.file,
    owner: metadata.owner,
    documentType: metadata.documentType,
  };
}
