import { IRiskAssessment, RiskAssessmentStatus, getApprovalStageInfo, IRiskAssessmentPermissions, IDepartmentEvaluationSummary } from '../../models/riskAssessment';

export class RiskAssessmentPermissionHelper {
  constructor(private assessment: IRiskAssessment, private permissions: IRiskAssessmentPermissions) {}

  public isBasicMetaDataReadOnly(): boolean {
    if (!this.permissions.canEditMetadata) {
      return true;
    }

    switch (this.assessment.status) {
      case RiskAssessmentStatus.Created:
      case RiskAssessmentStatus.Assessment:
      case RiskAssessmentStatus.Evaluation:
        return false;
    }

    return true;
  }

  public isProjectCategoryReadOnly(): boolean {
    if (this.assessment.status === RiskAssessmentStatus.Approval && this.canEditSafeDocuments()) {
      return false;
    }

    return this.isBasicMetaDataReadOnly();
  }

  public isPspElementReadOnly(): boolean {
    if (!this.permissions.canEditMetadata) {
      return true;
    }

    switch (this.assessment.status) {
      case RiskAssessmentStatus.Created:
      case RiskAssessmentStatus.Assessment:
      case RiskAssessmentStatus.Evaluation:
      case RiskAssessmentStatus.Approved:
        return false;
    }

    return true;
  }

  public isAssessmentReadOnly(departmentId: string): boolean {
    return !this.hasDepartmentRights(departmentId) || !(this.assessment.status === RiskAssessmentStatus.Assessment);
  }
  public isOverviewReadOnly(): boolean {
    return !this.permissions.canEditAssessments || this.assessment.status !== RiskAssessmentStatus.Evaluation;
  }

  public canEditCompletedSummary(summary: IDepartmentEvaluationSummary): boolean {
    return this.permissions.canEditAssessments && this.assessment.status === RiskAssessmentStatus.Assessment && summary.isEvaluationCompleted;
  }

  public isApprovalReadOnly(flowId: string, stageId: string): boolean {
    if (this.assessment.status !== RiskAssessmentStatus.Approval) {
      return true;
    }

    const stageInfo = getApprovalStageInfo(this.assessment, flowId, stageId);
    return !this.hasApprovalRights(stageId) || !stageInfo.isCurrent;
  }

  public canChangeApprovalState(flowId: string, stageId: string): boolean {
    if (this.assessment.status !== RiskAssessmentStatus.Approval) {
      return false;
    }

    const stageInfo = getApprovalStageInfo(this.assessment, flowId, stageId);
    return this.permissions.canEditApproval.includes(stageId) && stageInfo.isCurrent;
  }

  public canEditCommunication(): boolean {
    return this.permissions.canEditCommunication;
  }

  public canPrintRiskAnalysisReport(): boolean {
    return this.permissions.branchPermissions.canPrintRiskAnalysisReport;
  }

  public canEditAppendicesDocuments(): boolean {
    return this.permissions.branchPermissions.canEditAppendicesDocuments;
  }

  public canEditSafeDocuments(): boolean {
    return this.permissions.branchPermissions.canEditSafeDocuments;
  }

  public canEditContractDocuments(): boolean {
    return this.permissions.branchPermissions.canEditContractDocuments;
  }

  public canEditPmCalcDocuments(): boolean {
    return this.permissions.branchPermissions.canEditPmCalcDocuments;
  }

  public canEditClearanceDocuments(): boolean {
    return this.permissions.canEditClearanceDocuments;
  }

  public canReadClearanceDocuments(): boolean {
    return this.permissions.canReadClearanceDocuments;
  }

  public isProjectAdmin(): boolean {
    return this.permissions.branchPermissions.isProjectAdmin;
  }

  public hasDepartmentRights(departmentId: string): boolean {
    return this.permissions.canEditEvaluation.includes(departmentId);
  }

  public hasApprovalRights(stageId: string): boolean {
    return this.permissions.canEditApproval.includes(stageId);
  }

  public isAssignedToDepartment(departmentId: string): boolean {
    return this.permissions.assignedDepartments.includes(departmentId);
  }
}
