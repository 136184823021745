import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';
import { ITopic, ITopicWithDepartmentUsages, IDepartmentRef, createTopic } from '../../models/masterdata';
import { Spin } from 'antd';
import _ from 'lodash';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import topicService from '../../shared/services/topicService';
import TopicsList from './TopicsList';
import { sortResult } from '../../shared/services/masterdataQueryHelper';
import nameof from 'ts-nameof.macro';
import { ICheckableTag } from '../../shared/components/CheckableTagGroup/CheckableTagGroup';
import { TopicsEditFormComponent } from './TopicsEditFormComponent';

export const Topics: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);
  const { loading, data: topics } = useData<ITopicWithDepartmentUsages[]>(id, topicService.getAllTopics);
  const [editObj, setEditObj] = useState<ITopic>();
  useEffect(() => setEditObj(id && _.cloneDeep(_.find(topics, (t) => t.id === id) || createTopic())), [id, topics]);

  const cancelEdit = (): void => history.goBack();
  const onSave = async (topic: ITopic) => {
    const saveAction = topic.id ? topicService.editTopic(topic) : topicService.createTopic(topic);
    const newEditObj = await tryAsyncAction(saveAction);
    setEditObj(newEditObj);
    cancelEdit();
  };
  const onDelete = async (topic: ITopic) => {
    await tryAsyncAction(topicService.deleteTopic(topic));
    cancelEdit();
  };

  const [searchText, setSearchText] = useState('');
  const [filterDepartments, setFilterDepartments] = useState<string[]>([]);
  const filteredIncoterms = useMemo(() => {
    return prepareFilteredItems(topics, searchText, filterDepartments, ctx);
  }, [searchText, topics, searchText, filterDepartments]);
  const tagOptions = useMemo(() => getDepartmentsFromTopics(topics, ctx), [topics]);

  if (loading) {
    return <Spin></Spin>;
  }

  if (editObj) {
    return <TopicsEditFormComponent loading={loading} topic={editObj} onSave={onSave} onDelete={onDelete} cancelEdit={cancelEdit} />;
  }
  return (
    <TopicsList
      loading={loading}
      topics={filteredIncoterms}
      searchText={searchText}
      setSearchText={setSearchText}
      filterDepartments={filterDepartments}
      setFilterDepartments={setFilterDepartments}
      departmentTagOptions={tagOptions}
    />
  );
};

function prepareFilteredItems(topics: ITopicWithDepartmentUsages[], searchText: string, filterDepartments: string[], ctx: IUserInfoCtx) {
  const filtered = _.filter(topics, (topic) => {
    const matchesSearchText = !searchText || _.includes(_.toLower(ctx.t(topic.name)), _.toLower(searchText)) || _.includes(_.toLower(ctx.t(topic.name)), _.toLower(searchText));

    const matchesDepartments =
      _.isEmpty(filterDepartments) ||
      _.chain(topic.usedInDepartments)
        .map((ud) => ud.name)
        .some((n) => _.some(filterDepartments, (fd) => fd === ctx.t(n)))
        .value();

    return matchesSearchText && matchesDepartments;
  });

  _.forEach(
    filtered,
    (topic) =>
      (topic.usedInDepartmentsUI = _.join(
        _.map(topic.usedInDepartments, (d) => ctx.t(d.name)),
        ', ',
      )),
  );
  return sortResult(filtered, ctx.languageSettings);
}

function getDepartmentsFromTopics(topics: ITopicWithDepartmentUsages[], ctx: IUserInfoCtx): ICheckableTag[] {
  if (!topics) {
    return [];
  }
  const allDepartments = _.flatten(_.map(topics, (t) => t.usedInDepartments));
  const uniqueDepartments = _.uniqBy(
    allDepartments,
    nameof<IDepartmentRef>((d) => d.id),
  );

  return _.map(sortResult(uniqueDepartments, ctx.languageSettings), (d) => {
    const name = ctx.t(d.name);
    return { tag: name, title: name };
  });
}
