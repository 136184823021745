import React, { FC, useContext } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { IIncoterm, NewIncotermId } from '../../models/masterdata';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import styles from './IncotermsList.module.css';
import { NewAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox, { SearchBox } from '../../shared/components/FormBox/FormBox';
import _ from 'lodash';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { getForLanguage } from '../../models/IMultiLanguageString';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { useHistory } from 'react-router';
import { TFunction } from 'i18next';
import { Table } from '../../shared/components/Table/Table';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { EditOutlined } from '@ant-design/icons';

const navPaths = ['navigation.masterdata', 'navigation.incoterms'];
export const IncotermsList: FC<IncotermsListProps> = ({ loading, incoterms, setSearchText, searchText }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);

  const create = React.useCallback(() => history.push('/incoterms/' + NewIncotermId), []);
  const edit = React.useCallback((id: string) => history.push('/incoterms/' + id), []);
  const columns = React.useMemo(() => createColumns(t, ctx, edit), []);
  const onSearch = (e: any) => setSearchText(e.target.value);

  return (
    <ContentWrapper>
      <MasterdataHeader>
        <CommandBarV2 paths={navPaths}>
          <NewAction text="masterdata.incoterms.newIncoterm" onClick={create} />
        </CommandBarV2>
      </MasterdataHeader>
      <HtcCardWrapper>
        <HtcCard>
          <SearchBox>
            <Input placeholder={t('masterdata.incoterms.searchText')} allowClear onChange={onSearch} value={searchText} />
          </SearchBox>
        </HtcCard>
        <HtcCard flex>
          <FormBox title={t('common.results')} flex contentflex>
            <Table rowKey="id" columns={columns} dataSource={incoterms} loading={loading} />
          </FormBox>
        </HtcCard>
      </HtcCardWrapper>
    </ContentWrapper>
  );
};

function createColumns(t: TFunction, ctx: IUserInfoCtx, onEdit: (id: string) => void) {
  return [
    {
      title: t('masterdata.incoterms.code'),
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (text: string, record: IIncoterm) => getForLanguage(record.code, ctx.languageSettings),
    },
    {
      title: t('masterdata.incoterms.description'),
      dataIndex: 'description',
      key: 'description',
      width: 250,
      render: (text: string, record: IIncoterm) => getForLanguage(record.description, ctx.languageSettings),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: IIncoterm) => <Button id="editIncoterm" type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={() => onEdit(record.id)} />,
      width: 100,
    },
  ];
}

interface IncotermsListProps {
  incoterms: IIncoterm[];
  loading: boolean;
  setSearchText: (text: string) => void;
  searchText: string;
}
