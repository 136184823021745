import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { IUsersEditViewModel, IUserEditViewModel, IUserAddViewModel } from '../../models/user';

export const getAllUsersForEdit = createAsyncAction('getAllUsersForEdit/fetch_request', 'getAllUsersForEdit/fetch_success', 'getAllUsersForEdit/fetch_failure')<void, IUsersEditViewModel, Error>();

export const selectUser = createStandardAction('selectUser')<string>();
export const cancelEditUser = createStandardAction('cancelEditUser')<void>();
export const saveEditUser = createAsyncAction('saveEditUser/fetch_request', 'saveEditUser/fetch_success', 'saveEditUser/fetch_failure')<IUserEditViewModel, IUserEditViewModel, Error>();

export const addAndSelectNewUser = createAsyncAction('addAndSelectNewUser/fetch_request', 'addAndSelectNewUser/fetch_success', 'addAndSelectNewUser/fetch_failure')<
  IUserAddViewModel,
  IUserEditViewModel,
  Error
>();

export const loadUserWithCopiedClaims = createAsyncAction('loadUserWithCopiedClaims/fetch_request', 'loadUserWithCopiedClaims/fetch_success', 'loadUserWithCopiedClaims/fetch_failure')<
  { userId: string; userToCopyId: string },
  IUserEditViewModel,
  Error
>();
