import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IUserEditViewModel, IBranchOfIndustryRef, ITenantRef, IDepartmentRefWithTenantAndBranch, IAzureUser, IUserAddViewModel } from '../models/user';
import UserList from './UserList';
import UserEditForm from './UserEditForm';
import { ITenantInfo } from '../models/masterdata/ITenant';
import { UserPermissions } from '../models';

class UsersComponent extends PureComponent<IUsersComponentProps> {
  render() {
    const { selectedUser, cancelEditUser, saveEditUser, assignableTenants, allTenants, assignableBranchesOfIndustry, assignableDepartments, permissions } = this.props;

    if (selectedUser) {
      return (
        <UserEditForm
          assignableTenants={assignableTenants}
          allTenants={allTenants}
          assignableBranchesOfIndustry={assignableBranchesOfIndustry}
          assignableDepartments={assignableDepartments}
          user={selectedUser}
          cancelEditUser={cancelEditUser}
          saveEditUser={saveEditUser}
          permissions={permissions}
          users={this.props.users}
          loadUserWithCopiedClaims={this.loadUserWithClaims}
        />
      );
    }
    return <UserList {...this.props} />;
  }
  private loadUserWithClaims = (userId: string, userToCopyId: string) => {
    const copyInfo: { userId: string; userToCopyId: string } = { userId, userToCopyId };
    this.props.loadUserWithCopiedClaims(copyInfo);
  };
}

export default withTranslation()(UsersComponent);

export interface IUsersComponentProps extends WithTranslation {
  isLoading: boolean;
  assignableTenants: ITenantRef[];
  allTenants: ITenantInfo[];
  assignableBranchesOfIndustry: IBranchOfIndustryRef[];
  assignableDepartments: IDepartmentRefWithTenantAndBranch[];
  users: IUserEditViewModel[];
  selectedUser: IUserEditViewModel;
  permissions: UserPermissions;
  loadUsers: any;
  selectUser: (id: string) => void;
  addAndSelectUser: (user: IUserAddViewModel) => void;
  cancelEditUser: any;
  saveEditUser: (user: IUserEditViewModel) => void;
  loadUserWithCopiedClaims: (copyInfo: { userId: string; userToCopyId: string }) => void;
}
