import _ from 'lodash';
import { UserPermissions } from './UserPermissions';
import {
  CanManageUsersInUI,
  CanSetUserActiveState,
  CanChangeAppAdministratorState,
  CanAssignUserToTenant,
  CanAssignUserToBranchOfIndustry,
  CanAssignUserToDepartment,
  CanChangeTenantAdministratorState,
  CanDeleteBranchesOfIndustry,
  CanEditSafeDocuments,
  AppAdministrator,
  AccessFromDepartmentSync,
} from './RiskAppClaims';
import { IBranchOfIndustryRef, IDepartmentRefWithTenantAndBranch } from './models';

export class SecurityPermissions {
  constructor(private permissions: UserPermissions) {}

  public get canManageUsers() {
    return this.hasAppClaim(CanManageUsersInUI) || this.hasBranchClaim(CanManageUsersInUI);
  }

  public get canSetUserActiveState() {
    return this.hasAppClaim(CanSetUserActiveState);
  }

  public get canChangeAppAdministratorState() {
    return this.hasAppClaim(CanChangeAppAdministratorState);
  }

  public get canCanChangeTenantAdministratorState() {
    return this.hasAppClaim(CanChangeTenantAdministratorState);
  }

  public get isAppAdministrator() {
    return this.hasAppClaim(AppAdministrator);
  }

  public get isAccessFromDepartmentSync() {
    return this.hasAppClaim(AccessFromDepartmentSync);
  }

  public canAssignUserToTenant(tenantId: string) {
    return this.permissions.hasAppClaim(CanAssignUserToTenant, tenantId);
  }

  public canEditTenant(tenantId: string, branchesOfIndustry: IBranchOfIndustryRef[], departments: IDepartmentRefWithTenantAndBranch[]) {
    return this.canAssignUserToTenant(tenantId) || _.some(branchesOfIndustry, (b) => b.tenantId === tenantId && this.canSeeBranchOfIndustry(b, departments));
  }

  public canAssignUserToBranchOfIndustry(tenantId: string, branchId: string) {
    return this.hasTenantClaim(CanAssignUserToBranchOfIndustry, branchId, tenantId);
  }

  public canDeleteBranchesOfIndustry(tenantId: string, branchId: string) {
    return this.hasBranchClaim(CanDeleteBranchesOfIndustry, '', tenantId, branchId);
  }

  public canAssignUserToDepartment(departmentRef: IDepartmentRefWithTenantAndBranch) {
    return this.hasBranchClaim(CanAssignUserToDepartment, departmentRef.id, departmentRef.tenantId, departmentRef.branchOfIndustryId);
  }

  public canChangeSafeDocumentsState() {
    return this.hasAppClaim(AppAdministrator);
  }

  public canSeeBranchOfIndustry(branchRef: IBranchOfIndustryRef, departments: IDepartmentRefWithTenantAndBranch[]) {
    return this.canAssignUserToBranchOfIndustry(branchRef.tenantId, branchRef.id) || _.some(departments, (d) => d.tenantId === branchRef.tenantId && this.canEditDepartment(d));
  }
  public canEditBranchOfIndustry(branchRef: IBranchOfIndustryRef) {
    return this.canAssignUserToBranchOfIndustry(branchRef.tenantId, branchRef.id);
  }

  public canEditDepartment(departmentRef: IDepartmentRefWithTenantAndBranch) {
    return this.canAssignUserToDepartment(departmentRef);
  }

  private hasBranchClaim(type: string, value: string = null, tenantId: string = null, branchId: string = null) {
    return this.permissions.hasBranchClaim(type, value, { tenantId: tenantId, branchOfIndustryId: branchId });
  }

  private hasTenantClaim(type: string, value: string, tenantId: string = null) {
    return this.permissions.hasTenantClaim(type, value, { tenantId: tenantId });
  }

  private hasAppClaim(type: string) {
    return this.permissions.hasAppClaim(type);
  }
}
