import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HtcCard } from '../../../shared/HtcCardComponent';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { IRiskAssessment, getDepartmentSummaries, IDepartmentEvaluationSummary, IDepartmentEvaluationStageTemplate, getDepartentEvaluationStageTemplates } from '../../../models/riskAssessment';
import classnames from 'classnames';
import _ from 'lodash';
import styles from './EvaluationSummaryOverview.module.css';
import { EvaluationSummaryStatus } from './EvaluationSummaryStatus';
import { UserInfoCtx } from '../../../UserInfoContext';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { getIndicatorClassForEvalSummary } from '../RiskColorCodes';

export const EvaluationSummaryOverview: FC<{ riskAssessment: IRiskAssessment; showRiskIndicator: boolean }> = ({ riskAssessment, showRiskIndicator }) => {
  const summaries = getDepartmentSummaries(riskAssessment);
  const departmentStages = _.sortBy(getDepartentEvaluationStageTemplates(riskAssessment), (d) => d.name) as IDepartmentEvaluationStageTemplate[];
  const mappedDepartmentStages = _.map(departmentStages, (d) => ({ assignedDepartment: d, summary: _.find(summaries, (s) => s.department.id === d.department.id) }));

  const [t] = useTranslation();

  return (
    <HtcCard>
      <FormBox title={t('components.EvaluationSummaryOverview.header')}>
        <div className={styles.table}>
          {_.map(mappedDepartmentStages, (s) => (
            <EvaluationSummaryRow key={s.assignedDepartment.id} summary={s.summary} department={s.assignedDepartment} showRiskIndicator={showRiskIndicator} />
          ))}
        </div>
      </FormBox>
    </HtcCard>
  );
};

const EvaluationSummaryRow: FC<{ summary: IDepartmentEvaluationSummary; department: IDepartmentEvaluationStageTemplate; showRiskIndicator: boolean }> = ({
  summary,
  department,
  showRiskIndicator,
}) => {
  const context = useContext(UserInfoCtx);
  return (
    <div className={styles.row}>
      {showRiskIndicator && (
        <div className={classnames(styles.cell, styles.cell_risk)}>{summary && <div className={classnames(styles.riskselector_indicator, getIndicatorClassForEvalSummary(summary.risk))} />}</div>
      )}
      <div className={classnames(styles.cell, styles.cell_status)}>
        <EvaluationSummaryStatus summary={summary} />
      </div>
      <div className={classnames(styles.cell, styles.cell_departmentName)}>
        <Content>{getForLanguage(department.name, context.languageSettings)}</Content>
      </div>
    </div>
  );
};

const Content: FC = (props) => {
  return <span className={styles.cellcontent}>{props.children}</span>;
};
