import * as _ from 'lodash';
import { IBranchOfIndustry, IBranchOfIndustryRef, IBranchOfIndustryCommand } from '../../models/masterdata';
import HttpClient from './httpClient';
import { queryMasterdataOptions } from './masterdataQueryHelper';
import { LanguageSettings } from '../../models/LanguageSettings';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';
import LRU from 'lru-cache';
import { UniqueValidation, IUniqueValidationErrorResult } from '../../models/IUniqueValidation';
import axios, { AxiosInstance } from 'axios';

interface IBranchOfIndustryResponse {
  branchesOfIndustry: IBranchOfIndustry[];
}

const cacheOptions = { maxAge: 1000 * 60 * 60 };
const cache = new LRU<string, Map<IMultiLanguageString, IBranchOfIndustryRef>>(cacheOptions);

const getAllBranchesOfIndustry = async (): Promise<IBranchOfIndustry[]> => {
  const result = await HttpClient.getInstance().get<IBranchOfIndustryResponse>(`branchesofindustry`);
  return getBranchOfIndustryFromResponse(result.data);
};

const getAllBranchesOfIndustryByTenantId = async (tenantId: string): Promise<IBranchOfIndustry[]> => {
  const result = await HttpClient.getInstance().get<IBranchOfIndustryResponse>(`branchesofindustry/${tenantId}`);
  return result.data.branchesOfIndustry;
};
const createBranchOfIndustry = async (item: IBranchOfIndustryCommand): Promise<IBranchOfIndustry> => {
  const result = await HttpClient.getInstance().post<IBranchOfIndustryResponse>(`branchesofindustry`, item);
  return getBranchOfIndustryFromResponse(result.data)[0];
};

const editBranchOfIndustry = async (item: IBranchOfIndustryCommand): Promise<IBranchOfIndustry> => {
  const result = await HttpClient.getInstance().put<IBranchOfIndustryResponse>(`branchesofindustry/${item.id}`, item);
  return getBranchOfIndustryFromResponse(result.data)[0];
};

const deleteBranchOfIndustry = async (item: IBranchOfIndustry): Promise<void> => {
  await HttpClient.getInstance().delete(`branchesofindustry/${item.id}`);
};

async function queryBranchOfIndustryOptions(query: string, settings: LanguageSettings, tenantId: string, branchId: string): Promise<IBranchOfIndustryRef[]> {
  return queryMasterdataOptions('branchesofindustry', query, settings, cache, tenantId, branchId);
}

const isUnique = async (branch: IBranchOfIndustry): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/branchesofindustry/isunique`, branch);

  return new UniqueValidation(result.data.validationErrors);
};

function getBranchOfIndustryFromResponse(response: IBranchOfIndustryResponse) {
  return _.map(response.branchesOfIndustry, (branchOfIndustry) => {
    return branchOfIndustry;
  });
}

export default {
  createBranchOfIndustry,
  getAllBranchesOfIndustry,
  getAllBranchesOfIndustryByTenantId,
  editBranchOfIndustry,
  deleteBranchOfIndustry,
  queryBranchOfIndustryOptions,
  isUnique,
};
