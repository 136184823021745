import React from 'react';
import { DocumentMetadataVersion, DocumentMetadataHistory } from '../../../models/documents/IDocumentMetaData';
import { Table } from '../Table/Table';
import formatter from '../../formatter';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './VersionHistoryList.module.css';
import { DownloadOutlined } from '@ant-design/icons';

export const VersionHistoryList: React.FC<IVersionHistoryListProps> = ({ downloadVersion, versionHistory, isCheckedOut }) => {
  const [t] = useTranslation();
  const getVersionInformation = (record: DocumentMetadataVersion) => {
    return `(${record.userName} | ${formatter.formatDateWithTimeString(record.timestamp.toString())})`;
  };

  const createColumns = () => {
    return [
      {
        title: t('commonActions.documentVersionHistory'),
        dataIndex: 'status',
        key: 'status',
        width: 10,
        render: (text: string, record: DocumentMetadataVersion, index: number) => {
          return (
            <>
              <div className={index === 0 ? styles.currentVersion : styles.historyVersion}>
                <div>Version {formatter.formatDocumentVersion(record.version)} </div>
                <div>
                  {getVersionInformation(record)}
                  <Button id="downloadFile" style={{ marginLeft: '10px' }} type="primary" shape="circle" size="small" icon={<DownloadOutlined />} onClick={() => downloadVersion(record.version)} />
                </div>
              </div>
            </>
          );
        },
      },
    ];
  };
  const history = !isCheckedOut ? versionHistory.versions : versionHistory.versions && versionHistory.versions.slice(1);

  return <Table rowKey="version" columns={createColumns()} dataSource={history} pagination={false} />;
};

interface IVersionHistoryListProps {
  versionHistory: DocumentMetadataHistory;
  downloadVersion: (version: any) => void;
  isCheckedOut: boolean;
}
