import moment from 'moment';

export interface IRiskComment {
  user: string;
  content: string;
  created: string;
  isNew?: boolean;
}

export function createRiskComment(options: Partial<IRiskComment> = {}): IRiskComment {
  return Object.assign({ user: '', content: '', created: moment().toISOString(), isNew: true }, options);
}
