import moment from 'moment';
import _ from 'lodash';

function formatDateString(isoDate: string) {
  return isoDate ? moment(isoDate).format('DD.MM.YYYY') : null;
}

function formatDateWithTimeString(isoDate: string) {
  return isoDate ? moment(isoDate).format('DD.MM.YYYY HH:mm') : null;
}

function formatDateWithTimeIsoString(isoDate: string) {
  return isoDate ? moment(isoDate).format('YYYY-MM-DD HH:mm') : null;
}

function formatDocumentVersion(version: string): string {
  const splitted = _.split(version, '.', 3);
  if (splitted.length <= 1) {
    return '0.0';
  }

  splitted[0] = (parseInt(splitted[0], 10) - 1).toString();
  return _.join(splitted, '.');
}

function isNotEmpty(text: string): boolean {
  if (!text) {
    return false;
  }

  return !!text.trim();
}

export default {
  formatDateString,
  formatDateWithTimeString,
  formatDateWithTimeIsoString,
  isNotEmpty,
  formatDocumentVersion,
};
