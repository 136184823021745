import React, { useContext, FC } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { ICustomer, NewCustomerId } from '../../models/masterdata';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';

import { SearchBox, FormBox } from '../../shared/components/FormBox/FormBox';
import { NewAction } from '../../shared/components/CommandBar/CommandBar';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { getForLanguage } from '../../models/IMultiLanguageString';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import { Table } from '../../shared/components/Table/Table';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { userService } from '../../shared/services/userService';
import { EditOutlined } from '@ant-design/icons';

const navPaths = ['navigation.masterdata', 'navigation.customers'];
export const CustomerList: FC<ICustomerListProps> = ({ loading, customers, setSearchText, searchText }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);

  const create = React.useCallback(() => history.push('/customers/' + NewCustomerId), [history]);
  const edit = React.useCallback((id: string) => history.push('/customers/' + id), [history]);
  const columns = React.useMemo(() => createColumns(t, ctx, edit), [ctx, edit, t]);
  const onSearch = (e: any) => setSearchText(e.target.value);

  return (
    <ContentWrapper>
      <MasterdataHeader>
        <CommandBarV2 paths={navPaths}>
          <NewAction text="masterdata.customers.newCustomer" onClick={create} />
        </CommandBarV2>
      </MasterdataHeader>
      <HtcCardWrapper>
        <HtcCard>
          <SearchBox>
            <Input placeholder={t('masterdata.customers.searchText')} allowClear onChange={onSearch} value={searchText} />
          </SearchBox>
        </HtcCard>
        <HtcCard>
          <FormBox title={t('common.results')} flex contentflex>
            <Table rowKey="id" columns={columns} dataSource={customers} loading={loading} />
          </FormBox>
        </HtcCard>
      </HtcCardWrapper>
    </ContentWrapper>
  );
};

function createColumns(t: TFunction, ctx: IUserInfoCtx, onEdit: (id: string) => void) {
  const hasDescription = userService.hasCustomerDescription();

  const result = [];
  result.push({
    title: t('masterdata.customers.debitorNumber'),
    dataIndex: 'debitorNumber',
    key: 'debitorNumber',
    width: 250,
  });

  if (hasDescription) {
    result.push({
      title: t('masterdata.customers.description'),
      dataIndex: 'description',
      key: 'description',
      width: 250,
      render: (text: string, record: ICustomer) => getForLanguage(record.description, ctx.languageSettings),
    });
  }

  result.push({
    title: t('masterdata.customers.debitorName'),
    dataIndex: 'debitorName',
    key: 'debitorName',
    width: 250,
    render: (text: string, record: ICustomer) => getForLanguage(record.debitorName, ctx.languageSettings),
  });
  result.push({
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: ICustomer) => <Button id="editCustomer" type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={() => onEdit(record.id)} />,
    width: 100,
  });

  return result;
}

interface ICustomerListProps {
  customers: ICustomer[];
  loading: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
}
