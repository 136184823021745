import { Avatar, Card } from 'antd';
import { Meta } from 'antd/lib/list/Item';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { IUserTenantInfo } from '../../../models/masterdata/ITenant';
import './TenantSelection.css';
import styles from './TenantSelection.module.css';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ITenantSelectionProps extends WithTranslation {
  availableTenants: IUserTenantInfo[];
  selectedTenantId?: string;
  onSelectedTenantChange?: (tenant: IUserTenantInfo) => void;
}

const TenantSelection: React.FC<ITenantSelectionProps> = ({ availableTenants, selectedTenantId, onSelectedTenantChange, t }) => {
  const handleTenantClick = (tenant: IUserTenantInfo) => {
    if (onSelectedTenantChange) {
      onSelectedTenantChange(tenant);
    }
  };

  return (
    <div className={styles.flexContainer}>
      <div className={styles.headline}>
        <h2>{t('tenantSelection.title')}</h2>
      </div>
      <div className={styles.flexRow}>
        {availableTenants.map((val, idx, values) => (
          <Card
            key={val.name + val.abbr + 'tenantselection' + idx}
            hoverable
            className={classnames(styles.card, val.id == selectedTenantId ? styles.selectedCard : '')}
            cover={<img alt="logo" src={process.env.PUBLIC_URL + 'assets/' + (val.logo || 'logosmall.png')} className={styles.cardImage} />}
            onClick={() => handleTenantClick(val)}
          >
            <Meta avatar={<Avatar>{val.abbr}</Avatar>} title={val.name} />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default withTranslation()(TenantSelection);
