import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { IRiskAssessmentStatusMonitorViewModel, createRiskAssessmentStatusMonitorViewModel, IRiskAssessmentListViewModels } from '../../models/riskAssessment';
import * as actions from './actions';

export const NewRiskAssessmentId = 'new';

export interface IRiskAssessmentState {
  riskAssessmentListView: IRiskAssessmentListViewModels;
  riskAssessmentStatusMonitorView: IRiskAssessmentStatusMonitorViewModel;
  isLoading: boolean;
}

export type RiskAssessmentsAction = ActionType<typeof actions>;

export const riskAssessmentReducer = combineReducers<IRiskAssessmentState, RiskAssessmentsAction>({
  riskAssessmentListView: (state: IRiskAssessmentListViewModels = { totalCount: 0, riskAssessments: [] }, action: RiskAssessmentsAction) => {
    switch (action.type) {
      case getType(actions.getRiskAssessmentList.success):
        return action.payload;
      default:
        return state;
    }
  },
  riskAssessmentStatusMonitorView: (state: IRiskAssessmentStatusMonitorViewModel = createRiskAssessmentStatusMonitorViewModel(), action: RiskAssessmentsAction) => {
    switch (action.type) {
      case getType(actions.getRiskAssessmentStatusMonitor.success):
        return action.payload;
      default:
        return state;
    }
  },
  isLoading: (state: boolean = false, action: RiskAssessmentsAction) => {
    switch (action.type) {
      case getType(actions.getRiskAssessmentList.request):
      case getType(actions.createRiskAssessment.request):
      case getType(actions.getRiskAssessmentStatusMonitor.request):
        return true;
      case getType(actions.getRiskAssessmentList.failure):
      case getType(actions.createRiskAssessment.failure):
      case getType(actions.getRiskAssessmentStatusMonitor.failure):
      case getType(actions.getRiskAssessmentList.success):
      case getType(actions.createRiskAssessment.success):
      case getType(actions.getRiskAssessmentStatusMonitor.success):
        return false;
      default:
        return state;
    }
  },
});
