import React, { createElement } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import styles from './Exception.module.css';
import getExceptionPageConfig from './typeConfig';

interface IExceptionProps {
  type: '403' | '404' | '500';
  className?: string;
  backText?: string;
  linkElement?: string;
  title?: string;
  desc?: string;
  img?: string;
  actions?: React.ReactElement;
  redirect?: string;
}

class Exception extends React.PureComponent<IExceptionProps, {}> {
  static defaultProps = {
    backText: 'back to home',
    redirect: '/',
  };

  constructor(props: IExceptionProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, backText, linkElement = 'a', type, title, desc, img, actions, redirect, ...rest } = this.props;
    const pageConfig = getExceptionPageConfig(type);
    const clsString = classNames(styles.exception, className);
    return (
      <div className={clsString} {...rest}>
        <div className={styles.imgBlock}>
          <div className={styles.imgEle} style={{ backgroundImage: img != null ? `url(${img})` : pageConfig.img }} />
        </div>
        <div className={styles.content}>
          <h1>{title || pageConfig.title}</h1>
          <div className={styles.desc}>{desc || pageConfig.desc}</div>
          <div className={styles.actions}>
            {actions ||
              createElement(
                linkElement,
                {
                  to: redirect,
                  href: redirect,
                },
                <Button type="primary">{backText}</Button>,
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default Exception;
