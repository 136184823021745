import React from 'react';
import styles from './FormMultiCardSelect.module.css';
import { List, Checkbox, Form, Button, Divider } from 'antd';
import { FormikProps, getIn, FieldArray } from 'formik';
import _ from 'lodash';
import { FormCheckboxInput } from '../../FormComponents';
import { SortableCard } from '../SortableCard/SortableCard';
import { HtcCard } from '../../HtcCardComponent';
import { getForLanguage, IMultiLanguageString } from '../../../models/IMultiLanguageString';
import { LanguageSettings } from '../../../models/LanguageSettings';
import { CloseOutlined, DoubleRightOutlined } from '@ant-design/icons';

export class FormMultiCardSelect<T> extends React.Component<FormikProps<any> & any, IMultiCardSelectState> {
  constructor(props: any) {
    super(props);
    this.state = {
      size: props.pageSize ? props.pageSize : 10,
      selectedItems: [],
      checkedList: [],
      availableItems: calculateAvailableItems(props.values, props.name, props.dataSource),
      page: 1,
      isSortable: false,
    };
  }

  static getDerivedStateFromProps(props: any, state: IMultiCardSelectState) {
    return {
      availableItems: calculateAvailableItems(props.values, props.name, props.dataSource),
    };
  }

  render() {
    const { values, errors, name, config, label, isSortable, titleProperty, dividerStyle, settings, dataSource, formik } = this.props;
    const checked = this.state.checkedList;
    const validationErrors = _.filter(getIn(errors, name), (e) => e != undefined);
    return (
      <div className={styles.Container}>
        <List
          key={`${name}_List`}
          itemLayout="vertical"
          dataSource={this.state.availableItems}
          className={styles.List}
          renderItem={(item: any, index: any) => (
            <List.Item key={this.getName(item.name, settings)} className={styles.rowContainer}>
              <Checkbox.Group value={checked} className={styles.rowCheckbox}>
                <Checkbox value={index} onChange={this.toggleSelect}>
                  <div className={styles.rowContent}>{this.getName(item.name, settings)}</div>
                </Checkbox>
              </Checkbox.Group>
            </List.Item>
          )}
        ></List>
        <Form.Item
          key={`${name}_Form`}
          label={label}
          style={{ width: '60%' }}
          hasFeedback={!!getIn(errors, name)}
          validateStatus={validationErrors && validationErrors.length > 0 && 'error'}
          help={getIn(errors, name)}
        >
          <FieldArray
            name={name}
            render={(arrayHelpers) => (
              <div key={name} style={{ display: 'flex' }}>
                <div className={styles.actionButton}>
                  <Button style={{ margin: 'auto' }} icon={<DoubleRightOutlined />} onClick={() => this.add(arrayHelpers)} />
                </div>
                <div className={styles.Form}>
                  {getIn(values, name) &&
                    getIn(values, name).map((item: any, index: any) => {
                      if (isSortable) {
                        return (
                          <SortableCard
                            key={index}
                            index={`cardEntry_${index}`}
                            onRemove={() => this.remove(arrayHelpers, index)}
                            onSortUp={() => arrayHelpers.swap(index, index - 1)}
                            onSortDown={() => arrayHelpers.swap(index, index + 1)}
                            isFirst={index != 0}
                            isLast={index + 1 != getIn(values, name).length}
                            title={`${index + 1} - ${this.getName(getIn(item, titleProperty), settings)}`}
                            dividerStyle={dividerStyle}
                            cardStyle={{ minHeight: 60 }}
                            hasShadow={true}
                          >
                            <div className={styles.rowContent}>{config.map((c: any) => this.getRenderedProperty(c, index, item, formik, settings))}</div>
                          </SortableCard>
                        );
                      } else {
                        return (
                          <HtcCard key={index}>
                            <div className={styles.rowContent}>
                              <Divider className={dividerStyle} orientation="left">
                                {this.getName(getIn(item, titleProperty), settings)}
                              </Divider>
                              <div className={styles.actionContainer}>
                                <CloseOutlined className={styles.arrowDelete} onClick={() => this.remove(arrayHelpers, index)} />
                              </div>
                              {config.map((c: any) => this.getRenderedProperty(c, index, item, formik, settings))}
                            </div>
                          </HtcCard>
                        );
                      }
                    })}
                </div>
              </div>
            )}
          />
        </Form.Item>
      </div>
    );
  }

  private getRenderedProperty(itemConfig: any, index: number, item: any, formik: any, settings: LanguageSettings) {
    const property = itemConfig['dataIndex'];
    const propertyValue = item[property];
    switch (itemConfig.type) {
      case 'Checkbox':
        return (
          <FormCheckboxInput
            styles={{ width: '100%' }}
            {...formik}
            key={`${this.props.name}.[${index}].${property}`}
            formClassName={styles.noMarginBottom}
            labelCol={{ span: 5 }}
            name={`${this.getName(this.props.name, settings)}.[${index}].${property}`}
            label={itemConfig['label']}
          />
        );
    }
  }

  private getName = (name: string | IMultiLanguageString, settings: LanguageSettings): string => {
    if (typeof name == 'string') {
      return name;
    }
    return getForLanguage(name as IMultiLanguageString, settings);
  };

  private toggleSelect = (event: any) => {
    const itemCopy = _.cloneDeep(this.state.selectedItems);
    const checkedList = _.cloneDeep(this.state.checkedList);

    const pageIndex = (this.state.page - 1) * this.state.size + event.target.value;
    if (event.target.checked) {
      const item = this.state.availableItems[pageIndex];
      itemCopy.push(item);
      checkedList.push(pageIndex);
    } else {
      const itemToDelete = this.state.availableItems[pageIndex];
      const index = itemCopy.map((e) => e.id).indexOf(itemToDelete.id);
      itemCopy.splice(index, 1);

      checkedList.splice(checkedList.indexOf(pageIndex), 1);
    }

    this.setState({ selectedItems: itemCopy, checkedList: checkedList });
  };

  private add = (arrayHelpers: any) => {
    const itemCopy = _.cloneDeep(this.state.availableItems);

    this.state.selectedItems.map((item: any) => {
      arrayHelpers.push(item);
      const index = itemCopy.map((e) => e.id).indexOf(item.id);
      itemCopy.splice(index, 1);
    });
    this.setState({ availableItems: itemCopy, selectedItems: [], checkedList: [] });
  };

  private remove = (arrayHelpers: any, index: number) => {
    const selected = getIn(this.props.values, this.props.name);
    const itemCopy = _.cloneDeep(this.state.availableItems);
    itemCopy.push(selected[index]);

    arrayHelpers.remove(index);
    this.setState({ availableItems: itemCopy, selectedItems: [], checkedList: [] });
  };
}

function calculateAvailableItems(values: any, name: any, dataSource: any) {
  const items: any = [];
  const selected = getIn(values, name);
  _.map(dataSource, (item) => {
    if (!selected || !selected.some((element: any) => element.id == item.id)) {
      items.push(item);
    }
  });
  return items;
}

interface IMultiCardSelectState {
  selectedItems: any[];
  checkedList: any[];
  availableItems: any[];
  page: number;
  size: number;
  isSortable: boolean;
}
