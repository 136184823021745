import { Button, Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { Fragment, PureComponent } from 'react';
import * as yup from 'yup';
import { FormTextInput, FormSelectInput, FormAzureUserPicker } from '../shared/FormComponents';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IAzureUser, IAzureUserRef } from '../models/user';

class UserAddForm extends PureComponent<IUserAddFormProps, {}> {
  constructor(props: IUserAddFormProps) {
    super(props);
  }
  public render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Formik enableReinitialize={true} initialValues={this.createEmpty()} onSubmit={this.handleSave}>
          {(formik) => {
            const { submitForm, isSubmitting } = formik;
            return (
              <Form layout="vertical">
                <FormAzureUserPicker {...formik} name="user" label={t('users.addUser.user')} />
                <Form.Item>
                  <Button disabled={isSubmitting} type="primary" onClick={submitForm}>
                    {t('users.addUser.saveButton')}
                  </Button>
                </Form.Item>
              </Form>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
  private handleSave = (item: IUserAddEntry, actions: FormikHelpers<IUserAddEntry>) => {
    const { t } = this.props;
    if (!item || !item.user) {
      actions.setSubmitting(false);
      actions.setErrors(t('users.addUser.userIsRequired'));
    } else {
      try {
        this.props.onSave(item.user.user);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    }
  };
  private createEmpty = () => {
    return {
      user: null,
    } as IUserAddEntry;
  };
}

export default withTranslation()(UserAddForm);

interface IUserAddFormProps extends WithTranslation {
  onSave: (item: IAzureUser) => void;
}

interface IUserAddEntry {
  user: IAzureUserRef;
}
