import React, { SFC, CSSProperties } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import styles from './HtcCardComponent.module.css';
export const HtcCardWrapper: SFC<{ style?: CSSProperties; noPadding?: boolean }> = ({ children, style, noPadding }) => {
  if (noPadding) {
    style = _.assign({ padding: 0 }, style);
  }

  return (
    <div style={style} className={classnames(styles.contentWrapper)}>
      {children}
    </div>
  );
};

export const HtcCard: SFC<{
  style?: CSSProperties;
  flex?: boolean;
  flexRow?: boolean;
  noPadding?: boolean;
  selectable?: boolean;
  isSelected?: boolean;
}> = React.memo(({ style, children, flex, flexRow, noPadding, selectable, isSelected }) => {
  if (flex) {
    style = _.assign({ flex: '1 1 auto' }, style);
  }

  if (flexRow) {
    style = _.assign({ flexFlow: 'row nowrap' }, style);
  }

  if (noPadding) {
    style = _.assign({ padding: 0 }, style);
  }
  return (
    <div style={style} id="htcCard" className={classnames(styles.card, selectable && styles.selectable, isSelected && styles.isselected)}>
      {children}
    </div>
  );
});

HtcCard.displayName = 'HtcCard';
