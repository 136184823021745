import { IUserClaim } from './models';
import { UserInfo, getActiveTenantIdFromSettings, getActiveBranchOfIndustryIdFromSettings } from './UserInfo';
import _ from 'lodash';
import { Issuer } from './RiskAppClaims';
import { MetadataPermissions } from './MetadataPermissions';
import { SecurityPermissions } from './SecurityPermissions';
import { RiskAssessmentPermissions } from './RiskAssessmentPermissions';

export interface IGetClaimOptions {
  tenantId?: string;
  branchOfIndustryId?: string;
}
export class UserPermissions {
  public readonly metadata: MetadataPermissions;
  public readonly security: SecurityPermissions;
  public readonly riskAssessment: RiskAssessmentPermissions;

  private constructor(private claims: IUserClaim[], private tenantId: string, private branchOfIndustryId: string) {
    if (!claims) {
      this.claims = [];
    }
    this.metadata = new MetadataPermissions(this);
    this.security = new SecurityPermissions(this);
    this.riskAssessment = new RiskAssessmentPermissions(this);
  }

  public hasAppClaim(type: string, value: string = null): boolean {
    if (value) {
      return _.some(this.claims, (c) => c.issuer === Issuer && c.type === type && c.value === value);
    }

    return _.some(this.claims, (c) => c.issuer === Issuer && c.type === type);
  }

  public hasBranchClaim(type: string, value: string = null, options: IGetClaimOptions = {}): boolean {
    const tenantId = options.tenantId || this.tenantId;
    const branchOfIndustryId = options.branchOfIndustryId || this.branchOfIndustryId;
    if (!tenantId || !branchOfIndustryId) {
      return false;
    }

    const issuer = this.getBranchIssuer(tenantId, branchOfIndustryId);
    return _.some(this.claims, (c) => c.issuer === issuer && c.type === type && (value == null || value === c.value));
  }

  public hasTenantClaim(type: string, value: string = null, options: IGetClaimOptions = {}) {
    const tenantId = options.tenantId || this.tenantId;
    if (!tenantId) {
      return false;
    }

    const issuer = this.getTenantIssuer(tenantId);
    return _.some(this.claims, (c) => c.issuer === issuer && c.type === type && (value == null || value === c.value));
  }

  private getBranchIssuer(tenantId: string, branchOfIndustryId: string): string {
    return `${Issuer}/${tenantId}/${branchOfIndustryId}`;
  }

  private getTenantIssuer(tenantId: string): string {
    return `${Issuer}/${tenantId}`;
  }

  public static from(claims: IUserClaim[], tenantId: string, branchOfIndustryId: string) {
    return new UserPermissions(claims, tenantId, branchOfIndustryId);
  }

  public static fromUserInfo(userInfo: UserInfo): UserPermissions {
    const activeTenantId = getActiveTenantIdFromSettings(userInfo && userInfo.userSetting);
    const activeBranchId = getActiveBranchOfIndustryIdFromSettings(userInfo && userInfo.userSetting);
    return new UserPermissions(userInfo && userInfo.claims, activeTenantId, activeBranchId);
  }

  public static empty(): UserPermissions {
    return new UserPermissions([], '', '');
  }
}
