import React, { useState, useCallback } from 'react';
import { Upload, Button } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { IDocumentMetadata, createDocumentMetaData } from '../../../models/documents/IDocumentMetaData';
import { FormTextAreaInput, FormRadioYesNo } from '../../FormComponents';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FileUploadBox } from './FileUploadBox';
import { VersionHistoryList } from './VersionHistoryList';
import Panel from '../Panel/Panel';
import Form from 'antd/lib/form/Form';
import { SaveOutlined } from '@ant-design/icons';

const FileEditPanel: React.FC<IFileEditPanelProps> = ({ isVisible, onSave, documentMetadata, disabled, downloadVersion, onClose }) => {
  const [t] = useTranslation();
  const [file, setFile] = useState(null);

  const getInitData = useCallback(() => _.cloneDeep(documentMetadata), [documentMetadata]);

  const canSave = (isSubmitting: boolean): boolean => {
    return !isSubmitting && (file != null || !!documentMetadata.id);
  };

  const ensureFileName = (fileName: string) => (!fileName && file ? (file.file.name as string) : fileName);

  const handleSave = async (metadata: IDocumentMetadata, actions: FormikHelpers<IDocumentMetadata>) => {
    metadata.fileName = ensureFileName(metadata.fileName);
    onSave(metadata, file);
    actions.setSubmitting(false);
  };

  return (
    <Panel label={t('components.DocumentList.uploadDocument')} drawerVisible={isVisible} onClose={onClose}>
      <Formik enableReinitialize={true} initialValues={getInitData()} onSubmit={handleSave}>
        {(formik) => {
          const { isSubmitting, submitForm } = formik;
          return (
            <Form layout="vertical">
              <FileUploadBox
                existingFileName={documentMetadata.fileName}
                disabled={disabled}
                setFile={setFile}
                uploadHint={t('commonActions.uploadDocumentHint')}
                uploadDesc={t('commonActions.uploadDocumentDesc')}
              />
              <div style={{ marginTop: 25 }}>{file ? file.file.name : documentMetadata.fileName}</div>
              <FormTextAreaInput disabled={disabled} {...formik} name="comment" label={t('riskAssessmentDocument.comment')} />
              <FormRadioYesNo {...formik} disabled={disabled} name={'isParaphrased'} label={t('riskAssessmentDocument.paraphrased')} />
              <Button id="saveAction" icon={<SaveOutlined />} style={{ marginBottom: 25, marginTop: 25 }} onClick={submitForm} disabled={!canSave(isSubmitting) || disabled} type="primary">
                {t('commonActions.save')}
              </Button>
              <VersionHistoryList downloadVersion={downloadVersion} versionHistory={documentMetadata.history} isCheckedOut={documentMetadata.isCheckedOut} />
            </Form>
          );
        }}
      </Formik>
    </Panel>
  );
};

export default FileEditPanel;

interface IFileEditPanelProps {
  disabled: boolean;
  isVisible: boolean;
  documentMetadata: IDocumentMetadata;
  onSave: (metadata: IDocumentMetadata, file: any) => void;
  downloadVersion: (version: any) => void;
  onClose: () => void;
}
