import React, { FC, useContext } from 'react';
import { FormikProps } from 'formik';
import { IRiskAssessment, IRiskAssessmentEditVm, IRiskAssessmentOnSaveCallback } from '../../../models/riskAssessment';
import { HtcCard } from '../../../shared/HtcCardComponent';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { EvaluationSummaryList } from './EvaluationSummaryList';
import styles from './EvaluationSummary.module.css';
import { PermissionCtx } from '../RiskAssessmentContext';
import PermissionCheck from '../../../shared/components/PermissionCheck/PermissionCheck';
import { TFunction } from 'i18next';
import { UserInfoCtx } from '../../../UserInfoContext';
import { LanguageSettings } from '../../../models/LanguageSettings';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { GenerateReport } from '../../../shared/components/CommandBar/CommandBar';
import { IGenerateReportFunc, EvaluationSummaryReport } from '../reporting/model';
import { AssessmentPageHeader } from '../assessment/PageHeader/PageHeader';
import { AssessmentPageContent } from '../assessment/PageContent/PageContent';
import { ApprovalFlows } from '../approval/ApprovalFlows';
import { Tabs } from 'antd';
import { useTenantBranch } from '../../../TenantBranchContext';

const TP = 'riskAssessment.editform.overview.summary.';
const TabPane = Tabs.TabPane;

const EvaluationSummary: FC<IEvaluationSummaryProps> = ({ formik, onSave, riskAssessmentVm, generateReport }) => {
  let context = useContext(PermissionCtx);
  let { tenantId, branchId } = useTenantBranch();
  let [t] = useTranslation();

  let showApprovalActions = riskAssessmentVm.commonConfig.hasApprovalActionsOnEvaluationPage;
  return (
    <PermissionCheck hasAccess={riskAssessmentVm.permissions.canReadEvaluation}>
      <>
        <AssessmentPageHeader current="evaluationsummary">
          {context.permissionHelper.canPrintRiskAnalysisReport() && (
            <GenerateReport text="riskAssessment.createEvaluationSummary" onClick={() => generateReport(new EvaluationSummaryReport(formik.values), formik.values, tenantId, branchId)} />
          )}
        </AssessmentPageHeader>
        <AssessmentPageContent>
          <div className={styles.evaluationsummarycontainer}>
            <HtcCard flex>
              <EvaluationSummaryList formik={formik} riskAssessmentEditVm={riskAssessmentVm} />
            </HtcCard>
          </div>
          {showApprovalActions && (
            <div className={styles.infocontainer}>
              <div className={styles.infoitem} style={{ marginLeft: '0.25rem' }}>
                <HtcCard>
                  <Tabs type="card" animated={false}>
                    <TabPane tab={t('components.ApprovalActions.approvalHeader')} key="1">
                      <ApprovalFlows riskAssessmentVm={riskAssessmentVm} assessment={formik.values} onSave={onSave} config={riskAssessmentVm.commonConfig} />
                    </TabPane>
                  </Tabs>
                </HtcCard>
              </div>
              <div className={styles.infoitem}></div>
            </div>
          )}
        </AssessmentPageContent>
      </>
    </PermissionCheck>
  );
};
export default EvaluationSummary;

function getWarnings(assessment: IRiskAssessment, t: TFunction, languageSettings: LanguageSettings): string[] {
  const result = [];

  _.forEach(assessment.assessmentRequiredDepartmentsWithoutCompletion, (department) =>
    result.push(t(TP + 'requiredDepartmentAssessmentMissing', { departmentName: getForLanguage(department.name, languageSettings) })),
  );

  return result;
}

interface IEvaluationSummaryProps {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
  onSave: IRiskAssessmentOnSaveCallback;
  generateReport: IGenerateReportFunc;
}
