import { connect } from 'react-redux';
import { IRootState, getAllTenants, getUserPermissions, getActiveTenant } from '../ducks/reducer';
import UsersComponent from './UsersComponent';
import * as actions from './ducks';
import { getSelectedUser } from './ducks/reducer';

const mapStateToProps = (state: IRootState) => {
  return {
    user: state.app.user,
    users: state.user.users,
    selectedUser: getSelectedUser(state.user),
    isLoading: state.user.isLoading,
    assignableTenants: state.user.assignableTenants,
    assignableBranchesOfIndustry: state.user.assignableBranchesOfIndustry,
    assignableDepartments: state.user.assignableDepartments,
    allTenants: getAllTenants(state.app),
    permissions: getUserPermissions(state.app),
  };
};
export default connect(mapStateToProps, {
  loadUsers: actions.getAllUsersForEdit.request,
  selectUser: actions.selectUser,
  addAndSelectUser: actions.addAndSelectNewUser.request,
  cancelEditUser: actions.cancelEditUser,
  saveEditUser: actions.saveEditUser.request,
  loadUserWithCopiedClaims: actions.loadUserWithCopiedClaims.request,
})(UsersComponent);
