import React, { PureComponent, CSSProperties } from 'react';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Select } from 'antd';
import _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

const Option = Select.Option;

export interface IsRef {
  id: string;
  name: any;
}

abstract class PickerBase<P extends IsRef, T> extends PureComponent<IPickerBaseProps<P> & T, IPickerBaseState<P>> {
  private onSearch$: Subject<string>;
  private subscription: Subscription;

  state: IPickerBaseState<P> = {
    data: [],
    value: {} as P | P[],
    isLoading: false,
  };

  constructor(props: any) {
    super(props);
    this.getOptions = _.memoize(this.getOptions);
  }

  async componentDidMount() {
    this.onSearch$ = new Subject<string>();
    const allOptions = await this.queryItems('');
    this.setState({ data: allOptions });
    let singleDefaultValue = null;
    if (allOptions && allOptions.length === 1 && !this.state.value && this.props.defaultIfSingle) {
      singleDefaultValue = allOptions[0];
      this.handleChange(singleDefaultValue.id);
    }

    this.subscription = this.onSearch$
      .pipe(
        debounceTime(500),
        switchMap((query) => this.queryItems(query)),
      )
      .subscribe((options) => this.setState({ data: options }));

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  abstract onQueryItems(query: string): Promise<P[]>;
  abstract getDisplayName(name: any): string;
  customRender(name: string, additionalInformation?: IsRef): string {
    return name;
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  render() {
    const options = this.getOptions(this.state.data);
    let display = this.customRender(this.getDisplayName((this.state.value as P)?.name), this.state.value as P);

    return (
      <Select
        allowClear={this.props.allowClear}
        showSearch
        labelInValue
        value={
          this.state.value &&
          (this.props.mode === undefined
            ? (this.state.value as P)?.id && {
                value: (this.state.value as P)?.id,
                label: this.getStyledDisplayName(display),
              }
            : Array.isArray(this.state.value)
            ? (this.state.value as P[])?.map((v) => v.id && { value: v.id, label: this.getDisplayName(v.name) })
            : null)
        }
        placeholder={this.props.placeholder}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={true}
        suffixIcon={this.state.isLoading === true ? null : <SearchOutlined />}
        optionFilterProp="children"
        onSearch={this.handleSearch}
        filterOption={(input, option) => {
          return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={this.handleChange}
        notFoundContent={null}
        onBlur={this.props.handleBlur}
        loading={this.state.isLoading}
        disabled={this.props.disabled}
        optionLabelProp="title"
        showAction={['focus', 'click']}
        data-automation-id={this.props.dataAutomationId || this.props.name}
        mode={this.props.mode}
      >
        {options}
      </Select>
    );
  }

  queryItems = async (query: string): Promise<P[]> => {
    this.setState({ isLoading: true });
    const items = await this.onQueryItems(query);
    this.setState({ isLoading: false });
    return items;
  };

  preLastWordEllipsisStyle: React.CSSProperties = {
    display: 'inline-block',
    maxWidth: 'calc(100% - 65px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'bottom',
  };

  lastWordStyle: React.CSSProperties = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    verticalAlign: 'bottom',
  };

  getStyledDisplayName = (name: any) => {
    const displayName = this.getDisplayName(name);
    const { preLastText, lastWord } = this.applyPreLastWordEllipsis(displayName);

    return (
      <>
        <span style={this.preLastWordEllipsisStyle}>{preLastText}</span>
        {lastWord ? (
          <>
            <span> </span>
            <span style={this.lastWordStyle}>({lastWord})</span>{' '}
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  getOptions = (data: P[]) => {
    data = data || [];
    if (!data || data.length === 0) {
      return null;
    }
    this.ensureSelectedValueIsOption(data);
    return data.map((d) => {
      if (d !== undefined) {
        let displayName = this.customRender(this.getDisplayName(d.name), d);
        const { preLastText, lastWord } = this.applyPreLastWordEllipsis(displayName);

        return (
          <Option value={d.id} key={d.id} label={this.getStyledDisplayName(displayName)} title={displayName} data-automation-id={(this.props.dataAutomationId || this.props.name) + '-option-' + d.id}>
            <span className="ant-select-item-option-content" title={displayName}>
              {lastWord ? this.getStyledDisplayName(displayName) : <span>{displayName}</span>}
            </span>
          </Option>
        );
      }
    });
  };

  applyPreLastWordEllipsis(text: string) {
    const words = text.split(' ');

    const lastWordIsNumber = /^\d+$/.test(words[words.length - 1]);

    if (words.length > 1 && lastWordIsNumber) {
      const lastWord = words.pop();
      const preLastText = words.join(' ');
      return { preLastText, lastWord };
    } else {
      return { preLastText: text, lastWord: '' };
    }
  }

  ensureSelectedValueIsOption = (data: P[]) => {
    if (this.props.mode === undefined) {
      const selectedValue = this.props.value as P;
      if (_.isEmpty(selectedValue) || !selectedValue || _.some(this.state.data, (d) => d != undefined && d.id === selectedValue.id)) {
        return;
      }
      data.unshift(selectedValue);
    } else {
      if (Array.isArray(this.props.value)) {
        (this.props.value as P[])?.forEach((selectedValue) => {
          if (_.isEmpty(selectedValue) || !selectedValue || _.some(this.state.data, (d) => d != undefined && d.id === selectedValue.id)) {
            return;
          }
          data.unshift(selectedValue);
        });
      } else {
        return;
      }
      return;
    }
  };

  addAndSelectOption(data: P | P[]) {
    let newData = [] as P[];
    if (this.props.mode === undefined) {
      newData = [...this.state.data, data as P];
    } else {
      newData = [...this.state.data, ...(data as P[])];
    }
    this.setState({ value: data, data: newData });
    this.props.onChange(data);
  }

  // Wurde durch filterOption ersetzt
  handleSearch = (text: string) => {
    this.onSearch$.next(text);
  };

  handleChange = (selectedOption: { value: string; label: React.ReactNode } | { value: string; label: React.ReactNode }[]) => {
    if (this.props.mode == undefined) {
      const newValue = _.find(this.state.data, (d) => d.id === (selectedOption as { value: string; label: React.ReactNode }).value);
      this.setState({ value: newValue as P });
      this.props.onChange(newValue as P);
    } else {
      const newValue = (selectedOption as { value: string; label: React.ReactNode }[]).map((v) => _.find(this.state.data, (d) => d.id === v.value));
      this.setState({ value: newValue as P[] });
      this.props.onChange(newValue as P[]);
    }
  };
}

export default PickerBase;

export interface IPickerBaseProps<T> {
  allowClear?: boolean;
  value?: T | T[];
  placeholder?: string;
  onChange: (value: T | T[]) => void;
  onQueryItems?: (query: string) => Promise<T[]>;
  handleBlur?: (e: any) => void;
  onRef?: (e: any) => void;
  style?: CSSProperties;
  disabled?: boolean;
  defaultIfSingle?: boolean;
  dataAutomationId?: string;
  name: string;
  tenantId: string;
  branchId: string;
  mode?: 'multiple';
}

export interface IPickerBaseState<T> {
  data: T[];
  value: T | T[];
  isLoading: boolean;
}
