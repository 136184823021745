import React, { useContext, useReducer } from 'react';
import {
  IRiskAssessment,
  IRiskAssessmentConfig,
  IApprovalFlow,
  IApprovalFlowsState,
  IRiskAssessmentOnSaveCallback,
  IRiskAssessmentEditVm,
  IAssessmentTransitionData,
  ApprovalFlowTypes,
  PoaData,
  PartialClearanceData,
  FullClearanceData,
} from '../../../models/riskAssessment';
import _ from 'lodash';

import styles from './ApprovalFlows.module.css';
import { UserInfoCtx, IUserInfoCtx } from '../../../UserInfoContext';
import { Button, Alert } from 'antd';
import { AssessmentTransitionConfirm } from '../../AssessmentTransitionConfirm/AssessmentTransitionConfirm';
import { reducer, createCancel, requestTransition, Action, createEmptyState } from './approvalFlowsState';
import { ApprovalFlowStartTransition } from '../../../models/riskAssessment/AssessmentTransition';
import { RiskAssessmentUpdates } from '../../../models/riskAssessment/RiskAssessmentUpdates';
import formatter from '../../../shared/formatter';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { isApprovalAvailable } from '../transitionActions/approvalStageHelper';
import classnames from 'classnames';
import { getIconForFlowById } from '../formhelper';
import { SafetyCertificateOutlined } from '@ant-design/icons';

const initialState = createEmptyState();

export const ApprovalFlows: React.FC<IApprovalFlowsProps> = ({ assessment, riskAssessmentVm, config, onSave }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation();
  let approvalFlows = getApprovalFlows(assessment);

  if (approvalFlows.length === 0) {
    return <Alert message={t('components.ApprovalFlows.noFlows')} type="info" />;
  }

  const canStartFlows = assessment.approvalFlows.notActive && isApprovalAvailable(assessment);

  return (
    <>
      <div className={styles.actions}>
        {approvalFlows.map((flow) => (
          <ApprovalFlowInfo
            assessment={assessment}
            hasMultipleFlows={config.hasMultipleApprovalFlows}
            key={flow.id}
            flow={flow}
            dispatch={dispatch}
            flowsState={assessment.approvalFlows}
            canStartFlows={canStartFlows}
          />
        ))}
      </div>
      <AssessmentTransitionConfirm
        config={config}
        possibleAdditionalMailReceivers={riskAssessmentVm.additionalMailReceivers}
        byOrderUsers={riskAssessmentVm.byOrderUsers}
        riskAssessment={assessment}
        visible={state.showDrawer}
        transition={state.currentTransition}
        mustProvideNotificationData={state.mustProvideNotificationData}
        mustEnsureRequiredDepartmentsForNotifications={state.mustEnsureRequiredDepartmentsForNotifications}
        onCancel={() => dispatch(createCancel())}
        onConfirm={(data) => {
          performUpdate(data, assessment, riskAssessmentVm, onSave);
          dispatch(createCancel());
        }}
      />
    </>
  );
};

function performUpdate(data: IAssessmentTransitionData, riskAssessment: IRiskAssessment, riskAssessmentVm: IRiskAssessmentEditVm, onSave: IRiskAssessmentOnSaveCallback) {
  if (data.transitionData instanceof PoaData) {
    riskAssessment.metadata.vendorPoa = data.transitionData.vendorPoa;
    riskAssessment.metadata.offerNrPoa = data.transitionData.offerNrPoa;
  }
  if (data.transitionData instanceof PartialClearanceData) {
    riskAssessment.metadata.billRecipient = data.transitionData.billRecipient;
    riskAssessment.metadata.deliveryTimes = data.transitionData.deliveryTimes;
    riskAssessment.metadata.signatureDate = data.transitionData.signatureDate;
    riskAssessment.metadata.estimatedDateOfTurnover = data.transitionData.estimatedDateOfTurnover;
    riskAssessment.metadata.indicationOfMainScope = data.transitionData.indicationOfMainScope;
  }

  if (data.transitionData instanceof FullClearanceData) {
    riskAssessment.metadata.billRecipient = data.transitionData.billRecipient;
    riskAssessment.metadata.deliveryTimes = data.transitionData.deliveryTimes;
    riskAssessment.metadata.projectStart = data.transitionData.projectStart;
    riskAssessment.metadata.signatureDate = data.transitionData.signatureDate;
    riskAssessment.metadata.estimatedDateOfTurnover = data.transitionData.estimatedDateOfTurnover;
    riskAssessment.metadata.indicationOfMainScope = data.transitionData.indicationOfMainScope;
  }

  const updates = RiskAssessmentUpdates.from(riskAssessmentVm.assessment, riskAssessment).withTransition(data);
  onSave(updates);
}

function getApprovalFlows(assessment: IRiskAssessment) {
  if (!hasApprovalFlows(assessment)) {
    return [];
  }

  return assessment.approvalFlows.flows;
}

function hasApprovalFlows(assessment: IRiskAssessment): boolean {
  return assessment.approvalFlows?.flows?.length > 0;
}

interface IApprovalFlowsProps {
  assessment: IRiskAssessment;
  riskAssessmentVm: IRiskAssessmentEditVm;
  config: IRiskAssessmentConfig;
  onSave: IRiskAssessmentOnSaveCallback;
}

interface IApprovalFlowInfoProps {
  hasMultipleFlows: boolean;
  dispatch: (action: Action) => void;
  canStartFlows: boolean;
  flowsState: IApprovalFlowsState;
  flow: IApprovalFlow;
  assessment: IRiskAssessment;
  dataAutomationId?: string;
}
const ApprovalFlowInfo: React.FC<IApprovalFlowInfoProps> = ({ assessment, flowsState, flow, dispatch, canStartFlows, hasMultipleFlows, dataAutomationId }) => {
  let ctx = useContext(UserInfoCtx);
  let [t] = useTranslation();

  if (flow.isCompleted || flowsState.currentFlowId === flow.id) {
    return (
      <ApprovalInfo
        assessment={assessment}
        hasMultipleFlows={hasMultipleFlows}
        flow={flow}
        flowsState={flowsState}
        dispatch={dispatch}
        canStartFlows={canStartFlows}
        dataAutomationId={dataAutomationId}
      />
    );
  }

  let flowStartName = hasMultipleFlows ? ctx.t(flow.name) : t('components.ApprovalFlows.singleFlowStart');
  return (
    <Button
      data-automation-id={'button-' + (dataAutomationId || ctx.t(flow.name))}
      size="large"
      className={styles.action}
      type="primary"
      icon={<SafetyCertificateOutlined />}
      onClick={() => dispatch(requestTransition(ApprovalFlowStartTransition.from(flow, assessment)))}
      disabled={!flowsState.notActive || !canStartFlows}
    >
      {flowStartName}
    </Button>
  );
};

const ApprovalInfo: React.FC<IApprovalFlowInfoProps> = ({ assessment, flow, flowsState, canStartFlows, hasMultipleFlows, dispatch, dataAutomationId }) => {
  let ctx = useContext(UserInfoCtx);
  let [t] = useTranslation();

  let flowName = hasMultipleFlows ? ctx.t(flow.name) : t('components.ApprovalFlows.singleFlowName');

  return (
    <div className={classnames(styles.action, !flow.isCompleted && styles.active)} style={{ border: '1px solid #b9b9b9' }}>
      <div style={{ fontSize: '16px', color: 'black', paddingLeft: '1rem', marginTop: '0.5rem' }}>
        <div className={styles.header}>
          {getIconForFlowById(assessment, flow.id)} <span style={{ marginLeft: '0.25rem' }}>{flowName}</span>
        </div>
      </div>
      <ul>
        {!flow.isCompleted && (
          <>
            <li>
              {t('components.ApprovalFlows.startedAt')} {formatter.formatDateWithTimeString(flow.startedAt)}
            </li>
            <li>
              {t('components.ApprovalFlows.startedBy')} {flow.startedBy}
            </li>
            <li>
              {t('components.ApprovalFlows.currentStage')} {getCurrentStageName(flowsState, ctx)}
            </li>
          </>
        )}
        {flow.isCompleted && (
          <>
            <li>
              {getCompletionAt(flow, t)} {formatter.formatDateWithTimeString(flow.completedAt)}
            </li>
            <li>
              {getCompletionBy(flow, t)} {flow.completedBy}
            </li>
          </>
        )}
      </ul>
      {flow.isCompleted && (
        <div style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
          <Button
            disabled={!canStartFlows}
            size="small"
            onClick={() => dispatch(requestTransition(ApprovalFlowStartTransition.from(flow, assessment)))}
            data-automation-id={'button-start-again-' + (dataAutomationId || flowName)}
          >
            {t('components.ApprovalFlows.startAgain')}
          </Button>
        </div>
      )}
    </div>
  );
};

function getCurrentStageName(flowState: IApprovalFlowsState, ctx: IUserInfoCtx) {
  const currentFlow = _.find(flowState.flows, (f) => f.id === flowState.currentFlowId);
  const currentStage = _.find(currentFlow?.stages, (s) => s.id === flowState.currentStageId);
  return ctx.t(currentStage?.name);
}

function getCompletionAt(flow: IApprovalFlow, t: TFunction): string {
  switch (flow.flowType) {
    case ApprovalFlowTypes.PowerofAttorney:
      return t('components.ApprovalFlows.grantedAt');
  }

  return t('components.ApprovalFlows.approvedAt');
}

function getCompletionBy(flow: IApprovalFlow, t: TFunction): string {
  switch (flow.flowType) {
    case ApprovalFlowTypes.PowerofAttorney:
      return t('components.ApprovalFlows.grantedBy');
  }

  return t('components.ApprovalFlows.approvedBy');
}
