import React, { PureComponent, SFC, FC } from 'react';
import { WithTranslation, withTranslation, useTranslation } from 'react-i18next';
import { IRiskAssessmentEditVm, IRiskAssessment, getCategorisationHelpDocumentUrl } from '../../../models/riskAssessment';
import { HtcCard, HtcCardWrapper } from '../../../shared/HtcCardComponent';
import { FormikProps, getIn } from 'formik';
import { FormRow, FormRadioYesNo, FormPercentInput, FormMoneyInput } from '../../../shared/FormComponents';
import FormBox from '../../../shared/components/FormBox/FormBox';
import nameof from 'ts-nameof.macro';
import _ from 'lodash';
import Big from 'big.js';
import styles from './Calculation.module.css';
import { PermissionCtx } from '../RiskAssessmentContext';
import PermissionCheck from '../../../shared/components/PermissionCheck/PermissionCheck';
import { UserInfoCtx } from '../../../UserInfoContext';
import { formatNumberForDisplay } from '../../../shared/numericHelpers';
import { AssessmentPageHeader } from '../assessment/PageHeader/PageHeader';
import { AssessmentPageContent } from '../assessment/PageContent/PageContent';
import { QuestionOutlined } from '@ant-design/icons';

type RA = IRiskAssessment;

const TP = 'riskAssessment.editform.calculation.';
class Calculation extends PureComponent<ICalculationProps> {
  render() {
    const { t, formik, riskAssessmentVm } = this.props;
    const total = this.getTotal(formik.values);
    const readOnly = this.context.permissionHelper.isBasicMetaDataReadOnly();
    const projectCategoryReadOnly = this.context.permissionHelper.isProjectCategoryReadOnly();
    const defaultRiskInPercent = this.getDefaultRiskInPercent(formik.values);
    const config = riskAssessmentVm.commonConfig;
    const canReadMargin = riskAssessmentVm.permissions.canReadCalculationMargin;
    return (
      <PermissionCheck hasAccess={riskAssessmentVm.permissions.canReadCalculation}>
        <UserInfoCtx.Consumer>
          {(userInfoCtx) => (
            <>
              <AssessmentPageHeader mainLevelTr="metadata" current="calculation" />
              <AssessmentPageContent>
                <HtcCardWrapper>
                  <HtcCard>
                    <FormBox title={t(TP + 'projectcategory')} icon={QuestionOutlined} iconLink={getCategorisationHelpDocumentUrl(formik.values)}>
                      <FormRow>
                        <FormRadioYesNo {...formik} name={nameof.full<RA>((a) => a.categorization.isRiskProject)} label={t('riskAssessment.isRiskProject')} disabled={projectCategoryReadOnly} />
                        <FormRadioYesNo {...formik} name={nameof.full<RA>((a) => a.categorization.isMajorProject)} label={t('riskAssessment.isMajorProject')} disabled={projectCategoryReadOnly} />
                        <FormRadioYesNo {...formik} name={nameof.full<RA>((a) => a.categorization.isLossProject)} label={t('riskAssessment.isLossProject')} disabled={projectCategoryReadOnly} />
                      </FormRow>
                    </FormBox>
                  </HtcCard>
                  <HtcCard>
                    <FormBox title={t(TP + 'machineType')}>
                      <FormRow>
                        <FormMoneyInput required {...formik} label={t(TP + 'total')} name={nameof.full<RA>((a) => a.calculation.machineTypeTotal)} disabled={readOnly} currency={config.currency} />

                        {canReadMargin && (
                          <FormPercentInput
                            required
                            {...formik}
                            label={t(TP + 'dbIIIMin', { marginalReturn: userInfoCtx.t(config.marginalReturn) })}
                            name={nameof.full<RA>((a) => a.calculation.machineTypeDb3MinPercent)}
                            disabled={readOnly}
                          />
                        )}
                        {canReadMargin && (
                          <FormPercentInput
                            required
                            {...formik}
                            label={t(TP + 'dbIIIOffer', { marginalReturn: userInfoCtx.t(config.marginalReturn) })}
                            name={nameof.full<RA>((a) => a.calculation.machineTypeDb3OfferPercent)}
                            disabled={readOnly}
                          />
                        )}
                      </FormRow>
                    </FormBox>
                  </HtcCard>
                  {config.hasPAndD && (
                    <HtcCard>
                      <FormBox title={t(TP + 'pAndD')}>
                        <FormRow>
                          <FormMoneyInput {...formik} label={t(TP + 'total')} name={nameof.full<RA>((a) => a.calculation.pAndDTotal)} disabled={readOnly} currency={config.currency} />
                          <FormPercentInput
                            {...formik}
                            label={t(TP + 'dbIIIMin', { marginalReturn: userInfoCtx.t(config.marginalReturn) })}
                            name={nameof.full<RA>((a) => a.calculation.pAndDb3MinInPercent)}
                            disabled={readOnly}
                          />
                        </FormRow>
                      </FormBox>
                    </HtcCard>
                  )}
                  <HtcCard>
                    <FormBox title={t(TP + 'total')}>
                      <label className={styles.total}>{formatNumberForDisplay(total, config.currency)}</label>
                      <div>
                        {config.useDefaultRiskValue && <span className={styles.risknote}>{t(TP + 'defaultRisk', { defaultRiskInPercent: formatNumberForDisplay(defaultRiskInPercent) })}</span>}
                      </div>
                    </FormBox>
                  </HtcCard>
                  {/* Disable IC-Project until USA joins <HtcCard>
                    <IcProject formik={formik} readOnly={readOnly} />
                  </HtcCard> */}
                </HtcCardWrapper>
              </AssessmentPageContent>
            </>
          )}
        </UserInfoCtx.Consumer>
      </PermissionCheck>
    );
  }

  getTotal = (riskAssessment: IRiskAssessment): number => {
    // TODO auslagern nach RiskAmounts
    const total1 = _.get(riskAssessment, nameof.full(riskAssessment.calculation.machineTypeTotal, 1), 0) || 0;
    const total2 = _.get(riskAssessment, nameof.full(riskAssessment.calculation.pAndDTotal, 1), 0) || 0;
    try {
      const number = Big(total1);
      const num = number.add(total2).toFixed(2);
      return +num;
    } catch (e) {
      return 0;
    }
  };

  getDefaultRiskInPercent = (riskAssessment: IRiskAssessment): number => {
    return _.get(riskAssessment, nameof.full(riskAssessment.calculation.defaultRiskInPercent, 1));
  };
}

const IcProject: FC<{ formik: FormikProps<IRiskAssessment>; readOnly: boolean }> = ({ formik, readOnly }) => {
  const [t] = useTranslation();
  const isIcProject = getIn(
    formik.values,
    nameof.full<RA>((a) => a.calculation.icProject.isIcProject),
  );
  return (
    <FormBox title={t(TP + 'icProject')}>
      <FormRow>
        <FormRadioYesNo
          {...formik}
          label={t(TP + 'icProject')}
          name={nameof.full<RA>((a) => a.calculation.icProject.isIcProject)}
          onChange={(_) => {
            formik.setFieldValue(nameof.full<RA>((a) => a.calculation.icProject.accordingToDirective) as any, true);
          }}
          disabled={readOnly}
        />
        {isIcProject && <FormRadioYesNo {...formik} disabled={readOnly} label={t(TP + 'icProjectAccordingToDirective')} name={nameof.full<RA>((a) => a.calculation.icProject.accordingToDirective)} />}
        {isIcProject && <FormPercentInput {...formik} disabled={readOnly} label={t(TP + 'icProjectDiscount')} name={nameof.full<RA>((a) => a.calculation.icProject.discount)} />}
      </FormRow>
    </FormBox>
  );
};

Calculation.contextType = PermissionCtx;
export default withTranslation()(Calculation);

interface ICalculationProps extends WithTranslation {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
}
