import _ from 'lodash';
import { IRiskAssessmentTemplate, IRiskAssessmentTemplateRef } from '../../models/riskAssessment';
import HttpClient from './httpClient';
import LRU from 'lru-cache';
import { LanguageSettings } from '../../models/LanguageSettings';
import { getForLanguage } from '../../models/IMultiLanguageString';
import { multiLanguageStringMatchesQuery } from './masterdataQueryHelper';
import { UniqueValidation, IUniqueValidationErrorResult } from '../../models/IUniqueValidation';

interface IRiskAssessmentTemplatesResponse {
  riskAssessmentTemplates: IRiskAssessmentTemplate[];
}

interface IRiskAssessmentTemplateResponse extends IRiskAssessmentTemplate {}

interface IRiskAssessmentTemplateOptionResponse {
  options: IRiskAssessmentTemplateRef[];
}

const cacheOptions = { maxAge: 1000 * 60 * 60 };
const cache = new LRU<string, IRiskAssessmentTemplateRef[]>(cacheOptions);

const getAllRiskAssessmentTemplates = async (): Promise<IRiskAssessmentTemplate[]> => {
  const result = await HttpClient.getInstance().get<IRiskAssessmentTemplatesResponse>(`riskAssessmentTemplates`);
  return getRiskAssessmentTemplatesFromResponse(result.data);
};
const getAllRiskAssessmentTemplateForEditOrCreation = async (id: string): Promise<IRiskAssessmentTemplate> => {
  const result = await HttpClient.getInstance().get<IRiskAssessmentTemplateResponse>(`riskAssessmentTemplates/${id}`);
  return result.data;
};

const createRiskAssessmentTemplate = async (item: IRiskAssessmentTemplate): Promise<IRiskAssessmentTemplate> => {
  const result = await HttpClient.getInstance().post<IRiskAssessmentTemplatesResponse>(`riskAssessmentTemplates`, item);
  return getRiskAssessmentTemplatesFromResponse(result.data)[0];
};

const editRiskAssessmentTemplate = async (item: IRiskAssessmentTemplate): Promise<IRiskAssessmentTemplate> => {
  const result = await HttpClient.getInstance().put<IRiskAssessmentTemplatesResponse>(`riskAssessmentTemplates/${item.id}`, item);
  return getRiskAssessmentTemplatesFromResponse(result.data)[0];
};

const cloneRiskAssessmentTemplate = async (item: IRiskAssessmentTemplate): Promise<IRiskAssessmentTemplate> => {
  const result = await HttpClient.getInstance().post<IRiskAssessmentTemplatesResponse>(`riskAssessmentTemplates/clones`, item);
  return getRiskAssessmentTemplatesFromResponse(result.data)[0];
};

const deleteRiskAssessmentTemplate = async (item: IRiskAssessmentTemplate): Promise<void> => {
  await HttpClient.getInstance().delete(`riskAssessmentTemplates/${item.id}`);
};

function getRiskAssessmentTemplatesFromResponse(response: IRiskAssessmentTemplatesResponse): IRiskAssessmentTemplate[] {
  return _.map(response.riskAssessmentTemplates, (riskAssessmentTemplate) => {
    return riskAssessmentTemplate;
  });
}

async function getDefaultRefForBranch(tenantId: string, branchId: string): Promise<IRiskAssessmentTemplateRef> {
  const options = await getTemplateOptionsForBranch(tenantId, branchId);
  return options[0] || null;
}

async function getTemplateOptionsForBranch(tenantId: string, branchId: string): Promise<IRiskAssessmentTemplateRef[]> {
  const cacheItems = cache.get(branchId);
  if (cacheItems) {
    return cacheItems;
  }

  const result = await HttpClient.getInstance().get<IRiskAssessmentTemplateOptionResponse>(`riskAssessmentTemplates/options?query=&loadAll=true&tenantId=${tenantId}&branchId=${branchId}`);
  const data = result.data.options || [];
  cache.set(branchId, data);

  return data;
}

const isUnique = async (template: IRiskAssessmentTemplate): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/riskAssessmentTemplates/isunique`, template);
  return new UniqueValidation(result.data.validationErrors);
};

async function queryRiskAssessmentTemplateOptions(query: string, settings: LanguageSettings, tenantId: string, branchId: string): Promise<IRiskAssessmentTemplateRef[]> {
  const options = await getTemplateOptionsForBranch(tenantId, branchId);
  const matchingOptions = options.filter((o) => multiLanguageStringMatchesQuery(query, settings, o.name));
  return _.orderBy(matchingOptions, (item) => item != null && getForLanguage(item.name, settings), ['asc']);
}

export const RiskAssessmentTemplateService = {
  getAllRiskAssessmentTemplates,
  getAllRiskAssessmentTemplateForEditOrCreation,
  createRiskAssessmentTemplate,
  editRiskAssessmentTemplate,
  cloneRiskAssessmentTemplate,
  deleteRiskAssessmentTemplate,
  queryRiskAssessmentTemplateOptions,
  getDefaultRefForBranch,
  isUnique,
};
