import React from 'react';

export const TenantBranchCtx = React.createContext<ITenantBranchContext>({ tenantId: null, branchId: null });

export interface ITenantBranchContext {
  tenantId: string;
  branchId: string;
}

export function useTenantBranch() {
  return React.useContext(TenantBranchCtx);
}
