import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import styles from './NumericInput.module.css';
import classNames from 'classnames';
import { formatNumberForDisplay, toNumber } from '../../numericHelpers';

export const NumericInput: React.FC<INumericInputProps> = (props) => {
  const { decimalPlaces = 2, suffix, value, disabled, dataAutomationId } = props;
  const [rawValue, setRawValue] = useState(formatNumberForDisplay(value));

  useEffect(() => {
    if (toNumber(rawValue) !== value) {
      setRawValue(formatNumberForDisplay(value));
    }
  }, [value]);

  const runAfterUpdate = useRunAfterUpdate();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!isValidInput(value, decimalPlaces)) {
      return;
    }
    setRawValue(value);
    props.onChange(toNumber(value));
  };

  const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatNumberForEdit(value);
    if (rawValue === formatted) {
      e.target.select();
    } else {
      setRawValue(formatted);
      e.persist();
      runAfterUpdate(() => e.target.select());
    }
  };

  const onBlur = () => {
    const { onBlur } = props;
    setRawValue(formatNumberForDisplay(value));
    if (onBlur) {
      onBlur();
    }
  };
  return (
    <Input
      className={classNames(styles.numericinput, disabled && 'disabled')}
      suffix={suffix}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      value={rawValue}
      data-automation-id={dataAutomationId}
    ></Input>
  );
};

function useRunAfterUpdate() {
  const afterPaintRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = (fn) => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

function isValidInput(input: string, decimalPlaces: number): boolean {
  if (input) {
    input = input.replace(/\./g, '');
  }
  const validNumberRegex = new RegExp(`^(0|[1-9][0-9]*)(,[0-9]{0,${decimalPlaces}})?$`);
  const passesRegex = validNumberRegex.test(input);
  const number = toNumber(input);
  return (!isNaN(number) && passesRegex) || input === '';
}

function formatNumberForEdit(number: number): string {
  if (number == undefined) {
    return '';
  }
  let val = number.toString();
  return val.replace('.', ',');
}

interface INumericInputProps {
  value: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
  decimalPlaces?: number;
  suffix: string;
  disabled?: boolean;
  dataAutomationId?: string;
}
