import React, { useContext, CSSProperties } from 'react';
import { IMultiLanguageString } from '../../../../models/IMultiLanguageString';
import { UserInfoCtx } from '../../../../UserInfoContext';
import { NavPosition } from '../../NavPosition';

import styles from './PageHeader.module.css';
import { useTranslation } from 'react-i18next';
import { CommandBarV2 } from '../../../../shared/components/CommandBar/CommandBarV2';

const breadcrumbStyle: CSSProperties = { fontWeight: 500, fontSize: '16px' };

export const AssessmentPageHeader: React.FC<{ mainLevelTr?: string; current: IMultiLanguageString | string; postfix?: string }> = ({ mainLevelTr = 'assessment', current, postfix, children }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();

  let currentLevel: string = null;

  if (typeof current === 'string') {
    currentLevel = t(`components.NavPosition.${current}`);
  } else {
    currentLevel = ctx.t(current);
  }

  if (postfix) {
    currentLevel += ' ' + postfix;
  }
  return (
    <CommandBarV2 style={{ height: '42px' }} breadcrumbStyle={breadcrumbStyle} translatePaths={false} paths={[currentLevel]}>
      {children}
    </CommandBarV2>
  );
};
