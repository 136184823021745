import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import customIcon from './CustomIcon';
import { EditOutlined } from '@ant-design/icons';

export const DocumentIconColumn: React.FC<IDocumentIconRowProps> = ({ className, isCheckedOut, isArchived, isParaphrased }) => {
  const [t] = useTranslation();

  return (
    <div className={className}>
      {isCheckedOut && <div className="statusIcon">{customIcon.checkedOut()}</div>}
      {isArchived && (
        <Tooltip placement="top" title={t('components.DocumentList.archived')}>
          <div className="statusIcon">{customIcon.archive_show()}</div>
        </Tooltip>
      )}
      {isParaphrased && (
        <Tooltip placement="top" title={t('components.DocumentList.signed')}>
          <EditOutlined />
        </Tooltip>
      )}
    </div>
  );
};

interface IDocumentIconRowProps {
  className: any;
  isCheckedOut: boolean;
  isArchived: boolean;
  isParaphrased: boolean;
}
