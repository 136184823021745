import nameof from 'ts-nameof.macro';
import _ from 'lodash';
import Big from 'big.js';
import { IRiskAssessment, IRiskAssessmentEvaluation, ITopicEvaluation, getEvaluationSummary } from './IRiskAssessment';

export interface IDepartmentEvaluationTotals {
  riskAmountFromTopics: number;
  riskMeasureAmountFromTopics: number;
  riskAmount: number;
  riskMeasureAmount: number;
  riskAfterMeasureAmount: number;
}

export interface ISummaryEvaluationTotals {
  summaryMeasureAmount: number;
  summaryRiskAfterMeasureAmount: number;
  evaluationsMeasureAmount: number;
  evaluationsRiskAfterMeasureAmount: number;
  evaluationsRiskAmount: number;
  increasedRiskAmount: number;
  defaultRiskAmount: number;
  defaultRiskInPercent: number;
}

const getTotalsForDepartment = (assessment: IRiskAssessment, departmentId: string): IDepartmentEvaluationTotals => {
  const evaluation = getDepartmentEvaluation(assessment, departmentId);
  if (!evaluation) {
    return {
      riskAmountFromTopics: 0,
      riskMeasureAmountFromTopics: 0,
      riskAmount: 0,
      riskMeasureAmount: 0,
      riskAfterMeasureAmount: 0,
    };
  }

  const topicTotals = _.reduce(
    evaluation.topicEvaluations,
    (totals, topic) => {
      return {
        riskAmount: add(totals.riskAmount, topic.riskAmount),
        measureAmount: add(totals.measureAmount, topic.measureAmount),
      };
    },
    { riskAmount: 0, measureAmount: 0 },
  );

  return {
    riskAmountFromTopics: topicTotals.riskAmount,
    riskMeasureAmountFromTopics: topicTotals.measureAmount,
    riskAmount: evaluation.riskAmount || 0,
    riskMeasureAmount: evaluation.measureAmount || 0,
    riskAfterMeasureAmount: evaluation.riskAfterMeasureAmount || 0,
  };
};

const getTotalsForSummary = (assessment: IRiskAssessment): ISummaryEvaluationTotals => {
  const summary = getEvaluationSummary(assessment);
  if (!summary) {
    return {
      summaryMeasureAmount: 0,
      summaryRiskAfterMeasureAmount: 0,
      evaluationsMeasureAmount: 0,
      evaluationsRiskAfterMeasureAmount: 0,
      evaluationsRiskAmount: 0,
      defaultRiskInPercent: 0,
      increasedRiskAmount: 0,
      defaultRiskAmount: 0,
    };
  }

  const departmentTotals = _.reduce(
    summary.departmentSummaries,
    (totals, departmentSummary) => {
      return {
        summaryMeasureAmount: add(totals.summaryMeasureAmount, departmentSummary.summaryMeasureAmount),
        summaryRiskAfterMeasureAmount: add(totals.summaryRiskAfterMeasureAmount, departmentSummary.summaryRiskAfterMeasureAmount),
        evaluationsMeasureAmount: add(totals.evaluationsMeasureAmount, departmentSummary.evaluationAmounts.measureAmount),
        evaluationsRiskAfterMeasureAmount: add(totals.evaluationsRiskAfterMeasureAmount, departmentSummary.evaluationAmounts.riskAfterMeasureAmount),
        evaluationsRiskAmount: add(totals.evaluationsRiskAmount, departmentSummary.evaluationAmounts.riskAmount),
      };
    },
    { summaryMeasureAmount: 0, summaryRiskAfterMeasureAmount: 0, evaluationsMeasureAmount: 0, evaluationsRiskAfterMeasureAmount: 0, evaluationsRiskAmount: 0 },
  );

  return {
    summaryMeasureAmount: departmentTotals.summaryMeasureAmount,
    summaryRiskAfterMeasureAmount: departmentTotals.summaryRiskAfterMeasureAmount,
    evaluationsMeasureAmount: departmentTotals.evaluationsMeasureAmount,
    evaluationsRiskAfterMeasureAmount: departmentTotals.evaluationsRiskAfterMeasureAmount,
    evaluationsRiskAmount: departmentTotals.evaluationsRiskAmount,
    defaultRiskInPercent: summary.defaultRiskInPercent,
    defaultRiskAmount: summary.summaryDefaultRiskAmount || 0,
    increasedRiskAmount: add(summary.summaryDefaultRiskAmount, departmentTotals.summaryRiskAfterMeasureAmount),
  };
};

function getDepartmentEvaluation(assessment: IRiskAssessment, departmentId: string): IRiskAssessmentEvaluation {
  if (departmentId == null) {
    return null;
  }

  const evaluations: IRiskAssessmentEvaluation[] = _.get(assessment, nameof.full(assessment.evaluation.evaluations, 1));
  return _.find(evaluations, (e) => e.department.id === departmentId);
}

function add(a: number, b: number): number {
  a = _.isFinite(a) ? a : 0;
  b = _.isFinite(b) ? b : 0;
  const num = Big(a).add(b).toFixed(2);
  return +num;
}

export default {
  getTotalsForDepartment,
  getTotalsForSummary,
};
