import React, { useContext } from 'react';
import { IRiskAssessmentEditVm, IRiskAssessment, IRiskAssessmentOnSaveCallback, IEnsureDocumentCallback, IUploadDocumentsCallback, RiskAssessmentStatus } from '../../../models/riskAssessment';
import { FormikProps } from 'formik';
import { HtcCard } from '../../../shared/HtcCardComponent';
import {
  createDocumentMetadataForOfferOrderContract,
  createDocumentMetadataForCalculation,
  createDocumentMetadataForMisc,
  DocumentTypes,
  getMetadataDocuments,
  getClearanceDocuments,
  createDocumentMetadataForClearance,
} from '../../../models/documents/IDocumentMetaData';
import styles from './Documents.module.css';
import _ from 'lodash';
import { DocumentList } from '../../components/DocumentList/DocumentList';
import { RiskAssessmentOwner } from '../../../models/documents/IDocumentMetaData';
import { UserInfoCtx } from '../../../UserInfoContext';
import { Tabs } from 'antd';
import { AssessmentPageHeader } from '../assessment/PageHeader/PageHeader';
import { AssessmentPageContent } from '../assessment/PageContent/PageContent';
import { DocumentTabs } from '../shared/DocumentTabs/DocumentTabs';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

export const DocumentsMetadata: React.FC<IDocumentsMetadataProps> = ({ formik, riskAssessmentVm, onSave, onEnsureDocument, onUploadDocuments }) => {
  const [t] = useTranslation();
  const { commonConfig: config, permissions } = riskAssessmentVm;
  const riskAssessment = formik.values;
  const userCtx = useContext(UserInfoCtx);
  return (
    <>
      <AssessmentPageHeader mainLevelTr="metadata" current="documents" />
      <AssessmentPageContent>
        <HtcCard style={{ height: '100%' }}>
          <DocumentTabs defaultActiveKey={'1'}>
            {permissions.branchPermissions.canEditContractDocuments && (
              <TabPane tab={userCtx.t(config.contractAndCalculationDocuments)} key="1">
                <DocumentList
                  riskAssessment={riskAssessment}
                  riskAssessmentVm={riskAssessmentVm}
                  onSave={onSave}
                  onEnsureDocument={onEnsureDocument}
                  onUploadDocuments={onUploadDocuments}
                  createNewMetadata={() => createDocumentMetadataForOfferOrderContract(new RiskAssessmentOwner(riskAssessment.id))}
                  documents={getMetadataDocuments(riskAssessment, DocumentTypes.OfferOrderContract)}
                />
              </TabPane>
            )}
            {permissions.branchPermissions.canEditPmCalcDocuments && (
              <TabPane tab={userCtx.t(config.pmCalculationDocuments)} key="2">
                <DocumentList
                  riskAssessment={riskAssessment}
                  riskAssessmentVm={riskAssessmentVm}
                  onSave={onSave}
                  onEnsureDocument={onEnsureDocument}
                  onUploadDocuments={onUploadDocuments}
                  createNewMetadata={() => createDocumentMetadataForCalculation(new RiskAssessmentOwner(riskAssessment.id))}
                  documents={getMetadataDocuments(riskAssessment, DocumentTypes.Calculation)}
                />
              </TabPane>
            )}
            {permissions.branchPermissions.canEditAppendicesDocuments && (
              <TabPane tab={userCtx.t(config.generalDocuments)} key="3">
                <DocumentList
                  riskAssessment={riskAssessment}
                  riskAssessmentVm={riskAssessmentVm}
                  onSave={onSave}
                  onEnsureDocument={onEnsureDocument}
                  onUploadDocuments={onUploadDocuments}
                  createNewMetadata={() => createDocumentMetadataForMisc(new RiskAssessmentOwner(riskAssessment.id))}
                  documents={getMetadataDocuments(riskAssessment, DocumentTypes.Miscellaneous)}
                />
              </TabPane>
            )}
            {config.hasClearanceDocuments && permissions.canReadClearanceDocuments && (
              <TabPane tab={t('riskAssessment.documents.clearance')} key="4">
                <DocumentList
                  disabled={!permissions.canReadClearanceDocuments}
                  riskAssessment={riskAssessment}
                  riskAssessmentVm={riskAssessmentVm}
                  onSave={onSave}
                  onEnsureDocument={onEnsureDocument}
                  onUploadDocuments={onUploadDocuments}
                  createNewMetadata={() => createDocumentMetadataForClearance(riskAssessment)}
                  documents={getClearanceDocuments(riskAssessment)}
                />
              </TabPane>
            )}
          </DocumentTabs>
        </HtcCard>
      </AssessmentPageContent>
    </>
  );
};

interface IDocumentsMetadataProps {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
  onSave: IRiskAssessmentOnSaveCallback;
  onEnsureDocument: IEnsureDocumentCallback;
  onUploadDocuments: IUploadDocumentsCallback;
}
