import React, { CSSProperties } from 'react';
import { CommandBarNavTypes } from './CommandBar';
import { PathBreadcrumb } from '../PathBreadcrumb/PathBreadcrumb';

import styles from './CommandBarV2.module.css';
export const CommandBarV2: React.FC<ICommandBarV2Props> = ({ paths, translatePaths = true, children, breadcrumbStyle, style }) => {
  return (
    <div style={style} className={styles.commandbarwrapper}>
      <div className={styles.headerwrapper}>
        <PathBreadcrumb style={breadcrumbStyle} paths={paths} translatePaths={translatePaths} />
      </div>
      <div className={styles.actionwrapper}>{children}</div>
    </div>
  );
};

interface ICommandBarV2Props {
  paths?: CommandBarNavTypes[];
  translatePaths?: boolean;
  breadcrumbStyle?: CSSProperties;
  style?: CSSProperties;
}
