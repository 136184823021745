import { Alert, Form, Select, Spin, Tooltip } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useContext, useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { FormLanguageTextInput, FormRadioButtonGroup, FormRadioYesNo, FormSelectInput } from '../../shared/FormComponents';
import { useTranslation } from 'react-i18next';
import { HtcCard, HtcCardWrapper } from '../../shared/HtcCardComponent';
import { SaveAction, CancelAction, DeleteAction, EditAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { getForLanguage, testRequiredLanguageFields } from '../../models/IMultiLanguageString';
import { UserInfoCtx } from '../../UserInfoContext';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import { ILanguage } from '../../models/LanguageSettings';
import _ from 'lodash';
import { TFunction } from 'i18next';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { hasChanges } from '../../shared/masterDataHelpers';
import { IDepartment, ITopic, IDepartmentOption, IMasterdataEditProps, DepartmentTypes, getAssessmentVisibilityTypeOptions, IBranchOfIndustry } from '../../models/masterdata';
import { FormMultiCardSelect } from '../../shared/components/FormMultiCardSelect/FormMultiCardSelect';
import styles from './DepartmentEditFormComponent.module.css';
import nameof from 'ts-nameof.macro';
import departmentService from '../../shared/services/departmentService';
import { notifyError } from '../../shared/notificationHelper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { IRootState, getActiveBranchOfIndustry, getActiveTenant, getAllTenants } from '../../ducks/reducer';
import { useSelector } from 'react-redux';
import { IBranchOfIndustryInfo, ITenantInfo, IUserTenantInfo } from '../../models/masterdata/ITenant';
import { IDepartmentSync } from '../../models/masterdata/IDepartmentSync';
import { ITenantRef } from '../../models/user';
import { WarningOutlined } from '@ant-design/icons';
import branchOfIndustryService from '../../shared/services/branchOfIndustryService';
import { useHistory } from 'react-router-dom';

function getTopicsDataSource(topics: ITopic[]) {
  return _.map(topics, (c) => ({ id: c.id, name: c.name }));
}
const navPaths = ['navigation.masterdata', { name: 'navigation.departments', linkTo: '/departments' }];
export const DepartmentEditFormComponent: FC<IDepartmentEditFormProps> = ({ cancelEdit, department, onDelete, onSave, departmentTypeOptions, availableTopics }) => {
  const assignable: ITenantInfo[] = useSelector((state: IRootState) => getAllTenants(state.app));
  const [availableBranches, setAvailableBranches] = useState<IBranchOfIndustry[]>([]);
  const history = useHistory();
  const activeTenant: IUserTenantInfo = useSelector((state: IRootState) => getActiveTenant(state.app));
  const activeBranchOfIndustry: IBranchOfIndustryInfo = useSelector((state: IRootState) => getActiveBranchOfIndustry(state.app));
  const ctx = useContext(UserInfoCtx);
  let isAppAdministrator = ctx.userInfo.permissions.security.isAppAdministrator;
  const [t] = useTranslation();
  const settings = ctx.languageSettings;
  const departmentClone = useMemo(() => _.cloneDeep(department), [department]);
  const [validationScheme] = useState(getValidationScheme(t, settings.getDefaultLanguage()));
  const topicDataSource = useMemo(() => getTopicsDataSource(availableTopics), [availableTopics]);
  const assessmentVisibilityTypes = getAssessmentVisibilityTypeOptions(t);
  const [showDepartmentSync, setShowDepartmentSync] = useState<boolean>(false);
  const [selectedTenant, setSelectedTenant] = useState<ITenantRef>(null);
  const [selectedBranch, setSelectedBranch] = useState<IBranchOfIndustry>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<IDepartment>(null);
  const [departmentList, setDepartmentList] = useState<IDepartment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [copyClaimFromExist, setCopyClaimsFromExist] = useState<boolean>(false);
  const [alertString, setAlertString] = useState('');

  const isNew = !department.id;
  const nav = Object.assign([], navPaths);
  nav.push(ctx.t(department.name));
  const handleDelete = () => onDelete(department);
  const handleSave = async (department: IDepartment, actions: FormikHelpers<IDepartment>) => {
    const validationErrors = await departmentService.isUnique(department);
    if (!validationErrors.hasErrors()) {
      try {
        await onSave(department);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  useEffect(() => {
    const getAlertString = async () => {
      if (department?.copyClaimsFrom !== null && department?.copyClaimsFrom?.id !== null && Object.keys(department.copyClaimsFrom).length !== 0) {
        setCopyClaimsFromExist(true);
        const foundTenant = assignable.find((t) => t.id === department?.copyClaimsFrom?.tenantId);
        if (foundTenant) {
          setSelectedTenant(foundTenant);
        }
        const fixedBranches = await branchOfIndustryService.getAllBranchesOfIndustryByTenantId(foundTenant?.id);
        const fixexBranch = fixedBranches.find((t) => t.id === department?.copyClaimsFrom?.branchOfIndustryId);
        const fixedDepartmentList = await departmentService.getItemsByBranchId(fixexBranch?.id, foundTenant?.id);
        const fixedDepartment = fixedDepartmentList.find((t) => t.id === department?.copyClaimsFrom?.id);
        setSelectedBranch(fixexBranch);
        setSelectedDepartment(fixedDepartment);
        setAlertString(foundTenant?.name + '/' + getForLanguage(fixexBranch?.name, settings) + '/' + getForLanguage(fixedDepartment?.name, settings));
      }
    };
    getAlertString();
  }, [department]);

  useEffect(() => {
    const fetchBranches = async () => {
      if (selectedTenant !== null && selectedTenant !== undefined) {
        let availableBranches = await branchOfIndustryService.getAllBranchesOfIndustryByTenantId(selectedTenant?.id);
        setAvailableBranches(availableBranches);
      }
    };
    fetchBranches();
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedTenant !== null && selectedBranch?.id !== '') {
      const fetchDepartments = async () => {
        let result = await departmentService.getItemsByBranchId(selectedBranch?.id, selectedTenant.id);
        setDepartmentList(result);
      };
      fetchDepartments();
    }
  }, [selectedBranch]);

  const mapDepartmentSyncItem = (): IDepartmentSync => {
    return {
      targetTenant: selectedTenant.id,
      targetBranchOfIndustry: selectedBranch.id,
      targetDepartment: selectedDepartment.id,
      baseTenant: activeTenant.id,
      baseBranchOfIndustry: activeBranchOfIndustry.id,
      baseDepartment: department.id,
    };
  };

  const saveDepartmentSync = () => {
    const setDepartmentSync = async () => {
      setLoading(true);
      if (selectedTenant !== null && selectedBranch !== null && selectedDepartment !== null) {
        await departmentService.addDepartmentSync(mapDepartmentSyncItem());
      }
      setLoading(false);
      history.goBack();
    };
    if (selectedTenant !== null && selectedBranch !== null && selectedDepartment !== null) {
      setDepartmentSync();
    }
  };

  const editDepartmentSync = () => {
    const editSync = async () => {
      setLoading(true);
      if (selectedTenant !== null && selectedBranch !== null && selectedDepartment !== null) {
        await departmentService.editDepartmentSync(mapDepartmentSyncItem());
      }
      setLoading(false);
      history.goBack();
    };
    editSync();
  };

  const removeDepartmentSync = () => {
    const removeSync = async () => {
      setLoading(true);
      if (selectedTenant !== null && selectedBranch !== null && selectedDepartment !== null) {
        const result: IDepartment = await departmentService.removeDepartmentSync(mapDepartmentSyncItem());
        if (result !== null && result !== undefined) {
          setShowDepartmentSync(false);
          setSelectedTenant(null);
          setSelectedBranch(null);
          setSelectedDepartment(null);
          setCopyClaimsFromExist(false);
        }
      }
      setLoading(false);
      history.goBack();
    };
    removeSync();
  };

  const cancelDepartmentSync = () => {
    setShowDepartmentSync(false);
    setSelectedTenant(null);
    setSelectedBranch(null);
    setSelectedDepartment(null);
  };

  return (
    <ContentWrapper>
      <Formik enableReinitialize={true} initialValues={departmentClone} onSubmit={handleSave} validationSchema={validationScheme}>
        {(formik) => {
          const { isSubmitting, submitForm, values } = formik;
          return (
            <>
              <RiskPrompt active={hasChanges(department, values)} />
              <MasterdataHeader>
                <CommandBarV2 paths={nav}>
                  {!isNew && <DeleteAction disabled={isSubmitting} onClick={handleDelete} />}
                  <CancelAction disabled={isSubmitting} onClick={cancelEdit} />
                  <SaveAction disabled={isSubmitting} onClick={submitForm} />
                </CommandBarV2>
              </MasterdataHeader>
              <HtcCardWrapper>
                <Form layout="vertical">
                  <LanguageTabPage>
                    {(lp) => (
                      <HtcCard flex>
                        {copyClaimFromExist && (
                          <Alert
                            style={{ margin: '10px' }}
                            icon={<WarningOutlined />}
                            showIcon
                            message={
                              <span>
                                {t('masterdata.departments.editform.syncAlert')} <strong>{alertString}</strong>{' '}
                              </span>
                            }
                            type="warning"
                          />
                        )}
                        <FormBox title={t('masterdata.departments.editform.details')} flex contentflex>
                          <FormRadioButtonGroup {...formik} options={departmentTypeOptions} name={nameof<IDepartment>((d) => d.type)} label={t('masterdata.departments.type')} />
                          <FormLanguageTextInput {...formik} name={nameof<IDepartment>((d) => d.name)} label={t('masterdata.departments.name')} selectedLanguage={lp.lang} />
                          <FormLanguageTextInput {...formik} name={nameof<IDepartment>((d) => d.abbreviation)} label={t('masterdata.departments.abbreviation')} selectedLanguage={lp.lang} />
                          {values.type === DepartmentTypes.Approval && (
                            <FormRadioYesNo {...formik} name={nameof<IDepartment>((d) => d.isManagement)} label={t('masterdata.departments.isManagement')} />
                          )}
                        </FormBox>
                        {values.type === DepartmentTypes.Sales && (
                          <FormBox title={t('masterdata.departments.assessmentSettings')}>
                            <FormSelectInput
                              {...formik}
                              name={nameof<IDepartment>((d) => d.assessmentVisibility)}
                              label={t('masterdata.departments.assessmentVisibility')}
                              options={assessmentVisibilityTypes}
                            />
                          </FormBox>
                        )}

                        <FormBox title={t('masterdata.departments.assignedTopics')} flex contentflex>
                          <FormMultiCardSelect
                            {...formik}
                            name="assignedTopics"
                            dataSource={topicDataSource}
                            config={[{}]}
                            isSortable={true}
                            titleProperty={'name'}
                            dividerStyle={styles.dividerStyle}
                            settings={settings}
                          />
                        </FormBox>
                        {isAppAdministrator && (
                          <FormBox title={t('masterdata.departments.departmentSync')} flex contentflex>
                            {loading && <Spin />}
                            <div className={styles.departmentSyncButtons}>
                              {copyClaimFromExist && showDepartmentSync ? (
                                <div>
                                  <SaveAction onClick={editDepartmentSync} /> <DeleteAction onClick={removeDepartmentSync} /> <CancelAction onClick={() => setShowDepartmentSync(false)} />{' '}
                                </div>
                              ) : showDepartmentSync ? (
                                <div>
                                  <SaveAction disabled={selectedDepartment == null} onClick={saveDepartmentSync} />
                                  <CancelAction onClick={cancelDepartmentSync} />
                                </div>
                              ) : (
                                <EditAction onClick={() => setShowDepartmentSync(true)} />
                              )}
                            </div>
                            {showDepartmentSync && (
                              <div>
                                <div className={styles.marginButton}>
                                  <label>{t('masterdata.departments.departmentSyncTypes.tenant')}</label>
                                  <Select
                                    value={selectedTenant?.name}
                                    showSearch
                                    placeholder={t('masterdata.departments.departmentSyncTypes.tenant')}
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                      const selected = assignable.find((tenant) => tenant.name === value);
                                      setSelectedTenant(selected);
                                      setSelectedBranch(null);
                                      setSelectedDepartment(null);
                                    }}
                                    filterOption={(input, option) => option?.props?.children && option.props.children.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  >
                                    {assignable &&
                                      assignable
                                        .filter((option) => option.id !== activeTenant.id)
                                        .map((option) => (
                                          <Select.Option value={option.name} key={option.id}>
                                            {option.name}
                                          </Select.Option>
                                        ))}
                                  </Select>
                                </div>

                                {selectedTenant && (
                                  <div className={styles.marginButton}>
                                    <label>{t('masterdata.departments.departmentSyncTypes.branch')}</label>
                                    <Select
                                      value={selectedBranch?.id}
                                      showSearch
                                      placeholder={t('masterdata.departments.departmentSyncTypes.branch')}
                                      optionFilterProp="children"
                                      onChange={(value) => {
                                        const selected = availableBranches.find((branch) => branch?.id === value);
                                        setSelectedBranch(selected);
                                        setSelectedDepartment(null);
                                      }}
                                      filterOption={(input, option) => option?.props?.children && option.props.children.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                      {availableBranches &&
                                        availableBranches.map((option) => (
                                          <Select.Option value={option.id} key={option.id}>
                                            {getForLanguage(option.name, settings)}
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  </div>
                                )}
                                {selectedBranch && (
                                  <div className={styles.marginButton}>
                                    <label>{t('masterdata.departments.departmentSyncTypes.department')}</label>
                                    <Select
                                      value={selectedDepartment?.id}
                                      showSearch
                                      placeholder={t('masterdata.departments.departmentSyncTypes.department')}
                                      optionFilterProp="children"
                                      onChange={(value) => {
                                        const selected = departmentList.find((department) => department?.id === value);
                                        setSelectedDepartment(selected);
                                      }}
                                      filterOption={(input, option) => option?.props?.children && option.props.children.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                      {departmentList &&
                                        departmentList.map((option) => (
                                          <Select.Option value={option.id} key={option.id}>
                                            {getForLanguage(option.name, settings)} ({getForLanguage(option.abbreviation, settings)})
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  </div>
                                )}
                              </div>
                            )}
                          </FormBox>
                        )}
                      </HtcCard>
                    )}
                  </LanguageTabPage>
                </Form>
              </HtcCardWrapper>
            </>
          );
        }}
      </Formik>
    </ContentWrapper>
  );
};

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  return yup.object().shape<any>({
    name: testRequiredLanguageFields(50, defaultLanguage, t),
    abbreviation: testRequiredLanguageFields(50, defaultLanguage, t),
    assignedTopics: yup.array(),
  });
}

interface IDepartmentEditFormProps extends IMasterdataEditProps<IDepartment> {
  department: IDepartment;
  departmentTypeOptions: IDepartmentOption[];
  availableTopics: ITopic[];
}
