import React, { FC, CSSProperties } from 'react';
import Icon, { SearchOutlined } from '@ant-design/icons';
import styles from './FormBox.module.css';
import _ from 'lodash';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';

export const FormBox: FC<{
  title: string;
  icon?: React.ForwardRefExoticComponent<any>;
  iconLink?: string;
  style?: CSSProperties;
  flex?: boolean;
  contentflex?: boolean;
  overflowX?: boolean;
  requiredIndicator?: boolean;
}> = ({ title, icon, iconLink, children, style, flex, contentflex, overflowX, requiredIndicator }) => {
  if (flex) {
    style = _.assign({ flex: '1 1 auto' }, style);
  }

  let contentStyle: CSSProperties;

  if (overflowX) {
    contentStyle = { overflowX: 'auto', overflowY: 'hidden' };
  }
  return (
    <div id="formbox" className={styles.formbox} style={style}>
      <div className={styles.formbox_header}>
        <span>
          {requiredIndicator && <RequiredIndicator />}
          {title}
        </span>
        {icon && !iconLink && <Icon className={styles.formbox_header_icon} component={icon as any} />}
        {icon && iconLink && (
          <a className={styles.formbox_header_link} href={iconLink} target="_blanc">
            <Icon className={styles.formbox_header_icon} component={icon as any} />
          </a>
        )}
      </div>
      <div style={contentStyle} className={classnames(styles.formbox_content, contentflex && styles.formbox_content_flex)}>
        {children}
      </div>
    </div>
  );
};

export const SearchBox: FC = ({ children }) => {
  const [t] = useTranslation();
  return (
    <FormBox title={t('commonActions.filter')} icon={SearchOutlined} style={{ marginBottom: '1rem' }}>
      {children}
    </FormBox>
  );
};

const RequiredIndicator: FC = () => (
  <div className="ant-form-item-label">
    <label className="ant-form-item-required" />
  </div>
);

export default FormBox;
