import React, { FC } from 'react';
import { IDepartmentEvaluationSummary } from '../../../models/riskAssessment';
import { MailOutlined, CheckOutlined } from '@ant-design/icons';

export const EvaluationSummaryStatus: FC<{ summary: IDepartmentEvaluationSummary }> = ({ summary }) => {
  if (!summary) {
    return null;
  }
  if (summary.isEvaluationCompleted) {
    return <CheckOutlined />;
  }

  if (summary.isEvaluationRequired) {
    return <MailOutlined />;
  }

  return null;
};
