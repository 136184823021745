import React, { SFC, CSSProperties, Children } from 'react';
import { Drawer, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const ConfirmationPanel: React.FC<IConfirmationPanelProps> = ({ label, drawerVisible, onClose, onCancel, onConfirm }) => {
  const [t] = useTranslation();
  return (
    <Drawer title={label} placement="right" closable={true} onClose={onClose} visible={drawerVisible} width={386}>
      <div>{t('components.DocumentList.confirmAction')}</div>
      <Button style={{ marginRight: '0.5rem' }} onClick={() => onCancel()}>
        {t('common.no')}
      </Button>
      <Button type="primary" onClick={() => onConfirm()}>
        {t('common.yes')}
      </Button>
    </Drawer>
  );
};

export default ConfirmationPanel;

interface IConfirmationPanelProps {
  label: string;
  drawerVisible: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}
