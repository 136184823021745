import React, { FC, useContext } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { ITopic, ITopicWithDepartmentUsages, NewTopicId } from '../../models/masterdata';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import styles from './TopicsList.module.css';
import { NewAction } from '../../shared/components/CommandBar/CommandBar';
import { SearchBox, FormBox } from '../../shared/components/FormBox/FormBox';
import _ from 'lodash';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import nameof from 'ts-nameof.macro';
import CheckableTagGroup, { ICheckableTag } from '../../shared/components/CheckableTagGroup/CheckableTagGroup';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import { Table } from '../../shared/components/Table/Table';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { EditOutlined } from '@ant-design/icons';

const navPaths = ['navigation.masterdata', 'navigation.topics'];

const TopicsList: FC<ITopicsListProps> = ({ loading, topics, setSearchText, searchText, filterDepartments, setFilterDepartments, departmentTagOptions }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);

  const create = React.useCallback(() => history.push('/topics/' + NewTopicId), []);
  const edit = React.useCallback((id: string) => history.push('/topics/' + id), []);
  const columns = React.useMemo(() => createColumns(t, ctx, edit), []);
  const onSearch = (e: any) => setSearchText(e.target.value);
  return (
    <ContentWrapper>
      <MasterdataHeader>
        <CommandBarV2 paths={navPaths}>
          <NewAction text="masterdata.topics.newTopic" onClick={create} />
        </CommandBarV2>
      </MasterdataHeader>
      <HtcCardWrapper>
        <HtcCard>
          <SearchBox>
            <Input placeholder={t('masterdata.topics.searchText')} allowClear value={searchText} onChange={(ev) => setSearchText(_.trim(ev.target.value))} />
            <CheckableTagGroup
              isFilter={true}
              title={t('masterdata.departments.departmentType')}
              tags={departmentTagOptions}
              defaultSelected={filterDepartments}
              onSelectedTagsChange={(s) => setFilterDepartments(_.map(s, (tag) => tag.tag))}
              isMulti
            />
          </SearchBox>
        </HtcCard>
        <HtcCard>
          <FormBox title={t('common.results')} flex contentflex>
            <Table rowKey="id" columns={columns} dataSource={topics} loading={loading} />
          </FormBox>
        </HtcCard>
      </HtcCardWrapper>
    </ContentWrapper>
  );
};

function createColumns(t: TFunction, ctx: IUserInfoCtx, onEdit: (id: string) => void) {
  return [
    {
      title: t('masterdata.topics.name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text: string, record: ITopic) => ctx.t(record.name),
    },
    {
      title: t('masterdata.topics.usedInDepartments'),
      dataIndex: 'usedInDepartmentsUI',
      key: 'usedInDepartmentsUI',
      width: 400,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 100,
      render: (text: string, record: ITopic) => <Button id="editTopic" type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={() => onEdit(record.id)} />,
    },
  ];
}

export default TopicsList;

interface ITopicsListProps {
  topics: ITopicWithDepartmentUsages[];
  loading: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
  filterDepartments: string[];
  setFilterDepartments: (tags: string[]) => void;
  departmentTagOptions: ICheckableTag[];
}
