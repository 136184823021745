import React from 'react';

const checkIn = () => (
  <svg className="customIcon" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.41 3.41">
    {' '}
    <defs /> <rect className="cls-1" x="0.13" y="0.13" width="3.16" height="3.16" />{' '}
    <path d="M3.53,2.53l-.36.35L1.93,1.63a.06.06,0,0,0-.07,0l-.28.29s0,.05,0,.06L2.81,3.24l-.35.35s0,.08,0,.08h1s.05,0,.06-.05h0V2.54s0,0,0,0Z" transform="translate(-.92 -.92)" />{' '}
  </svg>
);
const checkOut = () => (
  <svg className="customIcon" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.41 3.41">
    {' '}
    <defs /> <rect className="cls-1" x="0.13" y="0.13" width="3.16" height="3.16" />{' '}
    <path d="M1.64,2.77,2,2.41,3.23,3.66a.06.06,0,0,0,.07,0l.28-.29s0,0,0-.06L2.35,2.05,2.7,1.7s0-.08,0-.08l-1.06,0a.08.08,0,0,0-.06,0V2.75a0,0,0,0,0,.05,0Z" transform="translate(-.92 -.92)" />{' '}
  </svg>
);
const checkOut_Cancel = () => (
  <svg className="customIcon" id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.41 3.41">
    {' '}
    <defs /> <rect className="cls-1" x="0.13" y="0.13" width="3.16" height="3.16" />{' '}
    <path
      d="M1.65,3.63H2l.07,0L2.63,3l.55.63a.12.12,0,0,0,.08,0h.37s.05,0,0-.06a0,0,0,0,0,0,0l-.79-.91.79-.91s0-.06,0-.07h-.4a.08.08,0,0,0-.08,0l-.55.64-.54-.64a.09.09,0,0,0-.07,0H1.65s-.06,0-.06,0a0,0,0,0,0,0,.05l.76.91-.76.91v.07S1.64,3.63,1.65,3.63Z"
      transform="translate(-.92 -.92)"
    />{' '}
  </svg>
);

const archive = () => (
  <svg className="customIcon archiveIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.43 9.37">
    {' '}
    <g id="Ebene_2" data-name="Ebene 2">
      {' '}
      <g id="Ebene_1-2" data-name="Ebene 1">
        {' '}
        <path d="M6.14,2.05H4.23a.15.15,0,0,0-.16.14v.13c0,.28,0,.28.09.28H5.75l0,4H4.42l0,.21a1.15,1.15,0,0,1-1.15.86,1.16,1.16,0,0,1-1.16-.86L2,6.61H.66l0-4H2.26c.08,0,.07-.11.07-.27V2.19a.15.15,0,0,0-.16-.14H.29A.28.28,0,0,0,0,2.31v6.8a.28.28,0,0,0,.29.26H6.14a.28.28,0,0,0,.29-.26V2.31A.28.28,0,0,0,6.14,2.05Z" />{' '}
        <path d="M4.58,3.74h-.9V.09A.09.09,0,0,0,3.58,0H2.85a.09.09,0,0,0-.09.09V3.74h-.9c-.09,0-.13.08-.08.14L3.14,5.41a.11.11,0,0,0,.14,0v0L4.66,3.88a.08.08,0,0,0,0-.12Z" />{' '}
      </g>{' '}
    </g>{' '}
  </svg>
);
const archive_revert = () => (
  <svg className="customIcon archiveIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.43 9.37">
    {' '}
    <g id="Ebene_2" data-name="Ebene 2">
      {' '}
      <g id="Ebene_1-2" data-name="Ebene 1">
        {' '}
        <path d="M6.14,2.05H4.23a.15.15,0,0,0-.16.14v.13c0,.28,0,.28.09.28H5.75l0,4H4.42l0,.21a1.15,1.15,0,0,1-1.15.86,1.16,1.16,0,0,1-1.16-.86L2,6.61H.66l0-4H2.26c.08,0,.07-.11.07-.27V2.19a.15.15,0,0,0-.16-.14H.29A.28.28,0,0,0,0,2.31v6.8a.28.28,0,0,0,.29.26H6.14a.28.28,0,0,0,.29-.26V2.31A.28.28,0,0,0,6.14,2.05Z" />{' '}
        <path d="M1.86,1.7h.89V5.36a.1.1,0,0,0,.1.09h.73a.1.1,0,0,0,.1-.09V1.7h.9a.09.09,0,0,0,.08-.14L3.29,0a.1.1,0,0,0-.13,0h0L1.78,1.56a.08.08,0,0,0,0,.12S1.83,1.7,1.86,1.7Z" />{' '}
      </g>{' '}
    </g>{' '}
  </svg>
);
const archive_show = () => (
  <svg className="customIcon" viewBox="0 0 6.4 7.3">
    <path d="M6.1 0H.3C.1 0 0 .1 0 .3v6.8c0 .1.1.3.3.3h5.8c.2 0 .3-.1.3-.3V.3c0-.2-.1-.3-.3-.3zM4.4 4.6v.2c-.1.5-.6.8-1.2.8s-1-.4-1.2-.9v-.2H.7v-4H5.8v4H4.4z" />
    <path d="M1 1H5.5V1.5H1z" />
    <path d="M1 1.8H5.5V2.3H1z" />
    <path d="M1 2.6H5.5V3.1H1z" />
    <path d="M1 3.4H5.5V3.9H1z" />
  </svg>
);

//Status Icons
const paraFile = () => (
  <svg className="customIcon" viewBox="0 0 6.4 7.3">
    <path d="M6.1 3.2h-.6v-2C5.5.5 5 0 4.4 0H2.1C1.4 0 .9.5.9 1.2v2H.3c-.2 0-.3.2-.3.3V7c0 .2.1.3.3.3h5.8c.2 0 .3-.1.3-.3V3.5c0-.1-.1-.3-.3-.3zM3.5 5.4v.5s0 .1-.1.1H3s-.1 0-.1-.1v-.5c-.1-.2-.2-.4 0-.6s.4-.2.6-.1c.2.1.2.4.1.6-.1 0-.1.1-.1.1zm1.4-2.2H1.6v-2c0-.3.2-.5.5-.5h2.3c.3 0 .5.2.5.5v2z" />
  </svg>
);
const checkedOut = () => (
  <svg className="customIcon" viewBox="0 0 6 6">
    <path d="M3.3.3L0 0l.3 3.3 1-1L5 6h.2l.8-.9v-.2L2.3 1.3l1-1z" />
  </svg>
);

export default {
  checkIn,
  checkOut,
  checkOut_Cancel,
  archive,
  archive_revert,
  checkedOut,
  archive_show,
  paraFile,
};
