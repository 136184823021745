import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useMedia } from 'react-use';

export const SizeDetector: React.FC<{ onChange: (isSmall: boolean) => void }> = ({ onChange }) => {
  const isSmall = useIsSmallDevice();
  useEffect(() => {
    onChange(isSmall);
  }, [isSmall]);
  return null;
};

export function useIsSmallDevice() {
  return useMedia('(max-width: 1000px)');
}

export function isMobileDevice() {
  return isMobile;
}

export function getSubmenuAction(): 'hover' | 'click' {
  return isMobile ? 'click' : 'hover';
}
