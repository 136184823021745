import React, { useState, useMemo } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import { Table as AntTable, Empty } from 'antd';
import styles from './Table.module.css';
import { TableProps } from 'antd/lib/table';
import { useDebounce } from 'react-use';
import _ from 'lodash';

const TableLocale = {
  emptyText: <Empty description={false} />,
};

function alternatingRowStyle<T>(record: T, index: number): string {
  return index % 2 === 0 ? styles.row : styles.alternaterow;
}

function canScroll(dataSource: any[]) {
  return dataSource && dataSource.length > 0;
}

export const Table: React.FC<TableProps<any>> = ({ rowClassName = alternatingRowStyle, rowSelection, rowKey, columns, dataSource, onRow, onChange, loading, pagination, scroll }) => {
  const paginationToApply = pagination == null ? { pageSize: 50 } : pagination;
  const scrollConfig = useMemo(() => {
    if (!canScroll(dataSource as any)) {
      return null;
    }

    return _.assign({ x: true }, scroll);
  }, [dataSource, scroll]);

  return (
    <AntTable
      style={{ width: '100%' }}
      onRow={onRow}
      rowKey={rowKey}
      rowSelection={rowSelection}
      rowClassName={rowClassName}
      columns={columns}
      dataSource={dataSource}
      onChange={onChange}
      size="small"
      pagination={paginationToApply}
      loading={loading}
      locale={TableLocale}
      scroll={scrollConfig}
    />
  );
};

export const ScrollTable: React.FC<TableProps<any>> = ({ rowClassName = alternatingRowStyle, rowSelection, rowKey, columns, dataSource, onRow, onChange, loading, pagination }) => {
  const [scroll, setScroll] = useState<{ y: number | boolean; x: number | boolean }>({ y: true, x: true });
  const [height, setHeight] = useState(100);
  useDebounce(
    () => {
      const scrollHeight = height - 80;
      const newScroll = { x: true, y: scrollHeight > 0 ? scrollHeight : true };
      setScroll(newScroll);
    },
    50,
    [height],
  );

  const paginationToApply = pagination == null ? { pageSize: 50 } : pagination;
  return (
    <ContainerDimensions>
      {({ height, width }) => {
        setHeight(height);
        return (
          <AntTable
            style={{ width: '100%' }}
            onRow={onRow}
            rowKey={rowKey}
            rowSelection={rowSelection}
            rowClassName={rowClassName}
            columns={columns}
            dataSource={dataSource}
            onChange={onChange}
            size="small"
            pagination={paginationToApply}
            scroll={scroll as any}
            loading={loading}
            locale={TableLocale}
          />
        );
      }}
    </ContainerDimensions>
  );
};
