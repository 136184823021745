import _ from 'lodash';
import { connect } from 'react-redux';
import AppComponent from './AppComponent';
import { IRootState, getActiveTenant, getUserPermissions } from './ducks/reducer';
import { setActiveTenant } from './tenantselection/ducks/actions';

const mapStateToProps = (state: IRootState) => {
  return {
    user: state.app.user,
    userInfo: state.app.userInfo,
    activeTenant: getActiveTenant(state.app),
    permissions: getUserPermissions(state.app),
  };
};
export default connect(mapStateToProps, {
  selectActiveTenant: setActiveTenant.request,
})(AppComponent);
