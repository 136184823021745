import { UserPermissions } from './UserPermissions';
import { CanCreateRiskAssessment } from './RiskAppClaims';

export class RiskAssessmentPermissions {
  constructor(private permissions: UserPermissions) {}

  public get CanCreateRiskAssessment() {
    return this.hasBranchClaim(CanCreateRiskAssessment);
  }

  private hasBranchClaim(type: string) {
    return this.permissions.hasBranchClaim(type);
  }
}
