import React from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { notifyError } from '../../notificationHelper';
import { InboxOutlined } from '@ant-design/icons';

export const FileUploadBox: React.FC<IFileUploadBoxProps> = ({ disabled, setFile, uploadDesc, uploadHint, existingFileName }) => {
  const isCorrectExtension = (fileName: string) => {
    if (fileName != '' && existingFileName != '') {
      const curExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      const destExt = existingFileName.substr(existingFileName.lastIndexOf('.') + 1);
      return curExt == destExt;
    }
    return true;
  };

  const onChange = (file: any) => {
    if (file && !isCorrectExtension(file.file.name)) {
      notifyError('commonActions.fileError');
    } else {
      setFile(file);
    }
  };
  return (
    <Dragger disabled={disabled} name="file" multiple={false} customRequest={onChange} showUploadList={false}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{uploadDesc}</p>
      <p className="ant-upload-hint">{uploadHint}</p>
    </Dragger>
  );
};

interface IFileUploadBoxProps {
  disabled: boolean;
  setFile: (file: any) => void;
  existingFileName: string;
  uploadDesc: string;
  uploadHint: string;
}
