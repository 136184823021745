import React, { SFC, useState } from 'react';
import { IUserEditViewModel, ITenantConfiguration, IBranchOfIndustryRef, createTenantConfig, ITenantRef, IDepartmentRefWithTenantAndBranch } from '../models/user';
import { Card } from 'antd';
import * as _ from 'lodash';
import { FieldArray, FormikProps } from 'formik';

import styles from './UserEditForm.module.css';
import { TFunction } from 'i18next';
import FormBox from '../shared/components/FormBox/FormBox';
import { TenantConfiguration } from './TenantConfiguration';
import classnames from 'classnames';
import { ITenantInfo } from '../models/masterdata/ITenant';
import TagGroup from '../shared/components/TagGroup/TagGroup';
import { UserPermissions } from '../models';
import { HtcCard } from '../shared/HtcCardComponent';
import { FormTrLabel } from '../shared/FormComponents';

export const TenantConfigurations: SFC<{
  formik: FormikProps<IUserEditViewModel>;
  t: TFunction;
  assignableDepartments: IDepartmentRefWithTenantAndBranch[];
  assignableBranchesOfIndustry: IBranchOfIndustryRef[];
  assignableTenants: ITenantRef[];
  allTenants: ITenantInfo[];
  permissions: UserPermissions;
}> = React.memo(({ formik, t, assignableBranchesOfIndustry, assignableTenants, assignableDepartments, allTenants, permissions }) => {
  const { values } = formik;
  const tr = getTranslateFunc(t);
  const isTenantEditable = (tenantRef: ITenantRef) => permissions.security.canEditTenant(tenantRef.id, assignableBranchesOfIndustry, assignableDepartments);
  const assignedTenants = values.assignedTenants || [];
  const [selectedTenantIndex, setSelectedTenantIndex] = useState(0);
  const selectedTenant = assignedTenants[selectedTenantIndex];
  return (
    <>
      <FieldArray
        name="assignedTenants"
        render={(arrayHelpers) => (
          <div>
            <HtcCard>
              <FormBox title={tr('tenantAssignments')}>
                <TagGroup
                  title={tr('availableTenants')}
                  tags={getNotAssignedTenantsTags(permissions, formik.values.assignableTenants, assignedTenants.filter(isTenantEditable))}
                  onClick={(t) => arrayHelpers.push(createTenantConfig(t.object))}
                />
              </FormBox>
            </HtcCard>
            <HtcCard>
              {_.some(assignableTenants, isTenantEditable) && (
                <>
                  <FormBox title={tr('tenantConfigSelection')}>
                    <div className={styles.tenantcardcontainer}>
                      {values.assignedTenants.map((tenant, index) => {
                        const tenantInfo = _.find(allTenants, (t) => t.id === tenant.id);
                        if (!isTenantEditable(tenant)) {
                          return null;
                        }
                        return (
                          <Card
                            key={index}
                            hoverable
                            className={classnames(styles.tenantcard, index == selectedTenantIndex ? styles.selectedtenantcard : '')}
                            cover={<img alt="logo" src={process.env.PUBLIC_URL + 'assets/' + (tenantInfo.logo || 'logosmall.png')} className={styles.tenantcardimage} />}
                            onClick={() => setSelectedTenantIndex(index)}
                          >
                            <h5 className={styles.tenantcardtitle}>
                              {tenant.name} ({tenantInfo.abbr})
                            </h5>
                          </Card>
                        );
                      })}
                    </div>
                    <div style={{ border: '1px solid #62a8ee' }}>
                      <FormTrLabel text={tr('tenantConfiguration', { tenant: selectedTenant && selectedTenant.name })} />
                      {!_.isEmpty(assignedTenants) && (
                        <TenantConfiguration
                          formik={formik}
                          t={t}
                          tenant={selectedTenant}
                          valuesIndex={selectedTenantIndex}
                          assignableBranchesOfIndustry={assignableBranchesOfIndustry}
                          assignableDepartments={assignableDepartments}
                          permissions={permissions}
                          assignedTenants={assignedTenants}
                          onTenantRemove={() => {
                            setSelectedTenantIndex(0);
                            arrayHelpers.remove(selectedTenantIndex);
                          }}
                        />
                      )}
                    </div>
                  </FormBox>
                </>
              )}
            </HtcCard>
          </div>
        )}
      />
    </>
  );
});

TenantConfigurations.displayName = 'TenantConfigurations';

function getNotAssignedTenantsTags(permissions: UserPermissions, assignableTenants: ITenantRef[], assignedTenants: ITenantConfiguration[]) {
  return _.differenceBy(assignableTenants, assignedTenants, 'id')
    .filter((t) => permissions.security.canAssignUserToTenant(t.id))
    .map((t) => ({ tag: t.id, title: t.name, object: t }));
}

function getTranslateFunc(t: TFunction) {
  return (key: string, values: any = undefined) => t('users.userEditForm.' + key, values);
}
