// import "babel-polyfill";
// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
// import "./polyfill.js";
import { initialize } from './AppInitializer';
import './index.css';
import * as serviceWorker from './serviceWorker';

initialize();

serviceWorker.unregister();
