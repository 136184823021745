import React from 'react';
import { DocumentActionIcon } from './DocumentActionIcon';
import styles from './DocumentList.module.css';
import documentPermissionHelper, { IDocumentPermissionInfo } from './DocumentPermissionHelper';
import { IDocumentMetadata } from '../../../models/documents/IDocumentMetaData';
import { useTranslation } from 'react-i18next';
import customIcon from './CustomIcon';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

export const DocumentActionIconRow: React.FC<IDocumentActionIconRowProps> = ({
  disabled,
  documentPermissionInfo,
  record,
  onEditClicked,
  onCheckInClicked,
  onCheckOutClicked,
  onRevertCheckOutClicked,
  onArchiveClicked,
  onRevertArchiveClicked,
  onDeleteClicked,
}) => {
  const [t] = useTranslation();
  return (
    <div className={styles.actionContainer}>
      <DocumentActionIcon
        disabled={!documentPermissionHelper.canRead(documentPermissionInfo)}
        onClick={() => onEditClicked()}
        toolTip={record.isCheckedOut ? t('components.DocumentList.editDocument') : t('components.DocumentList.showDocument')}
        icon={<EyeOutlined />}
      />
      {documentPermissionHelper.isCheckInButtonVisible(documentPermissionInfo) && (
        <DocumentActionIcon
          disabled={disabled || !documentPermissionHelper.canCheckIn(documentPermissionInfo)}
          onClick={() => onCheckInClicked()}
          toolTip={t('components.DocumentList.checkInDocument')}
          icon={customIcon.checkIn()}
        />
      )}
      {documentPermissionHelper.isCheckOutButtonVisible(documentPermissionInfo) && (
        <DocumentActionIcon
          disabled={disabled || !documentPermissionHelper.canCheckOut(documentPermissionInfo)}
          onClick={() => onCheckOutClicked()}
          toolTip={t('components.DocumentList.checkOutDocument')}
          icon={customIcon.checkOut()}
        />
      )}
      {documentPermissionHelper.isRevertCheckOutButtonVisible(documentPermissionInfo) && (
        <DocumentActionIcon
          disabled={disabled || !documentPermissionHelper.canRevertCheckIn(documentPermissionInfo)}
          onClick={() => onRevertCheckOutClicked()}
          toolTip={t('components.DocumentList.revertCheckOutDocument')}
          icon={customIcon.checkOut_Cancel()}
        />
      )}
      {documentPermissionHelper.isArchiveButtonVisible(documentPermissionInfo) && (
        <DocumentActionIcon
          disabled={disabled || !documentPermissionHelper.canArchive(documentPermissionInfo)}
          onClick={() => onArchiveClicked()}
          toolTip={t('components.DocumentList.archiveDocument')}
          icon={customIcon.archive()}
        />
      )}
      {documentPermissionHelper.isRevertArchiveButtonVisible(documentPermissionInfo) && (
        <DocumentActionIcon
          disabled={disabled || !documentPermissionHelper.canRevertArchive(documentPermissionInfo)}
          onClick={() => onRevertArchiveClicked()}
          toolTip={t('components.DocumentList.revertArchiveDocument')}
          icon={customIcon.archive_revert()}
        />
      )}

      {documentPermissionHelper.isDeleteButtonVisible(documentPermissionInfo) && (
        <DocumentActionIcon
          disabled={disabled || !documentPermissionHelper.canDelete(documentPermissionInfo)}
          onClick={() => onDeleteClicked()}
          toolTip={t('components.DocumentList.deleteDocument')}
          icon={<DeleteOutlined />}
        />
      )}
    </div>
  );
};

interface IDocumentActionIconRowProps {
  disabled: boolean;
  record: IDocumentMetadata;
  documentPermissionInfo: IDocumentPermissionInfo;
  onEditClicked: () => void;
  onCheckInClicked: () => void;
  onCheckOutClicked: () => void;
  onRevertCheckOutClicked: () => void;
  onArchiveClicked: () => void;
  onRevertArchiveClicked: () => void;
  onDeleteClicked: () => void;
}
