import React from 'react';
import _ from 'lodash';
import { IPickerBaseProps, IPickerBaseState } from '../PickerBase/PickerBase';
import { IAzureUserRef } from '../../../models/user';
import { Select } from 'antd';
import styles from './AzureUserPicker.module.css';
import StringPickerBase from '../PickerBase/StringPickerBase';
import { userService } from '../../services/userService';

const Option = Select.Option;

class AzureUserPicker extends StringPickerBase<IAzureUserRef> {
  constructor(props: any) {
    super(props);
  }
  static getDerivedStateFromProps(props: IPickerBaseProps<IAzureUserRef>, state: IPickerBaseState<IAzureUserRef>): Partial<IPickerBaseState<IAzureUserRef>> {
    if ((state.value && (state.value as IAzureUserRef).id) !== (props.value && (props.value as IAzureUserRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<IAzureUserRef[]> {
    const onQueryItems = this.props.onQueryItems || userService.queryAvailableAzureUsersOptions;
    return onQueryItems(query);
  }

  getOptionTitle(ref: { id: string; name: string }): string {
    return ref.name;
  }

  getOptions = (data: IAzureUserRef[]) => {
    data = data || [];
    this.ensureSelectedValueIsOption(data);
    return !data || data.length === 0
      ? null
      : data.map(
          (d) =>
            d != undefined && (
              <Option key={d.id} title={d.name} value={d.id}>
                <div className={styles.firstLine}>{d.name}</div>
                <div className={styles.secondLine}>{d.user.mail || '-'}</div>
                <div className={styles.thirdLine}>
                  {d.user.companyName || '-'} ({d.user.tenantName || '-'})
                </div>
              </Option>
            ),
        );
  };
}

export default AzureUserPicker;
