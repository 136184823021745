export const RiskAppBranchId = 'Risk_Common_Branch';

export const KnownTenantIds = {
  Kiefel: '5d1c841a7ac8454f0c8ae101',
  PackSys: '5d1c841a7ac8454f0c8ae102',
  Bms: '5d1c841a7ac8454f0c8ae103',
  Servtec: '5d1c841a7ac8454f0c8ae104',
  Halvotec: '5d1c841a7ac8454f0c8ae105',
};

export const DefaultPageSize = 15;

export const DefaultDocumentListScrollConfig = { y: 170 };
