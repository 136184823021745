import React, { FC, useState, useCallback, useContext } from 'react';
import { getRiskAssessmentEvaluation, getPathToRiskAssessmentEvaluationField } from '../../formhelper';
import { useTranslation } from 'react-i18next';
import { RiskAssessmentUpdates } from '../../../../models/riskAssessment/RiskAssessmentUpdates';
import FormBox from '../../../../shared/components/FormBox/FormBox';
import { SingleFormRow, FormMoneyInput } from '../../../../shared/FormComponents';
import styles from './EvaluationPage.module.css';
import { Button, Popconfirm, Drawer } from 'antd';
import { IRiskAssessment, IRiskAssessmentEditVm, IRiskAssessmentConfig, IRiskAssessmentEvaluation, IRiskAssessmentOnSaveCallback } from '../../../../models/riskAssessment';
import { FormikProps } from 'formik';
import RiskAmounts, { IDepartmentEvaluationTotals } from '../../../../models/riskAssessment/RiskAmounts';
import { UserInfoCtx } from '../../../../UserInfoContext';
import { ValidationErrorDrawer } from '../../ValidationErrorDrawer';
import { ValidationError } from 'yup';
import RiskAssessmentStatusValidators from '../../../../models/riskAssessment/RiskAssessmentStatusHelper';
import { CalculatorOutlined } from '@ant-design/icons';

const TP = 'riskAssessment.editform.assessment.';

export const Risks: FC<IRiskProps> = ({ formik, departmentId, onSave, riskAssessmentVm, disabled = false, finishButtonDisabled = false }) => {
  const assessment = formik.values;
  const config = riskAssessmentVm.commonConfig;
  const evaluation = getRiskAssessmentEvaluation(assessment, departmentId);
  const [t] = useTranslation();
  const [showCompletePane, setShowCompletePane] = useState(false);
  const onCancel = () => setShowCompletePane(false);
  const showPane = () => setShowCompletePane(true);
  const completeEvaluation = useCallback(() => {
    setShowCompletePane(false);
    const updates = RiskAssessmentUpdates.from(riskAssessmentVm.assessment, formik.values).completeEvaluation(evaluation.department);
    onSave(updates);
  }, [riskAssessmentVm.assessment, formik.values, evaluation.department, onSave]);
  const reopenEvaluation = useCallback(() => {
    setShowCompletePane(false);
    const updates = RiskAssessmentUpdates.from(riskAssessmentVm.assessment, formik.values).reopenEvaluation(evaluation.department);
    onSave(updates);
  }, [riskAssessmentVm.assessment, formik.values, evaluation.department, onSave]);

  const field = getPathToRiskAssessmentEvaluationField;
  return (
    <FormBox style={{ width: '18rem' }} title={t(TP + 'risks')}>
      <SingleFormRow>
        <CalulatedRiskAmountInput
          formik={formik}
          labelKey="riskamount"
          fieldName={field(formik.values, departmentId, 'riskAmount')}
          departmentId={departmentId}
          disabled={disabled}
          onGetCalculatedRisk={(risks) => risks.riskAmountFromTopics}
          config={config}
        />
      </SingleFormRow>
      <SingleFormRow>
        <CalulatedRiskAmountInput
          formik={formik}
          labelKey="measurementAmount"
          fieldName={field(formik.values, departmentId, 'measureAmount')}
          departmentId={departmentId}
          disabled={disabled}
          onGetCalculatedRisk={(risks) => risks.riskMeasureAmountFromTopics}
          config={config}
        />
      </SingleFormRow>
      <SingleFormRow>
        <CalulatedRiskAmountInput
          formik={formik}
          labelKey="riskAfterMeasurement"
          fieldName={field(formik.values, departmentId, 'riskAfterMeasureAmount')}
          departmentId={departmentId}
          disabled={disabled}
          canCalulate={false}
          config={config}
        />
      </SingleFormRow>
      {evaluation.isCompleted ? (
        <>
          <SingleFormRow>
            <Button block type="primary" onClick={showPane} disabled={finishButtonDisabled} style={{ marginTop: '0.5rem' }} data-automation-id="button-reopenEvaluation">
              {t(TP + 'reopenEvaluation')}
            </Button>
          </SingleFormRow>
          <ReopenEvaluationPane visible={showCompletePane} onCancel={onCancel} reopenEvaluation={reopenEvaluation} />
        </>
      ) : (
        <>
          <SingleFormRow>
            <Button block type="primary" onClick={showPane} disabled={finishButtonDisabled} style={{ marginTop: '0.5rem' }} data-automation-id="button-completeEvaluation">
              {t(TP + 'completeEvaluation')}
            </Button>
          </SingleFormRow>
          <CompleteEvaluationPane config={config} visible={showCompletePane} onCancel={onCancel} completeEvaluation={completeEvaluation} evaluation={evaluation} />
        </>
      )}
    </FormBox>
  );
};

interface IRiskProps {
  departmentId: string;
  formik: FormikProps<IRiskAssessment>;
  onSave: IRiskAssessmentOnSaveCallback;
  riskAssessmentVm: IRiskAssessmentEditVm;
  disabled: boolean;
  finishButtonDisabled: boolean;
}

const CalulatedRiskAmountInput: FC<{
  formik: FormikProps<IRiskAssessment>;
  departmentId: string;
  disabled: boolean;
  fieldName: string;
  labelKey: string;
  config: IRiskAssessmentConfig;
  canCalulate?: boolean;
  onGetCalculatedRisk?: (amount: IDepartmentEvaluationTotals) => number;
}> = ({ formik, disabled, fieldName, labelKey, departmentId, onGetCalculatedRisk, config, canCalulate = true }) => {
  const [t] = useTranslation();
  const cantCalculateOnClick = disabled || !canCalulate;
  return (
    <div className={styles.calculationbuttoncontainer}>
      <FormMoneyInput style={{ flex: 1 }} {...formik} label={t(TP + labelKey)} name={fieldName} disabled={disabled} currency={config.currency} dataAutomationId={'calculatedRiskAmount-' + labelKey} />
      <Popconfirm
        disabled={cantCalculateOnClick}
        title={t('components.CalulatedRiskAmountInput.message')}
        onConfirm={() => {
          const amountTotals = RiskAmounts.getTotalsForDepartment(formik.values, departmentId);
          const risk = onGetCalculatedRisk(amountTotals);
          formik.setFieldValue(fieldName as any, risk);
        }}
        okText={t('components.CalulatedRiskAmountInput.ok')}
        cancelText={t('components.CalulatedRiskAmountInput.cancel')}
      >
        <Button tabIndex={-1} className={styles.calculationbutton} icon={<CalculatorOutlined />} type="primary" disabled={cantCalculateOnClick}></Button>
      </Popconfirm>
    </div>
  );
};

const CompleteEvaluationPane: FC<{ config: IRiskAssessmentConfig; visible: boolean; onCancel: () => void; completeEvaluation: () => void; evaluation: IRiskAssessmentEvaluation }> = ({
  visible,
  onCancel,
  completeEvaluation,
  evaluation,
  config,
}) => {
  const [t] = useTranslation();
  const userInfoCtx = useContext(UserInfoCtx);

  if (!visible) {
    return null;
  }

  const validation = validate(evaluation);
  if (hasErrors(validation)) {
    return <ValidationErrorDrawer userInfoCtx={userInfoCtx} config={config} visible={visible} onCancel={onCancel} validationError={validation} title={t(TP + 'missingdataheader')} />;
  }
  return (
    <Drawer placement="right" visible={visible} width={500} title={t('components.CompleteEvaluationPane.header')} onClose={onCancel}>
      <p>{t('components.CompleteEvaluationPane.message')}</p>
      <div>
        <Button style={{ marginRight: '0.5rem' }} onClick={onCancel}>
          {t('common.no')}
        </Button>
        <Button type="primary" onClick={completeEvaluation}>
          {t('common.yes')}
        </Button>
      </div>
    </Drawer>
  );
};

const hasErrors = (validation: any) => {
  return validation != null && validation.errors.length > 0;
};

const validate = (evaluation: IRiskAssessmentEvaluation): ValidationError => {
  try {
    RiskAssessmentStatusValidators.getValidatorForCompleteEvaluation().validateSync(evaluation, {
      abortEarly: false,
    });
  } catch (e) {
    return e as any;
  }
  return null;
};

const ReopenEvaluationPane: FC<{ visible: boolean; onCancel: () => void; reopenEvaluation: () => void }> = ({ visible, onCancel, reopenEvaluation }) => {
  const [t] = useTranslation();

  return (
    <Drawer placement="right" visible={visible} width={500} title={t('components.ReopenEvaluationPane.header')} onClose={onCancel}>
      <p>{t('components.ReopenEvaluationPane.header')}</p>
      <div>
        <Button style={{ marginRight: '0.5rem' }} onClick={onCancel}>
          {t('common.no')}
        </Button>
        <Button type="primary" onClick={reopenEvaluation}>
          {t('common.yes')}
        </Button>
      </div>
    </Drawer>
  );
};
