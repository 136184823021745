import PickerBase, { IPickerBaseProps, IsRef } from './PickerBase';

abstract class StringPickerBase<P extends IsRef> extends PickerBase<P, {}> {
  constructor(props: IPickerBaseProps<P>) {
    super(props);
  }

  getDisplayName(name: any): string {
    return name;
  }
}

export default StringPickerBase;
