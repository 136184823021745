import React, { FC, useContext, useState } from 'react';

import _ from 'lodash';
import { Tabs } from 'antd';
import { ILanguage } from '../../../models/LanguageSettings';
import { UserInfoCtx } from '../../../UserInfoContext';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import SettingsService from '../../services/settingsService';

const { TabPane } = Tabs;
const LanguageTabPage: FC<ILanguageTabProps> = React.memo(({ tabs, onLanguageSwitched, defaultActiveLanguage, children }) => {
  const context = useContext(UserInfoCtx);
  const [langTabs] = useState(tabs || SettingsService.getConfig().availableLanguages || []);

  if (!defaultActiveLanguage) {
    defaultActiveLanguage = langTabs[0];
  }

  const [lang, setLang] = useState(defaultActiveLanguage);

  return (
    <Tabs
      animated={false}
      defaultActiveKey={defaultActiveLanguage.key}
      onChange={(tabKey) => {
        setLang(_.find(langTabs, (t) => t.key === tabKey));
        if (onLanguageSwitched) {
          onLanguageSwitched(tabKey);
        }
      }}
    >
      {_.map(langTabs, (t) => (
        <TabPane tab={getForLanguage(t.name, context.languageSettings)} key={t.key}>
          {children({ lang })}
        </TabPane>
      ))}
    </Tabs>
  );
});

LanguageTabPage.displayName = 'LanguageTabPage';

export default LanguageTabPage;

interface ILanguageTabProps {
  tabs?: ILanguage[];
  onLanguageSwitched?: (language: string) => void;
  defaultActiveLanguage?: ILanguage;
  children(props: IInjectedLanguageProps): JSX.Element;
}

export interface IInjectedLanguageProps {
  lang: ILanguage;
}
