import React, { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { History } from 'history';
import { Tabs } from 'antd';
import queryString from 'query-string';
import styles from './DocumentTabs.module.css';
import _ from 'lodash';

export const DocumentTabs: React.FC<{ defaultActiveKey?: string }> = ({ defaultActiveKey, children }) => {
  const location = useLocation();
  const history = useHistory();

  const values = queryString.parse(location.search);
  const docTab = (values.docTab as string) || defaultActiveKey;

  useEffect(() => {
    let availableTabs = [];
    React.Children.forEach(children, (child: any) => {
      if (child && child.key) {
        availableTabs.push(child.key);
      }
    });

    if (_.indexOf(availableTabs, docTab) === -1) {
      navigateToDocTab(history, availableTabs[0]);
    }
  }, [children, docTab, history]);

  const docTabChanged = useCallback((docTab: string) => navigateToDocTab(history, docTab), [history]);

  return (
    <Tabs className={styles.DocumentTabs} type="card" animated={false} activeKey={docTab} onChange={docTabChanged}>
      {children}
    </Tabs>
  );
};

function navigateToDocTab(history: History<{}>, docTab: string) {
  if (!docTab) {
    return;
  }
  history.push({ pathname: window.location.pathname, search: '?docTab=' + docTab });
}
