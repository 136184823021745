import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { ActionType, isActionOf } from 'typesafe-actions';
import { IRootState } from '../../ducks/reducer';
import { from, of } from 'rxjs';
import RiskAssessmentService from '../../shared/services/riskAssessmentService';
import { ICreateRiskAssessmentCommand, ICreateRiskAssessmentResult } from '../../models/riskAssessment';
import Routes, { navigate } from '../../shared/Routes';

type Action = ActionType<typeof actions>;

export const getRiskAssessmentListFlow: Epic<Action, Action, IRootState> = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(actions.getRiskAssessmentList.request)),
    switchMap((request) =>
      from(RiskAssessmentService.getRiskAssessmentList(request.payload)).pipe(
        map(actions.getRiskAssessmentList.success),
        catchError((error) => of(actions.getRiskAssessmentList.failure(error))),
      ),
    ),
  );

export const getRiskAssessmentStatusMonitorFlow: Epic<Action, Action, IRootState> = (actions$, state$) =>
  actions$.pipe(
    filter(isActionOf(actions.getRiskAssessmentStatusMonitor.request)),
    switchMap((request) =>
      from(RiskAssessmentService.getRiskAssessmentStatusMonitor(request.payload)).pipe(
        map(actions.getRiskAssessmentStatusMonitor.success),
        catchError((error) => of(actions.getRiskAssessmentStatusMonitor.failure(error))),
      ),
    ),
  );

export const createRiskAssessmentFlow: Epic<Action, Action, IRootState> = (action$, state) =>
  action$.pipe(
    filter(isActionOf(actions.createRiskAssessment.request)),
    switchMap((action) =>
      from(createRiskAssessmentItem(action.payload)).pipe(
        map(actions.createRiskAssessment.success),
        map((action) => {
          navigate(Routes.EditRiskAssessment.getPath(action.payload.token));
          window.location.reload();
          return action;
        }),
        catchError((error) => of(actions.createRiskAssessment.failure(error))),
      ),
    ),
  );

const createRiskAssessmentItem = async (item: ICreateRiskAssessmentCommand): Promise<ICreateRiskAssessmentResult> => {
  return RiskAssessmentService.createNewRiskAssessment(item);
};

export const riskAssessmentEpics = combineEpics(getRiskAssessmentListFlow, createRiskAssessmentFlow, getRiskAssessmentStatusMonitorFlow);
