import { FC, useContext } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import IconNavLink from '../../../shared/components/IconNavLink/IconNavLink';
import {
  IRiskAssessment,
  getDepartmentStagesToEvaluate,
  IRiskAssessmentPermissions,
  IRiskAssessmentConfig,
  getFlowsWithAccessibleStages,
  getAccessibleStagesForFlow,
  IApprovalFlow,
} from '../../../models/riskAssessment';
import { TFunction } from 'i18next';
import * as _ from 'lodash';
import { UserInfoCtx, IUserInfoCtx } from '../../../UserInfoContext';
import { getRiskAssessmentEditRoutes } from '../../../shared/Routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { getIconForFlowById, getIconForStageById } from '../formhelper';
import formatter from '../../../shared/formatter';
import { EuroOutlined, SafetyCertificateOutlined, CalculatorOutlined, HomeOutlined, FileTextOutlined, InfoCircleOutlined, MessageOutlined, FormOutlined, BuildOutlined } from '@ant-design/icons';
import { getSubmenuAction } from '../../../shared/components/SizeDetector';

const SubMenu = Menu.SubMenu;

const TR = 'riskAssessment.menu.';
const MainMenu: FC<IMainMenuProps> = (props) => {
  const [t] = useTranslation();
  const context = useContext(UserInfoCtx);

  const menuOptions: IMenuOptions = {
    ...props,
    t,
    context,
  };

  return (
    <Menu triggerSubMenuAction={getSubmenuAction()} selectedKeys={getSelectedMenuItems()} style={{ borderBottom: 0 }} mode="horizontal">
      {createMetadataMenu(menuOptions, context)}
      {createAssessmentMenu(menuOptions)}
      {createOverviewMenu(menuOptions)}
      {createApprovalMenu(menuOptions)}
    </Menu>
  );
};

function getSelectedMenuItems(): string[] {
  const pathParts = _.split(window.location.pathname, '/');
  const length = pathParts.length;
  if (length <= 1) {
    return null;
  }
  return [`${pathParts[length - 2]}/${pathParts[length - 1]}`];
}

export default withRouter(MainMenu);

interface IMainMenuProps extends RouteComponentProps {
  riskAssessment: IRiskAssessment;
  config: IRiskAssessmentConfig;
  permissions: IRiskAssessmentPermissions;
}

interface IMenuOptions extends IMainMenuProps {
  context: IUserInfoCtx;
  t: TFunction;
}

const createMetadataMenu = (options: IMenuOptions, userCtx: IUserInfoCtx) => {
  const { permissions, match, config } = options;
  if (!permissions.canReadMetadata) {
    return null;
  }
  const routes = getRiskAssessmentEditRoutes(match);
  return (
    <SubMenu
      title={
        <span className="submenu-title-wrapper">
          <EuroOutlined />
          {userCtx.t(config.salesDepartment)}
        </span>
      }
    >
      <Menu.Item key="metadata/basicmetadata">
        <IconNavLink icon={HomeOutlined} nameTrPrefix={TR} nameTrKey="metadata.metadata" to={routes.metadata.basicMetadata} dataAutomationId="riskmenu-basicmetadata" />
      </Menu.Item>
      {permissions.canReadCalculation && (
        <Menu.Item key="metadata/calculation">
          <IconNavLink icon={CalculatorOutlined} nameTrPrefix={TR} nameTrKey="metadata.calculation" to={routes.metadata.calculation} dataAutomationId="riskmenu-calculation" />
        </Menu.Item>
      )}
      <Menu.Item key="metadata/documents">
        <IconNavLink icon={FileTextOutlined} nameTrPrefix={TR} nameTrKey="metadata.documents" to={routes.metadata.documents} dataAutomationId="riskmenu-documents" />
      </Menu.Item>
      {config.hasMultipleApprovalFlows && (
        <Menu.Item key="metadata/additionalinformation">
          <IconNavLink
            icon={InfoCircleOutlined}
            nameTrPrefix={TR}
            nameTrKey="metadata.additionalinformation"
            to={routes.metadata.additionalInformation}
            dataAutomationId="riskmenu-additionalInformation"
          />
        </Menu.Item>
      )}
      <Menu.Item key="metadata/communication">
        <IconNavLink icon={MessageOutlined} nameTrPrefix={TR} nameTrKey="metadata.communication" to={routes.metadata.communication} dataAutomationId="riskmenu-communication" />
      </Menu.Item>
    </SubMenu>
  );
};

const createAssessmentMenu = (options: IMenuOptions) => {
  const { permissions, match, riskAssessment, context, t } = options;

  if (!permissions.canReadAssessments) {
    return null;
  }
  const routes = getRiskAssessmentEditRoutes(match);
  const departmentsToEvaluate = _.sortBy(getDepartmentStagesToEvaluate(riskAssessment), (d) => d.name);

  return (
    <SubMenu
      title={
        <span className="submenu-title-wrapper">
          <CalculatorOutlined />
          {t(TR + 'main.assessment')}
        </span>
      }
    >
      {departmentsToEvaluate.map((d) => {
        const departmentName = context.t(d.name);
        return (
          <Menu.Item key={`assessment/${d.id}`}>
            <IconNavLink icon={BuildOutlined} key={d.id} name={departmentName} to={`${routes.assessment}/${d.id}`} dataAutomationId={'riskmenu-evaluate-' + departmentName} />
          </Menu.Item>
        );
      })}
    </SubMenu>
  );
};

const createOverviewMenu = (options: IMenuOptions) => {
  const { permissions, match, t } = options;
  if (!permissions.canReadEvaluation) {
    return null;
  }

  const routes = getRiskAssessmentEditRoutes(match);

  return (
    <SubMenu
      title={
        <span className="submenu-title-wrapper">
          <HomeOutlined />
          {t(TR + 'main.overview')}
        </span>
      }
    >
      <Menu.Item key="overview/evaluationsummary">
        <IconNavLink icon={FormOutlined} nameTrPrefix={TR} nameTrKey="overview.evaluationsummary" to={routes.overview.evaluationSummary} dataAutomationId="riskmenu-overview-summary" />
      </Menu.Item>
      <Menu.Item key="overview/communication">
        <IconNavLink icon={MessageOutlined} nameTrPrefix={TR} nameTrKey="overview.communication" to={routes.overview.communication} dataAutomationId="riskmenu-overview-communication" />
      </Menu.Item>
    </SubMenu>
  );
};

const createApprovalMenu = (options: IMenuOptions) => {
  const { permissions, config } = options;
  if (!permissions.canReadApproval) {
    return null;
  }

  if (config.hasMultipleApprovalFlows) {
    return createApprovalMenuForFlows(options);
  }

  return createApprovalMenuForSingleFlow(options);
};

function createApprovalMenuForSingleFlow(options: IMenuOptions) {
  const { match, riskAssessment, context, t } = options;
  const routes = getRiskAssessmentEditRoutes(match);
  let flow = _.first(getFlowsWithAccessibleStages(riskAssessment));
  let stages = getAccessibleStagesForFlow(riskAssessment, flow);
  if (stages.length === 0) {
    return null;
  }

  return (
    <SubMenu
      title={
        <span className="submenu-title-wrapper">
          <SafetyCertificateOutlined />
          {t(TR + 'main.approval')}
        </span>
      }
    >
      {stages.map((stage) => {
        const stageName = context.t(stage.name);

        return (
          //(stage.isCompleted || isCurrentApprovalStage(riskAssessment, flow, stage)) && (
          <Menu.Item key={`approval/${stage.id}`}>
            <IconNavLink
              icon={getIconForStageById(riskAssessment, flow.id, stage.id)}
              name={stageName}
              to={`${routes.approval}/${flow.id}/${stage.id}`}
              dataAutomationId={'riskmenu-approval-' + stageName}
            />
          </Menu.Item>
          // )
        );
      })}
    </SubMenu>
  );
}

function getFlowMenuName(flow: IApprovalFlow, context: IUserInfoCtx): string {
  let name = context.t(flow.name);

  if (flow.completedAt) {
    name += ` (${formatter.formatDateString(flow.completedAt)})`;
  }

  return name;
}

function createApprovalMenuForFlows(options: IMenuOptions) {
  const { match, riskAssessment, context, t } = options;
  const routes = getRiskAssessmentEditRoutes(match);
  const flows = getFlowsWithAccessibleStages(riskAssessment);

  if (flows.length === 0) {
    return null;
  }

  return (
    <SubMenu
      title={
        <span className="submenu-title-wrapper">
          <SafetyCertificateOutlined />
          {t(TR + 'main.approval')}
        </span>
      }
    >
      {flows.map((flow) => {
        let stages = getAccessibleStagesForFlow(riskAssessment, flow);
        let flowName = getFlowMenuName(flow, context);

        return (
          <SubMenu
            key={flow.id}
            title={
              <span className="submenu-title-wrapper">
                {getIconForFlowById(riskAssessment, flow.id)}
                {flowName}
              </span>
            }
          >
            {stages.map((stage) => {
              let stageName = context.t(stage.name);
              return (
                <Menu.Item key={`approval/${flow.id}/${stage.id}`}>
                  <IconNavLink
                    icon={getIconForStageById(riskAssessment, flow.id, stage.id)}
                    name={stageName}
                    to={`${routes.approval}/${flow.id}/${stage.id}`}
                    dataAutomationId={`riskmenu-approval-${flowName}-${stageName}`}
                  />
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      })}
    </SubMenu>
  );
}

// Workaround zum Highlighting der aktiven Menüs. Für die aktuelle Anforderung ausreichend!
const isCurrent = (name: string) => {
  return window.location.pathname.split('/').includes(name);
};
