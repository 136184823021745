import React, { SFC, useState, useCallback } from 'react';
import { Tag, Row, Col } from 'antd';

import styles from './CheckableTagFilterGroup.module.css';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const CheckableTag = Tag.CheckableTag;

const CheckableTagFilterGroup: SFC<ICheckableTagFilterGroupProps> = ({
  title,
  tags,
  onChange,
  allTag,
  isFilter = false,
  isGrid = false,
  currentSelection = [],
  isMulti = false,
  useAllTag = true,
  dataAutomationId,
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [t] = useTranslation();
  /*
  const handleChange = (tag: ICheckableTag, checked: boolean) => {
    onChange(tag, checked);
  }
  */

  const handleChange = useCallback(
    (tag: ICheckableTag, checked: boolean) => {
      let nextSelectedTags: ICheckableTag[];
      if (tag === allTag) {
        nextSelectedTags = [];
      } else {
        if (isMulti) {
          nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t: ICheckableTag) => t !== tag);
        } else {
          nextSelectedTags = checked ? [tag] : [];
        }
      }

      if (!useAllTag && nextSelectedTags.length === 0) {
        nextSelectedTags = [tag];
      }

      setSelectedTags(nextSelectedTags);
      onChange(nextSelectedTags);
    },
    [allTag, isMulti, onChange, selectedTags, useAllTag],
  );

  if (isGrid) {
    return (
      <Row className={styles.container}>
        <Col span={2}>
          <h6 className={styles.title}>{title}</h6>
        </Col>
        <Col>{getCheckableTagComponent(t, handleChange, tags, currentSelection, allTag, title, dataAutomationId, isFilter, useAllTag)}</Col>
      </Row>
    );
  }
  return (
    <div className={styles.container}>
      <h6 className={styles.title}>{title}</h6>
      {getCheckableTagComponent(t, handleChange, tags, currentSelection, allTag, title, dataAutomationId, isFilter, useAllTag)}
    </div>
  );
};

const getCheckableTagComponent = (
  t: TFunction,
  handleChange: any,
  tags: ICheckableTag[],
  selectedTags: ICheckableTag[],
  allTag: ICheckableTag,
  title: string,
  dataAutomationId?: string,
  isFilter = false,
  useAllTag = true,
) => {
  return (
    <span data-automation-id={(dataAutomationId || 'tagfilter') + '-container-' + title}>
      {isFilter && useAllTag && (
        <CheckableTag
          key={allTag.tag}
          checked={selectedTags.length === 0}
          onChange={(checked) => handleChange(allTag, checked)}
          data-automation-id={(dataAutomationId || 'tagfilter') + '-tag-' + allTag.tag}
        >
          {t('common.all')}
        </CheckableTag>
      )}
      {tags.map((tag) => (
        <CheckableTag
          key={tag.tag}
          checked={selectedTags.indexOf(tag) > -1}
          onChange={(checked) => handleChange(tag, checked)}
          data-automation-id={(dataAutomationId || 'tagfilter') + '-tag-' + tag.tag}
        >
          {tag.title}
        </CheckableTag>
      ))}
    </span>
  );
};

export default CheckableTagFilterGroup;

interface ICheckableTagFilterGroupProps {
  tags: ICheckableTag[];
  title: string;
  isFilter?: boolean;
  isGrid?: boolean;
  isMulti?: boolean;
  currentSelection?: ICheckableTag[];
  onChange: (tags: ICheckableTag[]) => void;
  allTag: ICheckableTag;
  useAllTag: boolean;
  dataAutomationId?: string;
}

export interface ICheckableTag {
  tag: string;
  title: string;
}
