import React, { useContext, useMemo, useReducer } from 'react';
import riskAssessmentService from '../../../shared/services/riskAssessmentService';
import { IDocumentMetadata } from '../../../models/documents/IDocumentMetaData';
import { PermissionCtx } from '../../riskAssessment/RiskAssessmentContext';
import ConfirmationPanel from '../../../shared/components/Panel/ConfirmationPanel';
import { useTranslation } from 'react-i18next';
import { IRiskAssessmentEditVm, IRiskAssessment, IEnsureDocumentCallback, IUploadDocumentsCallback, IRiskAssessmentOnSaveCallback } from '../../../models/riskAssessment';
import styles from './DocumentList.module.css';
import documentPermissionHelper, { IDocumentPermissionInfo } from './DocumentPermissionHelper';
import _ from 'lodash';
import documentHelper, { downloadFileBlob } from './DocumentHelper';
import { Table } from '../../../shared/components/Table/Table';
import formatter from '../../../shared/formatter';
import { IDocumentsUploadData } from '../../../models/documents/IDocumentUploadMetaData';
import { UploadDocumentsPanel } from './UploadDocumentsPanel';
import FileCheckinPanel from '../../../shared/components/FileUpload/FileCheckinPanel';
import FileEditPanel from '../../../shared/components/FileUpload/FileEditPanel';
import { DocumentHeaderRow } from './DocumentHeaderRow';
import { DocumentIconColumn } from './DocumentIconColumn';
import { DocumentActionIconRow } from './DocumentActionIconRow';
import { DocumentLink } from './DocumentLink';
import { UserInfoCtx } from '../../../UserInfoContext';
import { reducer, createEdit, createCheckIn, createConfirm, createReset, createUpload, createShowArchived, IDocumentListState } from './documentListState';

const initalState: IDocumentListState = {
  actionMetadata: null,
  showEditPanel: false,
  showUploadPanel: false,
  showConfirmationPanel: false,
  showCheckInPanel: false,
  showArchived: false,
  confirmAction: null,
};

export const DocumentList: React.FC<IDocumentListProps> = React.memo(
  ({ titleTr, disabled, createNewMetadata, documents, riskAssessment, riskAssessmentVm, onEnsureDocument, onSave, onUploadDocuments, scroll }) => {
    const [state, dispatch] = useReducer(reducer, initalState);
    const [t] = useTranslation();
    const userCtx = useContext(UserInfoCtx);
    const permissionsCtx = useContext(PermissionCtx);

    const userInfo = userCtx.userInfo;
    const { actionMetadata, showEditPanel, showConfirmationPanel, confirmAction, showCheckInPanel, showUploadPanel, showArchived } = state;

    const riskAssessmentState = riskAssessment.status;
    const documentPermissionInfo = { userInfo: userCtx.userInfo, metaData: null, state: riskAssessmentState, context: permissionsCtx };
    const filteredDocuments = useMemo(() => (showArchived ? documents : _.filter(documents, (d) => !d.isArchived)), [showArchived, documents]);

    const createColumns = (documentPermissionInfo: IDocumentPermissionInfo) => {
      return [
        {
          className: styles.iconcolumn,
          title: '',
          dataIndex: 'status',
          key: 'status',
          width: 30,
          render: (text: string, record: IDocumentMetadata) => {
            return <DocumentIconColumn className={styles.statusIconsContainer} isArchived={record.isArchived} isCheckedOut={record.isCheckedOut} isParaphrased={record.isParaphrased} />;
          },
        },
        {
          title: t('components.DocumentList.name'),
          dataIndex: 'fileName',
          key: 'fileName',
          width: 150,
          render: (text: string, record: IDocumentMetadata) => {
            documentPermissionInfo.metaData = record;
            return (
              <DocumentLink
                isDownloadAble={documentPermissionHelper.canRead(documentPermissionInfo)}
                linkText={text}
                downloadFile={() => downloadFile(record.fileName, riskAssessment.id, record.id, null)}
              />
            );
          },
        },
        {
          title: t('components.DocumentList.versionAuthor'),
          dataIndex: 'versionAuthor',
          key: 'versionAuthor',
          render: (text: string, record: IDocumentMetadata) => getVersionAuthor(record),
        },
        {
          title: t('components.DocumentList.versionDate'),
          dataIndex: 'versionDate',
          key: 'versionDate',

          render: (text: string, record: IDocumentMetadata) => getVersionDate(record),
        },
        {
          title: t('components.DocumentList.actions'),
          key: 'actions',

          render: (text: string, record: IDocumentMetadata) => {
            documentPermissionInfo.metaData = record;
            return (
              <DocumentActionIconRow
                disabled={disabled}
                record={record}
                documentPermissionInfo={documentPermissionInfo}
                onEditClicked={() => dispatch(createEdit(record))}
                onCheckInClicked={() => dispatch(createCheckIn(record))}
                onCheckOutClicked={() => dispatch(createConfirm(record, (data) => onCheckOut(data)))}
                onRevertCheckOutClicked={() => dispatch(createConfirm(record, (data) => onRevertCheckOut(data)))}
                onArchiveClicked={() => dispatch(createConfirm(record, (data) => onArchive(data)))}
                onRevertArchiveClicked={() => dispatch(createConfirm(record, (data) => onRevertArchive(data)))}
                onDeleteClicked={() => dispatch(createConfirm(record, (data) => onDeleteDocument(data)))}
              />
            );
          },
        },
        {
          className: styles.versioncolumn,
          title: t('components.DocumentList.version'),
          key: 'version',
          render: (text: string, record: IDocumentMetadata) => <span>{getVersion(record)}</span>,
        },
      ];
    };

    const onCheckIn = (metaData: IDocumentMetadata, file: any) => documentHelper.onCheckIn(onEnsureDocument, metaData, file, riskAssessmentVm.assessment, riskAssessment);
    const onCheckOut = (metaData: IDocumentMetadata) => documentHelper.onCheckOut(onSave, riskAssessmentVm.assessment, riskAssessment, metaData);
    const onRevertCheckOut = (metaData: IDocumentMetadata) => documentHelper.onRevertCheckOut(onSave, riskAssessmentVm.assessment, riskAssessment, metaData);
    const onArchive = (metaData: IDocumentMetadata) => documentHelper.onArchive(onSave, riskAssessmentVm.assessment, riskAssessment, metaData);
    const onRevertArchive = (metaData: IDocumentMetadata) => documentHelper.onRevertArchive(onSave, riskAssessmentVm.assessment, riskAssessment, metaData);
    const onDeleteDocument = (metaData: IDocumentMetadata) => documentHelper.onDeleteDocument(onSave, riskAssessmentVm.assessment, riskAssessment, metaData);

    const onSaveDocument = async (metadata: IDocumentMetadata, file: any) => {
      documentHelper.onSaveDocument(onEnsureDocument, metadata, file, riskAssessmentVm.assessment, riskAssessment);
      dispatch(createReset());
    };
    const uploadDocuments = async (data: IDocumentsUploadData) => {
      documentHelper.onUploadDocuments(onUploadDocuments, riskAssessmentVm.assessment, riskAssessment, data);
      dispatch(createReset());
    };
    const cancelConfirm = () => {
      dispatch(createReset());
    };

    return (
      <>
        <DocumentHeaderRow
          disabled={disabled || !documentPermissionHelper.canCreate({ userInfo: userInfo, metaData: createNewMetadata(), state: riskAssessmentState, context: permissionsCtx })}
          showArchived={showArchived}
          onMultiUploadClicked={() => dispatch(createUpload(createNewMetadata()))}
          onToggleArchived={(checked: boolean) => dispatch(createShowArchived(checked))}
        />
        <Table rowKey="id" columns={createColumns(documentPermissionInfo)} dataSource={filteredDocuments} pagination={false} scroll={scroll} />
        <UploadDocumentsPanel metadata={createNewMetadata()} onUpload={uploadDocuments} visible={showUploadPanel} onClose={() => dispatch(createReset())} />
        <FileEditPanel
          onSave={(metadata, file) => {
            onSaveDocument(metadata, file);
          }}
          onClose={() => dispatch(createReset())}
          isVisible={showEditPanel}
          disabled={disabled || actionMetadata == null || !documentPermissionHelper.canEdit({ userInfo: userInfo, metaData: actionMetadata, state: riskAssessmentState, context: permissionsCtx })}
          documentMetadata={actionMetadata || createNewMetadata()}
          downloadVersion={(version: any) => downloadFile(`${formatter.formatDocumentVersion(version)}_${actionMetadata.fileName}`, riskAssessment.id, actionMetadata.id, version)}
        />
        <ConfirmationPanel
          label={t('components.DocumentList.confirm')}
          drawerVisible={showConfirmationPanel}
          onClose={cancelConfirm}
          onCancel={cancelConfirm}
          onConfirm={() => {
            confirmAction(actionMetadata);
            dispatch(createReset());
          }}
        />
        <FileCheckinPanel
          oldFileName={actionMetadata ? actionMetadata.fileName : ''}
          onSave={(file) => {
            onCheckIn(actionMetadata, file);
            dispatch(createReset());
          }}
          isVisible={showCheckInPanel}
          onClose={() => dispatch(createReset())}
        />
      </>
    );
  },
);

DocumentList.displayName = 'DocumentList';

async function downloadFile(fileName: string, assessmentId: string, documentId: string, version: any) {
  const report = await riskAssessmentService.loadFile(assessmentId, documentId, version);
  downloadFileBlob(report, fileName);
}

function getVersionAuthor(record: IDocumentMetadata) {
  if (record.history != null && record.history.versions[0] != null) {
    return `${record.history.versions[0].userName}`;
  }
  return '';
}

function getVersionDate(record: IDocumentMetadata) {
  if (record.history != null && record.history.versions[0] != null) {
    return `${formatter.formatDateWithTimeString(record.history.versions[0].timestamp.toString())}`;
  }
  return '';
}

function getVersion(record: IDocumentMetadata) {
  return formatter.formatDocumentVersion(record.history?.versions[0]?.version);
}

interface IDocumentListProps {
  riskAssessmentVm: IRiskAssessmentEditVm;
  riskAssessment: IRiskAssessment;
  titleTr?: string;
  disabled?: boolean;
  documents: IDocumentMetadata[];
  createNewMetadata: () => IDocumentMetadata;
  onEnsureDocument: IEnsureDocumentCallback;
  onUploadDocuments: IUploadDocumentsCallback;
  onSave: IRiskAssessmentOnSaveCallback;
  scroll?: { x?: number; y?: number };
}
