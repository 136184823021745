import React from 'react';
import { Button } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { useTranslation } from 'react-i18next';
import styles from './DocumentList.module.css';
import { ContainerOutlined, UploadOutlined } from '@ant-design/icons';

export const DocumentHeaderRow: React.FC<IDocumentHeaderRowProps> = ({ disabled, onMultiUploadClicked, showArchived, onToggleArchived }) => {
  const [t] = useTranslation();
  return (
    <div style={{ margin: '0.25rem 0' }}>
      <Button disabled={disabled} icon={<UploadOutlined />} size="small" onClick={() => onMultiUploadClicked()}>
        {t('components.DocumentList.uploadDocument')}
      </Button>
      <CheckableTag className={styles.showArchiveIcon} checked={showArchived} onChange={(checked) => onToggleArchived(checked)}>
        <ContainerOutlined style={{ fontSize: '16px' }} title={t(showArchived ? 'components.DocumentList.archivedDocumentsShown' : 'components.DocumentList.archivedDocumentsHidden')} />
      </CheckableTag>
    </div>
  );
};

interface IDocumentHeaderRowProps {
  disabled: boolean;
  showArchived: boolean;
  onMultiUploadClicked: () => void;
  onToggleArchived: (checked: boolean) => void;
}
