import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import SettingsService from './settingsService';
import { userService } from './userService';

let errorCounter: number = 0;
const createInstance = () => {
  const appConfig = SettingsService.getConfig();
  const user = userService.getCurrentUser();
  const settings = userService.getCurrentUserSettings();
  const tenantId = settings.activeTenantId || '';
  const branchId = userService.getActiveBranchOfIndustryId() || '';
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: 'Bearer ' + user.access_token,
      'Risk-TenantId': encodeURIComponent(tenantId),
      'Risk-BranchOfIndustryId': encodeURIComponent(branchId),
    },
    baseURL: `${appConfig.apiInstanceUrl}/api`,
  };

  const instance = Axios.create(config);
  instance.interceptors.response.use();

  return instance;
};

const createAxiosResponseInterceptor = () => {
  const interceptor = Axios.interceptors.response.use(
    (response) => {
      errorCounter = 0;
      return response;
    },
    (error) => {
      // Reject promise if usual error
      if (error.status !== 401) {
        return Promise.reject(error);
      }
      errorCounter++;
      if (errorCounter > 3) {
        return;
      }
      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      Axios.interceptors.response.eject(interceptor);

      userService
        .signinSilent()
        .then((response) => {
          error.response.config.headers['Authorization'] = 'Bearer ' + response.access_token;
          return Axios.create(error.response.config);
        })
        .catch((error) => {
          console.log(error);
          console.log('trying to log in');
          userService.login();
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);
    },
  );
};

export const getHttpClient = (forceNewInstance = false): AxiosInstance => {
  return createInstance();
};

export default { getInstance: getHttpClient };
