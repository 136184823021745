import React, { FC } from 'react';
import { Drawer, Button } from 'antd';
import FormBox from '../../shared/components/FormBox/FormBox';
import { ValidationError } from 'yup';
import styles from './ValidationErrorDrawer.module.css';
import { useTranslation } from 'react-i18next';
import { IRiskAssessmentConfig } from '../../models/riskAssessment';
import { TFunction } from 'i18next';
import { IUserInfoCtx } from '../../UserInfoContext';
import { FrownOutlined } from '@ant-design/icons';

const TR = 'components.AssessmentTransitionConfirm.';

export const ValidationErrorDrawer: FC<{
  visible: boolean;
  config: IRiskAssessmentConfig;
  validationError: ValidationError;
  userInfoCtx: IUserInfoCtx;
  onCancel: () => void;
  title: string;
}> = ({ visible, onCancel, validationError, title, config, userInfoCtx }) => {
  const [t] = useTranslation();

  if (!visible) {
    return null;
  }

  return (
    <Drawer placement="right" visible={visible} width={500} title={t(TR + 'header')} onClose={onCancel}>
      <FormBox title={title}>
        <p>{t(TR + 'missingdatamessage')}</p>
        <ul>
          {validationError.errors.map((e, idx) => (
            <li className={styles.listitem} key={idx}>
              <FrownOutlined className={styles.listitem_icon} />
              {trErrorMessage({ key: e, t, config, userInfoCtx })}
            </li>
          ))}
        </ul>
        <div>
          <Button style={{ marginRight: '0.5rem' }} onClick={() => onCancel()}>
            {t('common.cancel')}
          </Button>
        </div>
      </FormBox>
    </Drawer>
  );
};

function trErrorMessage({ key, t, config, userInfoCtx }: { key: string; t: TFunction; config: IRiskAssessmentConfig; userInfoCtx: IUserInfoCtx }): string {
  switch (key) {
    case 'calculation.machineTypeDb3MinPercent_required':
    case 'calculation.machineTypeDb3OfferPercent_required':
      return t('riskAssessment.validationMessages.' + key, { marginalReturn: userInfoCtx.t(config.marginalReturn) });
  }

  return t('riskAssessment.validationMessages.' + key);
}
