import React, { FC, useState, useCallback, useContext } from 'react';
import { FormikProps } from 'formik/dist/types';
import {
  IRiskAssessment,
  IRiskAssessmentEditVm,
  RiskAssessmentStatus,
  IStatusHistory,
  IStatusChange,
  IRiskAssessmentDepartmentEmailReceiver,
  IRiskAssessmentOnSaveCallback,
  IRiskAssessmentConfig,
  getApprovalStageForFlowById,
} from '../../../models/riskAssessment';
import { Timeline, Button, Tabs } from 'antd';
import styles from './Communication.module.css';
import { HtcCard } from '../../../shared/HtcCardComponent';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { EvaluationSummaryOverview } from './EvaluationSummaryOverview';
import formatter from '../../../shared/formatter';
import { TransitionActions } from '../transitionActions/TransitionActions';
import { PermissionCtx } from '../RiskAssessmentContext';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { IUserInfoCtx, UserInfoCtx } from '../../../UserInfoContext';
import { AssessmentPageHeader } from '../assessment/PageHeader/PageHeader';
import { AssessmentPageContent } from '../assessment/PageContent/PageContent';
import { ApprovalFlows } from '../approval/ApprovalFlows';
import { getFlowById, translateStatusForAssessment } from '../formhelper';
import { LeftOutlined, MailOutlined, StarOutlined, ThunderboltOutlined, ArrowRightOutlined, ArrowUpOutlined, ArrowDownOutlined, InfoOutlined } from '@ant-design/icons';

const TabPane = Tabs.TabPane;

export const Communication: FC<ICommunicationProps> = ({ riskAssessmentVm, formik, onSave }) => {
  const riskAssessment = formik.values;
  const [t] = useTranslation();
  const context = useContext(PermissionCtx);
  const canReadCommunication = context.permissionHelper.canEditCommunication();

  const transitions = {
    canShareForCommunication:
      canReadCommunication &&
      (riskAssessment.status === RiskAssessmentStatus.Created || riskAssessment.status === RiskAssessmentStatus.Assessment || riskAssessment.status === RiskAssessmentStatus.Evaluation),
    canGoToEvaluation: canReadCommunication && !(riskAssessment.status === RiskAssessmentStatus.Created || riskAssessment.status === RiskAssessmentStatus.Evaluation),
    canMoveToNoProject: canReadCommunication && riskAssessment.status !== RiskAssessmentStatus.Rejected,
    canMoveToApproved: canReadCommunication && riskAssessment.status === RiskAssessmentStatus.Evaluation,
    canCompleteAnalysis: false,
  };

  return (
    <>
      <AssessmentPageHeader mainLevelTr="metadata" current="communication" />
      <AssessmentPageContent>
        <div className={styles.splitcontainer}>
          <div className={styles.historycontainer}>
            <HistoryList history={riskAssessment.statusHistory} editVm={riskAssessmentVm} />
          </div>
          <div className={styles.middlecontainer}>
            <div className={styles.evaluationoverviewcontainer}>
              <EvaluationSummaryOverview riskAssessment={riskAssessment} showRiskIndicator={false} />
            </div>
            <div className={styles.actionscontainer}>
              <HtcCard flex>
                <Tabs type="card" animated={false}>
                  <TabPane tab={t('components.ApprovalActions.header')} key="1">
                    <TransitionActions formik={formik} onSave={onSave} riskAssessmentVm={riskAssessmentVm} transitions={transitions} />
                  </TabPane>
                  <TabPane tab={t('components.ApprovalActions.approvalHeader')} key="2">
                    <ApprovalFlows riskAssessmentVm={riskAssessmentVm} assessment={formik.values} onSave={onSave} config={riskAssessmentVm.commonConfig} />
                  </TabPane>
                </Tabs>
              </HtcCard>
            </div>
          </div>
        </div>
      </AssessmentPageContent>
    </>
  );
};

export interface ICommunicationProps {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
  onSave: IRiskAssessmentOnSaveCallback;
}

const HistoryList: FC<{ history: IStatusHistory; editVm: IRiskAssessmentEditVm }> = ({ history, editVm }) => {
  const [t] = useTranslation();
  const [change, setChange] = useState<IStatusChange>(null);
  const goBack = useCallback(() => setChange(null), [setChange]);

  if (change) {
    return <HistoryDetails change={change} goBack={goBack} />;
  }

  return (
    <HtcCard>
      <FormBox flex contentflex title={t('components.HistoryList.header')}>
        <div style={{ overflow: 'hidden', flex: '1 1 0', display: 'flex' }}>
          <Timeline style={{ overflow: 'auto', flex: 1, padding: '0.25rem' }}>
            {_.map(history && history.entries, (entry, idx) => (
              <HistoryEntry key={idx} change={entry} onSelect={setChange} editVm={editVm} />
            ))}
          </Timeline>
        </div>
      </FormBox>
    </HtcCard>
  );
};

const HistoryDetails: FC<{ change?: IStatusChange; goBack: () => void }> = ({ change, goBack }) => {
  const [t] = useTranslation();
  const data = change.notificationData;
  let mailReceivers = _.concat(change.mailData?.receivers?.to ?? [], change.mailData?.receivers?.cc ?? []);

  if (!data && !change.mailData) {
    return <div>{t('components.HistoryDetails.noData')}</div>;
  }
  return (
    <HtcCard>
      <FormBox title={t('components.HistoryDetails.header')}>
        <div style={{ marginBottom: '0.25rem' }}>
          <Button icon={<LeftOutlined />} onClick={goBack}>
            {t('components.HistoryDetails.goBack')}
          </Button>
        </div>
        {change.notificationData && (
          <>
            <FormBox title={t('components.HistoryDetails.subject')}>
              <div>{data.messageData.subject}</div>
            </FormBox>
            <FormBox title={t('components.HistoryDetails.body')}>
              <div>{data.messageData.body}</div>
            </FormBox>
            <FormBox title={t('components.HistoryDetails.receivers')}>
              <div className={styles.receiver_table}>
                {_.map(getDepartmentReceivers(change), (r) => (
                  <DepartmentReceiverRow key={r.id} receiver={r} />
                ))}
                {_.map(change.notificationData.additionalReceivers, (r, idx) => (
                  <div key={idx} className={styles.receiver_row}>
                    <div className={styles.receiver_cell}>
                      <MailOutlined />
                    </div>
                    <div className={styles.receiver_cell}>{r.username}</div>
                    <div className={styles.receiver_cell}>CC</div>
                  </div>
                ))}
              </div>
            </FormBox>
          </>
        )}
        {change.mailData && (
          <FormBox title={t('components.HistoryDetails.emailData')}>
            <div className={styles.receiver_table}>
              {_.map(mailReceivers, (r, idx) => (
                <div key={idx} className={styles.receiver_row}>
                  <div className={styles.receiver_cell}>
                    <MailOutlined />
                  </div>
                  <div className={styles.receiver_cell}>{r.address}</div>
                </div>
              ))}
              <div className={styles.receiver_row}>
                <div className={styles.receiver_cell}>
                  <InfoOutlined />
                </div>
                <div className={styles.receiver_cell}>
                  {t('components.HistoryDetails.emailSent')}: {t(change.mailData.mailHasBeenSent ? 'common.yes' : 'common.no')}
                </div>
              </div>
              <div className={styles.receiver_row}>
                <div className={styles.receiver_cell}>
                  <InfoOutlined />
                </div>
                <div className={styles.receiver_cell}>
                  {t('components.HistoryDetails.emailSentDate')}: {formatter.formatDateWithTimeString(change.mailData.mailSentDate)}
                </div>
              </div>
            </div>
            <div className={styles.receiver_row}>
              <div className={styles.receiver_cell}>
                <InfoOutlined />
              </div>
              <div className={styles.receiver_cell}>
                {t('components.HistoryDetails.externalMessageId')}: {change.mailData.externalMessageId}
              </div>
            </div>
          </FormBox>
        )}
      </FormBox>
    </HtcCard>
  );
};

const DepartmentReceiverRow: FC<{ receiver: IRiskAssessmentDepartmentEmailReceiver }> = ({ receiver }) => {
  const [showReceivers, setShowReceivers] = useState(false);
  const userInfo = useContext(UserInfoCtx);

  const hasEmailReceivers = receiver.emailReceivers && receiver.emailReceivers.length > 0;
  return (
    <div className={styles.receiver_row}>
      <div className={styles.receiver_cell}>
        <MailOutlined />
      </div>
      <div className={styles.receiver_cell}>
        {getForLanguage(receiver.name, userInfo.languageSettings)}
        {hasEmailReceivers && <Button onClick={() => setShowReceivers(!showReceivers)} type="link" size="small" icon={showReceivers ? <ArrowUpOutlined /> : <ArrowDownOutlined />} />}
        <div>
          {showReceivers && hasEmailReceivers && (
            <ul>
              {_.map(receiver.emailReceivers, (er) => (
                <li key={er.userId}>
                  {er.username} ({er.email})
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className={styles.receiver_cell} />
    </div>
  );
};

function dummyHandler(change: IStatusChange): void {}
const HistoryEntry: FC<{ change: IStatusChange; onSelect: (change: IStatusChange) => void; editVm: IRiskAssessmentEditVm }> = ({ change, onSelect, editVm }) => {
  const [t] = useTranslation();
  const config = editVm.commonConfig;
  const userInfoCtx = useContext(UserInfoCtx);
  const isCreateEvent = change.newStatus === RiskAssessmentStatus.Created && change.oldStatus === change.newStatus;
  const title = `${formatter.formatDateWithTimeString(change.timestamp)}, ${change.user}`;
  const hasDetails = change.notificationData;
  onSelect = hasDetails ? onSelect : dummyHandler;

  if (isCreateEvent) {
    return (
      <Timeline.Item color="blue" dot={<StarOutlined style={{ fontSize: '16px' }} />}>
        <span className={styles.historyentry_value}>{t('components.HistoryList.projectCreated')}</span>
        <br />
        <span className={styles.historyentry_title}>{title}</span>
      </Timeline.Item>
    );
  }

  return (
    <Timeline.Item color="green" dot={<ThunderboltOutlined style={{ fontSize: '16px' }} />}>
      <div className={hasDetails && styles.historyentrywithdata} onClick={() => onSelect(change)}>
        <span className={styles.historyentry_value}>
          {translateStatusForAssessment(change.oldStatus, t, userInfoCtx, config)}
          {getApprovalFlowNamePart(userInfoCtx, editVm, change)}
          {getApprovalStageNamePart(userInfoCtx, editVm, change.approvalFlowId, change.oldApprovalStageId)} <ArrowRightOutlined />{' '}
          {translateStatusForAssessment(change.newStatus, t, userInfoCtx, config)}
          {getApprovalFlowNamePart(userInfoCtx, editVm, change)}
          {getApprovalStageNamePart(userInfoCtx, editVm, change.approvalFlowId, change.newApprovalStageId)}
        </span>
        <br />
        <span className={styles.historyentry_title}>{title}</span>
      </div>
    </Timeline.Item>
  );
};

function getApprovalFlowNamePart(ctx: IUserInfoCtx, editVm: IRiskAssessmentEditVm, change: IStatusChange) {
  if (!editVm.commonConfig.hasMultipleApprovalFlows) {
    return null;
  }
  let approvalFlowName = ctx.t(getFlowById(editVm.assessment, change.approvalFlowId)?.name);
  return approvalFlowName ? ` '${approvalFlowName}'` : null;
}

function getApprovalStageNamePart(ctx: IUserInfoCtx, editVm: IRiskAssessmentEditVm, flowId: string, stageId: string) {
  let approvalStageName = ctx.t(getApprovalStageForFlowById(editVm.assessment.processTemplate, flowId, stageId)?.name);
  return approvalStageName ? ` (${approvalStageName})` : null;
}

function getDepartmentReceivers(status: IStatusChange) {
  return _.sortBy(
    _.filter(status.notificationData && status.notificationData.departmentReceivers, (d) => d.isRequired),
    (d) => d.name,
  );
}
