import * as _ from 'lodash';
import { ICustomer, ICustomerRef } from '../../models/masterdata';
import HttpClient from './httpClient';
import LRU from 'lru-cache';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';
import { LanguageSettings } from '../../models/LanguageSettings';
import { queryMasterdataOptions } from './masterdataQueryHelper';
import { IUniqueValidationErrorResult, UniqueValidation } from '../../models/IUniqueValidation';

interface ICustomerResponse {
  customers: ICustomer[];
}

const cacheOptions = { maxAge: 1000 * 60 };
const cache = new LRU<string, Map<IMultiLanguageString, ICustomerRef>>(cacheOptions);

interface ICustomerOptionResponse {
  options: ICustomerRef[];
}

const createCustomer = async (item: ICustomer): Promise<ICustomer> => {
  const result = await HttpClient.getInstance().post<ICustomerResponse>(`customers`, item);
  const response = getCustomersFromResponse(result.data);
  return response[0];
};

const editCustomer = async (item: ICustomer): Promise<ICustomer> => {
  const result = await HttpClient.getInstance().put<ICustomerResponse>(`customers/${item.id}`, item);
  const response = getCustomersFromResponse(result.data);
  return response[0];
};

const deleteCustomer = async (item: ICustomer): Promise<void> => {
  const result = await HttpClient.getInstance().delete(`customers/${item.id}`);
};

const getAllCustomers = async (): Promise<ICustomer[]> => {
  const result = await HttpClient.getInstance().get<ICustomerResponse>(`customers`);
  return getCustomersFromResponse(result.data);
};

function getCustomersFromResponse(response: ICustomerResponse) {
  return _.map(response.customers, (c) => {
    ensureProps(c);
    return c;
  });
}

const isUnique = async (customer: ICustomer): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/customers/isunique`, customer);
  return new UniqueValidation(result.data.validationErrors);
};

function ensureProps(customer: ICustomer) {}

async function queryCustomerOptions(query: string, settings: LanguageSettings, tenantId: string, branchId: string): Promise<ICustomerRef[]> {
  return queryMasterdataOptions('customers', query, settings, cache, tenantId, branchId);
}

export default {
  createCustomer,
  getAllCustomers,
  editCustomer,
  deleteCustomer,
  queryCustomerOptions,
  isUnique,
};
