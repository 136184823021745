import React, { useContext, FC } from 'react';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { IBranchOfIndustry, NewBranchOfIndustryId } from '../../models/masterdata';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import styles from './BranchesOfIndustryList.module.css';
import { NewAction } from '../../shared/components/CommandBar/CommandBar';
import { SearchBox, FormBox } from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { getForLanguage } from '../../models/IMultiLanguageString';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { useHistory } from 'react-router';
import { TFunction } from 'i18next';
import { Table } from '../../shared/components/Table/Table';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { EditOutlined } from '@ant-design/icons';

const navPaths = ['navigation.masterdata', 'navigation.branchesOfIndustry'];
export const BranchesOfIndustryList: FC<BranchesOfIndustryListProps> = ({ branchesOfIndustry, searchText, setSearchText, loading }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);

  const create = React.useCallback(() => history.push('/branchesofindustry/' + NewBranchOfIndustryId), []);
  const edit = React.useCallback((id: string) => history.push('/branchesofindustry/' + id), []);
  const columns = React.useMemo(() => createColumns(t, ctx, edit), []);
  const onSearch = (e: any) => setSearchText(e.target.value);

  return (
    <ContentWrapper>
      <MasterdataHeader>
        <CommandBarV2 paths={navPaths}>
          <NewAction text="masterdata.branchesOfIndustry.newBranchOfIndustry" onClick={create} />
        </CommandBarV2>
      </MasterdataHeader>
      <HtcCardWrapper>
        <HtcCard>
          <SearchBox>
            <Input placeholder={t('masterdata.branchesOfIndustry.searchText')} allowClear onChange={onSearch} value={searchText} />
          </SearchBox>
        </HtcCard>
        <HtcCard flex>
          <FormBox title={t('common.results')} flex contentflex>
            <div className={styles.list}>
              <Table rowKey="id" columns={columns} dataSource={branchesOfIndustry} loading={loading} />
            </div>
          </FormBox>
        </HtcCard>
      </HtcCardWrapper>
    </ContentWrapper>
  );
};

function createColumns(t: TFunction, ctx: IUserInfoCtx, onEdit: (id: string) => void) {
  return [
    {
      title: t('masterdata.branchesOfIndustry.name'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (text: string, record: IBranchOfIndustry) => getForLanguage(record.name, ctx.languageSettings),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: IBranchOfIndustry) => <Button id="editBranchOfIndustry" type="primary" shape="circle" size="small" icon={<EditOutlined />} onClick={() => onEdit(record.id)} />,
      width: 100,
    },
  ];
}

interface BranchesOfIndustryListProps {
  branchesOfIndustry: IBranchOfIndustry[];
  loading: boolean;
  searchText: string;
  setSearchText: (text: string) => void;
}
