import React, { FC, useContext } from 'react';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { ITopicEvaluation, IRiskAssessment, IRiskAssessmentConfig, IRiskAssessmentEditVm } from '../../../models/riskAssessment';
import nameof from 'ts-nameof.macro';
import { useTranslation } from 'react-i18next';
import styles from './EvaluationList.module.css';
import { RiskTypes, getRiskTypeOptions, ICriterion } from '../../../models/masterdata';
import _ from 'lodash';
import { FormikProps, getIn } from 'formik';
import { getRiskAssessmentEvaluation, getPathToTopicEvaluation } from '../formhelper';
import { FormMoneyInput, FormTextAreaInput } from '../../../shared/FormComponents';
import { Select } from 'antd';
import classnames from 'classnames';
import { UserInfoCtx } from '../../../UserInfoContext';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { getIndicatorClass } from '../RiskColorCodes';
import { EllipsisPopover } from '../../../shared/components/EllipsisText/EllipsisText';

const Option = Select.Option;
const TP = 'riskAssessment.editform.assessment.';
export const EvaluationList: FC<IEvaluationListProps> = React.memo(({ formik, departmentId, disabled, riskAssessmentEditVm }) => {
  const evaluation = getRiskAssessmentEvaluation(formik.values, departmentId);
  const evaluations = evaluation.topicEvaluations;
  const config = riskAssessmentEditVm.commonConfig;

  const [t] = useTranslation();
  return (
    <FormBox overflowX flex title={t(TP + 'criterias')}>
      <div className={styles.table}>
        <div className={styles.row}>
          <EvaluationCellHeader cellStyle={styles.cell_name} trKey={'name'} />
          <EvaluationCellHeader cellStyle={styles.cell_criteria} trKey={'criteria'} />
          <EvaluationCellHeader cellStyle={styles.cell_risk} trKey={'risk'} />
          <EvaluationCellHeader cellStyle={styles.cell_riskamount} trKey={'riskamount'} />
          <EvaluationCellHeader cellStyle={styles.cell_measurementAmount} trKey={'measurementAmount'} />
          <EvaluationCellHeader cellStyle={styles.cell_comment} trKey={'comment'} />
        </div>
        {evaluations.map((ev, i) => (
          <EvaluationRow key={ev.topicId} formik={formik} departmentId={departmentId} index={i} disabled={disabled} config={config} />
        ))}
      </div>
    </FormBox>
  );
});

EvaluationList.displayName = 'EvaluationList';

const EvaluationCellHeader: FC<{ cellStyle: string; trKey: string }> = ({ cellStyle, trKey }) => {
  const [t] = useTranslation();
  const className = classnames(styles.cell, styles.headercell);

  return (
    <div className={classnames(className, cellStyle)}>
      <span>{t(TP + trKey)}</span>
    </div>
  );
};

const EvaluationRow: FC<{ formik: FormikProps<IRiskAssessment>; departmentId: string; index: number; disabled?: boolean; config: IRiskAssessmentConfig }> = ({
  formik,
  departmentId,
  index,
  disabled,
  config,
}) => {
  const [t] = useTranslation();
  const pathToTopicEvaluation = getPathToTopicEvaluation(formik.values, departmentId, index);
  const getPathToField = (field: string): any => `${pathToTopicEvaluation}.${field}`;
  const topic: ITopicEvaluation = getIn(formik.values, pathToTopicEvaluation);
  const { setFieldValue } = formik;
  const context = useContext(UserInfoCtx);
  const updateRisk = (risk: RiskTypes) => setFieldValue(getPathToField(nameof<ITopicEvaluation>((t) => t.risk)) as any, risk);
  const updateCriterion = (criterion: ICriterion) => {
    setFieldValue(getPathToField(nameof<ITopicEvaluation>((t) => t.selectedCriterion)), criterion);
    setFieldValue(getPathToField(nameof<ITopicEvaluation>((t) => t.risk)), criterion?.risk);
  };
  const isAlternateRow = index % 2 == 0;
  const selectedRisk = topic.risk == RiskTypes.NotSet || topic.risk == null ? RiskTypes.None : topic.risk;
  const topicName = getForLanguage(topic.name, context.languageSettings);
  return (
    <div className={classnames(styles.row, isAlternateRow && styles.alternaterow)}>
      <div className={classnames(styles.cell, styles.cell_name)}>
        <span>{topicName}</span>
      </div>
      <div className={classnames(styles.cell, styles.cell_criteria)}>
        <CriterionSelector
          disabled={disabled}
          selectedCriterion={topic.selectedCriterion}
          possibleCriteria={topic.possibleCriteria}
          onSelect={updateCriterion}
          dataAutomationId={'criterion-selection-' + topicName}
        />
      </div>
      <div className={classnames(styles.cell, styles.cell_risk)}>
        <RiskSelector disabled={disabled} selectedRisk={selectedRisk} onSelect={updateRisk} dataAutomationId={'criterion-risk-' + topicName} />
      </div>
      <div className={classnames(styles.cell, styles.cell_riskamount)}>
        <FormMoneyInput
          currency={config.currency}
          disabled={disabled}
          {...formik}
          name={getPathToField(nameof<ITopicEvaluation>((t) => t.riskAmount))}
          dataAutomationId={'criterion-riskAmount-' + topicName}
        />
      </div>
      <div className={classnames(styles.cell, styles.cell_measurementAmount)}>
        <FormMoneyInput
          currency={config.currency}
          style={{ paddingBottom: 0 }}
          disabled={disabled}
          {...formik}
          name={getPathToField(nameof<ITopicEvaluation>((t) => t.measureAmount))}
          dataAutomationId={'criterion-measureAmount-' + topicName}
        />
      </div>
      <div className={classnames(styles.cell, styles.cell_comment)}>
        <FormTextAreaInput disabled={disabled} {...formik} rows={2} name={getPathToField(nameof<ITopicEvaluation>((t) => t.comment))} dataAutomationId={'criterion-comment-' + topicName} />
        <EllipsisPopover hint={t(TP + 'comment')} text={getIn(formik.values, getPathToField(nameof<ITopicEvaluation>((t) => t.comment)))} />
      </div>
    </div>
  );
};

const RiskSelector: FC<{ selectedRisk?: RiskTypes; onSelect: (risk: RiskTypes) => void; disabled?: boolean; dataAutomationId: string }> = React.memo(
  ({ selectedRisk, onSelect, disabled, dataAutomationId }) => {
    const [t] = useTranslation();
    return (
      <div className={styles.riskselector}>
        <Select disabled={disabled} value={selectedRisk} onChange={onSelect} data-automation-id={dataAutomationId}>
          {getRiskTypeOptions(t).map((o) => (
            <Option key={o.value} value={o.value} data-automation-id={dataAutomationId + '-option-' + o.value}>
              {o.desc}
            </Option>
          ))}
        </Select>
        <div className={classnames(styles.riskselector_indicator, getIndicatorClass(selectedRisk))} />
      </div>
    );
  },
);

RiskSelector.displayName = 'RiskSelector';

const CriterionSelector: FC<{
  selectedCriterion?: ICriterion;
  possibleCriteria: ICriterion[];
  onSelect: (criterion: ICriterion) => void;
  disabled?: boolean;
  dataAutomationId: string;
}> = React.memo(({ selectedCriterion, possibleCriteria, onSelect, disabled, dataAutomationId }) => {
  const selectedValue = _.get(selectedCriterion, nameof.full(selectedCriterion.key, 1));
  const context = useContext(UserInfoCtx);
  return (
    <Select allowClear disabled={disabled} value={selectedValue} onChange={(value) => onSelect(_.find(possibleCriteria, (p) => p.key === value))} data-automation-id={dataAutomationId}>
      {possibleCriteria.map((c) => (
        <Option key={c.key} value={c.key} data-automation-id={dataAutomationId + '-option-' + getForLanguage(c.description, context.languageSettings)}>
          {getForLanguage(c.description, context.languageSettings)}
        </Option>
      ))}
    </Select>
  );
});

CriterionSelector.displayName = 'CriterionSelector';

interface IEvaluationListProps {
  departmentId: string;
  formik: FormikProps<IRiskAssessment>;
  disabled?: boolean;
  riskAssessmentEditVm: IRiskAssessmentEditVm;
}
