import { LanguageSettings } from '../../models/LanguageSettings';
import { IMultiLanguageString, getForLanguage } from '../../models/IMultiLanguageString';
import { IBaseRef } from '../../models/masterdata/IBaseRef';
import HttpClient from './httpClient';
import LRU from 'lru-cache';
import _ from 'lodash';

export const multiLanguageStringMatchesQuery = (query: string, settings: LanguageSettings, key: IMultiLanguageString): boolean => matchesQuery(query, getForLanguage(key, settings));

export const matchesQuery = (query: string, value: string): boolean => {
  // war bisher mit indexOf implementiert, nach Kundenwunsch am 1.10.19 auf startswith umgestellt
  // return value.toLowerCase().indexOf(query.toLowerCase()) >= 0;

  return value.toLowerCase().startsWith(query.toLowerCase());
};

export const getAllMasterdataOptions = async <T extends IBaseRef>(
  name: string,
  cache: LRU<string, Map<IMultiLanguageString, T>>,
  tenantId: string,
  branchId?: string,
): Promise<Map<IMultiLanguageString, T>> => {
  let items = cache.get(branchId);
  if (!items) {
    const result = await HttpClient.getInstance().get<{ options: T[] }>(`${name}/options?query=&loadAll=true&tenantId=${tenantId}&branchId=${branchId}`);
    const data = result.data.options || [];
    if (data) {
      items = new Map<IMultiLanguageString, T>();
      _.map(data, (entry) => items.set(entry.name, entry));
      cache.set(branchId, items);
    }
  }
  return items;
};

export const queryMasterdataOptions = async <T extends IBaseRef>(
  masterdataType: string,
  query: string,
  settings: LanguageSettings,
  cache: LRU<string, Map<IMultiLanguageString, T>>,
  tenantId: string,
  branchId: string,
  sort: boolean = true,
): Promise<T[]> => {
  const items = await getAllMasterdataOptions(masterdataType, cache, tenantId, branchId);
  if (!query) {
    const resultArray = Array.from(items.values());
    if (sort) {
      return sortResult(resultArray, settings);
    } else {
      return resultArray;
    }
  }
  const filteredResult = filterByQuery(items, query, settings);
  if (sort) {
    return sortResult(filteredResult, settings);
  } else {
    return filteredResult;
  }
};

export const sortResult = <T extends IBaseRef>(filteredResult: T[], settings: LanguageSettings): T[] => {
  return _.orderBy(filteredResult, (item) => item != null && getForLanguage(item.name, settings).toLowerCase(), ['asc']);
};

export const filterByQuery = <T extends IBaseRef>(items: Map<IMultiLanguageString, T>, query: string, settings: LanguageSettings) => {
  const filteredResult = [];
  items.forEach((value: T, key: IMultiLanguageString) => {
    if (multiLanguageStringMatchesQuery(query, settings, key)) {
      filteredResult.push(value);
    }
  });
  return filteredResult;
};
