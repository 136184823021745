import React, { useContext } from 'react';
import nameof from 'ts-nameof.macro';
import { useTranslation } from 'react-i18next';
import { IRiskAssessmentMetadata, RiskAssessmentStatus, IRiskAssessmentCategorization, IRiskAssessmentConfig, IRiskAssessmentTemplate } from '../../models/riskAssessment';
import _ from 'lodash';
import styles from './Header.module.css';
import formatter from '../../shared/formatter';
import { Info } from '../../shared/components/Info/Info';
import { UserInfoCtx } from '../../UserInfoContext';
import { getForLanguage } from '../../models/IMultiLanguageString';
import { LanguageSettings } from '../../models/LanguageSettings';
import { getFullNameOrDefault } from '../../models/masterdata';

const TP = 'riskAssessment.header.';

const Header: React.FC<IHeaderProps> = (props) => {
  const [t] = useTranslation();
  const { metadata, status, config, template } = props;
  const context = useContext(UserInfoCtx);

  return (
    <div className={styles.header}>
      <div className={styles.infocontainer}>
        <div className={styles.column}>
          <div className={styles.table}>
            <Info labelKey={TP + 'vendor'} value={metadata.vendor} />
            <Info labelKey={TP + 'createdDate'} value={formatter.formatDateString(metadata.createdDate)} />
            <Info labelKey={TP + 'dueDate'} value={formatter.formatDateString(metadata.dueDate)} />
            <Info labelKey={TP + 'template'} value={getTemplateName(template, context.languageSettings)} />
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.table}>
            <Info labelKey={TP + 'incoterms'} value={getIncotermName(metadata, context.languageSettings)} />
            <Info labelKey={context.t(config.incotermsSupplement)} value={metadata.city} />
            <Info labelKey={TP + 'country'} value={getCountryName(metadata, context.languageSettings)} />
            <Info labelKey={TP + 'projectDiagnosis'} value={metadata.projectDiagnosis} />
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.table}>
            <Info labelKey={TP + 'branchOfIndustry'} value={getBranchOfIndustryName(metadata, context.languageSettings)} />
            {config.hasMachineType && (
              <>
                <Info labelKey={TP + 'machineType'} value={getMachineTypeName(metadata, context.languageSettings)} />
                <Info labelKey={TP + 'machineTypeInfo'} value={metadata.machineTypeInfo} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function getBranchOfIndustryName(metadata: IRiskAssessmentMetadata, settings: LanguageSettings): string {
  return getForLanguage(_.get(metadata, nameof.full(metadata.branchOfIndustry.name, 1)), settings);
}

function getMachineTypeName(metadata: IRiskAssessmentMetadata, settings: LanguageSettings): string {
  return getForLanguage(_.get(metadata, nameof.full(metadata.machineType.fullName, 1)), settings);
}

function getCountryName(metadata: IRiskAssessmentMetadata, settings: LanguageSettings): string {
  return getForLanguage(_.get(metadata, nameof.full(metadata.country.name, 1)), settings);
}

function getIncotermName(metadata: IRiskAssessmentMetadata, settings: LanguageSettings): string {
  return getForLanguage(_.get(metadata, nameof.full(metadata.incoterms.name, 1)), settings);
}

function getTemplateName(template: IRiskAssessmentTemplate, settings: LanguageSettings): string {
  return getForLanguage(_.get(template, nameof.full(template.name, 1)), settings);
}

interface IHeaderProps {
  template: IRiskAssessmentTemplate;
  metadata: IRiskAssessmentMetadata;
  status: RiskAssessmentStatus;
  categorization: IRiskAssessmentCategorization;
  config: IRiskAssessmentConfig;
}

export default React.memo(Header);
