import PickerBase, { IsRef, IPickerBaseProps } from './PickerBase';
import { getForLanguage, IMultiLanguageString } from '../../../models/IMultiLanguageString';
import { UserInfoCtx } from '../../../UserInfoContext';
import { LanguageSettings } from '../../../models/LanguageSettings';

abstract class LanguageAwarePickerBase<P extends IsRef, T extends ILanguageAwarePickerBaseProps<P> = ILanguageAwarePickerBaseProps<P>> extends PickerBase<P, T> {
  getDisplayName(name: any): string {
    if (typeof name === 'string') {
      return name;
    }
    return getForLanguage(name as IMultiLanguageString, this.props.languageSettings || this.context.languageSettings);
  }
}

LanguageAwarePickerBase.contextType = UserInfoCtx;
export default LanguageAwarePickerBase;

export interface ILanguageAwarePickerBaseProps<P> extends IPickerBaseProps<P> {
  languageSettings: LanguageSettings;
}
