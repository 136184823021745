import React, { FC, useContext, useState, useMemo } from 'react';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { SaveAction, CancelAction } from '../../shared/components/CommandBar/CommandBar';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { Form, Spin } from 'antd';
import { FormRadioYesNo, FormLanguageTextInput, FormTextInput, FormPercentInput } from '../../shared/FormComponents';
import FormBox from '../../shared/components/FormBox/FormBox';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { IGeneralSettingsEditVm, CommonConfig, ITenantConfig, IBranchOfIndustryConfig, ITenantSettings, IBranchOfIndustrySettings, ITenantTerms } from '../../models/masterdata';
import GeneralSettingService from '../../shared/services/generalSettingService';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import nameof from 'ts-nameof.macro';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import _ from 'lodash';
import { UserInfoCtx } from '../../UserInfoContext';
import { getForLanguage, IMultiLanguageString, testLanguageStringMaxLength } from '../../models/IMultiLanguageString';
import styles from './GeneralSettings.module.css';
import { ILanguage } from '../../models/LanguageSettings';
import { RecursivePartial } from '../../models/riskAssessment';
import { useHistory, useParams } from 'react-router';
import { useAsync } from 'react-use';
import { notifySaveSuccess, notifySaveFailed } from '../../shared/notificationHelper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';

const { TabPane } = Tabs;
const navPaths = ['navigation.masterdata', 'navigation.settings'];

export const GeneralSettings: FC<{}> = (props) => {
  const [settings, setSettings] = useState<IGeneralSettingsEditVm>();
  const routeParams = useParams<{ id: string }>();
  const originalSettings = useMemo(() => _.cloneDeep(settings), [settings]);
  const { loading, error } = useAsync(async () => {
    const settingsFromService = await GeneralSettingService.getGeneralSettingsForEdit();
    setSettings(settingsFromService);
  }, []);

  const handleSave = React.useCallback(
    async (updatedSettings: IGeneralSettingsEditVm, actions: FormikHelpers<IGeneralSettingsEditVm>) => {
      if (_.isEqual(originalSettings, updatedSettings)) {
        actions.setSubmitting(false);
        return;
      }

      try {
        const update = getUpdatePayload(originalSettings, updatedSettings);
        const newSettings = await GeneralSettingService.saveGeneralSettings(update);
        setSettings(newSettings);
        actions.setSubmitting(false);
        actions.resetForm({ values: newSettings });
        notifySaveSuccess();
      } catch (error) {
        actions.setErrors(error.toString() as any);
        notifySaveFailed();
      }
    },
    [settings],
  );
  return (
    <ContentWrapper>{loading || !settings ? <Spin></Spin> : <GeneralSettingEdit selectedTabKey={routeParams.id} settings={settings} handleSave={handleSave}></GeneralSettingEdit>}</ContentWrapper>
  );
};

function getUpdatePayload(originalSettings: IGeneralSettingsEditVm, updatedSettings: IGeneralSettingsEditVm) {
  const update: Partial<IGeneralSettingsEditVm> = {};
  update.tenant = _.isEqual(originalSettings.tenant, updatedSettings.tenant) ? undefined : updatedSettings.tenant;
  const branchUpdates: IBranchOfIndustryConfig[] = [];
  for (let i = 0; i < updatedSettings.branchesOfIndustry.length; i++) {
    if (!_.isEqual(originalSettings.branchesOfIndustry[i], updatedSettings.branchesOfIndustry[i])) {
      branchUpdates.push(updatedSettings.branchesOfIndustry[i]);
    }
  }
  update.branchesOfIndustry = branchUpdates;
  return update;
}

const GeneralSettingEdit: FC<{
  selectedTabKey: string;
  settings: IGeneralSettingsEditVm;
  handleSave: (generalSettings: IGeneralSettingsEditVm, actions: FormikHelpers<IGeneralSettingsEditVm>) => any;
}> = React.memo(({ settings, handleSave, selectedTabKey }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const tenantSettingPathPrefix = nameof.full<IGeneralSettingsEditVm>((p) => p.tenant) + '.';
  const branchSettingPathPrefix = nameof.full<IGeneralSettingsEditVm>((p) => p.branchesOfIndustry) + '.';
  const context = useContext(UserInfoCtx);
  const tenantConfigId = _.get(settings, 'tenant.id');

  const key = selectedTabKey || tenantConfigId || _.get(settings, 'branchesOfIndustry[0].id');

  return (
    <Formik enableReinitialize={true} initialValues={settings} onSubmit={handleSave} validationSchema={getValidationScheme(t)}>
      {(formik) => {
        const { submitForm, resetForm } = formik;
        return (
          <>
            <MasterdataHeader>
              <CommandBarV2 paths={navPaths}>
                <CancelAction onClick={() => resetForm({ values: settings })} />
                <SaveAction onClick={submitForm} />
              </CommandBarV2>
            </MasterdataHeader>
            <HtcCardWrapper>
              <Tabs animated={false} onChange={(tId) => history.push('/generalsettings/' + tId)} activeKey={key}>
                {_.map(settings.branchesOfIndustry, (b, idx) => (
                  <TabPane key={b.id} tab={getForLanguage(b.name, context.languageSettings)}>
                    <SettingEdit formik={formik} pathPrefix={branchSettingPathPrefix + `[${idx}]`} tenantConfig={settings.tenant} />
                  </TabPane>
                ))}
                <TabPane key={tenantConfigId} tab="Mandant">
                  <SettingEdit formik={formik} pathPrefix={tenantSettingPathPrefix} tenantConfig={settings.tenant} />
                </TabPane>
              </Tabs>
            </HtcCardWrapper>
          </>
        );
      }}
    </Formik>
  );
});

GeneralSettingEdit.displayName = 'GeneralSettingEdit';

const SettingEdit: FC<{ formik: FormikProps<IGeneralSettingsEditVm>; pathPrefix: string; tenantConfig: ITenantConfig }> = ({ formik, pathPrefix, tenantConfig }) => {
  const [t] = useTranslation();
  return (
    <>
      <HtcCard flex>
        <div className={styles.container}>
          <div className={styles.editcontainer}>
            <FormBox title={t('masterdata.generalSettings.editform.settings')}>
              <Form layout="vertical">
                <FormRadioYesNo {...formik} name={pathPrefix + nameof.full<CommonConfig>((c) => c.settings.useDefaultRiskValue)} label={t('masterdata.generalSettings.useDefaultRiskValue')} />
                <FormPercentInput
                  {...formik}
                  min={0}
                  name={pathPrefix + nameof.full<CommonConfig>((c) => c.settings.defaultRiskInPercent)}
                  label={t('masterdata.generalSettings.defaultRiskInPercent')}
                />
                <FormTextInput {...formik} name={pathPrefix + nameof.full<CommonConfig>((c) => c.settings.currency)} label={t('masterdata.generalSettings.currency')} />
              </Form>
            </FormBox>
          </div>
          <div className={styles.defaultscontainer}>
            <DefaultSettingsValues config={tenantConfig} />
          </div>
        </div>
      </HtcCard>
      <LanguageTabPage>
        {(lp) => (
          <HtcCard flex>
            <div className={styles.container}>
              <div className={styles.editcontainer}>
                <FormBox title={t('masterdata.generalSettings.editform.terms')}>
                  <Form layout="vertical">
                    <FormLanguageTextInput
                      {...formik}
                      name={pathPrefix + nameof.full<CommonConfig>((c) => c.terms.marginalReturn)}
                      label={t('masterdata.generalSettings.marginalReturn')}
                      selectedLanguage={lp.lang}
                    />
                    <FormLanguageTextInput
                      {...formik}
                      name={pathPrefix + nameof.full<CommonConfig>((c) => c.terms.salesDepartment)}
                      label={t('masterdata.generalSettings.salesDepartment')}
                      selectedLanguage={lp.lang}
                    />
                    <FormLanguageTextInput
                      {...formik}
                      name={pathPrefix + nameof.full<CommonConfig>((c) => c.terms.commissionNumber)}
                      label={t('masterdata.generalSettings.commissionNumber')}
                      selectedLanguage={lp.lang}
                    />
                    <FormLanguageTextInput
                      {...formik}
                      name={pathPrefix + nameof.full<CommonConfig>((c) => c.terms.incotermsSupplement)}
                      label={t('masterdata.generalSettings.incotermsSupplement')}
                      selectedLanguage={lp.lang}
                    />
                    <FormLanguageTextInput
                      {...formik}
                      name={pathPrefix + nameof.full<CommonConfig>((c) => c.terms.contractAndCalculationDocuments)}
                      label={t('masterdata.generalSettings.contractAndCalculationDocuments')}
                      selectedLanguage={lp.lang}
                    />
                    <FormLanguageTextInput
                      {...formik}
                      name={pathPrefix + nameof.full<CommonConfig>((c) => c.terms.pmCalculationDocuments)}
                      label={t('masterdata.generalSettings.pmCalculationDocuments')}
                      selectedLanguage={lp.lang}
                    />
                    <FormLanguageTextInput
                      {...formik}
                      name={pathPrefix + nameof.full<CommonConfig>((c) => c.terms.generalDocuments)}
                      label={t('masterdata.generalSettings.generalDocuments')}
                      selectedLanguage={lp.lang}
                    />
                  </Form>
                </FormBox>
              </div>
              <div className={styles.defaultscontainer}>
                <DefaultTermsValues config={tenantConfig} lang={lp.lang} />
              </div>
            </div>
          </HtcCard>
        )}
      </LanguageTabPage>
    </>
  );
};

const DefaultSettingsValues: FC<{ config: ITenantConfig }> = ({ config }) => {
  const [t] = useTranslation();

  return (
    <FormBox title={t('masterdata.generalSettings.editform.defaults')}>
      <Form layout="vertical">
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.namecell}>{t('masterdata.generalSettings.useDefaultRiskValue')}:</div>
            <div className={styles.valuecell}>{config.settings.useDefaultRiskValue ? t('common.yes') : t('common.no')}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.namecell}>{t('masterdata.generalSettings.defaultRiskInPercent')}:</div>
            <div className={styles.valuecell}>{config.settings.defaultRiskInPercent}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.namecell}>{t('masterdata.generalSettings.currency')}:</div>
            <div className={styles.valuecell}>{config.settings.currency}</div>
          </div>
        </div>
      </Form>
    </FormBox>
  );
};

const DefaultTermsValues: FC<{ config: ITenantConfig; lang: ILanguage }> = ({ config, lang }) => {
  const [t] = useTranslation();
  const context = useContext(UserInfoCtx);
  return (
    <FormBox title={t('masterdata.generalSettings.editform.defaults')}>
      <Form layout="vertical">
        <div className={styles.table}>
          <TermRow lang={lang} value={config.terms.marginalReturn} label={'marginalReturn'} />
          <TermRow lang={lang} value={config.terms.salesDepartment} label={'salesDepartment'} />
          <TermRow lang={lang} value={config.terms.commissionNumber} label={'commissionNumber'} />
          <TermRow lang={lang} value={config.terms.incotermsSupplement} label={'incotermsSupplement'} />
          <TermRow lang={lang} value={config.terms.contractAndCalculationDocuments} label={'contractAndCalculationDocuments'} />
          <TermRow lang={lang} value={config.terms.pmCalculationDocuments} label={'pmCalculationDocuments'} />
          <TermRow lang={lang} value={config.terms.generalDocuments} label={'generalDocuments'} />
        </div>
      </Form>
    </FormBox>
  );
};

const TermRow: FC<{ lang: ILanguage; label: string; value: IMultiLanguageString }> = React.memo(({ lang, label, value }) => {
  const [t] = useTranslation();
  const context = useContext(UserInfoCtx);

  return (
    <div className={styles.row}>
      <div className={styles.namecell}>{t('masterdata.generalSettings.' + label)}:</div>
      <div className={styles.valuecell}>{getForLanguage(value, context.languageSettings, lang)}</div>
    </div>
  );
});

TermRow.displayName = 'TermRow';

const getValidationScheme = (t: any) => {
  const validationConfig = {
    tenant: yup
      .object()
      .nullable()
      .shape<any>({
        settings: yup.object().shape<any>({
          currency: yup.string().required(),
          defaultRiskInPercent: yup.number().typeError(t('error.number.typeError')).required().min(0),
        }),
        terms: yup.object().shape<any>({
          marginalReturn: testLanguageStringMaxLength(50, t),
          salesDepartment: testLanguageStringMaxLength(50, t),
          commissionNumber: testLanguageStringMaxLength(50, t),
          incotermsSupplement: testLanguageStringMaxLength(50, t),
          contractAndCalculationDocuments: testLanguageStringMaxLength(50, t),
          pmCalculationDocuments: testLanguageStringMaxLength(50, t),
          generalDocuments: testLanguageStringMaxLength(50, t),
        }),
      }),
    branchesOfIndustry: yup.array().of(
      yup.object().shape<any>({
        settings: yup.object().shape<any>({
          defaultRiskInPercent: yup.number().nullable().min(0).typeError(''),
        }),
      }),
    ),
  };

  return yup.object().shape<any>(validationConfig);
};
