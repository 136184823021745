import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ActionType, isActionOf } from 'typesafe-actions';
import { IRootState } from '../../ducks/reducer';
import { IUserTenantInfo } from '../../models/masterdata/ITenant';
import * as actions from './actions';
import { navigate } from '../../shared/Routes';
import { userService } from '../../shared/services/userService';
export type Action = ActionType<typeof actions>;

export const setActiveTenantFlow: Epic<Action, Action, IRootState> = (action$, state) =>
  action$.pipe(
    filter(isActionOf(actions.setActiveTenant.request)),
    switchMap((action) =>
      from(setActiveTenant(action.payload, state.value)).pipe(
        map(actions.setActiveTenant.success),
        catchError((error) => of(actions.setActiveTenant.failure(error))),
      ),
    ),
  );

const setActiveTenant = async (tenant: IUserTenantInfo, state: IRootState): Promise<IUserTenantInfo> => {
  if (state.app && state.app.userInfo && state.app.userInfo.userSetting && state.app.userInfo.userSetting.activeTenantId == tenant.id) {
    return tenant;
  }
  const userInfo = await userService.ensureUserSetting({
    subjectId: userService.getCurrentUser().profile.sub,
    tenantId: tenant.id,
    selectedLanguage: state.app.userInfo.userSetting.selectedLanguage,
  });
  navigate('/');
  window.location.reload();
  return tenant;
};

export const tenantSelectionEpics = combineEpics(setActiveTenantFlow);
