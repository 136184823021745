import { Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IBranchOfIndustry, createBranchOfIndustryCommand, IBranchOfIndustryCommand } from '../../models/masterdata';
import { FormLanguageTextInput, FormLanguagePicker, FormBranchOfIndustryPicker } from '../../shared/FormComponents';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { SaveAction, CancelAction, DeleteAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { testRequiredLanguageFields } from '../../models/IMultiLanguageString';
import { UserInfoCtx } from '../../UserInfoContext';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import { ILanguage } from '../../models/LanguageSettings';
import _ from 'lodash';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { hasChanges } from '../../shared/masterDataHelpers';
import { TFunction } from 'i18next';
import branchOfIndustryService from '../../shared/services/branchOfIndustryService';
import { notifyError } from '../../shared/notificationHelper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import nameof from 'ts-nameof.macro';

const navPaths = ['navigation.masterdata', { name: 'navigation.branchesOfIndustry', linkTo: '/branchesofindustry' }];

export const BranchOfIndustryEditFormComponent: FC<IBranchOfIndustryEditFormProps> = ({ loading, branchOfIndustry, onDelete, onSave, cancelEdit, totalBranchCount }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const settings = ctx.languageSettings;
  const branchOfIndustryClone = useMemo(() => createBranchOfIndustryCommand(_.cloneDeep(branchOfIndustry)), [branchOfIndustry]);
  const [validationScheme] = useState(getValidationScheme(t, settings.getDefaultLanguage()));
  const isNew = !branchOfIndustry.id;
  const nav = Object.assign([], navPaths);
  nav.push(ctx.t(branchOfIndustry.name));
  const handleDelete = () => onDelete(branchOfIndustry);
  const handleSave = async (branchOfIndustry: IBranchOfIndustryCommand, actions: FormikHelpers<IBranchOfIndustryCommand>) => {
    const validationErrors = await branchOfIndustryService.isUnique(branchOfIndustry);
    if (!validationErrors.hasErrors()) {
      try {
        await onSave(branchOfIndustry);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  return (
    <ContentWrapper>
      <Formik enableReinitialize={true} initialValues={branchOfIndustryClone} onSubmit={handleSave} validationSchema={validationScheme}>
        {(formik) => {
          const { submitForm, values } = formik;
          return (
            <>
              <RiskPrompt active={hasChanges(branchOfIndustry, values)} />
              <MasterdataHeader>
                <CommandBarV2 paths={nav}>
                  {!isNew && false && <DeleteAction onClick={handleDelete} disabled={!canDeleteBranchesOfIndustry(ctx, branchOfIndustry.id, totalBranchCount)} />}
                  <CancelAction onClick={cancelEdit} />
                  <SaveAction onClick={submitForm} />
                </CommandBarV2>
              </MasterdataHeader>
              <HtcCardWrapper>
                <LanguageTabPage>
                  {(lp) => (
                    <>
                      <HtcCard flex>
                        <FormBox title={t('masterdata.branchesOfIndustry.editform.details')} flex contentflex>
                          <Form layout="vertical">
                            <FormLanguageTextInput {...formik} name="name" label={t('masterdata.branchesOfIndustry.name')} selectedLanguage={lp.lang} />
                            <FormLanguagePicker {...formik} name="defaultLanguage" label={t('masterdata.branchesOfIndustry.defaultLanguage')} selectedLanguage={lp.lang} />
                          </Form>
                        </FormBox>
                      </HtcCard>
                      {isNew && (
                        <HtcCard flex>
                          <FormBox title={t('masterdata.branchesOfIndustry.editform.copyBranch')} flex contentflex>
                            <Form layout="vertical">
                              <FormBranchOfIndustryPicker {...formik} name="branchIdToCopy" label={t('masterdata.branchesOfIndustry.name')} />
                            </Form>
                          </FormBox>
                        </HtcCard>
                      )}
                    </>
                  )}
                </LanguageTabPage>
              </HtcCardWrapper>
            </>
          );
        }}
      </Formik>
    </ContentWrapper>
  );
};

function canDeleteBranchesOfIndustry(ctx: any, branchId: string, branchCount: number) {
  return ctx.userInfo.permissions.security.canDeleteBranchesOfIndustry(ctx.userInfo.userSetting.activeTenantId, branchId) && branchCount > 1;
}

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  return yup.object().shape<any>({
    name: testRequiredLanguageFields(50, defaultLanguage, t),
  });
}

interface IBranchOfIndustryEditFormProps {
  loading: boolean;
  totalBranchCount: number;
  branchOfIndustry: IBranchOfIndustry;
  onSave: (branchOfIndustry: IBranchOfIndustryCommand) => Promise<void>;
  onDelete: (branchOfIndustry: IBranchOfIndustry) => void;
  cancelEdit: () => void;
}
