import { Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useContext, useState, useMemo } from 'react';
import * as yup from 'yup';
import { ICustomer, IMasterdataEditProps } from '../../models/masterdata';
import { FormTextInput, FormCountryPicker, FormCustomerPicker, FormLanguageTextInput } from '../../shared/FormComponents';
import { useTranslation } from 'react-i18next';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import { SaveAction, CancelAction, DeleteAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { UserInfoCtx } from '../../UserInfoContext';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import { ILanguage } from '../../models/LanguageSettings';
import _ from 'lodash';
import { TFunction } from 'i18next';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { hasChanges } from '../../shared/masterDataHelpers';
import { testLanguageStringMaxLength } from '../../models/IMultiLanguageString';
import { notifyError } from '../../shared/notificationHelper';
import customerService from '../../shared/services/customerService';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { userService } from '../../shared/services/userService';

const navPaths = ['navigation.masterdata', { name: 'navigation.customers', linkTo: '/customers' }];
export const CustomerEditFormComponent: FC<ICustomerEditFormProps> = ({ loading, customer, onDelete, onSave, cancelEdit }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const settings = ctx.languageSettings;
  const customerClone = useMemo(() => _.cloneDeep(customer), [customer]);
  const [validationScheme] = useState(getValidationScheme(t, settings.getDefaultLanguage()));
  const hasDescription = userService.hasCustomerDescription();
  const isNew = !customer.id;
  const nav = Object.assign([], navPaths);
  nav.push(ctx.t(customer.name));
  const handleDelete = () => onDelete(customer);
  const handleSave = async (customer: ICustomer, actions: FormikHelpers<ICustomer>) => {
    const validationErrors = await customerService.isUnique(customer);
    if (!validationErrors.hasErrors()) {
      try {
        await onSave(customer);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  return (
    <ContentWrapper>
      <Formik enableReinitialize={true} initialValues={customerClone} onSubmit={handleSave} validationSchema={validationScheme}>
        {(formik) => {
          const { submitForm, values } = formik;
          return (
            <HtcCardWrapper>
              <RiskPrompt active={hasChanges(customer, values)} />
              <MasterdataHeader>
                <CommandBarV2 paths={nav}>
                  {!isNew && <DeleteAction onClick={handleDelete} />}
                  <CancelAction onClick={cancelEdit} />
                  <SaveAction onClick={submitForm} />
                </CommandBarV2>
              </MasterdataHeader>
              <LanguageTabPage>
                {(lp) => (
                  <HtcCard flex>
                    <FormBox title={t('masterdata.customers.editform.details')} flex contentflex>
                      <Form layout="vertical">
                        <FormTextInput {...formik} name="debitorNumber" label={t('masterdata.customers.debitorNumber')} />
                        {hasDescription && <FormLanguageTextInput {...formik} name="description" label={t('masterdata.customers.description')} selectedLanguage={lp.lang} />}
                        <FormLanguageTextInput {...formik} name="debitorName" label={t('masterdata.customers.debitorName')} selectedLanguage={lp.lang} />
                        <FormCountryPicker {...formik} name="country" label={t('masterdata.customers.country')} selectedLanguage={lp.lang} />
                        <FormCustomerPicker
                          allowClear
                          {...formik}
                          name="companyGroup"
                          label={t('masterdata.customers.companyGroup')}
                          onChange={() => {}}
                          selectedLanguage={lp.lang}
                          defaultIfSingle={false}
                        />
                      </Form>
                    </FormBox>
                  </HtcCard>
                )}
              </LanguageTabPage>
            </HtcCardWrapper>
          );
        }}
      </Formik>
    </ContentWrapper>
  );
};

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  const hasDescription = userService.hasCustomerDescription();

  return yup.object().shape<any>({
    debitorNumber: yup.string().max(100).required(),
    description: hasDescription ? testLanguageStringMaxLength(500, t) : null,
    debitorName: testLanguageStringMaxLength(200, t),
  });
}

interface ICustomerEditFormProps extends IMasterdataEditProps<ICustomer> {
  customer: ICustomer;
}
