import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormBox from '../components/FormBox/FormBox';
import { FormLanguageTextInput, FormLanguageTextAreaInput } from '../FormComponents';
import { FormikProps } from 'formik';
import { IRiskAssessment, IRiskAssessmentTemplate, IMessageTemplate } from '../../models/riskAssessment';
import { ILanguage } from '../../models/LanguageSettings';
import nameof from 'ts-nameof.macro';

export const MessageTemplateEditor: FC<IMessageTemplateEditorProps> = ({ language, formik, labelTr, name }) => {
  const [t] = useTranslation();
  const label = t(labelTr);
  return (
    <FormBox title={label} flex contentflex>
      <FormLanguageTextInput {...formik} name={name + '.' + nameof<IMessageTemplate>((m) => m.subject)} label={t('masterdata.riskAssessmentTemplates.subject')} selectedLanguage={language} />
      <FormLanguageTextAreaInput {...formik} name={name + '.' + nameof<IMessageTemplate>((m) => m.body)} label={t('masterdata.riskAssessmentTemplates.message')} selectedLanguage={language} rows={5} />
      <FormLanguageTextAreaInput
        {...formik}
        name={name + '.' + nameof<IMessageTemplate>((m) => m.footer)}
        label={t('masterdata.riskAssessmentTemplates.footer')}
        selectedLanguage={language}
        rows={5}
      />
    </FormBox>
  );
};

interface IMessageTemplateEditorProps {
  language: ILanguage;
  formik: FormikProps<IRiskAssessmentTemplate>;
  name: string;
  labelTr: string;
}
