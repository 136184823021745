import { IMultiLanguageString } from '../IMultiLanguageString';

export const NewIncotermId = 'new';
export interface IIncoterm extends IIncotermRef {
  id: string;
  name: IMultiLanguageString;
  code: IMultiLanguageString;
  description: IMultiLanguageString;
  placeToSpecifiy: IMultiLanguageString;
}

export interface IIncotermRef {
  id: string;
  name: IMultiLanguageString;
}

export function createIncoterm(options: Partial<IIncoterm> = {}): IIncoterm {
  return Object.assign({ id: '', name: '', code: '', description: '', placeToSpecifiy: '' }, options);
}
