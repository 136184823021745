import React, { SFC } from 'react';
import { IRiskAssessment, IRiskAssessmentEditVm } from '../../../models/riskAssessment';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { EvaluationList } from './EvaluationList';

const Evaluation: SFC<IEvaluationProps> = React.memo((props) => {
  const { departmentId, formik, disabled, riskAssessmentEditVm } = props;
  return <EvaluationList disabled={disabled} formik={formik} departmentId={departmentId} riskAssessmentEditVm={riskAssessmentEditVm} />;
});

Evaluation.displayName = 'Evaluation';

export default Evaluation;

interface IEvaluationProps {
  departmentId: string;
  formik: FormikProps<IRiskAssessment>;
  disabled?: boolean;
  riskAssessmentEditVm: IRiskAssessmentEditVm;
}
