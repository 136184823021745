import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IUserTenantInfo } from '../models/masterdata/ITenant';
import TenantSelection from '../shared/components/TenantSelection/TenantSelection';
import { HtcCard, HtcCardWrapper } from '../shared/HtcCardComponent';

interface ITenantSelectionComponentProps extends WithTranslation {
  tenants: IUserTenantInfo[];
  selectedTenant: IUserTenantInfo;
  selectActiveTenant: (item: IUserTenantInfo) => void;
}

const TenantSelectionComponent: React.FC<ITenantSelectionComponentProps> = ({ tenants, selectedTenant, selectActiveTenant, t }) => {
  const handleSelectedTenantChange = (tenant: IUserTenantInfo) => {
    selectActiveTenant(tenant);
  };

  return (
    <HtcCardWrapper>
      <HtcCard>
        <TenantSelection availableTenants={tenants} selectedTenantId={selectedTenant && selectedTenant.id} onSelectedTenantChange={handleSelectedTenantChange} />
      </HtcCard>
    </HtcCardWrapper>
  );
};

export default withTranslation()(TenantSelectionComponent);
