import React, { useState, useContext } from 'react';
import { IMachineTypeRef, ITreeOptionNode } from '../../../models/masterdata';
import _ from 'lodash';
import machineTypesService from '../../services/machineTypesService';
import { useAsync } from 'react-use';
import { TreeSelect } from 'antd';
import { UserInfoCtx } from '../../../UserInfoContext';

interface IMachineTypeTreePickerProps {
  name: string;
  dataAutomationId: string;
  placeholder: string;
  onChange: (value: IMachineTypeRef) => void;
  disabled?: boolean;
  value?: IMachineTypeRef;
  handleBlur?: (e: any) => void;
  tenantId: string;
  branchId: string;
}
export const MachineTypeTreePicker: React.FC<IMachineTypeTreePickerProps> = ({ disabled, dataAutomationId, placeholder, onChange, value, handleBlur, tenantId, branchId }) => {
  let userCtx = useContext(UserInfoCtx);
  let [treeOptions, setTreeOptions] = useState<any[]>();
  let [allOptions, setAllOptions] = useState<ITreeOptionNode[]>();

  useAsync(async () => {
    let { tree, allNodes } = machineTypesService.getTreeData(await machineTypesService.getMachineTypesTreeOptions(tenantId, branchId), userCtx);
    setAllOptions(allNodes);
    setTreeOptions(tree);
  }, []);

  let isLoading = !treeOptions || treeOptions.length === 0;
  let selectedValue = isLoading ? null : value?.id;

  return (
    <TreeSelect
      treeNodeFilterProp="title"
      treeNodeLabelProp="title"
      showSearch
      treeDefaultExpandAll
      loading={isLoading}
      onChange={(id) => onChange(_.find(allOptions, (o) => o.id === id)?.value)}
      placeholder={placeholder}
      data-automation-id={dataAutomationId}
      treeData={treeOptions}
      value={selectedValue}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
};
