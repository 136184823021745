import React from 'react';
import { IPickerBaseProps, IPickerBaseState } from '../PickerBase/PickerBase';
import { IBranchOfIndustryRef } from '../../../models/masterdata';
import LanguageAwarePickerBase from '../PickerBase/LanguageAwarePickerBase';
import branchOfIndustryService from '../../services/branchOfIndustryService';

class BranchOfIndustryPicker extends LanguageAwarePickerBase<IBranchOfIndustryRef> {
  static getDerivedStateFromProps(props: IPickerBaseProps<IBranchOfIndustryRef>, state: IPickerBaseState<IBranchOfIndustryRef>): Partial<IPickerBaseState<IBranchOfIndustryRef>> {
    if ((state.value && (state.value as IBranchOfIndustryRef).id) !== (props.value && (props.value as IBranchOfIndustryRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<IBranchOfIndustryRef[]> {
    const onQueryItems = this.props.onQueryItems || branchOfIndustryService.queryBranchOfIndustryOptions;
    return onQueryItems(query, this.props.languageSettings, this.props.tenantId, this.props.branchId);
  }
}

export default BranchOfIndustryPicker;
