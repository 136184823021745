import React from 'react';
import {
  IRiskAssessment,
  IRiskAssessmentEvaluation,
  getDepartmentStagesToEvaluate,
  IDepartmentEvaluationSummary,
  IApprovalFlowStage,
  RiskAssessmentStatus,
  IRiskAssessmentConfig,
  IApprovalFlow,
} from '../../models/riskAssessment';
import { getIn } from 'formik';
import _ from 'lodash';
import nameof from 'ts-nameof.macro';
import { IRiskComment } from '../../models/IRiskComment';
import { TFunction } from 'i18next';
import { IUserInfoCtx } from '../../UserInfoContext';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';
import { ITenantTerms } from '../../models/masterdata';
import { CheckCircleOutlined, HourglassOutlined, PauseCircleOutlined } from '@ant-design/icons';

function pureGetPathToRiskAssessmentEvaluation(assessment: IRiskAssessment, departmentId: string): string {
  const index = _.findIndex(getDepartmentStagesToEvaluate(assessment), (stage) => stage.department.id === departmentId);
  return `evaluation.evaluations[${index}]`;
}

export const getPathToRiskAssessmentEvaluation = _.memoize(pureGetPathToRiskAssessmentEvaluation, (assessment, departmentId) => assessment.id + '||' + departmentId);

function pureGetPathToRiskAssessmentEvaluationField(assessment: IRiskAssessment, departmentId: string, fieldName: string): string {
  return `${getPathToRiskAssessmentEvaluation(assessment, departmentId)}.${fieldName}`;
}

export const getPathToRiskAssessmentEvaluationField = _.memoize(
  pureGetPathToRiskAssessmentEvaluationField,
  (assessment, departmentId, fieldName) => assessment.id + '||' + departmentId + '||' + fieldName,
);

function pureGetPathToTopicEvaluations(assessment: IRiskAssessment, departmentId: string): string {
  return `${getPathToRiskAssessmentEvaluation(assessment, departmentId)}.topicEvaluations`;
}

export const getPathToTopicEvaluations = _.memoize(pureGetPathToTopicEvaluations, (assessment, departmentId) => assessment.id + '||' + departmentId);

function pureGetPathToTopicEvaluation(assessment: IRiskAssessment, departmentId: string, editIndex: number): string {
  return `${getPathToRiskAssessmentEvaluation(assessment, departmentId)}.topicEvaluations[${editIndex}]`;
}

export const getPathToTopicEvaluation = _.memoize(pureGetPathToTopicEvaluation, (assessment, departmentId, editIndex) => assessment.id + '||' + departmentId + '||' + editIndex);

function pureGetPathToTopicEvaluationField(assessment: IRiskAssessment, departmentId: string, editIndex: number, fieldName: string): string {
  return `${getPathToRiskAssessmentEvaluation(assessment, departmentId)}.topicEvaluations[${editIndex}].${fieldName}`;
}

export const getPathToTopicEvaluationField = _.memoize(
  pureGetPathToTopicEvaluationField,
  (assessment, departmentId, editIndex, fieldName) => assessment.id + '||' + departmentId + '||' + editIndex + '||' + fieldName,
);

export function getRiskAssessmentEvaluation(assessment: IRiskAssessment, departmentId: string): IRiskAssessmentEvaluation {
  return getIn(assessment, getPathToRiskAssessmentEvaluation(assessment, departmentId));
}

export const getPathToDepartmentEvaluationSummaries = () => {
  return 'evaluationSummary.departmentSummaries';
};

function pureGetPathToDepartmentEvaluationSummary(assessment: IRiskAssessment, departmentId: string) {
  const index = _.findIndex(assessment.evaluationSummary.departmentSummaries, (ds) => ds.department.id == departmentId);
  return `${getPathToDepartmentEvaluationSummaries()}[${index}]`;
}

export const getPathToDepartmentEvaluationSummary = _.memoize(pureGetPathToDepartmentEvaluationSummary, (assessment: IRiskAssessment, departmentId: string) => assessment.id + '||' + departmentId);

export function getDepartmentEvaluationSummary(assessment: IRiskAssessment, departmentId: string): IDepartmentEvaluationSummary {
  return getIn(assessment, getPathToDepartmentEvaluationSummary(assessment, departmentId));
}

export function getDepartmentEvaluationSummaries(assessment: IRiskAssessment): IDepartmentEvaluationSummary[] {
  return getIn(assessment, getPathToDepartmentEvaluationSummaries());
}

function pureGetPathToRiskAssessmentApprovalStage(assessment: IRiskAssessment, flowId: string, stageId: string): string {
  let flow = getFlowById(assessment, flowId);
  let flowIndex = _.findIndex(assessment.approvalFlows.flows, (f) => flow === f);
  const stageIndex = _.findIndex(flow.stages, (stage) => stage.id === stageId);
  return `approvalFlows.flows[${flowIndex}].stages[${stageIndex}]`;
}

export function getFlowById(assessment: IRiskAssessment, id: string): IApprovalFlow {
  return _.find(assessment.approvalFlows?.flows, (flow) => flow.id === id);
}

export const getPathToRiskAssessmentApprovalStage = _.memoize(pureGetPathToRiskAssessmentApprovalStage, (assessment: IRiskAssessment, flowId, id) => assessment.id + '||' + flowId + '||' + id);
export function getRiskAssessmentApprovalStage(assessment: IRiskAssessment, flowId: string, id: string): IApprovalFlowStage {
  return getIn(assessment, getPathToRiskAssessmentApprovalStage(assessment, flowId, id));
}

function pureGetPathToRiskAssessmentApprovalStageField(assessment: IRiskAssessment, flowId: string, id: string, fieldName: string): string {
  return `${getPathToRiskAssessmentApprovalStage(assessment, flowId, id)}.${fieldName}`;
}

export function translateStatusForAssessment(status: RiskAssessmentStatus, t: TFunction, ctx: IUserInfoCtx, config: IRiskAssessmentConfig): string {
  return config && translateStatus(status, t, ctx, config.salesDepartment);
}

export function translateStatusForTenantSettings(status: RiskAssessmentStatus, t: TFunction, ctx: IUserInfoCtx, config: ITenantTerms): string {
  return config && translateStatus(status, t, ctx, config.salesDepartment);
}

function translateStatus(status: RiskAssessmentStatus, t: TFunction, ctx: IUserInfoCtx, salesDepartment: IMultiLanguageString): string {
  if (status === RiskAssessmentStatus.Evaluation) {
    return ctx.t(salesDepartment);
  }

  return t('riskAssessment.states.' + status);
}

export const getPathToRiskAssessmentApprovalStageField = _.memoize(
  pureGetPathToRiskAssessmentApprovalStageField,
  (assessment, id, fieldName) => assessment.id + '||' + id + '||' + fieldName + '||' + assessment.approvalFlows.currentFlowId,
);

export function getFirstCommentText(comments: IRiskComment[]): string {
  return (
    _.get(
      _.first(comments),
      nameof<IRiskComment>((c) => c.content),
    ) || null
  );
}

export function getIconForFlowById(assessment: IRiskAssessment, flowId: string) {
  let flow = getFlowById(assessment, flowId);
  if (!flow) {
    return null;
  }

  if (flow.isCompleted) {
    return <CheckCircleOutlined />;
  }

  if (flow.id === assessment.approvalFlows.currentFlowId) {
    return <HourglassOutlined />;
  }

  return null;
}

export function getIconForStageById(assessment: IRiskAssessment, flowId: string, stageId: string) {
  let flow = getFlowById(assessment, flowId);
  let stage = _.find(flow?.stages, (stage) => stage.id === stageId);
  if (!stage) {
    return null;
  }

  if (stage.isCompleted) {
    return CheckCircleOutlined;
  }

  if (stage.id === assessment.approvalFlows.currentStageId) {
    return HourglassOutlined;
  }

  if (stage.hasBeenStarted) {
    return PauseCircleOutlined;
  }

  return null;
}
