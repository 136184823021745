import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';
import { IIncoterm, createIncoterm } from '../../models/masterdata';
import incotermService from '../../shared/services/incotermService';
import { Spin } from 'antd';
import { IncotermsEditFormComponent } from './IncotermsEditFormComponent';
import _ from 'lodash';
import { IncotermsList } from './IncotermsList';
import { UserInfoCtx } from '../../UserInfoContext';

export const Incoterms: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);
  const { loading, data: terms } = useData<IIncoterm[]>(id, incotermService.getAllIncoterms);
  const [editObj, setEditObj] = useState<IIncoterm>();
  useEffect(() => setEditObj(id && _.cloneDeep(_.find(terms, (t) => t.id === id) || createIncoterm())), [id, terms]);

  const cancelEdit = (): void => history.goBack();
  const onSave = async (term: IIncoterm) => {
    const saveAction = term.id ? incotermService.editIncoterm(term) : incotermService.createIncoterm(term);
    const newEditObj = await tryAsyncAction(saveAction);
    setEditObj(newEditObj);
    cancelEdit();
  };
  const onDelete = async (term: IIncoterm) => {
    await tryAsyncAction(incotermService.deleteIncoterm(term));
    cancelEdit();
  };

  const [searchText, setSearchText] = useState('');
  const filteredIncoterms = useMemo(() => {
    const filtered = searchText
      ? _.filter(
          terms,
          (c) => _.includes(_.toLower(ctx.t(c.name)), searchText) || _.includes(_.toLower(ctx.t(c.code)), _.toLower(searchText)) || _.includes(_.toLower(ctx.t(c.description)), _.toLower(searchText)),
        )
      : terms;
    return _.sortBy(filtered, ['code']);
  }, [searchText, terms]);

  if (loading) {
    return <Spin></Spin>;
  }

  if (editObj) {
    return <IncotermsEditFormComponent loading={loading} incoterm={editObj} onSave={onSave} onDelete={onDelete} cancelEdit={cancelEdit} />;
  }
  return <IncotermsList loading={loading} incoterms={filteredIncoterms} searchText={searchText} setSearchText={setSearchText} />;
};
