import { IMultiLanguageString } from './IMultiLanguageString';
import { ILanguageOption } from '../shared/components/LanguagePicker/LanguagePicker';
import { IUserInfoCtx } from '../UserInfoContext';
import datePickerLocaleEN from 'antd/es/date-picker/locale/en_US';
import datePickerLocaleDE from 'antd/es/date-picker/locale/de_DE';
import _ from 'lodash';

export const DefaultLanguages = {
  de_DE: 'de_DE',
  en_US: 'en_US',
};

function isDE(ctx: IUserInfoCtx) {
  return ctx.getActiveLanguageKey() === DefaultLanguages.de_DE;
}

export function getDatePickerLocalFromContext(ctx: IUserInfoCtx) {
  return isDE(ctx) ? datePickerLocaleDE : datePickerLocaleEN;
}

export class LanguageSettings {
  private constructor(private languageSettings: ILanguageUserSettings) {}

  public static from(languageSettings: ILanguageUserSettings) {
    return new LanguageSettings(languageSettings);
  }

  public getActiveLanguage = () => this.languageSettings.selectedLanguage || this.languageSettings.defaultLanguage;
  public getDefaultLanguage = () => this.languageSettings.defaultLanguage || createLanguage();
}

export interface ILanguageUserSettings {
  selectedLanguage: ILanguage;
  defaultLanguage: ILanguage;
}

export interface ILanguage {
  key: string;
  name: IMultiLanguageString;
}

export function createLanguageUserSettings(options: Partial<ILanguageUserSettings> = {}): ILanguageUserSettings {
  return Object.assign(
    {
      selectedLanguage: { key: '', name: IMultiLanguageString.empty() },
      defaultLanguage: { key: '', name: IMultiLanguageString.empty() },
    },
    options,
  );
}

export function createLanguage(options: Partial<ILanguage> = {}): ILanguage {
  return Object.assign({ key: '', name: '' }, options);
}

export function createLanguageFromOption(option: ILanguageOption): ILanguage {
  return { key: (option as ILanguageOption).id, name: (option as ILanguageOption).name };
}

export function createOptionFromLanguage(language: Partial<ILanguage> = {}): ILanguageOption {
  if (_.isEmpty(language)) {
    return null;
  }
  return { id: language.key, name: language.name };
}
