import React, { FC, useContext } from 'react';
import { IRiskAssessment, getDepartmentSummaries, IDepartmentEvaluationSummary, IRiskAssessmentConfig, IRiskAssessmentEditVm } from '../../../models/riskAssessment';
import { FormikProps, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import FormBox from '../../../shared/components/FormBox/FormBox';
import nameof from 'ts-nameof.macro';
import styles from './EvaluationSummaryList.module.css';
import { getPathToDepartmentEvaluationSummary, getDepartmentEvaluationSummary } from '../formhelper';
import { FormMoneyInput, FormTextAreaInput } from '../../../shared/FormComponents';
import { EvaluationSummaryStatus } from './EvaluationSummaryStatus';
import { TFunction } from 'i18next';
import formatter from '../../../shared/formatter';
import { PermissionCtx } from '../RiskAssessmentContext';
import * as _ from 'lodash';
import { UserInfoCtx } from '../../../UserInfoContext';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import RiskAmounts from '../../../models/riskAssessment/RiskAmounts';
import { formatNumberForDisplay } from '../../../shared/numericHelpers';
import { getIndicatorClassForEvalSummary } from '../RiskColorCodes';
import { CalculatorOutlined, EuroOutlined } from '@ant-design/icons';
import { EllipsisText, EllipsisPopover } from '../../../shared/components/EllipsisText/EllipsisText';

const TP = 'riskAssessment.editform.overview.summary.';

export const EvaluationSummaryList: FC<IEvaluationSummaryListProps> = ({ formik, riskAssessmentEditVm }) => {
  const summaries = _.sortBy(getDepartmentSummaries(formik.values), (s) => s.department.name);
  const config = riskAssessmentEditVm.commonConfig;
  const ctx = useContext(UserInfoCtx);

  const [t] = useTranslation();
  const className = classnames(styles.cell, styles.headercell);
  return (
    <FormBox overflowX flex title={t(TP + 'summary')}>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.row}>
            <td colSpan={6} className={styles.departmentcell}>
              <CalculatorOutlined className={styles.headericon} />
              {t(TP + 'department')}
            </td>
            <td colSpan={3} className={styles.summarycell}>
              <EuroOutlined className={styles.headericon} />
              {ctx.t(config.salesDepartment)}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={classnames(className, styles.cell_risk)} />
            <td className={classnames(className, styles.cell_departmentName)}>
              <span>{t(TP + 'departmentName')}</span>
            </td>
            <td className={classnames(className, styles.cell_infos)} />
            <td className={classnames(className, styles.cell_riskamount)}>
              <span>{t(TP + 'riskamount')}</span>
            </td>
            <td className={classnames(className, styles.cell_measurementAmount)}>
              <span>{t(TP + 'measurementAmount')}</span>
            </td>
            <td className={classnames(className, styles.cell_riskAfterMeasurement)}>
              <span>{t(TP + 'riskAfterMeasurement')}</span>
            </td>
            <td className={classnames(className, styles.cell_summaryRiskAfterMeasureAmount)}>
              <span>{t(TP + 'summaryRiskAfterMeasureAmount')}</span>
            </td>
            <td className={classnames(className, styles.cell_summaryMeasureAmount)}>
              <span>{t(TP + 'summaryMeasureAmount')}</span>
            </td>
            <td className={classnames(className, styles.cell_summaryComment)}>
              <span>{t(TP + 'summaryComment')}</span>
            </td>
          </tr>
          {summaries.map((ev, i) => (
            <EvaluationSummaryRow key={ev.department.id} formik={formik} index={i} departmentId={ev.department.id} config={config} />
          ))}
          <EvaluationSummaryTotalRow key="summary" formik={formik} index={summaries.length} config={config}></EvaluationSummaryTotalRow>
        </tbody>
      </table>
    </FormBox>
  );
};

const EvaluationSummaryRow: FC<{ formik: FormikProps<IRiskAssessment>; index: number; departmentId: string; config: IRiskAssessmentConfig }> = ({ formik, index, departmentId, config }) => {
  const [t] = useTranslation();
  const currency = config.currency;
  const pathToEvaluationSummary = getPathToDepartmentEvaluationSummary(formik.values, departmentId);
  const getPathToField = (field: string) => `${pathToEvaluationSummary}.${field}`;
  const summary = getDepartmentEvaluationSummary(formik.values, departmentId);
  const context = useContext(PermissionCtx);
  const userInfoCtx = useContext(UserInfoCtx);
  const departmentName = getForLanguage(summary.department.name, userInfoCtx.languageSettings);
  const isAlternateRow = index % 2 === 0;
  return (
    <tr className={classnames(styles.row, isAlternateRow && styles.alternaterow)}>
      <td className={classnames(styles.cell, styles.cell_risk)}>
        <div className={classnames(styles.riskselector_indicator, getIndicatorClassForEvalSummary(summary.risk))} />
      </td>
      <td className={classnames(styles.cell, styles.cell_departmentName)}>
        <Content>{departmentName}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_infos)}>
        <table className={styles.infostable}>
          <tbody>
            <tr>
              <td className={styles.info_description_cell}>{t(TP + 'evaluationStatusMessage')}:</td>
              <td>
                <EvaluationSummaryStatus summary={summary} />
                <Content>{getEvaluationStatusMessage(summary, t)}</Content>
              </td>
            </tr>
            <tr>
              <td className={styles.info_description_cell}>{t(TP + 'evaluationComment')}:</td>
              <td>
                <Content>
                  <EllipsisText title={departmentName} hint={getEvaluationStatusHeaderMessage(summary, t)} text={summary.evaluationComment} />
                </Content>
              </td>
            </tr>
            <tr>
              <td className={styles.info_description_cell}>{t(TP + 'evaluationMeasures')}:</td>
              <td>
                <Content>
                  <EllipsisText title={departmentName} hint={getEvaluationMeasuresHeaderMessage(summary, t)} text={summary.evaluationMeasures} />
                </Content>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
      <td className={classnames(styles.cell, styles.cell_riskamount)}>
        <Content>{formatNumberForDisplay(summary.evaluationAmounts.riskAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_measurementAmount)}>
        <Content>{formatNumberForDisplay(summary.evaluationAmounts.measureAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_riskAfterMeasurement)}>
        <Content>{formatNumberForDisplay(summary.evaluationAmounts.riskAfterMeasureAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_summaryRiskAfterMeasureAmount)}>
        <FormMoneyInput
          {...formik}
          currency={config.currency}
          name={getPathToField(nameof<IDepartmentEvaluationSummary>((t) => t.summaryRiskAfterMeasureAmount))}
          disabled={context.permissionHelper.isOverviewReadOnly() && !context.permissionHelper.canEditCompletedSummary(summary)}
        />
      </td>
      <td className={classnames(styles.cell, styles.cell_summaryMeasureAmount)}>
        <FormMoneyInput
          {...formik}
          currency={config.currency}
          name={getPathToField(nameof<IDepartmentEvaluationSummary>((t) => t.summaryMeasureAmount))}
          disabled={context.permissionHelper.isOverviewReadOnly() && !context.permissionHelper.canEditCompletedSummary(summary)}
        />
      </td>
      <td className={classnames(styles.cell, styles.cell_summaryComment)}>
        <FormTextAreaInput
          {...formik}
          rows={2}
          name={getPathToField(nameof<IDepartmentEvaluationSummary>((t) => t.summaryComment))}
          disabled={context.permissionHelper.isOverviewReadOnly() && !context.permissionHelper.canEditCompletedSummary(summary)}
        />
        <EllipsisPopover hint={t(TP + 'summaryComment')} text={getIn(formik.values, getPathToField(nameof<IDepartmentEvaluationSummary>((t) => t.summaryComment)))} />
      </td>
    </tr>
  );
};

const EvaluationSummaryTotalRow: FC<{ formik: FormikProps<IRiskAssessment>; index: number; config: IRiskAssessmentConfig }> = ({ formik, index, config }) => {
  const currency = config.currency;
  const summaries = RiskAmounts.getTotalsForSummary(formik.values);

  return (
    <tr className={classnames(styles.row, styles.totalrow)}>
      <td className={classnames(styles.cell, styles.cell_risk)}></td>
      <td className={classnames(styles.cell, styles.cell_departmentName)}></td>
      <td className={classnames(styles.cell, styles.cell_infos)} />
      <td className={classnames(styles.cell, styles.cell_riskamount)}>
        <Content>{formatNumberForDisplay(summaries.evaluationsRiskAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_measurementAmount)}>
        <Content>{formatNumberForDisplay(summaries.evaluationsMeasureAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_riskAfterMeasurement)}>
        <Content>{formatNumberForDisplay(summaries.evaluationsRiskAfterMeasureAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_summaryRiskAfterMeasureAmount, styles.cell_totalwithcurrency)}>
        <Content>{formatNumberForDisplay(summaries.summaryRiskAfterMeasureAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_summaryMeasureAmount, styles.cell_totalwithcurrency)}>
        <Content>{formatNumberForDisplay(summaries.summaryMeasureAmount, currency)}</Content>
      </td>
      <td className={classnames(styles.cell, styles.cell_summaryComment)}></td>
    </tr>
  );
};

function getEvaluationStatusHeaderMessage(summary: IDepartmentEvaluationSummary, t: TFunction): string {
  if (!summary.appraisalChangedAt) {
    return null;
  }

  return `${formatter.formatDateString(summary.appraisalChangedAt)} - ${summary.appraisalChangedBy}`;
}

function getEvaluationMeasuresHeaderMessage(summary: IDepartmentEvaluationSummary, t: TFunction): string {
  if (!summary.measuresChangedAt) {
    return null;
  }

  return `${formatter.formatDateString(summary.measuresChangedAt)} - ${summary.measuresChangedBy}`;
}

function getEvaluationStatusMessage(summary: IDepartmentEvaluationSummary, t: TFunction): string {
  if (summary.isEvaluationCompleted) {
    return `${formatter.formatDateString(summary.evaluationCompletedAt)} - ${summary.evaluationCompletedBy}`;
  }

  if (summary.isEvaluationRequired) {
    return t(TP + 'inprogress');
  }

  return null;
}

const Content: FC<{}> = (props) => {
  return <span className={styles.cellcontent}>{props.children}</span>;
};

interface IEvaluationSummaryListProps {
  formik: FormikProps<IRiskAssessment>;
  riskAssessmentEditVm: IRiskAssessmentEditVm;
}
