import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Approval.module.css';
import {
  IRiskAssessmentEditVm,
  IRiskAssessment,
  IRiskAssessmentOnSaveCallback,
  IEnsureDocumentCallback,
  IUploadDocumentsCallback,
  IApprovalFlowStage,
  IApprovalFlowData,
  IRiskAssessmentConfig,
  hasAppraisalForAllFlows,
  IAppraisalFlowCommentCollection,
  IAppraisalFlowComment,
  ApprovalFlowTypes,
} from '../../../models/riskAssessment';
import { FormikProps } from 'formik';
import { HtcCard, HtcCardWrapper } from '../../../shared/HtcCardComponent';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { DocumentList } from '../../components/DocumentList/DocumentList';
import {
  createDocumentMetadataForMisc,
  DocumentTypes,
  ApprovalStageOwner,
  getApprovalDocuments,
  createDocumentMetadataForClearance,
  getClearanceDocuments,
  getSafeDocuments,
  createDocumentMetadataForSafe,
  RiskAssessmentOwner,
  createDocumentMetadataForOfferOrderContract,
  getMetadataDocuments,
  createDocumentMetadataForCalculation,
} from '../../../models/documents/IDocumentMetaData';
import _ from 'lodash';
import { FormCommentWithHistory } from '../../../shared/components/CommentHistory/CommentHistory';
import { getRiskAssessmentApprovalStage, getPathToRiskAssessmentApprovalStageField, getFlowById } from '../formhelper';
import { TransitionActions } from '../transitionActions/TransitionActions';
import { PermissionCtx } from '../RiskAssessmentContext';
import { getAvailableStageTransitions } from '../transitionActions/approvalStageHelper';
import { GenerateReport, IReportOptions } from '../../../shared/components/CommandBar/CommandBar';
import nameof from 'ts-nameof.macro';
import PermissionCheck from '../../../shared/components/PermissionCheck/PermissionCheck';
import { useRouteMatch } from 'react-router';
import { IGenerateReportFunc, ApprovalReport, OrderReleaseReport, POAReport } from '../reporting/model';
import classnames from 'classnames';
import { AssessmentPageHeader } from '../assessment/PageHeader/PageHeader';
import { AssessmentPageContent } from '../assessment/PageContent/PageContent';
import { Tabs, Comment, Button, Drawer, Timeline } from 'antd';
import { DocumentTabs } from '../shared/DocumentTabs/DocumentTabs';
import formatter from '../../../shared/formatter';
import riskAssessmentService from '../../../shared/services/riskAssessmentService';
import { HistoryPane } from '../assessment/EvaluationPage/HistoryPane';
import { IUserInfoCtx, UserInfoCtx } from '../../../UserInfoContext';
import { HistoryOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { DefaultDocumentListScrollConfig, KnownTenantIds } from '../../../models';
import { useTenantBranch } from '../../../TenantBranchContext';
import { TFunction } from 'i18next';
import { EvaluationSummaryList } from '../overview/EvaluationSummaryList';

let { TabPane } = Tabs;

let TR = 'riskAssessment.editform.approval.';

function getStageNamePostfix(assessment: IRiskAssessment, flowId: string, config: IRiskAssessmentConfig, context: IUserInfoCtx) {
  if (!config.hasMultipleApprovalFlows) {
    return null;
  }
  let flow = getFlowById(assessment, flowId);
  return `(${context.t(flow.name)})`;
}

function getReports(assessment: IRiskAssessment, flowId: string, t: any, config: IRiskAssessmentConfig): IReportOptions[] {
  let flow = getFlowById(assessment, flowId);
  if (flow.flowType == ApprovalFlowTypes.FullClearance || flow.flowType == ApprovalFlowTypes.PartialClearance) {
    return [
      { name: t('riskAssessment.createApprovalReport'), value: 'ApprovalReport' },
      { name: t('riskAssessment.createOrderReleaseReport'), value: 'OrderReleaseReport' },
    ];
  }
  if ((flow.flowType == ApprovalFlowTypes.PowerofAttorney || flow.flowType == ApprovalFlowTypes.DefaultWithPowerofAttorney) && config.hasPOAReport) {
    return [
      { name: t('riskAssessment.createApprovalReport'), value: 'ApprovalReport' },
      { name: t('riskAssessment.createPOAReport'), value: 'POAReport' },
    ];
  }
  return [{ name: t('riskAssessment.createApprovalReport'), value: 'ApprovalReport' }];
}

function onGenerateReportClick(
  reportValue: string,
  stage: IApprovalFlowStage,
  riskAssessment: IRiskAssessment,
  generateReport: IGenerateReportFunc,
  flowId: string,
  tenantId: string,
  branchId: string,
) {
  switch (reportValue) {
    case 'ApprovalReport':
      generateReport(new ApprovalReport(stage.id, flowId, riskAssessment), riskAssessment, tenantId, branchId);
      break;
    case 'OrderReleaseReport':
      generateReport(new OrderReleaseReport(stage.id, flowId, riskAssessment), riskAssessment, tenantId, branchId);
      break;
    case 'POAReport':
      generateReport(new POAReport(stage.id, flowId, riskAssessment), riskAssessment, tenantId, branchId);
      break;
  }
}

export let Approval: React.FC<IApprovalProps> = ({ formik, riskAssessmentVm, onSave, generateReport, onEnsureDocument, onUploadDocuments }) => {
  let [t] = useTranslation();
  let match = useRouteMatch<{ id: string; flowId: string }>();
  let permissionCtx = useContext(PermissionCtx);
  let userInfoCtx = useContext(UserInfoCtx);
  let { tenantId, branchId } = useTenantBranch();
  let permissions = riskAssessmentVm.permissions;
  let permissionHelper = permissionCtx.permissionHelper;
  let { id, flowId } = match.params;
  let riskAssessment = formik.values;
  let stage = getRiskAssessmentApprovalStage(riskAssessment, flowId, id);
  let readOnly = permissionCtx.permissionHelper.isApprovalReadOnly(flowId, id);
  let scrollConfig = DefaultDocumentListScrollConfig;

  let pathToComments = getPathToRiskAssessmentApprovalStageField(
    riskAssessment,
    flowId,
    id,
    nameof.full<IApprovalFlowStage>((r) => r.appraisal.comments),
  );
  let transitions = getAvailableStageTransitions(riskAssessment, riskAssessmentVm.commonConfig, id, readOnly);
  let config = riskAssessmentVm.commonConfig;
  let stageNamePostfix = getStageNamePostfix(riskAssessment, flowId, config, userInfoCtx);
  let additionalTabs = additionalDocumentTabs(riskAssessment.tenantId);

  return (
    <PermissionCheck hasAccess={permissions.canReadApproval}>
      <AssessmentPageHeader current={stage.name} postfix={stageNamePostfix}>
        {permissionHelper.canPrintRiskAnalysisReport() && (
          <GenerateReport
            reportOptions={getReports(riskAssessment, flowId, t, config)}
            onClick={(reportValue: string) => onGenerateReportClick(reportValue, stage, riskAssessment, generateReport, flowId, tenantId, branchId)}
          />
        )}
      </AssessmentPageHeader>
      <AssessmentPageContent>
        <HtcCardWrapper>
          <div className={styles.documentscontainer}>
            <HtcCard>
              <DocumentTabs defaultActiveKey="1">
                {config.hasClearanceDocuments && permissions.canReadClearanceDocuments && (
                  <TabPane tab={t('riskAssessment.documents.clearance')} key="1">
                    <DocumentList
                      disabled={!permissions.canReadClearanceDocuments}
                      riskAssessment={riskAssessment}
                      riskAssessmentVm={riskAssessmentVm}
                      onSave={onSave}
                      onEnsureDocument={onEnsureDocument}
                      onUploadDocuments={onUploadDocuments}
                      createNewMetadata={() => createDocumentMetadataForClearance(riskAssessment)}
                      documents={getClearanceDocuments(riskAssessment)}
                      scroll={scrollConfig}
                    />
                  </TabPane>
                )}
                {permissions.branchPermissions.canEditSafeDocuments && config.hasSafeDocuments && (
                  <TabPane tab={t('riskAssessment.documents.safe')} key="2">
                    <DocumentList
                      riskAssessment={riskAssessment}
                      riskAssessmentVm={riskAssessmentVm}
                      onSave={onSave}
                      onEnsureDocument={onEnsureDocument}
                      onUploadDocuments={onUploadDocuments}
                      disabled={readOnly || stage.isCompleted}
                      createNewMetadata={() => createDocumentMetadataForSafe(riskAssessment)}
                      documents={getSafeDocuments(riskAssessment)}
                      scroll={scrollConfig}
                    />
                  </TabPane>
                )}
                {permissions.branchPermissions.canEditContractDocuments && (
                  <TabPane tab={userInfoCtx.t(config.contractAndCalculationDocuments)} key="3">
                    <DocumentList
                      riskAssessment={riskAssessment}
                      riskAssessmentVm={riskAssessmentVm}
                      onSave={onSave}
                      onEnsureDocument={onEnsureDocument}
                      onUploadDocuments={onUploadDocuments}
                      createNewMetadata={() => createDocumentMetadataForOfferOrderContract(new RiskAssessmentOwner(riskAssessment.id))}
                      documents={getMetadataDocuments(riskAssessment, DocumentTypes.OfferOrderContract)}
                      scroll={scrollConfig}
                    />
                  </TabPane>
                )}
                <TabPane tab={t('riskAssessment.documents.my')} key="4">
                  <DocumentList
                    riskAssessment={riskAssessment}
                    riskAssessmentVm={riskAssessmentVm}
                    onSave={onSave}
                    onEnsureDocument={onEnsureDocument}
                    onUploadDocuments={onUploadDocuments}
                    disabled={readOnly || stage.isCompleted}
                    createNewMetadata={() => createDocumentMetadataForMisc(new ApprovalStageOwner(riskAssessment.id, stage.id))}
                    documents={getApprovalDocuments(riskAssessment, DocumentTypes.Miscellaneous, stage.id)}
                    scroll={scrollConfig}
                  />
                </TabPane>
                {additionalTabs.pmCalculationDocuments && permissions.branchPermissions.canEditPmCalcDocuments && (
                  <TabPane tab={userInfoCtx.t(config.pmCalculationDocuments)} key="5">
                    <DocumentList
                      riskAssessment={riskAssessment}
                      riskAssessmentVm={riskAssessmentVm}
                      onSave={onSave}
                      onEnsureDocument={onEnsureDocument}
                      onUploadDocuments={onUploadDocuments}
                      createNewMetadata={() => createDocumentMetadataForCalculation(new RiskAssessmentOwner(riskAssessment.id))}
                      documents={getMetadataDocuments(riskAssessment, DocumentTypes.Calculation)}
                    />
                  </TabPane>
                )}
                {additionalTabs.calculationDocuments && permissions.branchPermissions.canEditAppendicesDocuments && (
                  <TabPane tab={userInfoCtx.t(config.generalDocuments)} key="6">
                    <DocumentList
                      riskAssessment={riskAssessment}
                      riskAssessmentVm={riskAssessmentVm}
                      onSave={onSave}
                      onEnsureDocument={onEnsureDocument}
                      onUploadDocuments={onUploadDocuments}
                      createNewMetadata={() => createDocumentMetadataForMisc(new RiskAssessmentOwner(riskAssessment.id))}
                      documents={getMetadataDocuments(riskAssessment, DocumentTypes.Miscellaneous)}
                    />
                  </TabPane>
                )}
              </DocumentTabs>
            </HtcCard>
          </div>
          <HtcCard flex>
            <EvaluationSummaryList formik={formik} riskAssessmentEditVm={riskAssessmentVm} />
          </HtcCard>
          <div className={styles.infocontainer}>
            <div className={styles.infoitem}>
              <HtcCard flex>
                <FormCommentWithHistory required title={t(TR + 'evaluation')} style={{ maxHeight: '12rem' }} formik={formik} pathToComments={pathToComments} disabled={readOnly || stage.isCompleted} />
                {hasAppraisalForAllFlows(stage) && <ApprovalCommentHistory stage={stage} t={t} />}
                {config.hasMultipleApprovalFlows && <ApprovalData config={config} riskAssessmentId={riskAssessment.id} approvalData={riskAssessment.approvalFlows.approvalData} />}
              </HtcCard>
            </div>
            <div className={styles.infoitem}>
              <HtcCard flex>
                <Tabs type="card" animated={false}>
                  <TabPane tab={t('components.ApprovalActions.approvalHeader')} key="1">
                    <TransitionActions id={id} formik={formik} riskAssessmentVm={riskAssessmentVm} onSave={onSave} transitions={transitions} />
                  </TabPane>
                </Tabs>
              </HtcCard>
            </div>
          </div>
        </HtcCardWrapper>
      </AssessmentPageContent>
    </PermissionCheck>
  );
};

let ApprovalData: React.FC<{ config: IRiskAssessmentConfig; riskAssessmentId: string; approvalData: IApprovalFlowData }> = ({ config, approvalData, riskAssessmentId }) => {
  let [t] = useTranslation();
  let [showHistory, setShowHistory] = useState(false);
  return (
    <>
      <FormBox title={t('riskAssessment.approvalData')}>
        <div className={styles.historyactions}>
          <Button icon={<HistoryOutlined />} size="small" shape="circle" title={t('riskAssessment.changeHistory')} onClick={() => setShowHistory(true)} />
        </div>
        <table className={styles.riskstable}>
          <tbody>
            {approvalData.poa.vendorPoa && (
              <tr>
                <td className={classnames(styles.tablecol, styles.titlecell)}>{t('riskAssessment.vendorPoa')}:</td>
                <td className={classnames(styles.tablecol)}>{approvalData.poa.vendorPoa}</td>
              </tr>
            )}
            {config.hasOfferNrPoa && approvalData.poa.offerNrPoa && (
              <tr>
                <td className={classnames(styles.tablecol, styles.titlecell)}>{t('riskAssessment.offerNrPoa')}:</td>
                <td className={classnames(styles.tablecol)}>{approvalData.poa.offerNrPoa}</td>
              </tr>
            )}

            {approvalData.partialClearance.deliveryTimes && (
              <tr>
                <td className={classnames(styles.tablecol, styles.titlecell)}>{t('riskAssessment.deliveryTimes')}:</td>
                <td className={classnames(styles.tablecol)}>{approvalData.partialClearance.deliveryTimes}</td>
              </tr>
            )}
            {approvalData.partialClearance.billRecipient && (
              <tr>
                <td className={classnames(styles.tablecol, styles.titlecell)}>{t('riskAssessment.billRecipient')}:</td>
                <td className={classnames(styles.tablecol)}>{approvalData.partialClearance.billRecipient}</td>
              </tr>
            )}
            {approvalData.partialClearance.signatureDate && (
              <tr>
                <td className={classnames(styles.tablecol, styles.titlecell)}>{t('riskAssessment.signatureDate')}:</td>
                <td className={classnames(styles.tablecol)}>{formatter.formatDateString(approvalData.partialClearance.signatureDate)}</td>
              </tr>
            )}
            {approvalData.fullClearance.projectStart && (
              <tr>
                <td className={classnames(styles.tablecol, styles.titlecell)}>{t('riskAssessment.projectStart')}:</td>
                <td className={classnames(styles.tablecol)}>{formatter.formatDateString(approvalData.fullClearance.projectStart)} </td>
              </tr>
            )}
          </tbody>
        </table>
      </FormBox>
      <HistoryPane
        config={config}
        visible={showHistory}
        onClose={() => setShowHistory(false)}
        searchKey={'ApprovalData'}
        getHistory={() => riskAssessmentService.getApprovalDataHistory(riskAssessmentId)}
      />
    </>
  );
};

interface IApprovalProps {
  riskAssessmentVm: IRiskAssessmentEditVm;
  formik: FormikProps<IRiskAssessment>;
  onSave: IRiskAssessmentOnSaveCallback;
  onEnsureDocument: IEnsureDocumentCallback;
  onUploadDocuments: IUploadDocumentsCallback;
  generateReport: IGenerateReportFunc;
}

let ApprovalCommentHistory: React.FC<{ stage: IApprovalFlowStage; t: TFunction }> = ({ stage, t }) => {
  let [historyVisible, setHistoryVisible] = useState(false);
  return (
    <>
      <FormBox title={t(TR + 'approvalCommentHistory_title')}>
        <Button onClick={() => setHistoryVisible(true)} icon={<HistoryOutlined />}>
          {t(TR + 'approvalCommentHistory_action')}
        </Button>
      </FormBox>
      <CommentHistoryPanel onCancel={() => setHistoryVisible(false)} visible={historyVisible} appraisalFlowsCollection={stage.appraisalAllFlows} />
    </>
  );
};

let CommentList: FC<{ comments: IAppraisalFlowComment[] }> = ({ comments }) => {
  let ctx = useContext(UserInfoCtx);
  return (
    <div className={styles.commentlist}>
      <Timeline>
        {_.orderBy(comments, ['created'], ['desc']).map((c, index) => {
          return (
            <Timeline.Item key={index}>
              <Comment
                author={c.user}
                content={
                  <>
                    <SafetyCertificateOutlined /> <span style={{ fontWeight: 'bold' }}>Genehmigung {ctx.t(c.flowName)}</span>
                    <p>{c.content}</p>
                  </>
                }
                datetime={formatter.formatDateWithTimeString(c.created)}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

let CommentHistoryPanel: FC<{ visible: boolean; onCancel: () => void; appraisalFlowsCollection: IAppraisalFlowCommentCollection }> = ({ visible, onCancel, appraisalFlowsCollection }) => {
  const [t] = useTranslation();
  const hasComments = !_.isEmpty(appraisalFlowsCollection.comments);

  if (!visible) {
    return null;
  }

  return (
    <Drawer placement="right" visible={visible} width={500} title={t('components.AddCommentPanel.header')} onClose={onCancel}>
      {hasComments && <CommentList comments={appraisalFlowsCollection.comments} />}
      {!hasComments && <NoComments />}
    </Drawer>
  );
};

let NoComments: FC<{}> = (props) => {
  const [t] = useTranslation();
  return <div className={styles.nocomments}>{t('common.noEntries')}</div>;
};

interface IAdditionalDocumentTabsConfig {
  pmCalculationDocuments?: boolean;
  calculationDocuments?: boolean;
}

function additionalDocumentTabs(tenantId: string): IAdditionalDocumentTabsConfig {
  if (tenantId !== KnownTenantIds.Kiefel) {
    return {};
  }

  return {
    pmCalculationDocuments: true,
    calculationDocuments: true,
  };
}
