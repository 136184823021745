import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Info.module.css';

export const Info: React.FC<{ labelKey: string; value: string }> = ({ labelKey, value }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.table_row}>
      <span className={styles.column_label}>{t(labelKey)}:</span>
      <span className={styles.column_content}>{value}</span>
    </div>
  );
};

export const InfoRiskPanel: React.FC<{ labelKey: string; value: string }> = ({ labelKey, value }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.table_rowOuter}>
      <Info labelKey={labelKey} value={value}>
        {Children}
      </Info>
    </div>
  );
};
