import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';
import { ICustomer, createCustomer } from '../../models/masterdata';
import { Spin } from 'antd';
import _ from 'lodash';
import { UserInfoCtx } from '../../UserInfoContext';
import { useTranslation } from 'react-i18next';
import customerService from '../../shared/services/customerService';
import { CustomerList } from './CustomersList';
import { CustomerEditFormComponent } from './CustomersEditFormComponent';

export const Customers: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const { loading, data: customers } = useData<ICustomer[]>(id, customerService.getAllCustomers);
  const [editObj, setEditObj] = useState<ICustomer>();
  useEffect(() => setEditObj(id && _.cloneDeep(_.find(customers, (t) => t.id === id) || createCustomer())), [id, customers]);

  const cancelEdit = (): void => history.goBack();
  const onSave = async (customer: ICustomer) => {
    const saveAction = customer.id ? customerService.editCustomer(customer) : customerService.createCustomer(customer);
    const newEditObj = await tryAsyncAction(saveAction);
    setEditObj(newEditObj);
    cancelEdit();
  };
  const onDelete = async (department: ICustomer) => {
    await tryAsyncAction(customerService.deleteCustomer(department));
    cancelEdit();
  };

  const [searchText, setSearchText] = useState('');
  const filteredCustomers = useMemo(() => {
    const filtered = searchText
      ? _.filter(
          customers,
          (c) =>
            _.includes(_.toLower(c.debitorNumber), searchText) ||
            _.includes(_.toLower(ctx.t(c.description)), _.toLower(searchText)) ||
            _.includes(_.toLower(ctx.t(c.debitorName)), _.toLower(searchText)),
        )
      : customers;
    return _.sortBy(filtered, ['description']);
  }, [searchText, customers]);

  if (loading) {
    return <Spin></Spin>;
  }

  if (editObj) {
    return <CustomerEditFormComponent loading={loading} customer={editObj} onSave={onSave} onDelete={onDelete} cancelEdit={cancelEdit} />;
  }
  return <CustomerList loading={loading} customers={filteredCustomers} searchText={searchText} setSearchText={setSearchText} />;
};
