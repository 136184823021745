import React, { FC, useEffect } from 'react';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';

export const RiskPrompt: FC<IRiskPromptProps> = ({ active }) => {
  const [t] = useTranslation();
  const message = t('components.RiskPrompt.message');
  useEffect(() => {
    function leaveHandler(e: any) {
      (e || window.event).returnValue = message; //Gecko + IE
      return message; //Webkit, Safari, Chrome etc.
    }
    if (active) {
      window.addEventListener('beforeunload', leaveHandler);
    }
    return () => window.removeEventListener('beforeunload', leaveHandler);
  }, [active]);

  return <Prompt when={active} message={message} />;
};

interface IRiskPromptProps {
  active: boolean;
}
