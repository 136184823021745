import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HtcCardWrapper, HtcCard } from '../../../shared/HtcCardComponent';
import _ from 'lodash';
import { ICheckableTag } from '../../../shared/components/CheckableTagGroup/CheckableTagGroup';
import {
  getRiskAssessmentStatusOptions,
  IRiskAssessmentStatusMonitorViewModel,
  IRiskAssessmentListSearchConfig,
  IRiskAssessmentFilter,
  createRiskAssessmentListSearchConfig,
  RecursivePartial,
  IRiskAssessmentStatusMonitorEntry,
  RiskAssessmentStatus,
  IStoredRiskAssessmentListSearchConfig,
} from '../../../models/riskAssessment';
import { IBranchOfIndustry, ICountryRef, ITenantTerms } from '../../../models/masterdata';
import { StatusMonitorList } from './StatusMonitorList';
import { Radio } from 'antd';
import FormBox from '../../../shared/components/FormBox/FormBox';
import ListFilter from '../../components/ListFilter/ListFilter';
import { GenerateReport } from '../../../shared/components/CommandBar/CommandBar';
import riskAssessmentService from '../../../shared/services/riskAssessmentService';
import moment from 'moment';
import formatter from '../../../shared/formatter';
import { getForLanguage } from '../../../models/IMultiLanguageString';
import { UserInfoCtx } from '../../../UserInfoContext';
import { downloadFileBlob } from '../../components/DocumentList/DocumentHelper';
import { RiskAssessmentNavMenu } from '../../RiskAssessments';
import { CommandBarV2 } from '../../../shared/components/CommandBar/CommandBarV2';
import { MasterdataHeader } from '../../../shared/components/MasterdataHeader/MasterdataHeader';
import { userService } from '../../../shared/services/userService';
import { SearchOutlined } from '@ant-design/icons';
import { PaginationConfig } from 'antd/lib/pagination';
import { TableCurrentDataSource, SorterResult } from 'antd/lib/table/interface';
import { translateStatusForTenantSettings } from '../../riskAssessment/formhelper';

const navPaths = ['navigation.projects'];
const TP = 'riskAssessment.overview.status.';
const storeFilterConfigKey = 'StatusMonitor_storeFilterConfig';
class StatusMonitor extends Component<IStatusMonitorProps, IStatusMonitorState> {
  private branchesOfIndustryOptions: ICheckableTag[];
  private riskAssessmentStatusOptions: ICheckableTag[];
  private terms: ITenantTerms;

  constructor(props: IStatusMonitorProps, context: any) {
    super(props, context);
    this.state = {
      searchConfig: this.searchWith(this.loadFilterConfig() ?? createRiskAssessmentListSearchConfig(), { language: context.languageSettings.getActiveLanguage()?.key }),
      projectVolumeView: false,
    };
    this.terms = userService.getTenantTerms();
    this.branchesOfIndustryOptions = _.map(this.props.availableBranchesOfIndustry, (o) => ({
      tag: o.id,
      title: getForLanguage(o.name, context.languageSettings),
    }));
    this.riskAssessmentStatusOptions = this.getStatusOptions(props);
    this.load = _.debounce(this.load, 200);
    this.executeSearch = _.debounce(this.executeSearch, 400);
  }

  private getStatusOptions(props: IStatusMonitorProps) {
    let statusOptions = _.map(getRiskAssessmentStatusOptions(props.t, this.terms, this.context), (o) => ({ tag: o.value, title: o.desc }));
    statusOptions.unshift({ tag: RiskAssessmentStatus.FilterOpen, title: translateStatusForTenantSettings(RiskAssessmentStatus.FilterOpen, props.t, this.context, this.terms) });
    return statusOptions;
  }

  componentDidUpdate(prevProps: IStatusMonitorProps) {
    if (prevProps.branchOfIndustryId !== this.props.branchOfIndustryId) {
      const filter = this.getFilterForBranchOfIndustry();
      this.setState({ searchConfig: filter });
      this.props.load(filter);
    }
  }

  searchWith(current: IRiskAssessmentListSearchConfig, options: RecursivePartial<IRiskAssessmentListSearchConfig>): IRiskAssessmentListSearchConfig {
    const config: any = _.cloneDeep(current);
    return _.assign(config, options);
  }

  executeSearch(options: RecursivePartial<IRiskAssessmentListSearchConfig>): void {
    const config: any = this.searchWith(this.state.searchConfig, options);
    this.storeFilterConfig(config);
    this.setState({ searchConfig: config });
    this.load(config);
  }

  storeFilterConfig(filterConfig: IRiskAssessmentListSearchConfig) {
    let storeValue: IStoredRiskAssessmentListSearchConfig = _.assign(_.cloneDeep(filterConfig), { tenantId: userService.getActiveTenantId(), branchId: userService.getActiveBranchOfIndustryId() });
    window.sessionStorage.setItem(storeFilterConfigKey, JSON.stringify(storeValue));
  }

  loadFilterConfig(): IRiskAssessmentListSearchConfig | null {
    let rawValue = window.sessionStorage.getItem(storeFilterConfigKey);
    if (!rawValue) {
      return null;
    }
    let storeValue: IStoredRiskAssessmentListSearchConfig = JSON.parse(rawValue);
    if (storeValue.tenantId !== userService.getActiveTenantId() || storeValue.branchId !== userService.getActiveBranchOfIndustryId()) {
      window.sessionStorage.removeItem(storeFilterConfigKey);
      return null;
    }
    return storeValue;
  }

  onTableChange = (pagination: PaginationConfig, filters: any, sorter: SorterResult<IRiskAssessmentStatusMonitorEntry>, extra: TableCurrentDataSource<IRiskAssessmentStatusMonitorEntry>) => {
    this.executeSearch({ paging: pagination, sorting: { field: sorter.field as string, order: sorter.order } });
  };

  public async componentDidMount() {
    let filter = this.loadFilterConfig() ?? this.getFilterForBranchOfIndustry();
    this.executeSearch(filter);
  }

  public render(): any {
    const { t, isLoading } = this.props;
    const { totalCount, reportEntries } = this.props.riskAssessments;
    const reports = [
      { name: t('riskAssessment.createStatusMonitorReport'), value: 'default' },
      { name: t('riskAssessment.createProjectVolumeReport'), value: 'volume' },
    ];
    return (
      <>
        <MasterdataHeader>
          <CommandBarV2 paths={navPaths}>
            <RiskAssessmentNavMenu />
            <GenerateReport reportOptions={reports} text="riskAssessment.createStatusMonitorReport" onClick={(reportValue: string) => this.generateReport(reportValue)} />
          </CommandBarV2>
        </MasterdataHeader>
        <HtcCardWrapper>
          <HtcCard flex>
            <ListFilter
              onFilterChange={this.onFilterChange}
              availableBranchesOfIndustry={this.props.availableBranchesOfIndustry}
              branchOfIndustryId={this.props.branchOfIndustryId}
              branchesOfIndustryOptions={this.branchesOfIndustryOptions}
              filterConfig={this.state.searchConfig.filter}
              riskAssessmentStatusOptions={this.riskAssessmentStatusOptions}
              canFilterAllBranches={true}
            />
          </HtcCard>
          <StatusMonitorList
            searchConfig={this.state.searchConfig}
            riskAssessments={reportEntries}
            totalCount={totalCount}
            projectVolumeView={this.state.projectVolumeView}
            isLoading={isLoading}
            onTableChange={this.onTableChange}
          />
        </HtcCardWrapper>
      </>
    );
  }

  private onFilterChange = (filter: IRiskAssessmentFilter) => {
    this.executeSearch({ filter: filter });
  };

  private load = (filter: IRiskAssessmentListSearchConfig) => {
    this.props.load(filter);
  };

  private getFilterForBranchOfIndustry() {
    const searchConfig = _.cloneDeep(this.state.searchConfig);
    const option = _.find(this.branchesOfIndustryOptions, (b) => this.props.branchOfIndustryId === b.tag);
    searchConfig.filter.branchOfIndustryFilter = [option.tag];
    return searchConfig;
  }

  generateReport = async (reportValue: string) => {
    const report: any = await riskAssessmentService.createStatusMonitorReport(this.state.searchConfig, reportValue, this.context.languageSettings.getActiveLanguage());
    const head = report.headers['content-type'];
    const blob = new Blob([report.data], { type: head });

    downloadFileBlob(blob, `Statusreport_${formatter.formatDateWithTimeIsoString(moment().toISOString())}.xlsx`);
  };
}

StatusMonitor.contextType = UserInfoCtx;
export default withTranslation()(StatusMonitor as any);

interface IStatusMonitorProps extends WithTranslation {
  branchOfIndustryId: string;
  riskAssessments: IRiskAssessmentStatusMonitorViewModel;
  isLoading: boolean;
  availableBranchesOfIndustry: IBranchOfIndustry[];
  load: (filter: IRiskAssessmentListSearchConfig) => void;
}

interface IStatusMonitorState {
  searchConfig: IRiskAssessmentListSearchConfig;
  projectVolumeView: boolean;
}
