import React, { FC, useState, useMemo, useCallback, useContext, useEffect } from 'react';
import {
  RiskAssessmentStatus,
  INotificationData,
  IRiskAssessment,
  IAssessmentTransitionData,
  IRiskAssessmentAdditionalMailReceivers,
  IMessageTemplate,
  IRiskAssessmentConfig,
  TransitionData,
  PoaData,
  RecursivePartial,
  PartialClearanceData,
  IMessageTemplateGenerationData,
  ByOrderOfData,
  FullClearanceData,
  IByOrderUsers,
  IApprovalFlow,
  indicationOfMainScopeOptions,
} from '../../models/riskAssessment';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps, FieldArray } from 'formik';
import { Drawer, Button, Form, Alert, Checkbox, Tag, Input } from 'antd';
import FormBox from '../../shared/components/FormBox/FormBox';
import {
  FormTextInput,
  FormTextAreaInput,
  FormRadioYesNo,
  FormTextLabel,
  FormCheckboxInput,
  FormDateInput,
  FormTrLabel,
  FormUserPicker,
  FormRefItemPicker,
  FormSelectInput,
  FormAutoComplete,
} from '../../shared/FormComponents';
import EmailMessageService from '../../shared/services/emailMessageService';
import nameof from 'ts-nameof.macro';
import _ from 'lodash';
import styles from './AssessmentTransitionConfirm.module.css';
import RiskAssessmentStatusValidators from '../../models/riskAssessment/RiskAssessmentStatusHelper';
import { ValidationError } from 'yup';
import {
  StatusTransition,
  ApprovalStageTransition,
  CompleteAssessmentAnalysis,
  NoProject,
  ReleaseProjectForCommunication,
  AssessmentTransition,
  ReturnToEvaluation,
  ApprovalFlowStartTransition,
  CompleteApprovalStage,
  ReopenApprovalStage,
  getTransitionData,
  MoveToApproved,
} from '../../models/riskAssessment/AssessmentTransition';
import { TFunction } from 'i18next';
import { ValidationErrorDrawer } from '../riskAssessment/ValidationErrorDrawer';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { getForLanguage } from '../../models/IMultiLanguageString';
import * as yup from 'yup';

type RP<T> = RecursivePartial<T>;
const TR = 'components.AssessmentTransitionConfirm.';

export const AssessmentTransitionConfirm: FC<{
  visible: boolean;
  transition: AssessmentTransition;
  riskAssessment: IRiskAssessment;
  possibleAdditionalMailReceivers: IRiskAssessmentAdditionalMailReceivers;
  byOrderUsers: IByOrderUsers;
  mustProvideNotificationData: boolean;
  mustEnsureRequiredDepartmentsForNotifications?: boolean;
  onCancel: () => void;
  onConfirm: (data: IAssessmentTransitionData) => void;
  config: IRiskAssessmentConfig;
}> = ({
  visible,
  onCancel,
  onConfirm,
  transition,
  riskAssessment,
  mustProvideNotificationData,
  possibleAdditionalMailReceivers,
  byOrderUsers,
  config,
  mustEnsureRequiredDepartmentsForNotifications = false,
}) => {
  const [t] = useTranslation();
  const context = useContext(UserInfoCtx);
  const [messages, setMessages] = useState({ subject: '', body: '' });
  const messageTemplate = getMessageTemplateForTransition(transition, riskAssessment);
  const mustEnsureRequiredDepartments = mustEnsureRequiredDepartmentsForNotifications || config.mustEnsureRequiredDepartmentsForNotifications;

  const initialValues: ITransitionConfirmData = {
    notificationData: transition?.createNotificationData(riskAssessment, context.languageSettings, messages, mustEnsureRequiredDepartments),
    transitionData: getTransitionData(transition),
  };
  const validationScheme = useMemo(() => getValidationScheme(initialValues, t, config), [initialValues, t, config]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    let cancelled = false;

    const getTexts = async () => {
      if (!cancelled && (!mustProvideNotificationData || !messageTemplate)) {
        setMessages({ subject: '', body: '' });
        return;
      }
      const result = await EmailMessageService.getFormattedMessageForRiskAssessment(riskAssessment, messageTemplate, getGenerationData(transition, riskAssessment, config, context));
      if (result && !cancelled) {
        setMessages({ subject: getForLanguage(result.subject, context.languageSettings), body: getForLanguage(result.body, context.languageSettings) });
      } else if (!cancelled) {
        setMessages({ subject: '', body: '' });
      }
    };

    getTexts();

    return () => {
      cancelled = true;
    };
  }, [messageTemplate, riskAssessment, visible, transition, mustProvideNotificationData, context.languageSettings]);

  if (!visible) {
    return null;
  }
  const texts = getTextsForTransition(transition, t, context, config);

  let validationError: ValidationError = null;
  validationError = validate(transition, riskAssessment);
  let branchId = riskAssessment.metadata.branchOfIndustry.id;

  if (validationError) {
    return <ValidationErrorDrawer config={config} visible={visible} onCancel={onCancel} validationError={validationError} title={t(TR + 'missingdataheader')} userInfoCtx={context} />;
  }

  let hasNotificationToCreatorOption = !(transition instanceof ApprovalStageTransition);

  return (
    <Formik
      validationSchema={validationScheme}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(data, actions) => {
        onConfirm({ transition, notificationData: mustProvideNotificationData ? data.notificationData : null, transitionData: data.transitionData });
        actions.setSubmitting(false);
      }}
    >
      {(formik) => {
        const { submitForm, isValid } = formik;
        return (
          <Drawer placement="right" visible={visible} width={500} title={texts.header} onClose={onCancel}>
            <Form layout="vertical">
              <TransitionDataEdit formik={formik} branchId={branchId} byOrderUsers={byOrderUsers} config={config} />
              {mustProvideNotificationData && (
                <EmailNotificationData
                  formik={formik}
                  mustEnsureRequiredDepartmentsForNotifications={mustEnsureRequiredDepartments}
                  possibleAdditionalMailReceivers={possibleAdditionalMailReceivers}
                  notificationToCreatorOption={hasNotificationToCreatorOption}
                />
              )}
              <FormBox title={texts.header}>
                <p>{texts.message}</p>
                <div>
                  <Button style={{ marginRight: '0.5rem' }} onClick={() => onCancel()} data-automation-id="transition-confirm-no">
                    {t('common.no')}
                  </Button>
                  <Button data-automation-id="transition-confirm-yes" type="primary" disabled={!isValid} onClick={submitForm}>
                    {texts.okButton}
                  </Button>
                </div>
              </FormBox>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

const EmailNotificationData: FC<{
  formik: FormikProps<ITransitionConfirmData>;
  possibleAdditionalMailReceivers?: IRiskAssessmentAdditionalMailReceivers;
  mustEnsureRequiredDepartmentsForNotifications: boolean;
  notificationToCreatorOption: boolean;
}> = ({ formik, possibleAdditionalMailReceivers, mustEnsureRequiredDepartmentsForNotifications, notificationToCreatorOption }) => {
  const [t] = useTranslation();

  return (
    <FormBox title={t('components.EmailNotificationData.header')}>
      <FormTextInput {...formik} name={nameof.full<ITransitionConfirmData>((d) => d.notificationData.messageData.subject)} label={t('components.EmailNotificationData.subject')} />
      <FormTextAreaInput {...formik} name={nameof.full<ITransitionConfirmData>((d) => d.notificationData.messageData.body)} label={t('components.EmailNotificationData.body')} rows={6} />
      <MailReceivers mustEnsureRequiredDepartmentsForNotifications={mustEnsureRequiredDepartmentsForNotifications} formik={formik} />
      <AdditionalReceivers formik={formik} possibleAdditionalMailReceivers={possibleAdditionalMailReceivers} />
      {notificationToCreatorOption && (
        <FormRadioYesNo {...formik} name={nameof.full<ITransitionConfirmData>((d) => d.notificationData.notificationToCreator)} label={t('components.EmailNotificationData.notificationToCreator')} />
      )}
      <FormTextLabel />
    </FormBox>
  );
};

const MailReceivers: FC<{ formik: FormikProps<ITransitionConfirmData>; mustEnsureRequiredDepartmentsForNotifications: boolean }> = ({ formik, mustEnsureRequiredDepartmentsForNotifications }) => {
  const notificationData = formik.values.notificationData;
  const [t] = useTranslation();
  const context = useContext(UserInfoCtx);
  return (
    <>
      <FormTrLabel block trKey="components.EmailNotificationData.receivers" />
      <FieldArray
        name={nameof.full<ITransitionConfirmData>((d) => d.notificationData.departmentReceivers)}
        render={(arrayHelpers) => (
          <div className={styles.table}>
            {notificationData.departmentReceivers.map((dr, idx) => {
              const toggleDisabled = mustEnsureRequiredDepartmentsForNotifications && dr.mustParticipate;
              return (
                <div className={styles.row} key={dr.id}>
                  <div className={styles.control_cell}>
                    <FormCheckboxInput
                      {...formik}
                      text={getForLanguage(dr.name, context.languageSettings, context.languageSettings.getActiveLanguage())}
                      name={nameof.full<ITransitionConfirmData>((d) => d.notificationData.departmentReceivers) + `[${idx}].isRequired`}
                      disabled={toggleDisabled}
                    />
                  </div>
                  <div className={styles.message_cell}>
                    {dr.isRequired && !dr.hasEmailReceivers && <Alert message={t('components.EmailNotificationData.noMailReceiverDefined')} type="warning" showIcon />}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      />
    </>
  );
};

const AdditionalReceivers: FC<{ formik: FormikProps<ITransitionConfirmData>; possibleAdditionalMailReceivers: IRiskAssessmentAdditionalMailReceivers }> = ({
  formik,
  possibleAdditionalMailReceivers,
}) => {
  const [t] = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const filterHandler = useCallback(_.debounce(setFilter, 200), [setFilter]);
  const filteredMailReceivers = useMemo(() => _.filter(possibleAdditionalMailReceivers.receivers, (r) => filter == '' || r.username.toLowerCase().indexOf(filter) !== -1), [filter]);

  if (!possibleAdditionalMailReceivers || !possibleAdditionalMailReceivers.receivers) {
    return null;
  }

  return (
    <>
      <FormTrLabel block trKey="components.EmailNotificationData.additionalReceivers" />
      <Button size="small" onClick={() => setDrawerOpen(true)}>
        {t(TR + 'edit')}
      </Button>
      <div className={styles.cclist}>
        {_.map(formik.values.notificationData.additionalReceivers, (r) => (
          <Tag key={r.userId}>{r.username}</Tag>
        ))}
      </div>
      <Drawer title={t(TR + 'ccReceivers')} width={320} closable={true} onClose={() => setDrawerOpen(false)} visible={drawerOpen}>
        <div>
          <FormBox title="Filter">
            <Input allowClear onChange={(e) => filterHandler(e.target.value.toLowerCase())} />
          </FormBox>
          <FieldArray
            name={nameof.full<ITransitionConfirmData>((d) => d.notificationData.additionalReceivers)}
            render={(arrayHelpers) => (
              <FormBox title={t(TR + 'ccReceiversBox')}>
                {filteredMailReceivers.map((mr, idx) => (
                  <div key={idx}>
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(mr);
                        } else {
                          arrayHelpers.remove(_.findIndex(formik.values.notificationData.additionalReceivers, (re) => re.userId === mr.userId));
                        }
                      }}
                      key={mr.userId}
                    >
                      {mr.username}
                    </Checkbox>
                  </div>
                ))}
              </FormBox>
            )}
          />
        </div>
        <div className={styles.backContainer}>
          <Button onClick={() => setDrawerOpen(false)}>{t(TR + 'back')}</Button>
        </div>
      </Drawer>
    </>
  );
};

interface ITransitionDataEditProps {
  formik: FormikProps<ITransitionConfirmData>;
  branchId?: string;
  byOrderUsers?: IByOrderUsers;
  config: IRiskAssessmentConfig;
}
const TransitionDataEdit: React.FC<ITransitionDataEditProps> = ({ formik, branchId, byOrderUsers, config }) => {
  let data = formik.values.transitionData;

  if (data == null) {
    return null;
  }

  if (data instanceof PoaData) {
    return <PoaDataEdit formik={formik} config={config} />;
  }

  if (data instanceof PartialClearanceData) {
    return <PartialClearanceDataEdit formik={formik} config={config} />;
  }

  if (data instanceof FullClearanceData) {
    return <FullClearanceDataEdit formik={formik} config={config} />;
  }

  if (data instanceof ByOrderOfData) {
    return <ByOrderOfEdit formik={formik} branchId={branchId} byOrderUsers={byOrderUsers} config={config} />;
  }

  return null;
};

const PoaDataEdit: React.FC<ITransitionDataEditProps> = ({ formik, config }) => {
  let [t] = useTranslation();

  return (
    <FormBox title={t(TR + 'poa')}>
      {
        <FormTextAreaInput
          {...formik}
          required={true}
          rows={4}
          name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as PoaData).vendorPoa)}
          label={t(TR + 'poaVendor')}
          placeholder={t(TR + 'vendorPoaPlaceholder')}
        />
      }
      {config.hasPOAReport && config.hasOfferNrPoa && (
        <FormTextInput {...formik} required={true} name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as PoaData).offerNrPoa)} label={t(TR + 'poaOfferNr')} />
      )}
    </FormBox>
  );
};

const ByOrderOfEdit: React.FC<ITransitionDataEditProps> = ({ formik, branchId, byOrderUsers }) => {
  let [t] = useTranslation();
  return (
    <FormBox title={t(TR + 'byOrder')}>
      <FormRefItemPicker options={byOrderUsers.users} {...formik} name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as ByOrderOfData).byOrderOf)} label={t(TR + 'byOrderOf')} />
    </FormBox>
  );
};

const PartialClearanceDataEdit: React.FC<ITransitionDataEditProps> = ({ formik, config }) => {
  let [t] = useTranslation();

  return (
    <FormBox title={t(TR + 'partialClearance')}>
      <FormTextAreaInput
        {...formik}
        required={true}
        rows={3}
        name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as PartialClearanceData).billRecipient)}
        label={t(TR + 'billRecipient')}
        placeholder={t('riskAssessment.billRecipientPlaceholder')}
      />
      <FormTextInput
        {...formik}
        required={true}
        name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as PartialClearanceData).deliveryTimes)}
        description={t('descriptions.deliveryTimes')}
        label={t(TR + 'deliveryTimes')}
      />
      <FormDateInput {...formik} required={true} name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as PartialClearanceData).signatureDate)} label={t(TR + 'signatureDate')} />
      {config.hasEstimatedDateOfTurnover && (
        <FormDateInput
          {...formik}
          required={true}
          name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as PartialClearanceData).estimatedDateOfTurnover)}
          label={t(TR + 'estimatedDateOfTurnover')}
        />
      )}
      {config.hasIndicationOfMainScope && (
        <FormAutoComplete
          {...formik}
          required={true}
          name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as PartialClearanceData).indicationOfMainScope)}
          label={t(TR + 'indicationOfMainScope')}
          options={indicationOfMainScopeOptions}
        />
      )}
    </FormBox>
  );
};

const FullClearanceDataEdit: React.FC<ITransitionDataEditProps> = ({ formik, config }) => {
  let [t] = useTranslation();

  return (
    <FormBox title={t(TR + 'fullClearance')}>
      <FormTextAreaInput
        {...formik}
        required={true}
        rows={3}
        name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as FullClearanceData).billRecipient)}
        label={t(TR + 'billRecipient')}
        placeholder={t('riskAssessment.billRecipientPlaceholder')}
      />
      <FormTextInput
        {...formik}
        required={true}
        name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as FullClearanceData).deliveryTimes)}
        description={t('descriptions.deliveryTimes')}
        label={t(TR + 'deliveryTimes')}
      />
      <FormDateInput
        {...formik}
        required={true}
        name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as FullClearanceData).projectStart)}
        label={t(TR + 'projectStart')}
        description={t('descriptions.projectStart')}
      />
      <FormDateInput {...formik} required={true} name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as FullClearanceData).signatureDate)} label={t(TR + 'signatureDate')} />
      {config.hasEstimatedDateOfTurnover && (
        <FormDateInput
          {...formik}
          required={true}
          name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as FullClearanceData).estimatedDateOfTurnover)}
          label={t(TR + 'estimatedDateOfTurnover')}
        />
      )}
      {config.hasIndicationOfMainScope && (
        <FormAutoComplete
          {...formik}
          required={true}
          name={nameof.full<ITransitionConfirmData>((t) => (t.transitionData as FullClearanceData).indicationOfMainScope)}
          label={t(TR + 'indicationOfMainScope')}
          options={indicationOfMainScopeOptions}
        />
      )}
    </FormBox>
  );
};

function getGenerationData(transition: AssessmentTransition, riskAssessment: IRiskAssessment, config: IRiskAssessmentConfig, ctx: IUserInfoCtx): IMessageTemplateGenerationData | null {
  if (transition instanceof ApprovalStageTransition) {
    return {
      completingDepartmentId: transition.fromStage?.department?.id,
      fromDepartmentId: transition.fromStage?.department?.id,
      toDepartmentId: transition.toStage?.department?.id,
      approvalFlowId: riskAssessment.approvalFlows?.currentFlowId,
    };
  }

  if (transition instanceof ApprovalFlowStartTransition) {
    return {
      approvalFlowId: transition.flow?.id,
      toDepartmentId: getFirstStageDepartmentId(transition.flow),
      fromDepartmentName: ctx.t(config.salesDepartment),
    };
  }

  if (transition instanceof CompleteAssessmentAnalysis) {
    return {
      approvalFlowId: riskAssessment.approvalFlows?.currentFlowId,
    };
  }

  return null;
}

function getFirstStageDepartmentId(flow: IApprovalFlow) {
  return (flow?.stages || [])[0]?.department?.id;
}

function validate(transition: AssessmentTransition, riskAssessment: IRiskAssessment): ValidationError {
  if (transition instanceof StatusTransition) {
    return validateStatus(transition.fromStatus, transition.toStatus, riskAssessment);
  }

  return null;
}

function validateStatus(fromStatus: RiskAssessmentStatus, toStatus: RiskAssessmentStatus, riskAssessment: IRiskAssessment): ValidationError {
  try {
    RiskAssessmentStatusValidators.getValidatorForStatusTransition(fromStatus, toStatus).validateSync(riskAssessment, {
      abortEarly: false,
    });
  } catch (e) {
    return e as any;
  }
  return null;
}

function getMessageTemplateForTransition(transition: AssessmentTransition, riskAssessment: IRiskAssessment): IMessageTemplate {
  return transition?.getMessageTemplate(riskAssessment);
}

function getTextsForTransition(transition: AssessmentTransition, t: TFunction, userCtx: IUserInfoCtx, config: IRiskAssessmentConfig): { header: string; message: string; okButton: string } {
  let header = '';
  let message = '';
  let okButton = t('common.yes');
  // TODO: Die Texte sollten in die jeweilige Assessment Transition Klasse ausgelagert werden
  if (transition instanceof CompleteAssessmentAnalysis) {
    header = t(TR + 'completeAnalysisHeader');
    message = t(TR + 'completeAnalysisMessage');
  } else if (transition instanceof NoProject) {
    header = t(TR + 'noProjectStageHeader');
    message = t(TR + 'noProjectTransitionMessage');
  } else if (transition instanceof ReleaseProjectForCommunication) {
    header = t(TR + 'releaseProjectForCommunicationStageHeader');
    message = t(TR + 'releaseProjectForCommunicationTransitionMessage');
    okButton = t('common.send');
  } else if (transition instanceof ReturnToEvaluation) {
    let salesDepartment = { department: userCtx.t(config.salesDepartment) };
    header = t(TR + 'returnToEvaluationStageHeader', salesDepartment);
    message = t(TR + 'returnToEvaluationTransitionMessage', salesDepartment);
    okButton = t('common.yes');
  } else if (transition instanceof StatusTransition) {
    header = t(TR + 'statusHeader');
    message = t(TR + 'statusTransitionMessage');
    okButton = t('common.send');
  } else if (transition instanceof ApprovalStageTransition) {
    header = t(TR + 'approvalStageHeader');
    message = t(TR + 'approvalTransitionMessage');
    okButton = t('common.send');
  } else if (transition instanceof ApprovalFlowStartTransition) {
    header = t(TR + 'approvalFlowStartHeader');
    message = t(TR + 'approvalFlowStartTransitionMessage', { name: userCtx.t(transition.flow.name) });
    okButton = t('common.send');
  } else if (transition instanceof CompleteApprovalStage) {
    header = t(TR + 'approvalStageCompleteHeader');
    message = t(TR + 'approvalStageCompleteMessage');
  } else if (transition instanceof ReopenApprovalStage) {
    header = t(TR + 'approvalStageReopenHeader');
    message = t(TR + 'approvalStageReopenMessage');
  } else if (transition instanceof MoveToApproved) {
    header = t(TR + 'moveToApprovedStageHeader');
    message = t(TR + 'moveToApprovedTransitionMessage');
  }

  return {
    header,
    message,
    okButton,
  };
}

export interface ITransitionConfirmData {
  notificationData: INotificationData;
  transitionData?: TransitionData;
}

const getValidationScheme = (confirmData: ITransitionConfirmData, t: TFunction, config: IRiskAssessmentConfig) => {
  if (confirmData?.transitionData == null) return null;

  if (confirmData.transitionData instanceof PoaData) {
    return yup.object().shape<any>({
      transitionData: yup.object().shape<any>({
        vendorPoa: yup.string().required(t('error.required')).typeError(t('error.required')),
        offerNrPoa: config.hasPOAReport && config.hasOfferNrPoa ? yup.string().required(t('error.required')).typeError(t('error.required')) : undefined,
      }),
    });
  }

  if (confirmData.transitionData instanceof PartialClearanceData) {
    return yup.object().shape<any>({
      transitionData: yup.object().shape<any>({
        deliveryTimes: yup.string().required(t('error.required')).typeError(t('error.required')),
        billRecipient: yup.string().required(t('error.required')).typeError(t('error.required')),
        signatureDate: yup.date().required(t('error.required')).typeError(t('error.required')) as any,
        estimatedDateOfTurnover: config.hasEstimatedDateOfTurnover ? (yup.date().required(t('error.required')).typeError(t('error.required')) as any) : undefined,
        indicationOfMainScope: config.hasIndicationOfMainScope ? yup.string().required(t('error.required')).typeError(t('error.required')) : undefined,
      }),
    });
  }

  if (confirmData.transitionData instanceof FullClearanceData) {
    return yup.object().shape<any>({
      transitionData: yup.object().shape<any>({
        deliveryTimes: yup.string().required(t('error.required')).typeError(t('error.required')),
        billRecipient: yup.string().required(t('error.required')).typeError(t('error.required')),
        signatureDate: yup.date().required(t('error.required')).typeError(t('error.required')) as any,
        projectStart: yup.date().required(t('error.required')).typeError(t('error.required')) as any,
        estimatedDateOfTurnover: config.hasEstimatedDateOfTurnover ? (yup.date().required(t('error.required')).typeError(t('error.required')) as any) : undefined,
        indicationOfMainScope: config.hasIndicationOfMainScope ? yup.string().required(t('error.required')).typeError(t('error.required')) : undefined,
      }),
    });
  }

  return null;
};
