import { Collapse, Button, Tabs, Alert, Form } from 'antd';
import { Formik, FormikHelpers, FieldArray, FormikProps, FieldArrayRenderProps, getIn } from 'formik';
import React, { FC, useContext, useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import {
  IRiskAssessmentTemplate,
  createRiskAssessmentTemplate,
  IRiskAssessmentEvaluationStageTemplate,
  getDepartmentEvaluationStages,
  createDepartmentEvaluationStageTemplate,
  createDepartmentApprovalStageTemplate,
  IRiskAssessmentApprovalFlowTemplate,
  IApprovalStageTemplate,
  getApprovalStagesForFlowByIndex,
  createApprovalFlow,
  getApprovalFlowOptions,
  IRiskAssessmentEvaluationFlow,
  RecursivePartial,
  IRiskAssessmentApprovalFlowTemplates,
  getApprovalTypeTags,
  IRiskAssessmentDepartment,
} from '../../models/riskAssessment';
import { FormLanguageTextInput, FormRadioYesNo, FormRadioButtonGroup, FormSelectInput } from '../../shared/FormComponents';
import { useTranslation } from 'react-i18next';
import { HtcCard, HtcCardWrapper } from '../../shared/HtcCardComponent';
import _ from 'lodash';
import styles from './RiskAssessmentTemplatesEdit.module.css';
import { SaveAction, CancelAction, DeleteAction, CommandBarNavTypes } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { getForLanguage, testRequiredLanguageFields } from '../../models/IMultiLanguageString';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import { ILanguage } from '../../models/LanguageSettings';
import LanguageTabPage, { IInjectedLanguageProps } from '../../shared/components/LanguageTabPage/LanguageTabPage';
import { MessageTemplateEditor } from '../../shared/MessageTemplateEditor/MessageTemplateEditor';
import nameof from 'ts-nameof.macro';
import { TFunction } from 'i18next';
import { sortResult } from '../../shared/services/masterdataQueryHelper';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { useData } from '../../shared/masterDataHelpers';
import { Table } from '../../shared/components/Table/Table';
import { RefItemOptionsPicker } from '../../shared/components/RefItemPicker/RefItemPicker';
import { IDepartmentRef } from '../../models/masterdata';
import { RiskAssessmentTemplateService } from '../../shared/services/riskAssessmentTemplateService';
import { notifyError } from '../../shared/notificationHelper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';
import { DeleteButtonConfirm } from '../../shared/DeleteButtonConfirm/DeleteButtonConfirm';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import CheckableTagGroup, { ICheckableTag } from '../../shared/components/CheckableTagGroup/CheckableTagGroup';
import { PlusOutlined, DeleteOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { useTenantBranch } from '../../TenantBranchContext';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const TP = 'masterdata.riskAssessmentTemplates.editform.';

type RAT = IRiskAssessmentTemplate;
type AST = IApprovalStageTemplate;
type RAFT = IRiskAssessmentApprovalFlowTemplate;

const navPaths: CommandBarNavTypes[] = ['navigation.masterdata', { name: 'navigation.riskAssessmentTemplates', linkTo: '/riskassessmenttemplates' }];

function getDepartmentOptions(template: IRiskAssessmentTemplate, ctx: IUserInfoCtx) {
  const alreadyAssigned = _.map(getDepartmentEvaluationStages(template), (stage) => stage.department.id);
  const stillAssignable = _.filter(template.assignedDepartmentOptions, (o) => alreadyAssigned.indexOf(o.id) === -1);
  return sortResult(stillAssignable, ctx.languageSettings);
}

export const RiskAssessmentTemplatesEdit: FC<RiskAssessmentTemplatesEdit> = ({ id, onSave, cancelEdit, onDelete, onLoad }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const [validationScheme] = useState(getValidationScheme(t, ctx.languageSettings.getDefaultLanguage()));

  const { data } = useData<RAT>(id, () => onLoad(id));
  const [template, setTemplate] = useState(data ?? createRiskAssessmentTemplate());
  useEffect(() => setTemplate(data ?? createRiskAssessmentTemplate()), [data]);

  const isNew = !template.id;

  const settings = ctx.languageSettings;
  const nav = Object.assign([], navPaths);
  nav.push(getForLanguage(template.name, settings));

  const handleDelete = () => onDelete(template);
  const handleSave = async (riskAssessmentTemplate: IRiskAssessmentTemplate, actions: FormikHelpers<RAT>) => {
    const validationErrors = await RiskAssessmentTemplateService.isUnique(riskAssessmentTemplate);
    if (!validationErrors.hasErrors()) {
      try {
        const newTemplate = await onSave(riskAssessmentTemplate);
        actions.setSubmitting(false);
        setTemplate(newTemplate);
        cancelEdit();
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  return (
    <Formik enableReinitialize={true} initialValues={template} onSubmit={handleSave} validationSchema={validationScheme}>
      {(formik) => {
        const { isSubmitting, values, submitForm, isValid } = formik;
        return (
          <ContentWrapper>
            <RiskPrompt active={hasChanges(template, values)} />
            <MasterdataHeader>
              <CommandBarV2 paths={nav}>
                {!isNew && <DeleteAction disabled={isSubmitting} onClick={handleDelete} />}
                <CancelAction disabled={isSubmitting} onClick={cancelEdit} />
                <SaveAction disabled={isSubmitting || !isValid} onClick={submitForm} />
              </CommandBarV2>
            </MasterdataHeader>
            <HtcCardWrapper>
              <LanguageTabPage>
                {(lp) => (
                  <HtcCardWrapper>
                    <Form layout="vertical">
                      <HtcCard>
                        <Alert message={t(TP + 'editDisclaimer')} type="info" showIcon />
                        {!isValid && <Alert style={{ marginTop: '0.5rem' }} message={t(TP + 'validationErrors')} type="error" showIcon />}
                      </HtcCard>
                      <DefaultData formik={formik} lp={lp} />
                      <Notifications formik={formik} lp={lp} />
                      <SalesConfiguration formik={formik} />
                      <EvaluationConfiguration formik={formik} />
                      <ApprovalFlowConfiguration formik={formik} lp={lp} />
                    </Form>
                  </HtcCardWrapper>
                )}
              </LanguageTabPage>
            </HtcCardWrapper>
          </ContentWrapper>
        );
      }}
    </Formik>
  );
};

function hasChanges(original: IRiskAssessmentTemplate, candidate: IRiskAssessmentTemplate): boolean {
  return !_.isEqual(original, candidate);
}

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  return yup.object().shape<any>({
    name: testRequiredLanguageFields(50, defaultLanguage, t),
    evaluationFlow: yup.object().shape<any>({
      stages: yup.array().required().min(1),
    }),
    approvalFlows: yup.object().shape<any>({
      flows: yup
        .array()
        .required()
        .min(1)
        .of(
          yup.object().shape<any>({
            name: testRequiredLanguageFields(50, defaultLanguage, t),
            stages: yup.array().required().min(1),
          }),
        ),
    }),
  });
}

const DefaultData: React.FC<{ formik: FormikProps<RAT>; lp: IInjectedLanguageProps }> = ({ formik, lp }) => {
  const [t] = useTranslation();
  return (
    <HtcCard>
      <FormBox title={t(TP + 'details')} flex contentflex>
        <FormLanguageTextInput required {...formik} name="name" label={t('masterdata.riskAssessmentTemplates.name')} selectedLanguage={lp.lang} />
      </FormBox>
    </HtcCard>
  );
};

const Notifications: React.FC<{ formik: FormikProps<RAT>; lp: IInjectedLanguageProps }> = ({ formik, lp }) => {
  const [t] = useTranslation();
  let options = useMemo(() => _.map(formik.values.additionalCreatorCcReceiversOptions, (o) => ({ value: o.userId, desc: o.username })), [formik.values.additionalCreatorCcReceiversOptions]);
  return (
    <HtcCard>
      <FormBox title={t(TP + 'notificationTemplates')} flex contentflex>
        <Collapse>
          <Panel header={t(TP + 'statusChangedTemplate')} key={1}>
            <MessageTemplateEditor labelTr={TP + 'statusChangedTemplate'} name={nameof.full<RAT>((t) => t.notifications.statusChangedTemplate)} language={lp.lang} formik={formik} />
          </Panel>
          <Panel header={t(TP + 'reminderMailTemplate')} key={2}>
            <MessageTemplateEditor labelTr={TP + 'reminderMailTemplate'} name={nameof.full<RAT>((t) => t.notifications.reminderMailTemplate)} language={lp.lang} formik={formik} />
          </Panel>
          <Panel header={t(TP + 'evaluationCompletedTemplate')} key={3}>
            <MessageTemplateEditor labelTr={TP + 'evaluationCompletedTemplate'} name={nameof.full<RAT>((t) => t.notifications.evaluationCompletedTemplate)} language={lp.lang} formik={formik} />
          </Panel>
          <Panel header={t(TP + 'projectApprovalTemplate')} key={4}>
            <MessageTemplateEditor labelTr={TP + 'projectApprovalTemplate'} name={nameof.full<RAT>((t) => t.notifications.projectApprovalTemplate)} language={lp.lang} formik={formik} />
          </Panel>
          <Panel header={t(TP + 'projectApprovedTemplate')} key={5}>
            <MessageTemplateEditor labelTr={TP + 'projectApprovedTemplate'} name={nameof.full<RAT>((t) => t.notifications.projectApprovedTemplate)} language={lp.lang} formik={formik} />
          </Panel>
        </Collapse>
      </FormBox>
      <FormBox title={t(TP + 'creatorCcReceivers')} flex contentflex>
        <FormSelectInput name={nameof.full<RAT>((t) => t.additionalCreatorCcReceiverUserIds)} {...formik} label={t(TP + 'creatorCcReceiversLabel')} mode="multiple" options={options} />
      </FormBox>
    </HtcCard>
  );
};

const SalesConfiguration: React.FC<{ formik: FormikProps<RAT> }> = ({ formik }) => {
  const [t] = useTranslation();
  const ctx = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();

  const [addStageRef, setAddStageRef] = useState<IDepartmentRef | IDepartmentRef[]>();
  const template = formik.values;
  const dataSource = template.salesDepartments?.departments;
  const departmentOptions = getSalesDepartmentOptions(formik.values, ctx);
  return (
    <HtcCard>
      <FormBox requiredIndicator title={t(TP + 'assignedSalesDepartments')} flex contentflex>
        <FieldArray
          name={nameof.full<RAT>((t) => t.salesDepartments.departments)}
          render={(arrayHelper) => (
            <>
              <Table pagination={false} rowKey="id" columns={createSalesColumns(t, formik, arrayHelper, ctx)} dataSource={dataSource} />
              <FormBox title="">
                <div className={styles.addcontainer}>
                  <RefItemOptionsPicker
                    name="departmentsForSalesPicker"
                    languageSettings={ctx.languageSettings}
                    options={departmentOptions}
                    onChange={(selected) => setAddStageRef(selected)}
                    value={addStageRef}
                    tenantId={tenantId}
                    branchId={branchId}
                  />
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    disabled={!addStageRef}
                    onClick={() => {
                      arrayHelper.push(createDepartmentEvaluationStageTemplate(addStageRef as IDepartmentRef));
                      setAddStageRef(null);
                    }}
                  >
                    {t('common.add')}
                  </Button>
                </div>
              </FormBox>
            </>
          )}
        />
      </FormBox>
    </HtcCard>
  );
};

const EvaluationConfiguration: React.FC<IEvaluationConfigurationProps> = ({ formik }) => {
  const [t] = useTranslation();
  const ctx = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();

  const [addStageRef, setAddStageRef] = useState<IDepartmentRef | IDepartmentRef[]>();
  const template = formik.values;
  const dataSource = template.evaluationFlow.stages;
  const departmentOptions = getDepartmentOptions(formik.values, ctx);
  return (
    <HtcCard>
      <FormBox requiredIndicator title={t(TP + 'assignedDepartments')} flex contentflex>
        <FieldArray
          name={nameof.full<RAT>((t) => t.evaluationFlow.stages)}
          render={(arrayHelper) => (
            <>
              <Table pagination={false} rowKey="id" columns={createEvaluationColumns(t, formik, arrayHelper, ctx)} dataSource={dataSource} />
              <FormBox title="">
                <div className={styles.addcontainer}>
                  <RefItemOptionsPicker
                    name="departmentsForEvaluationPicker"
                    languageSettings={ctx.languageSettings}
                    options={departmentOptions}
                    onChange={(selected) => setAddStageRef(selected)}
                    value={addStageRef}
                    tenantId={tenantId}
                    branchId={branchId}
                  />
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    disabled={!addStageRef}
                    onClick={() => {
                      arrayHelper.push(createDepartmentEvaluationStageTemplate(addStageRef as IDepartmentRef));
                      setAddStageRef(null);
                    }}
                  >
                    {t('common.add')}
                  </Button>
                </div>
              </FormBox>
            </>
          )}
        />
      </FormBox>
    </HtcCard>
  );
};

function createEvaluationColumns(t: TFunction, formik: FormikProps<RAT>, arrayHelpers: FieldArrayRenderProps, ctx: IUserInfoCtx) {
  const getFieldPrefix = (record: IRiskAssessmentEvaluationStageTemplate) => {
    const index = _.indexOf(formik.values.evaluationFlow.stages, record);
    return `${nameof.full<RAT>((t) => t.evaluationFlow.stages)}[${index}]`;
  };

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      defaultSortOrder: 'ascend' as const,
      sorter: (a: IRiskAssessmentEvaluationStageTemplate, b: IRiskAssessmentEvaluationStageTemplate) => {
        return ctx.t(a.name).localeCompare(ctx.t(b.name));
      },
      render: (text: string, record: IRiskAssessmentEvaluationStageTemplate) => {
        const t = ctx.t(getIn(formik.values, `${getFieldPrefix(record)}.name`));
        return <span>{t}</span>;
      },
    },
    {
      title: t('masterdata.riskAssessmentTemplates.assignedDepartments.isAlwaysInvolved'),
      dataIndex: 'isAlwaysInvolved',
      width: 80,
      render: (text: string, record: IRiskAssessmentEvaluationStageTemplate) => {
        return <FormRadioYesNo {...formik} name={`${getFieldPrefix(record)}.isAlwaysInvolved`} />;
      },
    },
    {
      title: t('masterdata.riskAssessmentTemplates.assignedDepartments.isAssessmentRequired'),
      dataIndex: 'isAssessmentRequired',
      width: 80,
      render: (text: string, record: IRiskAssessmentEvaluationStageTemplate) => {
        return <FormRadioYesNo {...formik} name={`${getFieldPrefix(record)}.isAssessmentRequired`} />;
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      render: (text: string, record: IRiskAssessmentEvaluationStageTemplate) => (
        <Button shape="circle" size="small" icon={<DeleteOutlined />} onClick={() => arrayHelpers.remove(_.indexOf(formik.values.evaluationFlow.stages, record))} />
      ),
      width: 50,
    },
  ];
}

function createSalesColumns(t: TFunction, formik: FormikProps<RAT>, arrayHelpers: FieldArrayRenderProps, ctx: IUserInfoCtx) {
  const getFieldPrefix = (record: IRiskAssessmentDepartment) => {
    const index = _.indexOf(formik.values.salesDepartments.departments, record);
    return `${nameof.full<RAT>((t) => t.salesDepartments.departments)}[${index}]`;
  };

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      defaultSortOrder: 'ascend' as const,
      sorter: (a: IRiskAssessmentDepartment, b: IRiskAssessmentDepartment) => {
        return ctx.t(a.name).localeCompare(ctx.t(b.name));
      },
      render: (text: string, record: IRiskAssessmentDepartment) => {
        const t = ctx.t(getIn(formik.values, `${getFieldPrefix(record)}.name`));
        return <span>{t}</span>;
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      render: (text: string, record: IRiskAssessmentDepartment) => (
        <Button shape="circle" size="small" icon={<DeleteOutlined />} onClick={() => arrayHelpers.remove(_.indexOf(formik.values.evaluationFlow.stages, record))} />
      ),
      width: 50,
    },
  ];
}

interface IEvaluationConfigurationProps {
  formik: FormikProps<RAT>;
}

interface RiskAssessmentTemplatesEdit {
  id: string;
  onLoad: (id: string) => Promise<RAT>;
  onSave: (riskAssessmentTemplate: IRiskAssessmentTemplate) => Promise<IRiskAssessmentTemplate>;
  onDelete: (riskAssessmentTemplate: IRiskAssessmentTemplate) => Promise<void>;
  cancelEdit: () => void;
}

const actionButtonStyle = {
  marginLeft: '0.25rem',
};

const ApprovalFlowConfiguration: React.FC<{ formik: FormikProps<RAT>; lp: IInjectedLanguageProps }> = ({ formik, lp }) => {
  const [t] = useTranslation();
  const ctx = useContext(UserInfoCtx);

  const template = formik.values;
  const flows = template.approvalFlows?.flows || [];

  const [activeFlow, setActiveFlow] = useState<string>();
  useEffect(() => {
    if (activeFlow == null && flows.length > 0) {
      setActiveFlow('0');
    }
  }, [activeFlow, flows]);

  return (
    <FieldArray
      name={nameof.full<RAT>((t) => t.approvalFlows.flows)}
      render={(arrayHelpers) => {
        return (
          <HtcCard>
            {template.hasMultipleApprovalFlows && (
              <FormBox title={t(TP + 'actions')}>
                <Button style={{ marginBottom: '1rem' }} onClick={() => arrayHelpers.push(createApprovalFlow())}>
                  {t(TP + 'newApprovalFlow')}
                </Button>
              </FormBox>
            )}
            <FormBox title={t(TP + 'approvalStages')} flex contentflex>
              <Tabs animated={false} activeKey={activeFlow} onChange={setActiveFlow}>
                {flows.map((flow, idx) => (
                  <TabPane tab={ctx.t(flow.name) || t(TP + 'new')} key={idx.toString()}>
                    <ApprovalFlowEdit
                      flowIndex={idx}
                      formik={formik}
                      lp={lp}
                      onDelete={(flowIndex) => {
                        arrayHelpers.remove(flowIndex);
                        setActiveFlow(Math.max(0, flowIndex - 1).toString());
                      }}
                    />
                  </TabPane>
                ))}
              </Tabs>
            </FormBox>
          </HtcCard>
        );
      }}
    />
  );
};

interface IApprovalFlowEdit {
  flowIndex: number;
  formik: FormikProps<RAT>;
  lp: IInjectedLanguageProps;
  onDelete: (flowIndex: number) => void;
}

const ApprovalFlowEdit: React.FC<IApprovalFlowEdit> = ({ flowIndex, formik, lp, onDelete }) => {
  const [t] = useTranslation();
  const ctx = useContext(UserInfoCtx);
  const { tenantId, branchId } = useTenantBranch();
  const template = formik.values;
  const departmentOptionsForApproval = getApprovalStageOptions(template, flowIndex, ctx);
  const [addStageRef, setAddStageRef] = useState<IDepartmentRef | IDepartmentRef[]>();
  const dataSource = getApprovalStagesForFlowByIndex(formik.values, flowIndex);

  return (
    <FieldArray
      name={`${nameof.full<RAT>((t) => t.approvalFlows.flows)}[${flowIndex}].stages`}
      render={(arrayHelpers) => {
        return (
          <>
            {template.hasMultipleApprovalFlows && <AprovalFlowDataEdit formik={formik} flowIndex={flowIndex} lp={lp} onDelete={onDelete} />}
            <label className="ant-form-item-label ant-form-item-required">{t(TP + 'departments')}:</label>
            <Table pagination={false} rowKey="id" columns={createApprovalColumns(t, formik, flowIndex, arrayHelpers, ctx)} dataSource={dataSource} />
            <FormBox title="">
              <div className={styles.addcontainer}>
                <RefItemOptionsPicker
                  name="departmentForApprovalPicker"
                  languageSettings={ctx.languageSettings}
                  options={departmentOptionsForApproval}
                  onChange={(selected) => setAddStageRef(selected)}
                  value={addStageRef}
                  tenantId={tenantId}
                  branchId={branchId}
                />
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  disabled={!addStageRef}
                  onClick={() => {
                    arrayHelpers.push(createDepartmentApprovalStageTemplate(addStageRef));
                    setAddStageRef(null);
                  }}
                >
                  {t('common.add')}
                </Button>
              </div>
            </FormBox>
          </>
        );
      }}
    />
  );
};

const AprovalFlowDataEdit: React.FC<IApprovalFlowEdit> = ({ formik, flowIndex, lp, onDelete }) => {
  const [t] = useTranslation();
  const getFieldPrefix = (flowIndex: number, field: string) => {
    return `${nameof.full<RAT>((t) => t.approvalFlows.flows)}[${flowIndex}].${field}`;
  };

  const deleteDisabled = (formik.values.approvalFlows?.flows?.length || 0) <= 1;

  return (
    <div style={{ marginBottom: '0.5rem' }}>
      <DeleteButtonConfirm onDelete={() => onDelete(flowIndex)} disabled={deleteDisabled} />
      <FormLanguageTextInput {...formik} required label={t(TP + 'name')} name={getFieldPrefix(flowIndex, 'name')} selectedLanguage={lp.lang} />
      <FormRadioButtonGroup
        {...formik}
        options={getApprovalFlowOptions(t)}
        name={getFieldPrefix(
          flowIndex,
          nameof<RAFT>((d) => d.flowType),
        )}
        label={t(TP + 'type')}
      />
    </div>
  );
};

function createApprovalColumns(t: TFunction, formik: FormikProps<RAT>, currentFlowIndex: number, arrayHelpers: FieldArrayRenderProps, ctx: IUserInfoCtx) {
  const getFieldPrefix = (record: AST) => {
    const index = _.indexOf(formik.values.approvalFlows.flows[currentFlowIndex].stages, record);
    return `${nameof.full<RAT>((t) => t.approvalFlows.flows)}[${currentFlowIndex}].stages[${index}]`;
  };
  const getApprovalTypeField = (record: AST) => {
    const index: number = _.indexOf(formik.values.approvalFlows.flows[currentFlowIndex].stages, record);
    return `${nameof.full<RAT>((t) => t.approvalFlows.flows)}[${currentFlowIndex}].stages[${index}].approvalType`;
  };

  const count = getApprovalStagesForFlowByIndex(formik.values, currentFlowIndex).length;
  return [
    {
      title: 'Name',
      dataIndex: nameof<AST>((t) => t.name),
      width: 200,
      render: (text: string, record: AST) => {
        const t = ctx.t(getIn(formik.values, `${getFieldPrefix(record)}.name`));
        return <span>{t}</span>;
      },
    },
    {
      title: t('masterdata.riskAssessmentTemplates.approvalStages.canFinishProcess'),
      dataIndex: nameof<AST>((t) => t.canFinish),
      width: 80,
      render: (text: string, record: AST) => {
        return <FormRadioYesNo {...formik} name={`${getFieldPrefix(record)}.canFinish`} />;
      },
    },
    {
      title: t('masterdata.riskAssessmentTemplates.approvalType'),
      dataIndex: nameof<AST>((t) => t.approvalType),
      width: 80,
      render: (text: string, record: AST) => {
        return (
          <CheckableTagGroup
            defaultSelected={[getIn(formik.values, getApprovalTypeField(record))]}
            tags={getApprovalTypeTags(t)}
            onSelectedTagsChange={(tags: ICheckableTag[]) => {
              formik.setFieldValue(getApprovalTypeField(record), tags[0].tag);
            }}
          />
        );
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      render: (text: string, record: IApprovalStageTemplate, index: number) => (
        <>
          <Button shape="circle" size="small" icon={<DeleteOutlined />} onClick={() => arrayHelpers.remove(_.indexOf(getApprovalStagesForFlowByIndex(formik.values, currentFlowIndex), record))} />
          <Button
            shape="circle"
            type="primary"
            style={actionButtonStyle}
            size="small"
            icon={<UpOutlined />}
            disabled={index === 0}
            onClick={() => swapAndValidate(formik, arrayHelpers, index, index - 1)}
          />
          <Button
            shape="circle"
            type="primary"
            style={actionButtonStyle}
            size="small"
            icon={<DownOutlined />}
            disabled={index >= count - 1}
            onClick={() => swapAndValidate(formik, arrayHelpers, index, index + 1)}
          />
        </>
      ),
      width: 50,
    },
  ];
}

function getApprovalStageOptions(template: IRiskAssessmentTemplate, flowIndex: number, ctx: IUserInfoCtx) {
  const alreadyAssigned = _.map(getApprovalStageTemplates(template, flowIndex), (stage) => stage.id);
  const stillAssignable = _.filter(template.approvalStageOptions, (o) => alreadyAssigned.indexOf(o.id) === -1);
  return _.map(sortResult(stillAssignable, ctx.languageSettings));
}
function getApprovalStageTemplates(template: IRiskAssessmentTemplate, flowIndex: number): IApprovalStageTemplate[] {
  return template.approvalFlows.flows[flowIndex].stages;
}

function getSalesDepartmentOptions(template: IRiskAssessmentTemplate, ctx: IUserInfoCtx) {
  const alreadyAssigned = _.map(getSalesDepartments(template), (dep) => dep.id);
  const stillAssignable = _.filter(template.salesDepartmentOptions, (o) => alreadyAssigned.indexOf(o.id) === -1);
  return _.map(sortResult(stillAssignable, ctx.languageSettings));
}

function getSalesDepartments(template: IRiskAssessmentTemplate): IRiskAssessmentDepartment[] {
  return template.salesDepartments?.departments;
}

function swapAndValidate(formik: FormikProps<RAT>, arrayHelpers: FieldArrayRenderProps, oldIndex: number, newIndex: number) {
  arrayHelpers.swap(oldIndex, newIndex);
  // Es gibt Probleme mit der Validierung der tiefen Verschachtelung.
  // Darum nach dem Swap die Validierung erneut triggern
  setTimeout(() => formik.validateForm(), 0);
}
