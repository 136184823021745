import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IRiskAssessmentListViewModel, IRiskAssessmentStatusMonitorViewModel, IRiskAssessmentListSearchConfig, IRiskAssessmentListViewModels } from '../models/riskAssessment/IRiskAssessment';
import { IBranchOfIndustry } from '../models/masterdata';
import { IRootState, getActiveBranchOfIndustryId, getAvailableBranchesOfIndustry } from '../ducks/reducer';
import * as actions from './ducks';
import { connect } from 'react-redux';
import styles from './RiskAssessments.module.css';
import RiskAssessmentList from './overview/riskassessmentlist/RiskAssessmentList';
import StatusMonitor from './overview/statusmonitor/StatusMonitor';
import { RouteComponentProps, Route, Redirect, Switch, match, useRouteMatch } from 'react-router';
import { Menu } from 'antd';
import IconNavLink from '../shared/components/IconNavLink/IconNavLink';
import { EuroOutlined } from '@ant-design/icons';
import { getSubmenuAction } from '../shared/components/SizeDetector';

export class RiskAssessments extends PureComponent<IRiskAssessmentsProps & RouteComponentProps> {
  public render() {
    const {
      isLoading,
      riskAssessmentListView,
      riskAssessmentStatusMonitorView,
      branchOfIndustryId,
      loadRiskAssessmentViewModel,
      loadRiskAssessmentStatusMonitorViewModel,
      availableBranchesOfIndustry,
      match,
    } = this.props;

    const listUrl = getListUrl(match);
    const statusMonitorUrl = getStatusMonitorUrl(match);

    return (
      <div className={styles.mainwrapper}>
        <div className={styles.maincontentwrapper}>
          <Switch>
            <Route
              path={listUrl}
              render={(props) => (
                <RiskAssessmentList
                  {...props}
                  riskAssessments={riskAssessmentListView}
                  isLoading={isLoading}
                  branchOfIndustryId={branchOfIndustryId}
                  load={loadRiskAssessmentViewModel}
                  availableBranchesOfIndustry={availableBranchesOfIndustry}
                />
              )}
            />
            {/* <Route path={dashboardUrl} component={Dashboard} /> */}
            <Route
              path={statusMonitorUrl}
              render={(props) => (
                <StatusMonitor
                  {...props}
                  riskAssessments={riskAssessmentStatusMonitorView}
                  isLoading={isLoading}
                  branchOfIndustryId={branchOfIndustryId}
                  load={loadRiskAssessmentStatusMonitorViewModel}
                  availableBranchesOfIndustry={availableBranchesOfIndustry}
                />
              )}
            />
          </Switch>
          <Redirect from={match.url} to={listUrl} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState, ownProps: any) => {
  return {
    riskAssessmentListView: state.riskAssessment.riskAssessmentListView,
    riskAssessmentStatusMonitorView: state.riskAssessment.riskAssessmentStatusMonitorView,
    isLoading: state.riskAssessment.isLoading,
    branchOfIndustryId: getActiveBranchOfIndustryId(state.app),
    riskAssessmentRouteId: ownProps.riskAssessmentRouteId,
    availableBranchesOfIndustry: getAvailableBranchesOfIndustry(state.app),
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    loadRiskAssessmentViewModel: actions.getRiskAssessmentList.request,
    loadRiskAssessmentStatusMonitorViewModel: actions.getRiskAssessmentStatusMonitor.request,
  })(RiskAssessments),
);

interface IRiskAssessmentsProps extends RouteComponentProps, WithTranslation {
  selectedLanguage: string;
  branchOfIndustryId: string;
  selectedRiskAssessment: IRiskAssessmentListViewModel;
  riskAssessmentListView: IRiskAssessmentListViewModels;
  isLoading: boolean;
  availableBranchesOfIndustry: IBranchOfIndustry[];
  riskAssessmentRouteId: string;
  riskAssessmentStatusMonitorView: IRiskAssessmentStatusMonitorViewModel;
  loadRiskAssessmentViewModel: (filter: IRiskAssessmentListSearchConfig) => void;
  loadRiskAssessmentStatusMonitorViewModel: (filter: IRiskAssessmentListSearchConfig) => void;
}

const RiskAssessmentNavMenuStyle = { flex: 1, marginLeft: '1rem' };
export const RiskAssessmentNavMenu: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div style={RiskAssessmentNavMenuStyle}>
      <Menu triggerSubMenuAction={getSubmenuAction()} mode="horizontal" selectedKeys={getCurrentMenuKey(match)}>
        <Menu.Item key="list">
          <IconNavLink icon={EuroOutlined} nameTrPrefix="riskAssessment.overview.tabs." nameTrKey="list" to={getListUrl(match)} />
        </Menu.Item>
        <Menu.Item key="statusmonitor">
          <IconNavLink icon={EuroOutlined} nameTrPrefix="riskAssessment.overview.tabs." nameTrKey="statusmonitor" to={getStatusMonitorUrl(match)} />
        </Menu.Item>
      </Menu>
    </div>
  );
};

function getListUrl(match: match): string {
  return `/riskassessments/listoverview/list`;
}

function getStatusMonitorUrl(match: match): string {
  return `/riskassessments/listoverview/statusmonitor`;
}

function getCurrentMenuKey(match: match): string[] {
  if (match.url.indexOf(getListUrl(match)) !== -1) {
    return ['list'];
  }

  if (match.url.indexOf(getStatusMonitorUrl(match)) !== -1) {
    return ['statusmonitor'];
  }
}
