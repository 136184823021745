import { IIncotermRef } from '../../../models/masterdata';
import _ from 'lodash';
import { IPickerBaseProps, IPickerBaseState } from '../PickerBase/PickerBase';
import incotermService from '../../services/incotermService';
import LanguageAwarePickerBase from '../PickerBase/LanguageAwarePickerBase';

class IncotermPicker extends LanguageAwarePickerBase<IIncotermRef> {
  static getDerivedStateFromProps(props: IPickerBaseProps<IIncotermRef>, state: IPickerBaseState<IIncotermRef>): Partial<IPickerBaseState<IIncotermRef>> {
    if ((state.value && (state.value as IIncotermRef).id) !== (props.value && (props.value as IIncotermRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<IIncotermRef[]> {
    const onQueryItems = this.props.onQueryItems || incotermService.queryIncotermOptions;
    return onQueryItems(query, this.props.languageSettings, this.props.tenantId, this.props.branchId);
  }
}

export default IncotermPicker;
