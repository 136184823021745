import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';
import { ICountry, createCountry } from '../../models/masterdata';
import { Spin } from 'antd';
import { UserInfoCtx } from '../../UserInfoContext';
import _ from 'lodash';
import countryService from '../../shared/services/countryService';
import { sortResult } from '../../shared/services/masterdataQueryHelper';
import { CountryList } from './CountryList';
import { CountryEditFormComponent } from './CountryEditFormComponent';

export const Countries: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const ctx = useContext(UserInfoCtx);
  const { loading, data: countries } = useData<ICountry[]>(id, countryService.getAllCountries);
  const [editObj, setEditObj] = useState<ICountry>();
  useEffect(() => setEditObj(id && _.cloneDeep(_.find(countries, (t) => t.id === id) || createCountry())), [id, countries]);

  const cancelEdit = (): void => history.goBack();
  const onSave = async (country: ICountry) => {
    const saveAction = country.id ? countryService.editCountry(country) : countryService.createCountry(country);
    const newEditObj = await tryAsyncAction(saveAction);
    setEditObj(newEditObj);
    cancelEdit();
  };
  const onDelete = async (country: ICountry) => {
    await tryAsyncAction(countryService.deleteCountry(country));
    cancelEdit();
  };

  const [searchText, setSearchText] = useState('');
  const filteredCountries = useMemo(() => {
    const filtered = searchText
      ? _.filter(countries, (c) => _.includes(_.toLower(ctx.t(c.name)), _.toLower(searchText)) || _.includes(_.toLower(c.isoCode3Letters), _.toLower(searchText)))
      : countries;
    return sortResult(filtered, ctx.languageSettings);
  }, [searchText, countries]);

  if (loading) {
    return <Spin></Spin>;
  }

  if (editObj) {
    return <CountryEditFormComponent loading={loading} country={editObj} onSave={onSave} onDelete={onDelete} cancelEdit={cancelEdit} />;
  }
  return <CountryList loading={loading} countries={filteredCountries} searchText={searchText} setSearchText={setSearchText} />;
};
