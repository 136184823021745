import React from 'react';
import { useParams, useHistory } from 'react-router';
import { RiskAssessmentTemplatesList } from './RiskAssessmentTemplatesList';
import { RiskAssessmentTemplatesEdit } from './RiskAssessmentTemplatesEdit';
import { RiskAssessmentTemplateService } from '../../shared/services/riskAssessmentTemplateService';
import { Spin } from 'antd';
import { IRiskAssessmentTemplate } from '../../models/riskAssessment';
import { tryAsyncAction, useData } from '../../shared/masterDataHelpers';

export const RiskAssessmentTemplates: React.FC<{}> = React.memo((props) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { loading, data: templates } = useData<IRiskAssessmentTemplate[]>(id, RiskAssessmentTemplateService.getAllRiskAssessmentTemplates);
  const cancelEdit = (): void => history.goBack();

  const onSave = async (template: IRiskAssessmentTemplate) => {
    const saveAction = template.id ? RiskAssessmentTemplateService.editRiskAssessmentTemplate(template) : RiskAssessmentTemplateService.createRiskAssessmentTemplate(template);
    const result = await tryAsyncAction(saveAction);
    return result;
  };

  const onDelete = async (template: IRiskAssessmentTemplate) => {
    await tryAsyncAction(RiskAssessmentTemplateService.deleteRiskAssessmentTemplate(template));
    cancelEdit();
  };

  if (loading) {
    return <Spin></Spin>;
  }

  if (id) {
    return <RiskAssessmentTemplatesEdit id={id} onLoad={RiskAssessmentTemplateService.getAllRiskAssessmentTemplateForEditOrCreation} onSave={onSave} onDelete={onDelete} cancelEdit={cancelEdit} />;
  }
  return <RiskAssessmentTemplatesList loading={loading} templates={templates} />;
});

RiskAssessmentTemplates.displayName = 'RiskAssessmentTemplates';
