import { Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { useContext, useState, FC, useMemo } from 'react';
import * as yup from 'yup';
import { IMachineType, IMasterdataEditProps } from '../../models/masterdata';
import { FormLanguageTextInput } from '../../shared/FormComponents';
import { useTranslation } from 'react-i18next';
import { HtcCardWrapper, HtcCard } from '../../shared/HtcCardComponent';
import _ from 'lodash';
import { SaveAction, CancelAction, DeleteAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { UserInfoCtx } from '../../UserInfoContext';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import { ILanguage } from '../../models/LanguageSettings';
import { testRequiredLanguageFields } from '../../models/IMultiLanguageString';
import { TFunction } from 'i18next';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { hasChanges } from '../../shared/masterDataHelpers';
import machineTypesService from '../../shared/services/machineTypesService';
import { notifyError } from '../../shared/notificationHelper';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';

const navPaths = ['navigation.masterdata', { name: 'navigation.machineTypes', linkTo: '/machineTypes' }];
export const MachineTypeEditFormComponent: FC<IMachineTypeEditFormProps> = ({ loading, machineType, onDelete, onSave, cancelEdit, machineTypeParentId }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const settings = ctx.languageSettings;
  const machineTypeClone = useMemo(() => _.cloneDeep(machineType), [machineType]);
  const [validationScheme] = useState(getValidationScheme(t, settings.getDefaultLanguage()));

  const isNew = !machineType.id;
  const nav = Object.assign([], navPaths);
  nav.push(ctx.t(machineType.name));
  const handleDelete = () => onDelete(machineType);
  const handleSave = async (machineType: IMachineType, actions: FormikHelpers<IMachineType>) => {
    const validationErrors = await machineTypesService.isUnique(machineType);

    if (!validationErrors.hasErrors()) {
      try {
        machineType.parentId = machineTypeParentId;
        await onSave(machineType);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  return (
    <ContentWrapper>
      <Formik enableReinitialize={true} initialValues={machineTypeClone} onSubmit={handleSave} validationSchema={validationScheme}>
        {(formik) => {
          const { submitForm, values } = formik;
          return (
            <>
              <RiskPrompt active={hasChanges(machineType, values)} />
              <CommandBarV2 paths={nav}>
                {!isNew && <DeleteAction onClick={handleDelete} />}
                <CancelAction onClick={cancelEdit} />
                <SaveAction onClick={submitForm} />
              </CommandBarV2>
              <HtcCardWrapper>
                <HtcCard flex>
                  <LanguageTabPage>
                    {(lp) => (
                      <FormBox title={t('masterdata.machineTypes.editform.details')} flex contentflex>
                        <Form layout="vertical">
                          <FormLanguageTextInput {...formik} name="name" label={t('masterdata.machineTypes.name')} selectedLanguage={lp.lang} />
                        </Form>
                      </FormBox>
                    )}
                  </LanguageTabPage>
                </HtcCard>
              </HtcCardWrapper>
            </>
          );
        }}
      </Formik>
    </ContentWrapper>
  );
};

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  return yup.object().shape<any>({
    name: testRequiredLanguageFields(50, defaultLanguage, t),
  });
}

interface IMachineTypeEditFormProps extends IMasterdataEditProps<IMachineType> {
  machineType: IMachineType;
  machineTypeParentId: string;
}
