import { Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { FC, useContext, useState, useMemo } from 'react';
import * as yup from 'yup';
import { IIncoterm, IMasterdataEditProps } from '../../models/masterdata';
import { FormLanguageTextInput } from '../../shared/FormComponents';
import { useTranslation } from 'react-i18next';
import { HtcCard, HtcCardWrapper } from '../../shared/HtcCardComponent';
import { SaveAction, CancelAction, DeleteAction } from '../../shared/components/CommandBar/CommandBar';
import FormBox from '../../shared/components/FormBox/FormBox';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import { getForLanguage, testRequiredLanguageFields, testLanguageStringMaxLength } from '../../models/IMultiLanguageString';
import { UserInfoCtx } from '../../UserInfoContext';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import { ILanguage } from '../../models/LanguageSettings';
import _ from 'lodash';
import { TFunction } from 'i18next';
import { RiskPrompt } from '../../shared/components/RiskPrompt/RiskPrompt';
import { hasChanges } from '../../shared/masterDataHelpers';
import styles from './IncotermsEditFormComponent.module.css';
import incotermService from '../../shared/services/incotermService';
import { notifyError } from '../../shared/notificationHelper';
import { MasterdataHeader } from '../../shared/components/MasterdataHeader/MasterdataHeader';
import { CommandBarV2 } from '../../shared/components/CommandBar/CommandBarV2';

const navPaths = ['navigation.masterdata', { name: 'navigation.incoterms', linkTo: '/incoterms' }];

export const IncotermsEditFormComponent: FC<IncotermsEditFormProps> = ({ cancelEdit, incoterm, onDelete, onSave }) => {
  const ctx = useContext(UserInfoCtx);
  const [t] = useTranslation();
  const settings = ctx.languageSettings;
  const incotermClone = useMemo(() => _.cloneDeep(incoterm), [incoterm]);
  const [validationScheme] = useState(getValidationScheme(t, settings.getDefaultLanguage()));

  const isNew = !incoterm.id;
  const nav = Object.assign([], navPaths);
  nav.push(getForLanguage(incoterm.code, settings));
  const handleDelete = () => onDelete(incoterm);
  const handleSave = async (incoterm: IIncoterm, actions: FormikHelpers<IIncoterm>) => {
    const validationErrors = await incotermService.isUnique(incoterm);
    if (!validationErrors.hasErrors()) {
      try {
        await onSave(incoterm);
        actions.setSubmitting(false);
      } catch (error) {
        actions.setSubmitting(false);
        actions.setErrors(error.toString() as any);
      }
    } else {
      notifyError(validationErrors.getFormattedErrors(t));
    }
  };

  return (
    <ContentWrapper>
      <Formik enableReinitialize={true} initialValues={incotermClone} onSubmit={handleSave} validationSchema={validationScheme}>
        {(formik) => {
          const { submitForm, isSubmitting, values } = formik;
          return (
            <>
              <RiskPrompt active={hasChanges(incoterm, values)} />
              <MasterdataHeader>
                <CommandBarV2 paths={nav}>
                  {!isNew && <DeleteAction onClick={handleDelete} />}
                  <CancelAction disabled={isSubmitting} onClick={cancelEdit} />
                  <SaveAction disabled={isSubmitting} onClick={submitForm} />
                </CommandBarV2>
              </MasterdataHeader>
              <HtcCardWrapper>
                <LanguageTabPage>
                  {(lp) => (
                    <HtcCard flex>
                      <FormBox title={t('masterdata.incoterms.editform.details')} flex contentflex>
                        <Form layout="vertical">
                          <FormLanguageTextInput {...formik} name="code" label={t('masterdata.incoterms.code')} selectedLanguage={lp.lang} canEditMultiLanguage={false} />
                          <FormLanguageTextInput {...formik} name="description" label={t('masterdata.incoterms.description')} selectedLanguage={lp.lang} />
                          <FormLanguageTextInput {...formik} name="placeToSpecify" label={t('masterdata.incoterms.placeToSpecifiy')} selectedLanguage={lp.lang} />
                        </Form>
                      </FormBox>
                    </HtcCard>
                  )}
                </LanguageTabPage>
              </HtcCardWrapper>
            </>
          );
        }}
      </Formik>
    </ContentWrapper>
  );
};

function getValidationScheme(t: TFunction, defaultLanguage: ILanguage) {
  return yup.object().shape<any>({
    code: testRequiredLanguageFields(50, defaultLanguage, t),
    description: testRequiredLanguageFields(50, defaultLanguage, t),
    placeToSpecifiy: testLanguageStringMaxLength(50, t),
  });
}

interface IncotermsEditFormProps extends IMasterdataEditProps<IIncoterm> {
  incoterm: IIncoterm;
}
