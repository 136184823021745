import { IUserTenantInfo, ITenantInfo } from '../masterdata/ITenant';
import { IUserSetting } from '../masterdata';
import { UserPermissions } from './UserPermissions';
import { IUserInfoResult, IUserClaim } from './models';
import _ from 'lodash';

export class UserInfo {
  subjectId: string;
  name: string;
  availableTenants: IUserTenantInfo[];
  allTenants: ITenantInfo[];
  claims: IUserClaim[];
  userSetting: IUserSetting;
  permissions: UserPermissions;

  public clone(): UserInfo {
    return _.assign(new UserInfo(), _.cloneDeep(this));
  }
  public static from(infoResult: IUserInfoResult): UserInfo {
    return _.assign(new UserInfo(), infoResult, {
      permissions: UserPermissions.from(infoResult.claims, getActiveTenantIdFromSettings(infoResult.userSetting), getActiveBranchOfIndustryIdFromSettings(infoResult.userSetting)),
    });
  }
}

export function getActiveTenantIdFromSettings(settings: IUserSetting) {
  return settings && settings.activeTenantId;
}

export function getActiveBranchOfIndustryIdFromSettings(settings: IUserSetting) {
  const tenantId = getActiveTenantIdFromSettings(settings);
  if (!tenantId || !settings.activeBranchOfIndustryForTenantMap) {
    return '';
  }

  return settings.activeBranchOfIndustryForTenantMap[tenantId] || '';
}

export function getDefaultLanguageKeyForActiveBranchOfIndustry(settings: IUserSetting) {
  const branchId = getActiveBranchOfIndustryIdFromSettings(settings);
  if (!branchId || !settings.defaultLanguagesForBranchOfIndustriesMap) {
    return '';
  }

  return settings.defaultLanguagesForBranchOfIndustriesMap[branchId] || '';
}
