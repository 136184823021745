import * as _ from 'lodash';
import { RiskAppBranchId } from '../constants';
import { ILanguage } from '../LanguageSettings';

export interface IUserSetting {
  subjectId: string;
  activeTenantId: string;
  activeBranchOfIndustryForTenantMap: { [idx: string]: string };
  selectedLanguage: ILanguage;
  defaultLanguagesForBranchOfIndustriesMap: { [idx: string]: string };
}
export function settingsWithDefaults(options: Partial<IUserSetting> = {}): IUserSetting {
  return _.defaults(_.omitBy(options, _.isNull), { subjectId: '', activeTenantId: '', activeBranchOfIndustryForTenantMap: {}, selectedLanguage: null, defaultLanguagesForBranchOfIndustriesMap: {} });
}

export interface IEnsureUserSettingCommand {
  subjectId: string;
  tenantId: string;
  branchOfIndustryId: string;
  selectedLanguage: ILanguage;
}

export interface IEnsureTenantBranchDefaultCommand {
  subjectId: string;
  tenantId: string;
  branchOfIndustryId: string;
}

export interface IEnsureUserLanguageCommand {
  subjectId: string;
  selectedLanguage: ILanguage;
}
