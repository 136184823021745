import React from 'react';
import { Button, Form } from 'antd';
import * as yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import { Formik, FormikHelpers } from 'formik';

import Panel from '../../../shared/components/Panel/Panel';
import FormBox from '../../../shared/components/FormBox/FormBox';
import { FormTextInput, FormCountryPicker, FormCustomerPicker, FormLanguageTextInput } from '../../../shared/FormComponents';
import { ICustomer, createCustomer } from '../../../models/masterdata';
import { UserInfoCtx } from '../../../UserInfoContext';
import LanguageTabPage from '../../../shared/components/LanguageTabPage/LanguageTabPage';
import { HtcCard } from '../../../shared/HtcCardComponent';
import { SaveOutlined } from '@ant-design/icons';

class CustomerCreationPanel<T> extends React.Component<ICustomerCreationPanelProps, {}> {
  render() {
    const { t, hasDescription } = this.props;
    return (
      <Panel label={this.props.label} drawerVisible={this.props.isOpen == true} onClose={() => this.props.onClose()}>
        <Formik enableReinitialize={true} onSubmit={this.onSubmit} initialValues={createCustomer()} validationSchema={getCustomerValidationScheme()}>
          {(formik) => {
            const { isSubmitting, submitForm } = formik;
            return (
              <>
                <LanguageTabPage>
                  {(lp) => (
                    <HtcCard flex>
                      <FormBox title={t('masterdata.customers.editform.details')} flex contentflex>
                        <Form layout="vertical">
                          <FormTextInput {...formik} name="debitorNumber" label={t('masterdata.customers.debitorNumber')} />
                          {hasDescription && <FormLanguageTextInput {...formik} name="description" label={t('masterdata.customers.description')} selectedLanguage={lp.lang} />}
                          <FormLanguageTextInput {...formik} name="debitorName" label={t('masterdata.customers.debitorName')} selectedLanguage={lp.lang} />
                          <FormCountryPicker {...formik} name="country" label={t('masterdata.customers.country')} selectedLanguage={lp.lang} />
                          <FormCustomerPicker {...formik} name="companyGroup" label={t('masterdata.customers.companyGroup')} onChange={() => {}} selectedLanguage={lp.lang} defaultIfSingle={false} />
                        </Form>
                      </FormBox>
                    </HtcCard>
                  )}
                </LanguageTabPage>
                <Button id="saveAction" icon={<SaveOutlined />} style={{ marginBottom: 25 }} onClick={submitForm} disabled={isSubmitting} type="primary" data-automation-id="saveAction">
                  {t('commonActions.save')}
                </Button>
              </>
            );
          }}
        </Formik>
      </Panel>
    );
  }

  onSubmit = (customer: ICustomer, actions: FormikHelpers<ICustomer>) => {
    this.props.onSaveCustomer(customer);
    actions.resetForm();
  };
}

const getCustomerValidationScheme = () => {
  return yup.object().shape<any>({
    debitorNumber: yup.string().required(),
  });
};

CustomerCreationPanel.contextType = UserInfoCtx;
export default withTranslation()(CustomerCreationPanel);

interface ICustomerCreationPanelProps extends WithTranslation {
  isOpen: boolean;
  label: string;
  hasDescription: boolean;
  onSaveCustomer: (customer: ICustomer) => void;
  onClose: () => void;
}
