import React, { PureComponent } from 'react';
import { IRiskAssessmentEditVm, IRiskAssessment } from '../../models/riskAssessment';
import { Spin } from 'antd';
import { RiskAssessmentUpdates } from '../../models/riskAssessment/RiskAssessmentUpdates';
import RiskAssessmentEdit from './RiskAssessmentEdit';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import riskAssessmentService from '../../shared/services/riskAssessmentService';
import { notifySaveSuccess } from '../../shared/notificationHelper';
import { UserInfoCtx, IUserInfoCtx } from '../../UserInfoContext';
import reportService from '../../shared/services/reportService';
import { RiskAssessmentReportTypes } from './reporting/model';
import { IEnsureRiskAssessmentDocument, IUploadRiskAssessmentDocuments } from '../../models/riskAssessment/IEnsureRiskAssessmentDocument';
import { downloadFileBlob } from '../components/DocumentList/DocumentHelper';

class RiskAssessmentRaw extends PureComponent<IRiskAssessmentProps, IRiskAssessmentState> {
  state: IRiskAssessmentState = {
    riskAssessmentVm: null,
    isLoading: false,
  };

  componentDidMount() {
    const { match } = this.props;
    this.loadRiskAssessment(match.params.riskAssessmentId);
  }

  componentDidUpdate(prevProps: IRiskAssessmentProps) {
    const { match } = this.props;
    if (prevProps.match.params.riskAssessmentId !== match.params.riskAssessmentId) {
      this.loadRiskAssessment(match.params.riskAssessmentId);
    }
  }

  render() {
    const { t } = this.props;
    const { riskAssessmentVm, isLoading } = this.state;

    if (isLoading || riskAssessmentVm == null) {
      return <Spin tip={t('riskAssessment.notifications.load_assessment')} />;
    }

    return (
      <>
        <RiskAssessmentEdit
          riskAssessmentVm={riskAssessmentVm}
          onSave={this.onSave}
          onEnsureDocument={this.onEnsureDocument}
          onUploadDocuments={this.onUploadDocuments}
          getEvaluationHistory={riskAssessmentService.getEvaluationHistory}
          generateReport={this.generateReport}
        />
      </>
    );
  }

  onSave = async (updates: RiskAssessmentUpdates): Promise<void> => {
    if (!updates.hasUpdates) {
      return;
    }

    return new Promise((resolve) => {
      const updateAndNotify = async () => {
        const newAssessment = await riskAssessmentService.updateRiskAssessment(updates);
        this.setState({ riskAssessmentVm: newAssessment }, () => {
          notifySaveSuccess();
          resolve();
        });
      };
      updateAndNotify();
    });
  };

  onEnsureDocument = async (data: IEnsureRiskAssessmentDocument): Promise<void> => {
    this.setState({ isLoading: true });
    try {
      const result = await riskAssessmentService.uploadDocument(data);
      this.setState({ riskAssessmentVm: result });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onUploadDocuments = async (data: IUploadRiskAssessmentDocuments): Promise<void> => {
    this.setState({ isLoading: true });
    try {
      const result = await riskAssessmentService.uploadDocuments(data);
      this.setState({ riskAssessmentVm: result });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  loadRiskAssessment = async (idOrToken: string): Promise<void> => {
    this.setState({ isLoading: true });
    const result = await riskAssessmentService.loadRiskAssessment(idOrToken);
    this.setState({ riskAssessmentVm: result, isLoading: false });
  };

  generateReport = async (reportData: RiskAssessmentReportTypes, riskAssessment: IRiskAssessment, tenantId: string, branchId: string): Promise<void> => {
    const originalAssessment = this.state.riskAssessmentVm?.assessment;
    const langKey = (this.context as IUserInfoCtx).getActiveLanguageKey();
    await this.onSave(RiskAssessmentUpdates.from(originalAssessment, riskAssessment));

    const report = await reportService.createReport(originalAssessment.token, langKey, reportData, tenantId, branchId);
    if (!report) {
      console.error('GENERATE REPORT', reportData);
      return;
    }

    if (report.type === 'application/pdf') {
      downloadFileBlob(report, reportData.getDownloadFileName());
    }
  };
}

RiskAssessmentRaw.contextType = UserInfoCtx;

interface IRiskAssessmentProps extends WithTranslation, RouteComponentProps<{ riskAssessmentId: string }> {}

interface IRiskAssessmentState {
  riskAssessmentVm?: IRiskAssessmentEditVm;
  isLoading: boolean;
}

export const RiskAssessment = withTranslation()(RiskAssessmentRaw);
