import * as _ from 'lodash';
import { IMachineType, IMachineTypeRef, ITreeOptionNode } from '../../models/masterdata';
import HttpClient from './httpClient';
import LRU from 'lru-cache';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';
import { LanguageSettings } from '../../models/LanguageSettings';
import { queryMasterdataOptions } from './masterdataQueryHelper';
import { UniqueValidation, IUniqueValidationErrorResult } from '../../models/IUniqueValidation';
import { IUserInfoCtx } from '../../UserInfoContext';

interface IMachineTypeResponse {
  machineTypes: IMachineType[];
}

const cacheOptions = { maxAge: 1000 * 60 * 60 };
const cache = new LRU<string, ITreeOptionNode[]>(cacheOptions);

const createMachineType = async (item: IMachineType): Promise<IMachineType> => {
  const result = await HttpClient.getInstance().post<IMachineTypeResponse>(`machinetypes`, item);
  const response = getMachineTypesFromResponse(result.data);
  return response[0];
};

const editMachineType = async (item: IMachineType): Promise<IMachineType> => {
  const result = await HttpClient.getInstance().put<IMachineTypeResponse>(`machinetypes/${item.id}`, item);
  const response = getMachineTypesFromResponse(result.data);
  return response[0];
};

const deleteMachineType = async (item: IMachineType): Promise<IMachineType> => {
  const result = await HttpClient.getInstance().delete(`machinetypes/${item.id}`);
  const response = getMachineTypesFromResponse(result.data);
  return item;
};

const getAllMachineTypes = async (): Promise<IMachineType[]> => {
  const result = await HttpClient.getInstance().get<IMachineTypeResponse>(`machinetypes`);
  return getMachineTypesFromResponse(result.data);
};

function getMachineTypesFromResponse(response: IMachineTypeResponse) {
  return response.machineTypes;
}

const isUnique = async (machineType: IMachineType): Promise<UniqueValidation> => {
  const result = await HttpClient.getInstance().post<IUniqueValidationErrorResult>(`/machinetypes/isunique`, machineType);
  return new UniqueValidation(result.data.validationErrors);
};

async function getMachineTypesTreeOptions(tenantId: string, branchId: string) {
  let cacheKey = 'machineTypesTreeOptions' + tenantId + '_' + branchId;
  let items = cache.get(cacheKey);
  if (items) {
    return items;
  }

  const result = await HttpClient.getInstance().get<IMachineTypesTreeResponse>(`machinetypes/optionstree?tenantId=${tenantId}&branchId=${branchId}`);
  items = result.data.options;
  cache.set(cacheKey, items);
  return items;
}

function getTreeData(options: ITreeOptionNode[], ctx: IUserInfoCtx) {
  if (!options) {
    return { tree: [], allNodes: [] };
  }

  let allNodes = [];

  let tree = processTreeNodes(options, ctx, '', allNodes);
  return { tree, allNodes };
}

function processTreeNodes(nodes: ITreeOptionNode[], ctx: IUserInfoCtx, titlePrefix: string, allNodes: ITreeOptionNode[]) {
  let treeNodes = _.map(nodes, (node) => {
    allNodes.push(node);
    let nodeLabel = ctx.t(node.label);

    return {
      key: node.id,
      title: node.isLeaf ? titlePrefix + nodeLabel : nodeLabel,
      value: node.value.id,
      idRef: node.value,
      selectable: node.isLeaf,
      children: node.isLeaf ? null : processTreeNodes(node.children, ctx, titlePrefix + `${nodeLabel} / `, allNodes),
    };
  });

  return _.sortBy(treeNodes, (t) => t.title);
}

interface IMachineTypesTreeResponse {
  hasOptions: boolean;
  options: ITreeOptionNode[];
}

export default {
  createMachineType,
  getAllMachineTypes,
  editMachineType,
  deleteMachineType,
  getMachineTypesTreeOptions,
  isUnique,
  getTreeData,
};
