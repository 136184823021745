import * as actions from './actions';
import _ from 'lodash';
import { ActionType, isActionOf } from 'typesafe-actions';
import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { IRootState, getUserInfo, getUserSetting } from '../ducks/reducer';
import { UserInfo } from '../models/user/UserInfo';
import { ILanguage } from '../models/LanguageSettings';
import { IUserSetting } from '../models/masterdata';
import i18next from 'i18next';
import { userService } from '../shared/services/userService';
type Action = ActionType<typeof actions>;

export const setActiveBranchFlow: Epic<Action, Action, IRootState> = (action$, state) =>
  action$.pipe(
    filter(isActionOf(actions.selectBranchOfIndustrySettingForUser.request)),
    switchMap((action) =>
      from(setActiveBranchOfIndustryState(action.payload, state.value)).pipe(
        map(actions.selectBranchOfIndustrySettingForUser.success),
        catchError((error) => of(actions.selectBranchOfIndustrySettingForUser.failure(error))),
      ),
    ),
  );

export const setActiveLanguageFlow: Epic<Action, Action, IRootState> = (action$, state) =>
  action$.pipe(
    filter(isActionOf(actions.selectLanguageForUser.request)),
    switchMap((action) =>
      from(setActiveLanguage(action.payload, state.value)).pipe(
        map(actions.selectLanguageForUser.success),
        catchError((error) => of(actions.selectLanguageForUser.failure(error))),
      ),
    ),
  );

const setActiveBranchOfIndustryState = async (branchOfIndustrySetting: IBranchOfIndustrySetting, state: IRootState): Promise<UserInfo> => {
  let userInfo = getUserInfo(state.app);
  const userSetting = getUserSetting(state.app);
  if (
    (userSetting.activeBranchOfIndustryForTenantMap || {})[userSetting.activeTenantId] == branchOfIndustrySetting.branchOfIndustryId &&
    !hasLanguageChanged(branchOfIndustrySetting.language, userSetting)
  ) {
    return userInfo;
  } else {
    const updatedUserSetting = await userService.ensureTenantBranchDefault({
      subjectId: userService.getCurrentUser().profile.sub,
      tenantId: userSetting.activeTenantId,
      branchOfIndustryId: branchOfIndustrySetting.branchOfIndustryId,
    });

    userInfo = userInfo.clone();
    userInfo.userSetting = updatedUserSetting;
  }
  window.location.reload();

  return userInfo;
};

const setActiveLanguage = async (branchOfIndustrySetting: IBranchOfIndustrySetting, state: IRootState): Promise<UserInfo> => {
  let userInfo = getUserInfo(state.app);
  const userSetting = getUserSetting(state.app);
  if (!hasLanguageChanged(branchOfIndustrySetting.language, userSetting)) {
    return userInfo;
  } else {
    const updatedUserSetting = await userService.ensureUserLanguage({
      subjectId: userService.getCurrentUser().profile.sub,
      selectedLanguage: branchOfIndustrySetting.language,
    });
    userInfo = userInfo.clone();
    userInfo.userSetting = updatedUserSetting;
    i18next.changeLanguage(branchOfIndustrySetting.language.key);
  }
  window.location.reload();

  return userInfo;
};

const hasLanguageChanged = (newLanguage: ILanguage, userSetting: IUserSetting) => {
  if (userSetting.selectedLanguage == null) {
    return true;
  }
  return newLanguage.key != userSetting.selectedLanguage.key;
};
export const branchSelectionEpics = combineEpics(setActiveBranchFlow, setActiveLanguageFlow);

export interface IBranchOfIndustrySetting {
  branchOfIndustryId: string;
  language: ILanguage;
}
