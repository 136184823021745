import { Button, Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React, { PureComponent } from 'react';
import * as yup from 'yup';
import { ICriterion, createCriterion, getRiskTypeOptions } from '../../models/masterdata';
import { FormSelectInput, FormLanguageTextInput } from '../../shared/FormComponents';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserInfoCtx } from '../../UserInfoContext';
import { ILanguage } from '../../models/LanguageSettings';
import LanguageTabPage from '../../shared/components/LanguageTabPage/LanguageTabPage';
import _ from 'lodash';
import { testRequiredLanguageFields } from '../../models/IMultiLanguageString';

class CriterionEditFormComponent extends PureComponent<ICriterionEditFormProps> {
  private validationScheme: yup.ObjectSchema<any>;
  constructor(props: ICriterionEditFormProps, context: any) {
    super(props, context);
    this.validationScheme = this.getValidationScheme(props.t, context.languageSettings.getDefaultLanguage());
  }
  public render() {
    const { t } = this.props;
    const riskTypeOptions = getRiskTypeOptions(t);

    return (
      <Form layout="vertical">
        <Formik enableReinitialize={true} initialValues={this.props.criterion ? this.props.criterion : createCriterion()} onSubmit={this.handleSave} validationSchema={this.validationScheme}>
          {(formik) => {
            const { submitForm, isSubmitting } = formik;
            return (
              <LanguageTabPage>
                {(lp) => (
                  <Form layout="vertical">
                    <FormLanguageTextInput {...formik} name="description" label={t('masterdata.topics.criterion.description')} selectedLanguage={lp.lang} />
                    <FormSelectInput {...formik} name="risk" label={t('masterdata.topics.criterion.risk')} options={riskTypeOptions} />
                    <Form.Item>
                      <Button disabled={isSubmitting} type="primary" onClick={submitForm}>
                        {t('masterdata.topics.criterion.saveButton')}
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </LanguageTabPage>
            );
          }}
        </Formik>
      </Form>
    );
  }

  private handleSave = (criterion: ICriterion, actions: FormikHelpers<ICriterion>) => {
    try {
      this.props.onSave(criterion);
      actions.resetForm();
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors(error.toString() as any);
    }
  };
  private getValidationScheme(t: any, defaultLanguage: ILanguage) {
    return yup.object().shape<any>({
      key: yup.string().max(50).required(),
      description: testRequiredLanguageFields(50, defaultLanguage, t),
      risk: yup.mixed().required(),
    });
  }
}

CriterionEditFormComponent.contextType = UserInfoCtx;
export default withTranslation()(CriterionEditFormComponent as any);

interface ICriterionEditFormProps extends WithTranslation {
  criterion: ICriterion;
  onSave: (criterion: ICriterion) => void;
}
