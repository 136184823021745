import * as yup from 'yup';
import moment from 'moment';
import {
  IRiskAssessment,
  RecursivePartial,
  IRiskAssessmentCalculation,
  IRiskAssessmentMetadata,
  RiskAssessmentStatus,
  IRiskAssessmentEvaluation,
  IRiskAssessmentComments,
  IApprovalFlowStage,
  RiskAssessmentComment,
} from './IRiskAssessment';
import { TFunction } from 'i18next';
import riskAssessmentService from '../../shared/services/riskAssessmentService';
import _ from 'lodash';

function getValidatorForSaveMetaData(status: RiskAssessmentStatus) {
  let rules: any = {
    offerNr: yup.string().required('metadata.offerNr_required'),
    city: yup.string().required('metadata.city_required'),
    vendor: yup.string().required('metadata.vendor_required'),
    conditionsOfPayment: yup.string().nullable().required('metadata.conditionsOfPayment_required'),
    internalComment: yup.string().nullable().required('metadata.internalComment_required'),
    customer: getRefShape(),
    country: getRefShape(),
    machineType: getRefShape(),
    incoterms: getRefShape(),
  };

  if (needsToValidateDueDate(status)) {
    rules.dueDate = yup.date().typeError('metadata.dueDate_required').required('metadata.dueDate_required').min(getYesterday(), 'metadata.dueDate_min') as any;
  }
  return yup.object().shape<any>(rules);
}

export function getYesterday() {
  return moment(new Date()).add(-1, 'days').toDate();
}

function needsToValidateDueDate(status: RiskAssessmentStatus): boolean {
  switch (status) {
    case RiskAssessmentStatus.Created:
      return true;
  }
  return false;
}

export function getValidatorForOfferNumber(riskassessment: IRiskAssessment, t: TFunction) {
  const { id, tenantId, branchId } = riskassessment;

  return yup.object().shape<any>({
    offerNr: yup.string().test('Unique Offer No.', 'metadata.offerNoMustBeUnique', async (value: string): Promise<boolean> => {
      return await riskAssessmentService.isOfferNoValid(tenantId, branchId, value, id);
    }),
  });
}

function getValidatorForSaveCalculation() {
  return yup
    .object()
    .nullable()
    .shape<any>({
      machineTypeTotal: yup.number().typeError('calculation.machineTypeTotal_required').required('calculation.machineTypeTotal_required').min(0),
      machineTypeDb3MinPercent: yup.number().typeError('calculation.machineTypeDb3MinPercent_required').required('calculation.machineTypeDb3MinPercent_required').min(0),
      machineTypeDb3OfferPercent: yup.number().typeError('calculation.machineTypeDb3OfferPercent_required').required('calculation.machineTypeDb3OfferPercent_required').min(0),
    });
}

function getValidatorForShareForCommunication(status: RiskAssessmentStatus) {
  return yup.object().shape<any>({
    metadata: getValidatorForSaveMetaData(status),
    calculation: getValidatorForSaveCalculation(),
  });
}

function getValidatorForCompleteEvaluation() {
  return yup.object().shape<any>({
    appraisal: yup
      .object()
      .shape<any>({
        comments: yup
          .mixed()
          .required()
          .test('has-a-comment', 'evaluation.appraisal_required', (comments: RiskAssessmentComment[]) => {
            if (!comments || !comments[0]) {
              return false;
            }

            let newest = _.trim(comments[0].content);
            if (!newest) {
              return false;
            }

            return true;
          }),
      })
      .required()
      .typeError('evaluation.appraisal_required')
      .required('evaluation.appraisal_required'),
  });
}

function getRefShape() {
  return yup.object().nullable().shape({ id: yup.string().required() });
}

const alwaysValid = yup.object().shape<any>({});

function getValidatorForStatusTransition(from: RiskAssessmentStatus, to: RiskAssessmentStatus) {
  if (to === RiskAssessmentStatus.Assessment) {
    return getValidatorForShareForCommunication(from);
  }
  return alwaysValid;
}

function getValidatorForStatusSave(status: RiskAssessmentStatus) {
  if (status == RiskAssessmentStatus.Created) {
    return alwaysValid;
  }
  return getValidatorForShareForCommunication(status);
}

function canShareForCommunication(currentStatus: RiskAssessmentStatus): boolean {
  switch (currentStatus) {
    case RiskAssessmentStatus.Created:
    case RiskAssessmentStatus.Assessment:
    case RiskAssessmentStatus.Evaluation:
      return true;
  }
  return false;
}

function canMoveToEvaluation(currentStatus: RiskAssessmentStatus): boolean {
  switch (currentStatus) {
    case RiskAssessmentStatus.Created:
    case RiskAssessmentStatus.Evaluation:
      return false;
  }
  return true;
}

function canMoveToNoProject(currentStatus: RiskAssessmentStatus): boolean {
  return currentStatus !== RiskAssessmentStatus.Rejected && currentStatus !== RiskAssessmentStatus.Approved;
}

function canMoveToApproved(currentStatus: RiskAssessmentStatus): boolean {
  return currentStatus === RiskAssessmentStatus.Evaluation;
}

const canCompleteAnalyis = (currentStage: IApprovalFlowStage) => {
  try {
    const validation = getValidatorForCompleteEvaluation().validateSync(currentStage, {
      abortEarly: false,
    });
  } catch (e) {
    return false;
  }
  return true;
};

export default {
  getValidatorForCompleteEvaluation,
  getValidatorForStatusTransition,
  getValidatorForStatusSave,
  getValidatorForSaveMetaData,
  canShareForCommunication,
  canMoveToEvaluation,
  canMoveToNoProject,
  canMoveToApproved,
  canCompleteAnalyis,
};
