import React from 'react';
import { RiskAssessmentUpdates } from '../../../models/riskAssessment/RiskAssessmentUpdates';
import { IRiskAssessment, IRiskAssessmentOnSaveCallback } from '../../../models/riskAssessment';
import { DocumentMetadataOwnerTypes, IDocumentMetadata } from '../../../models/documents/IDocumentMetaData';
import { IEnsureRiskAssessmentDocument, IUploadRiskAssessmentDocuments } from '../../../models/riskAssessment/IEnsureRiskAssessmentDocument';
import { IDocumentsUploadData } from '../../../models/documents/IDocumentUploadMetaData';
import _ from 'lodash';

const onCheckIn = async (
  onEnsureDocument: (data: IEnsureRiskAssessmentDocument) => void,
  metadata: IDocumentMetadata,
  file: any,
  riskAssessmentVm: IRiskAssessment,
  riskAssessment: IRiskAssessment,
) => {
  const updates = RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment);
  updates.checkInDocument(riskAssessmentVm.id, metadata.id, metadata.owner);
  await onEnsureDocument({
    tokenOrId: riskAssessment.id,
    metadata: metadata,
    file: file,
    updates: updates,
  });
};

const onCheckOut = (onSave: IRiskAssessmentOnSaveCallback, riskAssessmentVm: IRiskAssessment, riskAssessment: IRiskAssessment, metadata: IDocumentMetadata) => {
  const updates = RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment);
  updates.checkOutDocument(riskAssessmentVm.id, metadata.id, metadata.owner);
  onSave(updates);
};
const onRevertCheckOut = (onSave: IRiskAssessmentOnSaveCallback, riskAssessmentVm: IRiskAssessment, riskAssessment: IRiskAssessment, metadata: IDocumentMetadata) => {
  const updates = RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment);
  updates.revertCheckOutDocument(riskAssessmentVm.id, metadata.id, metadata.owner);
  onSave(updates);
};
const onArchive = (onSave: IRiskAssessmentOnSaveCallback, riskAssessmentVm: IRiskAssessment, riskAssessment: IRiskAssessment, metadata: IDocumentMetadata) => {
  const updates = RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment);
  updates.archiveDocument(riskAssessmentVm.id, metadata.id, metadata.owner);
  onSave(updates);
};
const onRevertArchive = (onSave: IRiskAssessmentOnSaveCallback, riskAssessmentVm: IRiskAssessment, riskAssessment: IRiskAssessment, metadata: IDocumentMetadata) => {
  const updates = RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment);
  updates.revertArchiveDocument(riskAssessmentVm.id, metadata.id, metadata.owner);
  onSave(updates);
};
const onDeleteDocument = (onSave: IRiskAssessmentOnSaveCallback, riskAssessmentVm: IRiskAssessment, riskAssessment: IRiskAssessment, metadata: IDocumentMetadata) => {
  const updates = RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment);
  updates.deleteDocument(riskAssessmentVm.id, metadata.id, metadata.owner);
  onSave(updates);
};

const onSaveDocument = async (
  onEnsureDocument: (data: IEnsureRiskAssessmentDocument) => void,
  metadata: IDocumentMetadata,
  file: any,
  riskAssessmentVm: IRiskAssessment,
  riskAssessment: IRiskAssessment,
) => {
  await onEnsureDocument({
    tokenOrId: riskAssessment.id,
    metadata: metadata,
    file: file,
    updates: RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment),
  });
};

const onUploadDocuments = async (onUploadDocuments: (data: IUploadRiskAssessmentDocuments) => void, riskAssessmentVm: IRiskAssessment, riskAssessment: IRiskAssessment, data: IDocumentsUploadData) => {
  const uploadData: IUploadRiskAssessmentDocuments = {
    tokenOrId: riskAssessment.id,
    updates: RiskAssessmentUpdates.from(riskAssessmentVm, riskAssessment),
    files: _.map(data.files, (f) => f.file),
    metadata: _.map(data.files, (f) => ({ comment: f.comment, fileName: f.fileName, isParaphrased: f.isParaphrased, owner: f.owner, documentType: f.documentType })),
  };

  await onUploadDocuments(uploadData);
};

export function downloadFileBlob(content: Blob, fileName: string): void {
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    // for IE
    (window.navigator as any).msSaveOrOpenBlob(content, fileName);
  } else {
    const href = window.URL.createObjectURL(content);
    if (isiOS()) {
      window.open(href, '_self');
      return;
    }
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.download = `${fileName}`;
    a.href = href;
    a.click();
    window.URL.revokeObjectURL(href);
    a.remove();
  }
}
export function downloadFileUrl(sUrl: string): boolean {
  if (!sUrl) {
    return;
  }
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
  // iOS devices do not support downloading. We have to inform user about this.
  if (isiOS()) {
    // alert('Your device does not support files downloading. Please try again in desktop browser.');
    window.open(sUrl, '_blank');
    return false;
  }

  // If in Chrome or Safari - download via virtual link click
  if (isChrome || isSafari) {
    // Creating new link node.
    const link = document.createElement('a');
    link.href = sUrl;
    link.setAttribute('target', '_blank');

    if (link.download !== undefined) {
      // Set HTML5 download attribute. This will prevent file from opening if supported.
      const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
      link.download = fileName;
    }

    // Dispatching click event.
    if (document.createEvent) {
      const e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      link.dispatchEvent(e);
      return true;
    }
  }

  // Force file download (whether supported by server).
  if (sUrl.indexOf('?') === -1) {
    sUrl += '?download';
  }

  window.open(sUrl, '_blank');
  return true;
}

function isiOS() {
  const ua = window.navigator.userAgent;
  if (/(iP)/g.test(ua)) {
    return true;
  }
  if (ua.indexOf('iPad') > -1) {
    return true;
  }

  if (ua.indexOf('Macintosh') > -1) {
    // WTF APPLE?! Seit iPadOS 13 werden alle iPads mit einem Standard Mac UserAgent zurück gemeldet. Weil "desktop class browsing" sales bullshit und so.
    // es bleiben also nur noch Workarounds um ein Touch Gerät zu erkennen
    // Achtung: Wenn doch mal Touch Macs erscheinen können hier false positives zurück kommen!
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {}
  }

  return false;
}

export default {
  onCheckIn,
  onCheckOut,
  onRevertCheckOut,
  onArchive,
  onRevertArchive,
  onDeleteDocument,
  onSaveDocument,
  onUploadDocuments,
  downloadFileUrl,
  downloadFileBlob,
};
