import React from 'react';
import { IPickerBaseState, IPickerBaseProps } from '../PickerBase/PickerBase';
import { IUserViewModelRef } from '../../../models/user';
import { userService } from '../../services/userService';
import StringPickerBase from '../PickerBase/StringPickerBase';

class UserPicker extends StringPickerBase<IUserViewModelRef> {
  static getDerivedStateFromProps(props: IPickerBaseProps<IUserViewModelRef>, state: IPickerBaseState<IUserViewModelRef>): Partial<IPickerBaseState<IUserViewModelRef>> {
    if ((state.value && (state.value as IUserViewModelRef).id) !== (props.value && (props.value as IUserViewModelRef).id)) {
      return {
        value: Object.assign({}, props.value),
      };
    }
    return null;
  }

  onQueryItems(query: string): Promise<IUserViewModelRef[]> {
    const onQueryItems = this.props.onQueryItems || userService.getUserOptions;
    return onQueryItems(query, this.props.tenantId, this.props.branchId);
  }
}

export default UserPicker;
