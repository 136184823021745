import React, { FC } from 'react';
import Truncate from 'react-truncate';
import styles from './EllipsisText.module.css';
import { Popover, Tooltip } from 'antd';
import _ from 'lodash';
import { EyeOutlined } from '@ant-design/icons';

export const EllipsisText: FC<{ title: string; text: string; hint?: string; lines?: number }> = ({ title, text, hint, lines = 1 }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        <Truncate lines={lines}>
          {_.split(text, '\n').map((line, i, arr) => {
            const l = <span key={i}>{line}</span>;
            if (i === arr.length - 1) {
              return l;
            } else {
              return [l, <br key={i + 'br'} />];
            }
          })}
        </Truncate>
      </div>
      {text && <EllipsisPopover title={title} hint={hint} text={text} />}
    </div>
  );
};

export const EllipsisPopover: React.FC<{ title?: string; hint?: string; text: string }> = ({ title, hint, text }) => {
  const content = (
    <div className={styles.contentwrapper}>
      <div>
        <p className={styles.content}>{text}</p>
      </div>
    </div>
  );

  const titleComp = (
    <>
      <span className={styles.title}>{title}</span>
      <span className={styles.hint}>{hint}</span>
    </>
  );
  return (
    <div className={styles.icon}>
      <Popover trigger="click" title={titleComp} content={content}>
        <Tooltip title={hint}>
          <EyeOutlined />
        </Tooltip>
      </Popover>
    </div>
  );
};
