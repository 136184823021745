import { BrowserRouter as Router } from 'react-router-dom';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { ActionType } from 'typesafe-actions';
import App from './App';

import * as actions from './ducks/actions';
import epics from './ducks/epics';
import rootReducers, { IRootState } from './ducks/reducer';
import SettingsService from './shared/services/settingsService';
import Exception from './shared/components/Exception/Exception';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { UserInfoCtx } from './UserInfoContext';
import { LanguageSettings, DefaultLanguages } from './models/LanguageSettings';
import _ from 'lodash';
import { getDefaultLanguageKeyForActiveBranchOfIndustry } from './models/user/UserInfo';
import { getForLanguage } from './models/IMultiLanguageString';
import numeral from 'numeral';
import { userService } from './shared/services/userService';
import { TenantBranchCtx } from './TenantBranchContext';
import { ConfigProvider } from 'antd';

numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 't',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (number) => {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});

// switch between locales
numeral.locale('de');

// TODO : Sobald Usersettings fertig sind muss das dort gesetzt werden
moment.locale('de');
type Action = ActionType<typeof actions>;

export const initialize = async () => {
  await SettingsService.init();
  const epicMiddleWare = createEpicMiddleware<Action, Action, IRootState>();
  const store = createStore(rootReducers, applyMiddleware(epicMiddleWare));
  epicMiddleWare.run(epics);
  const user = await userService.init(store);
  let rootContent = null;
  if (user) {
    const userInfoObject = userService.getUserInfoObject();
    const availableLanguages = SettingsService.getConfig().availableLanguages;
    const defaultLanguageKey = getDefaultLanguageKeyForActiveBranchOfIndustry(userInfoObject.userSetting);
    const languageSettings = LanguageSettings.from({
      selectedLanguage: userInfoObject.userSetting.selectedLanguage,
      defaultLanguage: _.find(availableLanguages, (l) => l.key == defaultLanguageKey),
    });
    await initI18n(languageSettings.getActiveLanguage().key);

    const userInfoValue = {
      userInfo: userInfoObject,
      languageSettings: languageSettings,
      t: (text, lang) => {
        return getForLanguage(text, languageSettings, lang);
      },
      getActiveLanguageKey: () => languageSettings?.getActiveLanguage()?.key,
    };

    const tenantBranchValue = {
      tenantId: userService.getActiveTenantId(),
      branchId: userService.getActiveBranchOfIndustryId(),
    };

    rootContent = (
      <ConfigProvider direction="ltr">
        <Provider store={store}>
          <UserInfoCtx.Provider value={userInfoValue}>
            <TenantBranchCtx.Provider value={tenantBranchValue}>
              <Router>
                <App collapsed={true} availableLanguages={availableLanguages} />
              </Router>
            </TenantBranchCtx.Provider>
          </UserInfoCtx.Provider>
        </Provider>
      </ConfigProvider>
    );
  } else if (userService.isLoggingIn()) {
    // rootContent = <Redirect />; // Logging in
  } else {
    rootContent = <Exception type={'500'} />; // <div>Nix gut. Server nicht da?</div>;
  }

  ReactDOM.render(rootContent, document.getElementById('root'));
};

export const initI18n = async (language: string) => {
  await i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: language,
      fallbackLng: DefaultLanguages.de_DE,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        queryStringParams: { v: process.env.REACT_APP_VERSION },
      },
      react: {
        wait: true,
      },
      debug: true,
    });
};
