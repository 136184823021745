import React, { CSSProperties } from 'react';
import { CommandBarNavTypes } from '../CommandBar/CommandBar';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const defaultStyle: CSSProperties = { fontSize: '1rem', fontWeight: 300, textTransform: 'uppercase', margin: 'auto' };
export const PathBreadcrumb: React.FC<{ paths?: CommandBarNavTypes[]; translatePaths?: boolean; style?: CSSProperties }> = React.memo(({ paths, translatePaths, style }) => {
  const [t] = useTranslation();
  const mergeStyle = _.assign({}, defaultStyle, style);
  return (
    <Breadcrumb style={mergeStyle}>
      {_.map(paths, (p) => {
        if (typeof p === 'string') {
          return <Breadcrumb.Item key={p}>{translatePaths ? t(p) : p}</Breadcrumb.Item>;
        }

        const name = p.name;
        return (
          <Breadcrumb.Item key={name}>
            <Link to={p.linkTo}>{translatePaths ? t(name) : name}</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
});

PathBreadcrumb.displayName = 'PathBreadcrumb';
